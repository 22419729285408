import React, {useCallback, useEffect} from 'react';
import {AddressBookForm} from "@fik/components";
import {useEntityAction, usePureEntity} from "@fik/entity";

const AddressBook = () => {

    // contact to edit
    const [{entity: addressBook}, load] = usePureEntity({name: 'resellerProfile', params: {action: 'address-book'}});

    // save
    const [{isSuccess}, save] = useEntityAction({name: 'resellerProfile', action: 'saveAddressBook'});

    //- load on first render (load callback should not change)
    useEffect(() => load(), [load]);

    //- refresh after save
    useEffect(() => {
        if (isSuccess) {
            load();
        }
    }, [load, isSuccess]);

    const handleSubmit = useCallback((values) => {
        save({values});
    }, [save]);

    if (!addressBook) {
        return null;
    }

    return <AddressBookForm addressBook={addressBook} onSubmit={handleSubmit}/>;
};

export default AddressBook;