import React from 'react';
import {Address} from '@fik/ui';
import PropTypes from "prop-types";

const AddressBook = ({name, ...props}) => {
    return (<React.Fragment>
        <span>{name}</span><br />
        <Address {...props}/>
    </React.Fragment>);
};

AddressBook.propTypes = {
    name: PropTypes.string,
    street: PropTypes.string,
    zipCode: PropTypes.string,
    city: PropTypes.string
};

export default AddressBook;