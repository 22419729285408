import React from "react";
import PropTypes from 'prop-types';
import {LoadingContext} from "./../LoadingContext";
import useLoading from "./../useLoading";

const LoadingProvider = (props) => {
    const loading = useLoading();

    if (props.children && typeof props.children === 'function') {
        props = {...props, children: props.children(loading.isLoading)};
    }
    return <LoadingContext.Provider value={loading} {...props}/>;
};

LoadingProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func, PropTypes.string])
};

export default LoadingProvider;
