import React, {useState, useCallback} from 'react';
import {Redirect} from "react-router-dom";
import {RenewForm} from "@fik/components";
import {useNavigation} from "@fik/navigation";

const RenewLicenseShortcut = ({box = true}) => {
    const [result, setResult] = useState(null);
    const {routes} = useNavigation();

    const handleSubmit = useCallback((result) => {
        setResult(result);
    }, [setResult]);

    if (result && result.identificator && result.idType) {
        return <Redirect to={`${routes.order.create.path}?${result.idType}=${encodeURIComponent(result.identificator)}`}/>
    }

    return <RenewForm onSubmit={handleSubmit}/>
};

export default RenewLicenseShortcut;