import React from 'react';

const ErrorSeverityMessage = ({message}) => <div className={"p-w-xs m-xs alert alert-"+(message.severity || "info")}>
    <p>
        {message.text}
    </p>
</div>;

const ErrorTypeMessage = ({message}) => <div className={"p-w-xs m-xs alert alert-"+(message.type || "info")}>
    <p>
        {message.message}
    </p>
</div>;

const Errors = ({messages, className}) => {
    return <div className={className}>{messages.map((message, index) => (message.text ? <ErrorSeverityMessage key={index} message={message}/> : <ErrorTypeMessage key={index} message={message}/>))}</div>;
};

export default Errors;