import React, {useCallback, useState} from 'react';
import {Eshop} from "@fik/eshop";
import {useNavigation} from "@fik/navigation";
import {Redirect} from "react-router-dom";

const MarketingEshop = () => {
    const {routes} = useNavigation();
    const [isSaved, setIsSaved] = useState(false);
    const handleSaved = useCallback(() => {
        setIsSaved(true);
    }, [setIsSaved]);

    return isSaved ? <Redirect to={routes.marketingEshopOrdered.path} /> : <Eshop code="ALLINONE" onSaved={handleSaved} />;
}

export default MarketingEshop;