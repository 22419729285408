import React, {useCallback, useEffect} from "react";
import {Modal, useModal} from "@fik/ui";
import handleClick from "@fik/utils/handleClick";
import {LoadingProvider} from "@fik/loading";
import {useCmsPageBlocks} from "@fik/cms";
import {useLocalStorage, useSessionStorage} from "@fik/storage";
import usePageBlock from "@fik/page/usePageBlocks";

const ID = 'homepage-popup';
const BLOCK = 'popup';

const HomepagePopup = () => {
    const modal = useModal(ID);
    const popup = usePageBlock(BLOCK);
    const [popupSeen, setPopupSeen] = useSessionStorage('popus_seen_' + ID, false);
    const [popupDismissed, setPopupDismissed] = useLocalStorage('popup_dismiss_' + ID, false);

    useEffect(() => {
        const checkDate = popupDismissed ? Date.parse(popupDismissed) : null;
        if (popup && !popupSeen && (!checkDate || checkDate <= new Date())) {
            modal.show();
            setPopupSeen(true);
        }
    }, [popup, modal.show, popupSeen, popupDismissed, setPopupSeen]);

    const dismiss = useCallback(() => {
        modal.hide();
        let expire = new Date();
        expire.setDate(expire.getDate() + popup.dismiss.days);
        setPopupDismissed(expire);
    }, [modal.hide, popup, setPopupDismissed]);

    if (!popup) {
        return null;
    }

    const {message, title} = popup;

    return <Modal
        {...modal}
        bodyClassName='p-sm'
        renderHeader={() => <>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true"
                    onClick={handleClick(modal.hide)}>×
            </button>
            <h4 className="modal-title">{title}</h4>
            <div className='clearfix'/>
        </>}
        renderFooter={() => <>
            <button type="button" className="btn btn-white" data-dismiss="modal" onClick={handleClick(modal.hide)}>Ok</button>
            <button type="button" className="btn btn-primary" onClick={handleClick(dismiss)}>Nezobrazovat</button>
        </>}>
        <LoadingProvider>
            {modal.visible ? <div dangerouslySetInnerHTML={{__html: message}}/> : null}
        </LoadingProvider>
    </Modal>;
}

export default HomepagePopup;