import React, {useCallback, useState} from "react";
import PropTypes from 'prop-types';
import {Form} from "@mouseover/react-form";

const ID_TYPE_KEY = 'licenseKey';
const ID_TYPE_PUBLIC_ID = 'licensePublicId';
const ID_TYPE_EPLI = 'licenseEPLI';
const ID_TYPE_DESLOCK = 'licenseDeslockId';
const ID_TYPE_USERNAME = 'licenseUsername';

const RenewForm = ({onSubmit}) => {
    const [idType, setIdType] = useState(null);

    const handleChange = useCallback(({identificator}) => {
        if (!identificator) {
            setIdType(null);
            return;
        }
        if (identificator.match(/\b((AV|EAV|DEV|TRIAL)-[0-9]{6,})\b/)) {
            setIdType(ID_TYPE_USERNAME);
        } else if (identificator.match(/\b([a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4})\b/)) {
            setIdType(ID_TYPE_KEY);
        } else if (identificator.match(/\b([a-zA-Z0-9]{5}-[a-zA-Z0-9]{5}-[a-zA-Z0-9]{5}-[a-zA-Z0-9]{5})\b/)) {
            setIdType(ID_TYPE_DESLOCK);
        } else if (identificator.match(/\b([a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12})\b/)) {
            setIdType(ID_TYPE_EPLI);
        } else if (identificator.match(/\b([a-zA-Z0-9]{3}-[a-zA-Z0-9]{3}-[a-zA-Z0-9]{3})\b/)) {
            setIdType(ID_TYPE_PUBLIC_ID);
        } else {
            setIdType(null);
        }
    }, [setIdType]);

    const handleSubmit = useCallback(({identificator}) => {
        onSubmit({identificator, idType});
    }, [onSubmit, idType]);

    return <Form className='form'
        onChange={handleChange}
        onSubmit={handleSubmit}
        controls={{
            identificator: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Zadejte ID licence',
                },
                value: '',
                valid: false,
                touched: false,
                validation: {
                    required: true,
                },
                renderAppend: () => <span className='input-group-btn'>
                    <button
                        type="submit"
                        disabled={!idType}
                        className="btn btn-primary"
                    >Provést
                    </button>
                </span>
            }
        }}
    >

    </Form>
};

RenewForm.propTypes = {
    onSubmit: PropTypes.func
};

export default RenewForm;