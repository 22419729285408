import React, {useCallback, useState} from 'react';
import {FormField} from "@mouseover/react-form";
import PropTypes from "prop-types";
import handleClick from "@fik/utils/handleClick";
import useCompanyRegistrySearch from "../useCompanyRegistrySearch";
import {getOnPath} from "@mouseover/js-utils/src";
import CompaniesDropDown from "./CompaniesDropDown";

const COMPANY_FIELDS =  {name: '', companyId: '', vatId: '', street: '', city: '', zipCode: ''};

const CompanyFields = ({form, onSearchRegistry}) => {
    const [registrySearchResult, searchRegistry] = useCompanyRegistrySearch();
    const [path, setPath] = useState();

    const handleSearchRegistry = useCallback((source) => {
        setPath(source);
        searchRegistry(getOnPath(form, ['values', source]), source);
    }, [form, searchRegistry, setPath]);

    const handleSelectCompany = useCallback((addressBook) => {

        form.setValues({
            ...COMPANY_FIELDS,
            ...{
                name: addressBook.name,
                companyId: addressBook.companyId,
                vatId: addressBook.vatId,
                street: addressBook.street,
                city: addressBook.city,
                zipCode: addressBook.zipCode
            }
        });
    }, [form]);

    const handleClose = useCallback(() => {
        if (path) {
            setPath(null);
        }
    }, [path, setPath]);

    return (<>
        <div className="col-sm-6">
            <FormField form={form}
                       renderPrepend={() => <span className='input-group-btn'>
                       <button className='btn btn-default' onClick={handleClick(() => handleSearchRegistry('name'))}><i className='fa fa-search' /></button>
                   </span>}
                       name='name'
                       label='Název'
                       elementType='input'
                       elementConfig={{'autoComplete': 'nope'}}
            />
            {registrySearchResult && path === 'name' ? <CompaniesDropDown items={registrySearchResult} onSelect={handleSelectCompany} onClose={handleClose} /> : null}
        </div>
        <div className="col-sm-3">
            <FormField form={form}
                       renderPrepend={() => <span className='input-group-btn'>
                       <button className='btn btn-default' onClick={handleClick(() => handleSearchRegistry('companyId'))}><i className='fa fa-search' /></button>
                   </span>}
                       name='companyId'
                       label='IČ'
                       elementType='input'
                       elementConfig={{'autoComplete': 'nope'}}
            />
            {registrySearchResult && path === 'companyId' ? <CompaniesDropDown items={registrySearchResult} onSelect={handleSelectCompany} onClose={handleClose} /> : null}
        </div>
        <div className="col-sm-3">
            <FormField form={form}
                       name='vatId'
                       label='DIČ'
                       elementType='input'
                       elementConfig={{'autoComplete': 'nope'}}
            />
        </div>
    </>);
}

CompanyFields.propTypes = {
    form: PropTypes.object
};

export default CompanyFields;