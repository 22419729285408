import React, {useCallback} from 'react';
import {Form} from "@mouseover/react-form";
import {useDocumentContext} from "../useDocumentContext";

const DocumentAdditionalInfo = () => {
    const {
        document,
        changeValues,
    } = useDocumentContext();

    const handleValuesChanges = useCallback((values, valid) => {
        if (valid) {
            changeValues(values);
        }
    }, [changeValues]);

    return  document && document.note !== undefined
        ? <Form
            values={{
                note: document.note,
                customContractId: document.customContractId
            }}
            onChange={handleValuesChanges}
            controls={{
                customContractId: {
                    label: 'Číslo partnerské objednávky',
                    elementType: 'input'
                },
                note: {
                    label: 'Poznámka',
                    elementType: 'textarea'
                }
            }}/>
        : null;
};


export default DocumentAdditionalInfo;