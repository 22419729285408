import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Calculator} from "@fik/calculator";
import {Box} from "@fik/ui";
import {LoadingProvider} from "@fik/loading";

const DocumentEvidenceItem = ({confirm, cancel, selection, document}) => {
    const [channels, setChannels] = useState(null);
    useEffect(()=> {
        if (document) {
            setChannels({
                owner: document.owner,
                partner: document.partner,
                epc: document.epc
            })
        } else {
            setChannels(null);
        }
    }, [document]);

    const calculationData = useMemo(() => {
        return {initialSelection: selection, channels};
    }, [selection, channels]);

    return <LoadingProvider>
        {(isLoading) => <Box isLoading={isLoading} title="Položka">
            <Calculator calculationData={calculationData}
                        cancel={cancel}
                        confirm={confirm}
            />
        </Box>}
    </LoadingProvider>;
}

DocumentEvidenceItem.propTypes = {
    confirm: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    selection: PropTypes.object.isRequired
};

export default DocumentEvidenceItem;