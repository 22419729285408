import React from 'react';

import {Button} from "@fik/ui";
import {Form} from "@mouseover/react-form";

const BranchOfficeForm = ({branchOffice, onSubmit}) => {

    if (!branchOffice) {
        return null;
    }

    return <Form
        controls={{
            name: {
                label: 'Název',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Název',
                },
                value: '',
                valid: false,
                touched: false,
                validation: {
                    required: true,
                    minLength: 4
                }
            },
            street: {
                label: 'Ulice',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Ulice',
                },
                value: '',
                valid: false,
                touched: false,
                validation: {
                    required: true,
                    minLength: 4
                }
            },
            city: {
                label: 'Město',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Město',
                },
                value: '',
                valid: false,
                touched: false,
                validation: {
                    required: true,
                    minLength: 4
                }
            },
            zipCode: {
                label: 'PSČ',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'PSČ',
                },
                value: '',
                valid: false,
                touched: false,
                validation: {
                    required: true,
                    minLength: 5,
                    maxLength: 5
                }
            },
            email: {
                label: 'E-mail',
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'E-mail',
                },
                value: '',
                valid: false,
                touched: false,
                validation: {
                    required: true,
                    minLength: 4
                }
            },
            phone: {
                label: 'Telefon 1',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Telefon 1',
                },
                value: '',
                valid: false,
                touched: false,
                validation: {
                    required: true,
                    minLength: 9
                }
            },
            mobile: {
                label: 'Telefon 2',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Telefon 2',
                },
                value: '',
                valid: false,
                touched: false,
                validation: {
                    required: false,
                    minLength: 9
                }
            },
            web: {
                label: 'Webová adresa',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Webová adresa',
                },
                value: '',
                valid: false,
                touched: false,
                validation: {
                    required: false,
                    minLength: 3
                }
            },
            store: {
                elementType: 'checkbox',
                elementConfig: {autoComplete: 'off'},
                label: 'Kamenná prodejna',
                groupClass: 'form-group m-b-xs m-t-m'
            },
            sendOrderToCustomer: {
                elementType: 'checkbox',
                elementConfig: {autoComplete: 'off'},
                label: 'Odesílat licenční e-mail i na koncového uživatele',
                groupClass: 'form-group m-b-xs m-t-m'
            },
        }}
        onSubmit={onSubmit}
        values={branchOffice}
    render={(form) => (
        <Button
            btnType="primary"
            type="submit"
            disabled={!form.validation.valid}>Uložit</Button>
    )}/>;
};

export default BranchOfficeForm;