import React, {Component, useCallback, useMemo} from 'react';
import handleClick from "@fik/utils/handleClick";


const rangexy = (start, end) => Array.from({length: (end - start)}, (v, k) => k + start);

const usePaginator = ({page, count, limit}) => useMemo(() => {
    const pagination = {
        page,
        pageCount: !count ? null : Math.ceil(count / limit),
        pages: null,
        lastPage: null,
        firstPage: 1,
        itemPerPage: limit,
    };

    pagination.lastPage = !count ? null : 1 + Math.max(0, pagination.pageCount - 1);
    pagination.pages = rangexy(Math.max(1, page - 3), Math.min(pagination.lastPage, page + 3));

    let btnsCount = 4;
    let q = (pagination.pageCount - 1) / btnsCount;
    for (let i = 1; i < btnsCount; i++) {
        pagination.pages.push(Math.round(q * i) + pagination.firstPage);
    }

    pagination.pages.sort((a, b) => (a - b));
    pagination.pages = [...new Set(pagination.pages)];

    return pagination;
}, [page, count, limit]);

const Paginator = ({onPageChange, ...props}) => {
    const paginator = usePaginator(props);
    const setPage = useCallback((page) => {
        if (onPageChange) {
            onPageChange(page);
        }
    }, [onPageChange]);

    if (paginator.pageCount <= 1) {
        return null;
    }

    return  <ul className="pagination">
        <li className={paginator.page === paginator.firstPage ? "disabled" : null}>
            <a onClick={handleClick(() => setPage(paginator.firstPage))}>«</a>
        </li>

        <li className={"footable-page-arrow" + ( paginator.page === paginator.firstPage ? " disabled" : null)}><a onClick={handleClick(() => setPage(paginator.page - 1))}>‹</a></li>

        {paginator.pages.map((page) => (
            <li key={page} className={page === paginator.page  ? "active" : null}>
                <a onClick={handleClick(() => setPage(page))}>{page}</a>
            </li>
        ))}

        <li className={"footable-page-arrow" + (paginator.page === paginator.lastPage ? " disabled" : null)}><a onClick={handleClick(() => setPage(paginator.page + 1))}>›</a></li>

        <li className={paginator.page === paginator.lastPage ? "disabled" : null}>
            <a onClick={handleClick(() => setPage(paginator.lastPage))}>»</a>
        </li>
    </ul>
}

export default Paginator;