import {useCallback, useEffect, useRef, useState} from "react";

export const useReducerWithThunk = (reducer, initialArgs, initialize = (state) => state) => {
    const [state, setState] = useState(initialize(initialArgs));

    //- referenced state for getCurrentState
    const stateRef = useRef(state);
    const reducerRef = useRef(reducer);

    const getCurrentState = useCallback(() => stateRef.current, [stateRef]);
    const setCurrentState = useCallback((newState) => {
        stateRef.current = newState;
        setState(newState);
    }, [stateRef, setState]);

    const getCurrentReducer = useCallback(() => reducerRef.current, [reducerRef]);
    useEffect(() => {
        reducerRef.current = reducer;
    }, [reducer]);

    const reduce = useCallback((action) => getCurrentReducer()(getCurrentState(), action), [getCurrentState, getCurrentReducer]);

    const dispatch = useCallback((action) => typeof action === 'function'
        ? action(dispatch, getCurrentState)
        : setCurrentState(reduce(action))
        , [getCurrentState, setCurrentState, reduce]);

    return [state, dispatch];
};