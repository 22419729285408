import React, {useCallback, useState} from 'react';
import {Eshop} from "@fik/eshop";
import {Redirect} from "react-router-dom";
import {useNavigation} from "@fik/navigation";

const BoxEshop = () => {
    const {routes} = useNavigation();
    const [isSaved, setIsSaved] = useState(false);
    const handleSaved = useCallback(() => {
        setIsSaved(true);
    }, [setIsSaved]);

    return isSaved ? <Redirect to={routes.boxEshopOrdered.path} /> : <Eshop code="KRABICE" onSaved={handleSaved} />;
}

export default BoxEshop;