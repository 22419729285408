import {useCallback, useState} from "react";
import documentViews from "./documentViews";
import {CUSTOMER_VIEWS} from "@fik/customer-edit";
import {PARTNER_VIEWS} from "./partner/usePartner";
import isEmptyChannel from "@fik/utils/isEmptyChannel";

const useDocumentEditView = ({document, defaultView}) => {
    const [view, setView] = useState(defaultView);

    const handleCustomerViewChange = useCallback((customerView, isEdit) => {
        let newView = view;
        if (isEdit && view === documentViews.VIEW) {
            newView = isEmptyChannel(document.owner) && customerView === CUSTOMER_VIEWS.PICK ? documentViews.VIEW : documentViews.EDIT_CUSTOMER;
        } else if (!isEdit && view === documentViews.EDIT_CUSTOMER) {
            newView = documentViews.VIEW;
        }

        if (view !== newView) {
            setView(newView);
        }
    }, [setView, view, document]);

    const handlePartnerViewChange = useCallback((partnerView, isEdit) => {
        let newView = view;
        if (isEdit && view === documentViews.VIEW) {
            newView = isEmptyChannel(document.partner) && partnerView === PARTNER_VIEWS.PICK ? documentViews.VIEW : documentViews.EDIT_PARTNER;
        } else if (!isEdit && view === documentViews.EDIT_PARTNER) {
            newView = documentViews.VIEW;
        }

        if (view !== newView) {
            setView(newView);
        }
    }, [setView, view, document]);

    return {
        currentView: view,
        setView,
        enableAttachment: view === documentViews.VIEW,
        enableBranchOffice: view === documentViews.VIEW,
        enableEvidenceItem: view === documentViews.VIEW || view === documentViews.EDIT_EVIDENCE_ITEMS,
        enableCustomer: view === documentViews.VIEW || view === documentViews.EDIT_CUSTOMER,
        enablePartner: view === documentViews.VIEW || view === documentViews.EDIT_PARTNER || view === documentViews.EDIT_CUSTOMER,
        enableButtons: view === documentViews.VIEW,
        handleCustomerViewChange,
        handlePartnerViewChange
    };
}

export default useDocumentEditView;