import React from 'react';
import {Table} from "@fik/table";
import {Price} from "@fik/ui";

const renderRow = (props) => {
    const {row} = props;
    if (row && row.itemType === 'typPolozky.text') {
        return <tr><td colSpan={6}><strong>{row.itemName}</strong></td></tr>
    }
    return null;
};

const EvidenceItems = (props) => {

    const visiblePrices = props.visiblePrices || true;

    const columns =  [
        {
            key: 'item',
            title: 'Označení',
            render: ({row:item}) => (
                <React.Fragment>
                    {item.itemCode} {item.itemName}
                </React.Fragment>
            )
        },
        {
            key: 'itemAmount',
            title: 'Množství',
            render: ({row:item}) => (
                <React.Fragment>{item.itemAmount || '--'}</React.Fragment>
            )
        },
        {
            key: 'itemUnitPrice',
            title: 'Cena za MJ',
            className: 'text-right',
            render: ({row:item}) => (<Price price={item.itemUnitPrice}/>),
            visible: visiblePrices
        },
        {
            key: 'itemBasePrice',
            title: 'Základ',
            className: 'text-right',
            render: ({row:item}) => (<Price price={item.itemBasePrice}/>),
            visible: visiblePrices
        },
        {
            key: 'itemVat',
            title: 'DPH',
            className: 'text-right',
            render: ({row:item}) => (<Price price={item.itemVat}/>),
            visible: visiblePrices
        },
        {
            key: 'itemPrice',
            title: 'Celkem',
            className: 'text-right',
            render: ({row:item}) => (<Price price={item.itemVat + item.itemBasePrice}/>),
            visible: visiblePrices
        }
    ];

    return <Table responsive
                  containerClassName="m-t"
                  columns={columns}
                  rows={props.evidenceItems}
                  renderRow={renderRow}
                  primary='itemId'/>;
};


export default EvidenceItems;