import React from 'react';
import {isObject} from "@mouseover/js-utils";
import {Col, Row} from "@fik/ui";

const FaqAnswer = ({answer, isOpen}) => {
    return <Row>
        <Col lg={12}>
            <div className={"panel-collapse collapse" + (isOpen ? " in" : "")}>
                {isObject(answer) && answer.html
                    ? <div className="faq-answer" dangerouslySetInnerHTML={{__html: answer.html}}/>
                    : <div className="faq-answer">{answer}</div>
                }
            </div>
        </Col>
    </Row>;
};

export default FaqAnswer;