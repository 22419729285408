import React, {useCallback, useEffect, useMemo, useState} from "react";
import PropTypes from 'prop-types';
import {useDocumentContext} from "@fik/document-edit";
import {Form} from "@mouseover/react-form";
import {getValue, shallowEqual} from "@mouseover/js-utils";
import SolutionsContainer from "./SolutionsContainer";


const solutionsValidationRules = (solutions, validationRules) => {
    const current = validationRules && validationRules.children ? Object.fromEntries(Object.entries(validationRules.children).map(([,item]) => [item.id, item])) : {};
    return {...validationRules, children: Object.fromEntries(solutions.map((item, index) => [index, current.hasOwnProperty(item.id) ? current[item.id] : {}]))};
};

const shallowEqualArray = (a1, a2) => {
    if (!Array.isArray(a1) || !Array.isArray(a2)) {
        return a1 !== a2;
    }
    if (a1.length !== a2.length) {
        return false;
    }
    return a1.reduce((valid, current, currentIndex) => {
        if (valid) {
            valid = shallowEqual(current, a2[currentIndex]);
        }
        return valid;
    }, true);
}

const DealFormSolutionsWithDocument = ({solutions, products, validationRules, isSubmitted, document, changeValues}) => {
    const [initialSolutions, setInitialSolutions] = useState(null);
    const [lastSolutions, setLastSolutions] = useState(null);
    useEffect(() => {
        if (!initialSolutions) {
            const documentExpectedSolutions = getValue(document, ['expectedSolutions']) || [];
            const current = Object.fromEntries(documentExpectedSolutions.map((item) => [item.id, item]));
            const newOne = solutions.map((item) => {
                if (current.hasOwnProperty(item.id)) {
                    return {
                        ...current[item.id],
                        checked: current.hasOwnProperty(item.id),
                        name: item.name,
                        minAmount: item.minAmount,
                        unit: item.unit,
                    };
                } else {
                    return {...item, checked: false, value: null};
                }
            });
            setInitialSolutions(newOne);
            setLastSolutions(newOne);
        }
    }, [document, initialSolutions, setInitialSolutions, setLastSolutions]);

    const handleValuesChanges = useCallback((values, valid) => {
        const expectedSolutions = values && values.expectedSolutions ? values.expectedSolutions.filter((item) => item.checked).map(({checked, ...item}) => item) : null;
        if (!shallowEqualArray(values.expectedSolutions, lastSolutions)) {
            changeValues({expectedSolutions: expectedSolutions});
            setLastSolutions(values.expectedSolutions);
        }
    }, [changeValues, lastSolutions, setLastSolutions]);

    const formValidationRules = useMemo(() => ({expectedSolutions:  solutionsValidationRules(solutions, validationRules.expectedSolutions)}), [solutions, validationRules]);

    return document
        ? <Form
            values={{expectedSolutions: initialSolutions}}
            validationRules={formValidationRules}
            onChange={handleValuesChanges}
            isSubmitted={isSubmitted}
            render={(form) => <>
                <div className="row">
                    <div className="col-sm-3 col-sm-offset-3"><strong>Počet</strong></div>
                    <div className="col-sm-3"><strong>Současné řešení</strong></div>
                    <div className="col-sm-3"><strong>Expirace současného řešení</strong></div>
                </div>
                <SolutionsContainer validationRules={formValidationRules} form={form} solutions={solutions} products={products} currentSolutions={initialSolutions} />
            </>}/>
        : null;
}

const DealFormSolutions = (props) => {
    const {
        document,
        initialDocument: {expectedSolutions},
        changeValues,
    } = useDocumentContext();

    return document && props.solutions ? <DealFormSolutionsWithDocument {...props} document={document} changeValues={changeValues} /> : null;
};

DealFormSolutions.propTypes = {
    solutions: PropTypes.array,
    products: PropTypes.array
};


export default DealFormSolutions;


