import React from 'react';

const Header = ({category}) => {
    if (category) {
        return <h3>{category.label}{category.sublabel ? <><br/>
            <small>{category.sublabel}</small>
        </> : null}</h3>;
    }
    return null;

};

export default Header;