import React from 'react';
import {useEntity} from "@fik/entity";

const ContactEmailsSubscriptions = () => {
    const {entity} = useEntity({name: 'contact', params: {action: 'newsletters-subscription'}, timeToRefresh: -1});

    if (!entity) {
        return null;
    }

    return <table className='table'>
        <tbody>
        {Object.entries(entity).map(([name, checked], index) => <tr key={index}>
            <td><button className={"btn btn-circle " + (checked ? "btn-success" : "btn-warning")} type="button" style={{marginBottom: '2px'}}><i className={"fa " + (checked ? "fa-check" : "fa-times")}></i></button> {name}</td>
        </tr>)}
        </tbody>
    </table>;
};

export default ContactEmailsSubscriptions;
