import parseQueryString from "@fik/utils/parseQueryString";
import {useLocation} from 'react-router-dom';
import {useMemo} from "react";

const useQueryParams = (allowedParams) => {
    const location = useLocation();
    return useMemo(() => parseQueryString(location.search, allowedParams), [allowedParams, location.search]);
};

export default useQueryParams;
