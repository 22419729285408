import React from 'react';
import {Table} from '@fik/table';
import {Date, Price} from "@fik/ui";
import {DownloadLink} from "@fik/ui";

const InvoicesTable = (props) => {
    const columns = [
        {
            key: 'code',
            title: 'ID',
            render: ({row:item}) => item.code
        },
        {
            key: 'variableSym',
            title: 'VS',
            render: ({row:item}) => (
                <span>{item.variableSym}</span>
            )
        },
        {
            key: 'companyName',
            title: 'Klient',
            render: ({row:item}) => (
                <React.Fragment>
                    <strong>
                        {item.companyName}
                    </strong>
                    {item.contactEmail ? (<div className="description">
                        <i className="fa fa-envelope"/> {item.contactEmail}
                    </div>) : null}
                </React.Fragment>
            )
        },

        {
            key: 'dueDate',
            title: 'Splatnost',
            render: ({row:item}) => (
                <Date date={item.dueDate}/>
            )
        },
        {
            key: 'dateCreated',
            title: 'Vystaveno',
            render: ({row:item}) => (
                <Date date={item.dateCreated}/>
            )
        },
        {
            key: 'basePrice',
            title: 'Celkem bez DPH',
            render: ({row:item}) => (<Price price={item.basePrice}/>)
        },
        {
            key: 'leftToPay',
            title: 'Zbývá uhradit',
            render: ({row:item}) => (<Price price={item.leftToPay}/>)
        },
        {
            key: 'state',
            title: 'Stav',
            render: ({row:item}) => (<InvoiceState {...item}/>)
        },
        {
            key: 'menu',
            title: '',
            className: 'text-right',
            render: ({row:item}) => {
                return (
                    <>
                        <DownloadLink
                            resource='invoice'
                            params={{id: item.id}}
                            className="btn btn-default btn-sm">
                            <i className="fa fa-file-pdf-o" /> Stáhnout
                        </DownloadLink>
                    </>
                )
            }
        }
    ];

    return <Table striped responsive
                    columns={columns}
                    rows={props.rows}
                    primary='id'/>;
};

export default InvoicesTable;

const InvoiceState = ({canceled, overdue, isPaid}) => {

    let className = 'label ';
    let label = '';

    if (canceled) {
        label = 'Zrušená';
        className += 'label-default';
    } else if (isPaid) {
        label = 'Uhrazeno';
        className += 'label-success';
    } else if (!isPaid && !overdue) {
        label = 'Neuhrazeno';
        className += 'label-info';
    } else if (!isPaid && overdue) {
        label = 'Po splatnosti';
        className += 'label-danger';
    }

    return <span className={className}>{label}</span>;
}