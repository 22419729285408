import {useContext, useMemo} from 'react';
import {ModelContext} from "./ModelContext";

const useModel = (callback, deps) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const callbackMemo = useMemo(() => callback, deps);
    const model = useContext(ModelContext);
    return useMemo(() => callbackMemo(model), [callbackMemo, model]);
};

export default useModel;