import React from 'react';
import PropTypes from 'prop-types';
import {DatePicker} from "@fik/form";


const Ransom = ({ransom = [], form}) => {
    return (ransom === true ? <div className="row">
        <div className="col-sm-12">
            <DatePicker label="ESET výkupné - datum expirace konkurenčního řešení" name="itemRansomExpirationDate" form={form}/>
        </div>
    </div> : null);
};

Ransom.propTypes = {
    ransom: PropTypes.bool,
    form: PropTypes.object
};

export default Ransom;