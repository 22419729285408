import React from "react";
import Menu from "./Menu";
import Icon from "./Icon";

const CollapseItem = ({onClick, currentPath, icon = null, label, isActive = false, content}) => {
    const button = <a href='#' onClick={onClick}>{icon ? <Icon icon={icon}/>: null}{label}</a>;

    return (<>
        {button}
        <Menu content={content} currentPath={currentPath} className={'nav nav-second-level collapse' + (isActive ? ' in' : '')}/>
    </>)
};

export default CollapseItem;