import {useEffect} from "react";
import documentViews from "./documentViews";

/**
 *
 * @param {object} document Document instance
 * @param {boolean} existing True if document already exists
 * @param {object} documentModel
 * @param {object} evidenceItem Initial evidence item
 */
const useDocumentEditInit = ({document, existing, documentModel, evidenceItem}) => useEffect(() => {
    if (document) {
        documentModel.setDocument(document, existing);

        if (!existing) {
            documentModel.view.setView(documentViews.EDIT_CUSTOMER);
        }

        if (evidenceItem) {
            documentModel.evidenceItemEdit.startEditNewEvidenceItem(evidenceItem);
        }
    }
}, [
    document,
    documentModel.evidenceItemEdit,
    documentModel.setDocument,
    documentModel.view.setView,
    existing,
    evidenceItem
]);

export default useDocumentEditInit;