import React from "react";
import PropTypes from 'prop-types';
import {Form, FormField} from "@mouseover/react-form";

const ResellerRidForm = ({onSubmit, children, values}) => {
    return <Form
        values={values}
        validationRules={{}}
        onSubmit={onSubmit}
        render={(form) => (
            <>
                <div className="row">
                    <div className="col-sm-12">
                        <FormField form={form}
                                   name='id'
                                   elementType='input'
                                   elementConfig={{
                                       placeholder: 'RID partnera'
                                   }}
                                   renderAppend={() => (<span className="input-group-btn">
                                        <button type="submit" className="btn btn-primary">Hledat</button>
                                    </span>)}
                        />
                    </div>
                </div>
                {children}
            </>
        )}/>;
};

ResellerRidForm.propTypes = {
    onSubmit:PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
    values: PropTypes.object
};

export default ResellerRidForm;