import React from 'react';
import {Paginator} from "@fik/ui";
import OffersTable from "./OffersTable";

const OffersList = ({statesDict = {}, typesDict = {}, items = [], pagination = {}, onPageChange}) => (<React.Fragment>
    <OffersTable striped responsive
           statesDict={statesDict}
           typesDict={typesDict}
           rows={items}
    />

    <Paginator {...pagination}
               onPageChange={onPageChange}/>
</React.Fragment>);

export default OffersList;