import React from 'react';
import {useEntity} from "@fik/entity";
import Salesman from "../../components/Profile/Salesman/Salesman";
import {Col, Row} from "@fik/ui";

const EsetContacts = () => {
    const {
        entity: resellerProfile
    } = useEntity({name: 'profile'}, null);

    return (
        <div className="wrapper wrapper-content animated fadeInRight" data-app-module="modules/app">
            <Row>
                <Col lg={6}>
                    <div className="contact-box contact-box-main no-borders">
                        <div>
                            <h3>
                                <strong>Váš obchodník</strong>
                            </h3>
                            <Row>
                                <Col sm={7} className="no-padding no-margins no-padding">
                                    {resellerProfile && resellerProfile.staff ?
                                        <Salesman moreLink={false} salesman={resellerProfile.staff} path={process.env.REACT_APP_API_URL}/> : null}
                                </Col>
                                <Col sm={5}>
                                    <iframe
                                        title="mapa"
                                        src="https://api.mapy.cz/frame?params=%7B%22x%22%3A14.454130000864124%2C%22y%22%3A50.10750161522899%2C%22base%22%3A%221%22%2C%22layers%22%3A%5B%5D%2C%22zoom%22%3A17%2C%22url%22%3A%22https%3A%2F%2Fmapy.cz%2Fs%2F1ofA5%22%2C%22mark%22%3A%7B%22x%22%3A%2214.454130000864124%22%2C%22y%22%3A%2250.10750161522899%22%2C%22title%22%3A%22ESET%20software%2C%20spol.%20s%20r.o.%22%7D%2C%22overview%22%3Atrue%7D&amp;width=400&amp;height=280&amp;lang=cs"
                                        width="100%" height="200" style={{border: 'none'}} frameBorder="0">

                                    </iframe>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>

                <Col lg={6}>
                    <div className="contact-box contact-box-main no-borders">
                        <h3>
                            <strong>Obecné informace</strong>
                        </h3>
                        <Row>
                            <Col lg={6}>
                                <address>
                                    <strong>ESET software spol. s r.o.</strong><br/>
                                    Classic 7 Business Park<br/>
                                    Jankovcova 1037/49<br/>
                                    170 00 Praha 7
                                </address>
                            </Col>
                            <Col lg={6}>
                                <address>
                                    <strong>IČ: </strong>26467593, <strong>DIČ: </strong>CZ26467593<br/>
                                    <br/>
                                    <strong>Tel:</strong> +420 233 090 233<br/>
                                    <strong>E-mail:</strong> <a href="mailto:info@eset.cz">info@eset.cz</a>
                                </address>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col lg={3}>
                    <div className="contact-box contact-box-department">
                        <h3>
                            <strong>Obchodní oddělení</strong>
                        </h3>
                        <address>
                            <strong>Telefon:</strong> +420 222 811 135<br/>
                            <strong>E-mail:</strong> <a href="mailto:partner@eset.cz">partner@eset.cz</a>
                        </address>
                        V pracovní dny od 8:30 do 17:00
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="contact-box contact-box-department">
                        <h3>
                            <strong>Technické oddělení</strong>
                        </h3>
                        <address>
                            <strong>Telefon:</strong> +420 233 090 233<br/>
                            <strong>Kontaktní formulář:</strong> <a
                            href="https://servis.eset.cz/Knowledgebase/Article/View/79/0/jak-kontaktovat-technickou-podporu"
                            rel="noopener noreferrer"
                            target="_blank">servis.eset.cz</a>
                        </address>
                        V pracovní dny od 8:30 do 17:00
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="contact-box contact-box-department">
                        <h3>
                            <strong>Marketingové oddělení</strong>
                        </h3>
                        <address>
                            <strong>Telefon:</strong> +420 233 090 253<br/>
                            <strong>E-mail:</strong> <a href="mailto:marketing@eset.cz">marketing@eset.cz</a>
                        </address>
                        V pracovní dny od 8:30 do 17:00
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="contact-box contact-box-department">
                        <h3>
                            <strong>Fakturace a finanční oddělení</strong>
                        </h3>
                        <address>
                            <strong>Telefon:</strong> + 420 233 090 269<br/>
                            <strong>E-mail:</strong> <a href="mailto:fakturace@eset.cz">fakturace@eset.cz</a>
                        </address>
                        V pracovní dny od 8:30 do 17:00
                    </div>
                </Col>
            </Row>

            <Row>
                <Col lg={3}>
                    <div className="contact-box contact-box-department">
                        <h3>
                            <strong>Dotazy na GDPR</strong>
                        </h3>
                        <address>
                            <strong>E-mail:</strong> <a href="mailto:dpo@eset.cz">dpo@eset.cz</a>
                        </address>
                        V pracovní dny od 8:30 do 17:00
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default EsetContacts;