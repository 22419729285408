import React from 'react';
import {FormField} from "@mouseover/react-form";
import PropTypes from "prop-types";

const Selector = ({types = [], products = [], periods = [], selectType, minimum, maximum, form}) => {

    return (
        <div className="row">
            {selectType === 'renew' ?
                <div className="col-sm-12">
                    <FormField
                        name='itemLicenseIdCodes'
                        form={form}
                        label="ID licence"
                        elementType="text"
                    />
                </div> : null}
            <div className="col-sm-12">
                <FormField
                    name='itemProductTypeId'
                    form={form}
                    label="Typ licence"
                    elementType="select"
                    options={[{value: '', label: 'Vyberte typ licence'}, ...types.map(item => ({value: item.id, label: item.name}))]}
                    valueType="integer"
                />
            </div>
            <div className="col-sm-12">
                <FormField
                    name='itemProductFamilyId'
                    form={form}
                    label="Produkt"
                    elementType="select"
                    options={[{value: '', label: 'Vyberte produkt'}, ...products.map(item => ({value: item.id, label: item.name}))]}
                    valueType="integer"
                />
            </div>
            <div className="col-sm-8">
                <FormField
                    label="Délka licence"
                    form={form}
                    name='itemProductPeriodId'
                    elementType="select"
                    options={[{value: '', label: 'Vyberte délku licence'}, ...periods.map(item => ({
                        value: item.id,
                        label: item.name
                    }))]}
                    valueType="integer"
                />
            </div>
            <div className="col-sm-4">
                <FormField
                    label="Počet licencí"
                    form={form}
                    name='itemAmount'
                    elementType="input"
                    elementConfig={{
                        type: 'number',
                        placeholder: 'Zadejte počet',
                        min: minimum || 1,
                        max: maximum || 9999
                    }}
                    valueType="number"
                />
            </div>
        </div>
    );
};

Selector.propTypes = {
    types: PropTypes.array,
    products: PropTypes.array,
    periods: PropTypes.array,
    selectType: PropTypes.string,
    form: PropTypes.object
};

export default Selector;