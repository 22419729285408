import React from 'react';
import {Box} from "@fik/ui";

const GridItemYouTube = ({label, date, description, youtube}) => {

    return <Box contentClassName="product-box product-youtube">
        <div className="youtube">
            <img alt={label} src={"https://img.youtube.com/vi/"+ youtube +"/sddefault.jpg"} />
            <div className="play-button" />
        </div>
        <div className="product-desc">
            <div className="product-name">{label}</div>
            <div className="small m-t-xs">
                {description}
            </div>
            <small className="text-muted">{date}</small>
        </div>
    </Box>;

};

export default GridItemYouTube;