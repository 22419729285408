import React, {useCallback} from "react";
import {useEntity, useEntityAction} from "@fik/entity";
import {Box} from "@fik/ui";
import {CertificationRequestForm} from "@fik/components";

const CertificationRequestResult = ({isSuccess, entity}) => {
    return isSuccess ? <div className={'alert alert-' + entity.type}>{entity.message}</div> : null;
};

const CertificationRequest = ({contactId}) => {
    const {entity:certificationBranches} = useEntity({name: 'resellerProfile', params: {action: 'certification-branches'}});

    // save
    const [{isSuccess, result: certificationRequestResult}, request] = useEntityAction({name: 'resellerProfile', action: 'contactCertificationRequest'});

    const handleSubmit = useCallback((values) => {
        request({values});
    }, [request]);

    return <>
        <CertificationRequestResult isSuccess={isSuccess} entity={certificationRequestResult} />
        <Box title="Vyberte typ certifikace">
        {certificationBranches ? <CertificationRequestForm
            certificationBranches={certificationBranches}
            contactId={contactId}
            onSubmit={handleSubmit}
        /> : null}
            <p style={{fontStyle: 'italic'}}>* Informace pro přihlášení Vám budou zaslány na email uvedený v profilu osoby.</p>
            <p style={{fontStyle: 'italic'}}>** Splněním certifikace ESET Endpoint Encryption či ESET Secure Authentication nevzniká automaticky nárok k přidělení kategorie ESET Security Partner.</p>
    </Box>
    </>;
};

export default CertificationRequest;