import {useLocation} from "react-router-dom";
import {useCallback, useMemo} from "react";
import useQueryParamsContext from "./useQueryParamsContext";
import {searchParamsToObject} from "./components/QueryParamsProvider";

const useQueryParams = (defaultParams) => {
    const queryParamsContext = useQueryParamsContext();

    const urlSearchParams = useMemo(()=> {
        const returnParams = {...defaultParams};
        if (queryParamsContext.searchParams) {
            Object.entries(queryParamsContext.searchParams).forEach(([key, value]) => {
                if (defaultParams && defaultParams.hasOwnProperty(key)) {
                    returnParams[key] = value;
                }
            })
        }
        return returnParams;
    }, [queryParamsContext.searchParams, defaultParams]);

    const setQuery = useCallback((args) => {
        const params = typeof args === 'function' ? args(urlSearchParams) : args;
        queryParamsContext.change((current) => {
            const newParams = {...current};
            for (const [key, value] of Object.entries(params)) {
                if (!defaultParams || defaultParams.hasOwnProperty(key)) {
                    if (value !== defaultParams[key]) {
                        newParams[key] = value;
                    } else if (newParams.hasOwnProperty(key)) {
                        delete newParams[key];
                    }
                }
            }
            return newParams;
        });
    }, [queryParamsContext, defaultParams, urlSearchParams]);

    return [
        urlSearchParams,
        setQuery
    ];
}

export default useQueryParams;