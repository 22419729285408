import PropTypes from 'prop-types';
import React from "react";
import {DocumentContext} from "./../DocumentContext";
import useDocumentEdit from "./../useDocumentEdit";

export const DocumentProvider = (props) => {
    const documentModel  = useDocumentEdit(props);
    return <DocumentContext.Provider value={documentModel} {...props}/>
};

DocumentProvider.propTypes = {
    document: PropTypes.object,
    existing: PropTypes.bool,
    validate: PropTypes.bool,
};
