import React from 'react';
import moment from "moment";

const DateTime = ({date, format = 'DD. MM. YYYY  HH:mm'}) => {

    if (date instanceof Object && date.date) {
        date = date.date;
    }

    return (
        <>
            {date ? moment(date).format(format) : '-'}
        </>
    );
};

export default DateTime;