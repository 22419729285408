import React, {useCallback} from 'react';
import {Table, useRowDetail, ToggleRowDetailButton} from "@fik/table";
import {Channel, ChannelName} from "@fik/components";
import {Date} from "@fik/ui";
import LicenseOrderLink from "./LicenseOrderLink";
import LicenseOfferLink from "./LicenseOfferLink";
import LicenseLink from "./LicenseLink";
import LicenseNote from "./Detail/LicenseNote";
import LeakedInfo from "./LeakedInfo";
import {Icon, NavigationItem} from "@fik/navigation";

const filterChannel = (item, type) => item.licenseChannels.filter((item) => item.type === type).pop();

const LicensesTable = ({rows, onInvalidate, epcVisible = false, partnerVisible = true, children}) => {
    const {toggleRow, isVisibleRow} = useRowDetail();
    const handleNoteSave = useCallback((primaryValue) => {
        if (onInvalidate) {
            onInvalidate();
            toggleRow(primaryValue);
        }
    }, [onInvalidate, toggleRow]);

    return <Table striped responsive
                  rows={rows}
                  renderCellProps={{toggleRow, isVisibleRow}}
                  renderRowMessages={({row: license, primaryValue, columnsLength}) => (isVisibleRow(primaryValue) ?
                      <tr>
                          <td colSpan={columnsLength} className="no-borders">
                              <LicenseNote onSave={() => handleNoteSave(primaryValue)} resellerNote={license.resellerNote}
                                           id={license.licenseId}/>
                          </td>
                      </tr> : null)}
                  primary='licenseId'>

        {children ? children : <>
            <LicenseIdWithNoteColumn title='ID' headerClassName='col-md-1' />
            <LicenseCustomerInfo title='Klient' headerClassName='col-md-3' />
            {partnerVisible !== false && <LicensePartner title='Partner' />}
            {epcVisible && <LicenseEPC title='EPC' />}
            <LicenseProduct title='Produkt' />
            <LicenseDate title='Platnost od/do' />
            <LicenseStatus title='Stav' />
            <LicenseOrder />
            <LicenseDownload title='' headerClassName='col-md-2' />
        </>}

    </Table>;
};


export default LicensesTable;

export const LicenseIdColumn = ({row: item}) => (<>
    <LicenseLink item={item}>
        {item.licenseMainId ? (<div className="text-bold">{item.licenseMainId}</div>) : null}
    </LicenseLink>
    {item.leakedInfo ? <LeakedInfo leakedInfo={item.leakedInfo}/> : null}
</>);

export const LicenseIdWithNoteColumn = ({row: item, primaryValue, isVisibleRow, toggleRow}) => (<>
    <ToggleRowDetailButton
        isVisibleRow={isVisibleRow}
        primaryValue={primaryValue}
        toggleRow={toggleRow}
        openIcon="fa-list-alt"
        closeIcon="fa-list-alt"
        title={item.resellerNote}
        className={"btn btn-xs pull-right " + (item.resellerNote ? 'btn-success' : 'btn-default')}>
    </ToggleRowDetailButton>
    <LicenseIdColumn row={item} />
</>);

export const LicenseCustomerInfo = ({row: item}) => (
    <>
        {item.licensePrimaryName ? (<div className="text-bold">{item.licensePrimaryName}</div>) : null}
        {item.licenseEmail ? (
            <div className="icon-nowrap-block"><i className="fa fa-envelope"/> {item.licenseEmail}</div>) : null}
    </>
);

export const LicenseOwner =  ({row}) => {
    const channel = filterChannel(row, 'owner');
    return <>
        <ChannelName name={row.licensePrimaryName} addressBookId={channel ? channel.addressBookId : null} />
        {row.licenseEmail ? <div className='description'>
            <Icon icon='fa fa-envelope' /> {row.licenseEmail}
        </div> : null}
    </>;
};

export const LicensePartner = ({row}) => <LicenseChannel row={row} channelName='partner'/>;

export const LicenseEPC = ({row}) => <LicenseChannel row={row} channelName='epc'/>;

const LicenseChannel = ({row, channelName}) => {
    const channel = filterChannel(row, channelName);
    return (channel ? <Channel {...channel} /> : null);
}

export const LicenseProduct = ({row: item}) => (
    <>
        <div>{item.licenseProductName}</div>
        <div className="description">
            {item.licensePackProductName ? (<strong>{item.licensePackProductName}, </strong>) : null}
            {item.licenseTypeName ? (<span>{item.licenseTypeName}, </span>) : null}
            {item.licenseBusinessType ? (<span>{item.licenseBusinessType.name}</span>) : null}
            {item.licenseQuantity ? (
                <> množství: <strong>{item.licenseQuantity}</strong></>) : null}
        </div>
    </>
);

export const LicenseDate = ({row: item}) => (<>
        <div><Date date={item.licenseDate}/></div>
        <div><Date date={item.licenseExpiration}/></div>
    </>
);

export const LicenseStatus =  ({row: item}) => (
    <span className={'label label-default label-' + item.licenseBusinessStatusLabelStyle}
    >{item.licenseBusinessStatus}</span>
);

export const LicenseOrder = ({row: item}) => {
    let orderInfo = null;
    if (item.licenseOrder) {
        let orderClass;
        switch (item.licenseOrder.status) {
            case 'stavDoklObch.pripraveno':
                orderClass = 'label-info';
                break;
            case 'stavDoklObch.schvaleno':
                orderClass = 'label-warning';
                break;
            case 'stavDoklObch.hotovo':
                orderClass = 'label-success';
                break;
            case 'stavDoklObch.storno':
                orderClass = 'label-danger';
                break;
            default:
                orderClass = 'label-default';
                break;
        }
        orderInfo = (
            <span className={'label ' + orderClass}>{item.licenseOrder.status}</span>
        );
    }
    return (
        <>
            {item.licenseOrderId ? (<NavigationItem to="order:detail"
                                                    params={{id: item.licenseOrderId}}>{item.licenseOrderId.substr(5)}</NavigationItem>) : null}
            {orderInfo}
        </>
    );
};

export const LicenseDownload = ({row: item, primaryValue}) => {
    if (item.licenseStatusId !== 1) {
        return null;
    }
    return (
        <>
            <LicenseLink
                item={item}
                className="btn btn-default btn-sm m-r-sm">
                Zobrazit
            </LicenseLink>
            <LicenseOrderLink className="btn btn-success btn-sm m-r-sm" item={item}/>
            <LicenseOfferLink className="btn btn-warning btn-sm" item={item}/>
        </>
    )
};