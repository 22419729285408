import React from "react";
import {NavigationItem} from "@fik/navigation";

const ExpiringLicensesInfo = () => <p className='m-t-md'>
    <i className="fa fa-info-circle"/> Níže uvedený výpis obsahuje licence, které již expirovaly a nebo budou expirovat v následujících
    dnech a dosud nebyly prodlouženy. Výpis licencí je omezen v rozsahu -30 až +30 dní od data expirace. Bližší informace o celém expiračním
    procesu naleznete <NavigationItem to="page" params={{category: 'partnerstvi', page: 'exspiracni-proces'}}>zde</NavigationItem>.<br/>
    <i className="fa fa-info-circle"/> Kliknutím na tlačítko <button type="button" className="btn btn-xs btn-default"><span
    className="glyphicon glyphicon-list-alt"/></button> si můžete k licenci zapsat libovolnou poznámku. Pro zobrazení poznámky stačí
    najet myší na zelenou podobu tlačítka: <button type="button" className="btn btn-xs btn-success"><span
    className="glyphicon glyphicon-list-alt"/></button>
</p>;

export default ExpiringLicensesInfo;