import {usePureEntity} from "@fik/entity";
import {useCallback, useEffect} from "react";


const useCompanyRegistrySearch = () => {
    const [{entity:results}, load] = usePureEntity({name: 'customer', params: {action: 'search-registry'}});

    const search = useCallback((pattern, source) => {
        load({pattern, source});
    }, [load])

    return [results, search];
}

export default useCompanyRegistrySearch;