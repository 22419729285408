import {useCallback, useEffect, useMemo, useReducer} from "react";
import useRefOn from "@fik/utils/useRefOn";

export const PARTNER_VIEWS = {
    VIEW: 'partner.view',
    PICK: 'partner.pick',
    EDIT: 'partner.edit'
};

const ACTION = {
    CANCEL: 'CANCEL',
    PICK: 'PICK',
    EDIT: 'EDIT',
    SET_PARTNER: 'SET_PARTNER'
};

const INITIAL_STATE = {
    view: PARTNER_VIEWS.PICK,
    partner: null,
    isPartnerFilled: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ACTION.PICK:
            return {...state, view: PARTNER_VIEWS.PICK};
        case ACTION.CANCEL:
            return {...state, view: PARTNER_VIEWS.VIEW};
        case ACTION.EDIT:
            return {...state, view: PARTNER_VIEWS.EDIT};
        case ACTION.SET_PARTNER:
            const  isPartnerFilled = action.partner && action.partner.addressBook && action.partner.addressBook.name;
            return {
                ...state,
                view: isPartnerFilled ? PARTNER_VIEWS.VIEW : PARTNER_VIEWS.PICK,
                partner: action.partner,
                isPartnerFilled
            };
    }
    return state;
}

const usePartner = ({onPartnerChange, onViewChange, channel}) => {
    const [state, dispatch]  = useReducer(reducer, INITIAL_STATE);

    useEffect(() => {
        dispatch({type: ACTION.SET_PARTNER, partner: channel});
    }, [channel]);


    const cancel = useCallback(() => {
        dispatch({type:  ACTION.CANCEL});
    }, []);

    const pick = useCallback(() => {
        dispatch({type:  ACTION.PICK});
    }, []);

    const edit = useCallback(() => {
        dispatch({type:  ACTION.EDIT});
    }, []);

    const confirmChange = useCallback((values) => {
        if (onPartnerChange) {
            onPartnerChange(values);
        }
    }, [onPartnerChange]);

    const refOnViewChange = useRefOn(onViewChange);

    useEffect(() => {
        if (refOnViewChange.current) {
            refOnViewChange.current(state.view, state.view !== PARTNER_VIEWS.VIEW);
        }
    }, [refOnViewChange, state.view]);

    return useMemo(() => ({
        ...state,
        confirmChange,
        cancel,
        pick,
        edit
    }), [onPartnerChange, cancel, pick, edit, state]);
}

export default usePartner;