import React, {useCallback} from "react";
import {NavigationItem} from "@fik/navigation";
import {Form} from "@mouseover/react-form";
import {Button} from "@fik/ui";
import {TextInput} from "@fik/form";
import FooterText from "../../components/Navigation/Footer/FooterText";
import {useEntityAction} from "@fik/entity";

const VALIDATION = {
    username: {
        required: {
            params: true,
            message: "Vyplňte prosím uživatelské jméno."
        },
        minLength: 3
    }
};

const RequestSendForUsername = ({onSubmit}) => <>
    <p>Zadejte Vaše uživatelské jméno. Na e-mail asociovaný s&nbsp;Vaším účtem zašleme další instrukce pro přihlášení.</p>

    <div className="row">
        <div className="col-lg-12">
            <Form
                validationRules={VALIDATION}
                id="frm-forgotPassword"
                className="m-t"
                onSubmit={onSubmit}
                render={(form) => (<>
                    <TextInput label="Uživatelské jméno" form={form} name="username"/>
                    <Button
                        btnType="primary"
                        className="block full-width m-b"
                        type="submit"
                        disabled={!form.validation.valid}
                    >Zaslat nové heslo</Button>
                </>)}
            >
            </Form>

        </div>
    </div>
</>;

const ForgotPassword = () => {
    const [{isSuccess, isFetching, isFailed, error}, resetPassword] = useEntityAction({name: 'authentication', action: 'forgotPassword'});

    const handleSubmit = useCallback((values) => {
        resetPassword(values);
    }, [resetPassword]);

    return <div className="passwordBox animated fadeInDown">
        <div className="row">
            <div className="col-md-12">
                <div className="ibox-content">
                    <h2 className="font-bold">Zapomenuté heslo</h2>
                    {isFailed && error && <p className='alert alert-error'>{error.message}</p>}
                    {!isFetching && !isSuccess && <RequestSendForUsername onSubmit={handleSubmit}/>}
                    {isSuccess &&
                    <p>Jednorázové heslo bylo odesláno na Váš e-mail.<br/> Pokračujte prosím dle instrukcí v e-mailu.<br/> Toto okno
                        můžete nyní zavřít.</p>}
                    <div className="row">
                        <div className="col-lg-12">
                            <NavigationItem to="signIn">
                                <small>Zpět na úvodní stránku</small>
                            </NavigationItem>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr/>
        <div className="row">
            <div className="col-md-12">
                <div>
                    <FooterText/>
                </div>
            </div>
        </div>
    </div>;
}

export default ForgotPassword;