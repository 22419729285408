import React from 'react';
import {Box, Col, Row} from "@fik/ui";
import AddressBook from "./AddressBook/AddressBook";
import BranchOffice from "./BranchOffice/BranchOffice";
import {useEntity} from "@fik/entity";

const Settings = () => {
    const {
        entity: resellerProfile
    } = useEntity({name: 'profile'}, null);

    if (!resellerProfile) {
        return null;
    }

    return (
        <Row>
            <Col lg={6}>
                    {resellerProfile.branchOffice && resellerProfile.branchOffice.branchOfficeId
                        ? <Box title="Úprava pobočky"><BranchOffice/></Box>
                     :  <Box><AddressBook/></Box>}
            </Col>
        </Row>
    );
};

export default Settings;