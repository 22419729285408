import React, {useCallback, useState} from "react";
import PropTypes from 'prop-types';
import {Table} from "@fik/table";

const NameRow = ({item, primary, handleOptionChange, selected}) => (
    <div className="radio">
        <label>
            <input type="radio" className="i-checks"
                   value={primary}
                   checked={selected === primary}
                   onChange={handleOptionChange}
                   name="selected"/> {item.addressBook.name}
        </label>
    </div>
);

NameRow.propTypes = {
    item: PropTypes.object,
    primary: PropTypes.number,
    handleOptionChange: PropTypes.func,
    selected: PropTypes.number
};


const ChannelPickList = ({items, onPick}) => {

    const [selected, setSelected] = useState(null);

    const handleOptionChange = useCallback((changeEvent) => {
        const index = parseInt(changeEvent.target.value);
        setSelected(index);
        if (onPick) {
            onPick(items[index], index);
        }
    }, [onPick, items]);

    return items
        ? <Table striped responsive
                 columns={[
                     {
                         key: 'name',
                         title: 'Název',
                         render: ({row:item, primaryValue}) => (<NameRow item={item} primary={primaryValue} selected={selected}
                                                              handleOptionChange={handleOptionChange}/>)
                     },
                     {
                         key: 'companyId',
                         title: 'IČ',
                         render: ({row:item}) => (item.addressBook.companyId)
                     },
                     {
                         key: 'street',
                         title: 'Ulice',
                         render: ({row:item}) => (item.addressBook.street)
                     },
                     {
                         key: 'city',
                         title: 'Město',
                         render: ({row:item}) => (item.addressBook.city)
                     },
                     {
                         key: 'email',
                         title: 'E-mail',
                         render: ({row:item}) => (item.addressBook.email)
                     },
                     {
                         key: 'phone',
                         title: 'Telefon',
                         render: ({row:item}) => (item.addressBook.phone)
                     }
                 ]}
                 rows={items}/>
        : null

};

ChannelPickList.propTypes = {
    items: PropTypes.array,
    onPick: PropTypes.func
};

export default ChannelPickList;