import {generatePath} from "react-router-dom";
import {useMemo} from "react";
import useNavigation from "./useNavigation";
import queryString from "query-string";

const useNavigationLink = ({to, params, anchor, query}) => {
    const {getItemOnPath} = useNavigation();
    return useMemo(() => {
        const navigation = getItemOnPath(to);
        if (navigation && navigation.path) {
            try {
                return generatePath(navigation.path, params) + (anchor ? '#' + anchor : '') + (query ? '?' + queryString.stringify(query) : '');
            } catch (error) {
                console.log(params, error);
                return '#not-created';
            }
        }
        return null;
    }, [getItemOnPath, to, params, anchor, query]);
};

export default useNavigationLink;