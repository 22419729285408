import React, {useCallback, useEffect} from "react";
import useAuth from "@fik/auth/useAuth";
import {Redirect} from "react-router-dom";

const AuthToken = ({token}) => {
    const {authenticate, isAuthenticated, redirectPath, error, loading} = useAuth();

    useEffect(() => {
        authenticate({access_token: token})
    }, [token,authenticate]);

    if (isAuthenticated) {
        return <Redirect to={redirectPath} />;
    }

    return null;
};

export default AuthToken;

