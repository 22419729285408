export const createActionNames = (path) => {
    const prefix = (Array.isArray(path) ? path.join('/') : path) + '/';
    return {
        FETCH_INIT: `${prefix}FETCH_INIT`,
        FETCH_SUCCESS: `${prefix}FETCH_SUCCESS`,
        FETCH_FAILURE: `${prefix}FETCH_FAILURE`,
        FETCH_RESET: `${prefix}FETCH_RESET`,
    }
};

export default createActionNames;