import {useCallback, useMemo, useState} from 'react';

const useModal = (id) => {
    const [visible, setIsVisible] = useState(false);

    const toggle = useCallback(() => {
        setIsVisible(visible => !visible);
    }, [setIsVisible]);

    const show = useCallback(() => {
        setIsVisible(true);
    }, [setIsVisible]);

    const hide = useCallback(() => {
        setIsVisible(false);
    }, [setIsVisible]);

    return useMemo(() => ({
        visible,
        toggle,
        show,
        hide
    }), [visible, toggle, show, hide]);
};

export default useModal;