import React from 'react';
import useCmsFiles from "./../useCmsFiles";
import {FileManager} from "@fik/file-manager";

const Downloads = () => {
    const {filesTree, isSuccess} = useCmsFiles();

    if (!isSuccess) {
        return null;
    }

    return <FileManager filesTree={filesTree} />
};

export default Downloads;