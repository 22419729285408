import React from 'react';
import YouTube from "react-youtube";

const opts = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        enablejsapi: '1'
    }
};

const YouTubeDetail = (item) => {
    const {label, date, description, youtube} = item;
    return <div className="m-b-md">
        <YouTube
            containerClassName="youtube-detail"
            videoId={youtube}
            opts={opts}
        />
        <h4>{label}</h4>
        <p><em>{date}</em> | {description}</p>
        <hr />
    </div>;
};
export default YouTubeDetail;