import React from "react";
import {Box, Errors} from "@fik/ui";
import {Channel} from "@fik/document";
import handleClick from "@fik/utils/handleClick";
import usePartnerContext from "../partner/usePartnerContext";
import {PARTNER_VIEWS} from "../partner/usePartner";
import PartnerEdit from "../partner/PartnerEdit";
import {ResellerByRidFinder} from "@fik/reseller-edit";

const DocumentPartner = ({messages}) => {

    const {partner, isPartnerFilled, view, pick, edit, cancel, confirmChange} = usePartnerContext();

    const buttons = [];

    if ((view === PARTNER_VIEWS.PICK) || view === PARTNER_VIEWS.EDIT) {
        buttons.push(<button key="cancel" onClick={handleClick(cancel)} className='btn btn-xs btn-danger m-r-xs'>Zrušit <i className='fa fa-trash'/></button>);
    } else if (isPartnerFilled) {
        buttons.push(<button key="pick" onClick={handleClick(pick)} className='btn btn-xs btn-primary m-r-xs'>Vybrat <i className='fa fa-search'/></button>);
        buttons.push(<button key="edit" onClick={handleClick(edit)} className='btn btn-xs btn-primary m-r-xs'>Upravit <i className='fa fa-pencil'/></button>);
    }

    return <Box title="Partner"
                tools={buttons}>
        {messages && <Errors messages={messages}/>}
        {(view === PARTNER_VIEWS.PICK) && <ResellerByRidFinder
            autoConfirm={!isPartnerFilled}
            channelValues={partner}
            onConfirm={confirmChange}
            onCancel={cancel}
        />}
        {view === PARTNER_VIEWS.VIEW && <Channel {...partner} isCustomer={false}/>}
        {view === PARTNER_VIEWS.EDIT && <PartnerEdit partner={partner} changePartner={confirmChange} isCustomer={false}/>}
    </Box>;
};

export default DocumentPartner;
