import React, {useEffect} from 'react';
import {Box, Errors} from "@fik/ui";
import DocumentSaved from "./DocumentSaved";
import DocumentAdditionalInfo from "./DocumentAdditionalInfo";
import {useDocumentContext} from "../useDocumentContext";
import DocumentCustomerContainer from "./DocumentCustomerContainer";
import DocumentEvidenceItems from "./DocumentEvidenceItems";
import DocumentEvidenceItem from "./DocumentEvidenceItem";
import documentViews from "./../documentViews";
import DocumentAttachments from "./DocumentAttachments";
import {DisabledOverlay} from "@fik/ui";
import DocumentCheckboxes from "./DocumentChekboxes";
import DocumentBranchOffice from "./DocumentBranchOffice";
import DocumentPartnerContainer from "./DocumentPartnerContainer";
import {useDataSource} from "@fik/data-source";
import usePureDataSource from "@fik/data-source/usePureDataSource";

const DocumentEdit = ({dials}) => {

    const {
        document,
        view: {currentView, enableEvidenceItem, enableAttachment, enablePartner, enableBranchOffice, enableButtons},
        initialized,
        saving: {saveDocument, isSaved, documentType},
        evidenceItemEdit,
        validation: {availableButtons, validationMessages},
        isEpc,
        confirmPartnerChange,
        documentType: resourceName
    } = useDocumentContext();

    const {reset} = usePureDataSource({name: documentType || resourceName});

    useEffect(() => {
        if (isSaved) {
            reset();
        }
    }, [reset, isSaved]);

    if (!document || !initialized) {
        return null;
    }

    let content;

    if (isSaved) {
        content = <div className='row'>
            <div className="col-sm-12">
                <Box>
                    <h1>Úspěšně odesláno</h1>
                    <DocumentSaved documentType={documentType} document={document}/>
                </Box>
            </div>
        </div>;
    } else {
        content = <>
            <div className='row'>
                <div className='col-sm-12'>
                    <Errors messages={validationMessages.filter(message => message.path.indexOf('owner') !== 0 && message.path.indexOf('partner') !== 0 && message.path.indexOf('evidenceItems') !== 0)}/>
                    <DisabledOverlay on={!enableBranchOffice}>
                        {dials.branchOffices && dials.branchOffices.length > 0 ? <DocumentBranchOffice branchOffices={dials.branchOffices} /> : null}
                    </DisabledOverlay>
                    <DocumentPartnerContainer />
                    <DocumentCustomerContainer />
                    <DisabledOverlay on={!enableEvidenceItem}>
                        {currentView === documentViews.EDIT_EVIDENCE_ITEMS
                            ? <DocumentEvidenceItem
                                document={document}
                                confirm={evidenceItemEdit.confirmEvidenceItemEdit}
                                cancel={evidenceItemEdit.cancelEvidenceItemEdit}
                                selection={evidenceItemEdit.selection}/> : <DocumentEvidenceItems/>}
                    </DisabledOverlay>
                </div>
            </div>

            <div className='row'>
                <div className='col-sm-12'>
                    <DisabledOverlay on={!enableAttachment}>
                    <Box title="Poznámka a přílohy (nepovinné)">
                        <div className='row'>
                            <div className='col-sm-12 col-md-6'>
                                <DocumentAdditionalInfo/>
                            </div>
                            <div className='col-sm-12 col-md-6'>
                                <DocumentAttachments/>
                            </div>
                        </div>
                    </Box>
                    </DisabledOverlay>
                </div>
            </div>

            <div className="text-right m-t-md">
                <DisabledOverlay on={!enableButtons}>
                    <DocumentCheckboxes/>
                    {availableButtons.map(({documentType, label}) => (
                        <button key={documentType} onClick={() => saveDocument(documentType, document)} type="button"
                                className={"btn btn-lg btn-success m-l-sm btn-" + documentType}>{label}</button>))}
                </DisabledOverlay>
            </div>
        </>
    }

    return content;
};

export default DocumentEdit;
