import React from 'react';

const EnumValue = (props) => {
    if (!props.enum) {
        return (<>{props.value || '--'}</>);
    }

    return (<>{(props.enum[props.value] || props.value) || '--'}</>);
};

export default EnumValue;