import {useCallback, useContext, useMemo, useState} from 'react';
import {
    getCount,
    getPage,
    getPageElements,
    getPageIsLoading,
    getPages,
    selectCurrentBySearchParams,
    getPageLoadingState, getPageIncludes
} from "./selectors";
import {useDispatch} from "react-redux";
import {useResourceContext, ModelContext} from "@fik/model";
import {createActionNames, useResourceSelector} from "@fik/api";
import {loadDataSource, loadDataSourceCount} from "./actions";
import {useHookToLoading} from "@fik/loading";

export const ELEMENTS_PER_PAGE = 20;

const useDataSourceSelector = (context, {searchParams, pageNumber, limit, params}) => {
    return useResourceSelector(context, (state) => {
        const searchParamsState = selectCurrentBySearchParams(state, {
            page: pageNumber,
            limit: limit || ELEMENTS_PER_PAGE, ...params, ...searchParams
        }, {});
        const pages = getPages(searchParamsState);
        const selectedPage = getPage(pages, pageNumber);
        return {
            loadingState: getPageLoadingState(selectedPage),
            page: pageNumber,
            items: getPageElements(selectedPage),
            loading: getPageIsLoading(selectedPage),
            includes: getPageIncludes(selectedPage),
            pagination: {
                page: pageNumber,
                count: getCount(searchParamsState),
                limit: limit
            }
        }
    }, [searchParams, pageNumber, limit, params]);
};

export const usePureDataSource = ({name, limit, initialSearchParams, dataSourceResource = 'data-source', params}) => {

    const {storeName, name: resource} = useResourceContext(name);

    const dispatch = useDispatch();
    const model = useContext(ModelContext);

    const [searchParams, setSearchParams] = useState(initialSearchParams);
    const [page, setPage] = useState(1);

    const {loadingState, ...selectedState} = useDataSourceSelector(
        {storeName, name: dataSourceResource},
        {searchParams, pageNumber: page, limit: limit || ELEMENTS_PER_PAGE, params: params || {}}
    );

    const memoizedParams = useMemo(() => params ? params : {}, [params]);

    const load = useCallback((timeToRefresh = 3) => {
        dispatch(
            loadDataSource(model, timeToRefresh)({storeName, name: dataSourceResource})
            (
                resource,
                {page, limit: limit || ELEMENTS_PER_PAGE, ...memoizedParams, ...searchParams}
            )
        );
    }, [dataSourceResource, dispatch, limit, model, page, resource, searchParams, storeName, memoizedParams]);

    const count = useCallback(() => {
        dispatch(
            loadDataSourceCount(model, 0)({storeName, name: dataSourceResource, subAction: 'count'})
            (
                resource,
                {page, limit: limit || ELEMENTS_PER_PAGE, ...memoizedParams, ...searchParams}
            )
        );
    }, [dataSourceResource, dispatch, limit, model, page, resource, searchParams, storeName, memoizedParams]);

    const reset = useCallback(() => {
        const actions = createActionNames([storeName, dataSourceResource]);
        dispatch({type: actions.FETCH_RESET});
    }, [dispatch, storeName, dataSourceResource]);

    useHookToLoading(`${name}-date-source`, loadingState);

    // const handleSetPage = useCallback((page) => {
    //     setPage();
    // }, [setPage]);

    return {
        ...selectedState,
        loadingState,
        page,
        searchParams,
        setPage,
        setSearchParams: useCallback((params) => {
            setPage(1);
            setSearchParams(params);
        }, [setSearchParams, setPage]),
        load,
        count,
        reset,
        refresh: load // for backward compatibility
    };
};

export default usePureDataSource;