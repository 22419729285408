import {selectApiState} from "@fik/api/selectors";
import {resourceAsyncCallAction} from "@fik/api";

const shouldUpdateEntity = ({storeName, name: resource, timeToRefresh = 60}) => ([params], getState) => {
    const [loading, loaded, results, lastUpdateTime] = selectApiState(storeName, resource, params)(getState());
    if (!loading) {
        if (!params) {
            return !loaded;
        }

        if (!params.id) {
            if (!loaded) {
                return true;
            }
        } else if (params.id) {
            if (!loaded || (loaded && params.id !== results.id)) {
                return true;
            }
        }
    }

    return timeToRefresh < 0 ? isTimeToRefresh(lastUpdateTime,timeToRefresh * 1000) : false;
};


export const requestGetEntity = (model) => (context, alwaysFetch = false) => {
    return resourceAsyncCallAction(
        {...context},
        alwaysFetch !== true ? shouldUpdateEntity(context) : null,
        'get')
    ((...args) => model.entity().getEntity(context, ...args));
};

export const requestPostEntity = (model) => (context) => {
    return resourceAsyncCallAction(context, null, 'post')((...args) => {
        return model.entity().postEntity(context, ...args)
    });
};

export const requestPutEntity = (model) => (context) => {
    return resourceAsyncCallAction(context, null, 'put')((...args) => {
        return model.entity().putEntity(context, ...args)
    });
};

export const requestDeleteEntity = (model) => (context) => {
    return resourceAsyncCallAction(context, null, 'delete')((...args) => model.entity().deleteEntity(context, ...args));
};

export const requestEntityAction = (model) => (context) => {
    return resourceAsyncCallAction(context, null, 'action')((...args) => {
        return model.entity().action(context, ...args)
    });
};

const isTimeToRefresh = (prevTime, diff) => {
    return Date.now() - prevTime > diff;
};