import React, {useCallback, useMemo} from 'react';
import {AddressBookDetail, BranchSelector, ContactSelector} from "@fik/components";
import {Form} from "@mouseover/react-form";
import {Col, Row} from "@fik/ui";

const VALIDATION_RULES = {};

const PartnerEdit = ({partner, viewType, changePartner}) => {
    const {addressBook, contact, branchOffice, contacts, branchOffices, categoryInfo} = partner;

    const handleSubmit = useCallback(({contactId, branchOfficeId}, valid) => {
        if (valid) {
            changePartner({
                ...partner,
                contact: contactId ? contacts.find((element) => element.contactId === contactId) : null,
                branchOffice: branchOfficeId ? branchOffices.find((element) => element.branchOfficeId === branchOfficeId) : null
            })
        }
    }, [branchOffices, contacts, changePartner, addressBook]);

    const initialValues = useMemo(() => ({
        contactId: contact ? contact.contactId : null,
        branchOfficeId: branchOffice ? branchOffice.branchOfficeId : null
    }), [contact, branchOffice]);

    return (
        <Form
            onSubmit={handleSubmit}
            validationRules={VALIDATION_RULES}
            values={initialValues}
            render={(form) => {
                return <>
                    <Row>
                        <AddressBookDetail viewType={viewType} addressBook={addressBook} label='Firma'/>
                        <Col sm={4}>
                            <BranchSelector name='branchOfficeId' branchOffices={branchOffices} form={form}/>
                        </Col>
                        <Col sm={4}>
                            <ContactSelector name='contactId' contacts={contacts} form={form}/>
                        </Col>
                        {categoryInfo ? <div className="col-sm-4">{categoryInfo.name}<br/>{categoryInfo.commission}</div> : null}

                    </Row>
                    <Row>
                        <Col sm={12} className='text-right'>
                            <button type='submit' className="btn btn-success" disabled={!form.validation.valid}>Nastavit</button>
                        </Col>
                    </Row>
                </>
            }}>

        </Form>
    );
};

export default PartnerEdit;