import React, {useCallback} from 'react';
import {useEvidenceItemCalculator, Selector, Discount, Promo, Preview, Ransom} from "./../index";
import {Errors} from "@fik/ui";
import PropTypes from 'prop-types';
import handleClick from "@fik/utils/handleClick";
import DiscountCompetitors from "./DiscountCompetitors";
import useCompetitors from "../useCompetitors";
const Calculator = ({confirm, cancel, calculationData, onEvidenceItemChange}) => {

    const {
        types,
        products,
        periods,
        possibleDiscounts,
        possiblePromos,
        evidenceItem,
        form,
        loading,
        selectType,
        minimum,
        maximum,
        itemMessages,
        ransom
    } = useEvidenceItemCalculator({...calculationData, onEvidenceItemChange});

    const handleConfirm = useCallback(() => {
        confirm(evidenceItem);
    }, [confirm, evidenceItem]);

    const competitors = useCompetitors({evidenceItem,discounts:possibleDiscounts});

    return (
        <form>
            <Errors className="m-l-n m-r-n m-b-xs" messages={itemMessages ? itemMessages : []}/>
            <div className="row row-flex">
                <div className="col-sm-4 b-r">
                    <Selector
                        form={form}
                        selectType={selectType}
                        types={types}
                        products={products}
                        periods={periods}
                        minimum={minimum}
                        maximum={maximum}
                    />
                </div>
                <div className="col-sm-4 b-r">
                    <Discount
                        form={form}
                        discounts={possibleDiscounts}
                    />

                    {competitors && <DiscountCompetitors
                        form={form}
                        competitors={competitors} />}

                    <Promo
                        form={form}
                        promos={possiblePromos}
                    />

                    <Ransom
                        form={form}
                        ransom={ransom}
                        />

                </div>
                <div className="col-sm-4">
                    {loading ? (<div>Loading...</div>) :
                        (<Preview
                            evidenceItem={evidenceItem}
                        />)}
                </div>
            </div>
            {confirm || cancel ?
                <div className='row row-flex'>
                    <div className='col-sm-12'>
                        <div className='form-group'>
                            <div className='pull-right'>
                                {cancel ? <button onClick={handleClick(cancel)} className="btn btn-white m-r-sm">Zrušit</button> : null}
                                {confirm ? <button onClick={handleClick(handleConfirm)}
                                                   disabled={!evidenceItem}
                                                   className="btn btn-primary">Potvrdit</button> : null}
                            </div>
                        </div>
                    </div>
                </div> : null}
        </form>
    )
};


Calculator.propTypes = {
    initialSelection: PropTypes.object,
    onEvidenceItemChange: PropTypes.func,
    onLoading: PropTypes.func,
    confirm: PropTypes.func,
    cancel: PropTypes.func
};

export default Calculator;