import {getPage, getPages, selectCurrentBySearchParams} from "./selectors";
import {resourceAsyncCallAction} from "@fik/api";
import {getStoreResourceState} from "@fik/api/selectors";

const shouldUpdateDataSource = ({storeName}, timeToRefresh = 300) => ([resource, params], getState) => {
    const searchParamsState = selectCurrentBySearchParams(getStoreResourceState(storeName, 'data-source')(getState()), params, {});
    const pages = getPages(searchParamsState);
    const selectedPage = getPage(pages, params.page, false);

    if (selectedPage === false) {
        //- not defined at all
        return true;
    }

    if (selectedPage.isFetching) {
        //- already fetching
        return false;
    }

    if (timeToRefresh === 0) {
        return true;
    }

    return isTimeToRefresh(selectedPage.lastUpdateTime, timeToRefresh * 1000);
};

const isTimeToRefresh = (prevTime, diff) => {
    return Date.now() - prevTime > diff;
};

export const loadDataSource = (model, timeToRefresh = 300) => (context) => {
    return resourceAsyncCallAction(context, shouldUpdateDataSource(context, timeToRefresh))(model.dataSource(context.name).getDataSource);
};

export const loadDataSourceCount = (model) => (context) => {
    return resourceAsyncCallAction(context)(model.dataSource(context.name).getDataSourceCount);
};