import React from 'react';
import {useDials} from "@fik/dials";
import DocumentEdit from "./../components/DocumentEdit";
import {useEntity} from "@fik/entity";
import {DocumentProvider} from "./../components/DocumentProvider";
import {isEmpty} from "@mouseover/js-utils/src";
import PropTypes from "prop-types";
import {useEvidenceItemsStorage} from "@fik/calculator";


const DocumentEntityCreate = ({name, isEpc, params}) => {

    const existing = !isEmpty(params.licensePublicId || params.licenseEPLI || params.licenseKey || params.licenseUsername);
    const {entity} = useEntity({name, params: {action: 'new'}}, null, params);
    const dials = useDials({name});
    const {evidenceItems} = useEvidenceItemsStorage();

    return (
        entity ? <DocumentProvider document={entity}
                          documentType={name}
                          isEpc={isEpc}
                          evidenceItem={params.evidenceItem && evidenceItems[params.evidenceItem] ? evidenceItems[params.evidenceItem] : null}
                          existing={existing}>
            <DocumentEdit dials={dials} />
        </DocumentProvider> : null
    )
};

DocumentEntityCreate.propTypes = {
    name: PropTypes.string,
    params: PropTypes.shape({
        licensePublicId: PropTypes.string,
        licenseKey: PropTypes.string,
        licenseUsername: PropTypes.string,
        licenseEPLI: PropTypes.string
    })
};


export default DocumentEntityCreate;