import stringifyObjectKeyAndValues from "@fik/utils/stringifyObjectKeyAndValues";
import {getOnPath} from "@mouseover/js-utils";

const setLastUpdate = (filtersKey) => (state) => ({
    ...state,
    [filtersKey]: {
        ...state[filtersKey],
        lastUpdateTime: Date.now()
    }
});

const setPage = (filtersKey, page) => (state, action, pageData) => ({
    ...state,
    [filtersKey]: {
        ...state[filtersKey],
        pages: {
            ...state[filtersKey].pages,
            [page]: pageData
        }
    }
});

const setCount = (filtersKey) => (state, action) => ({
    ...state,
    [filtersKey]: {
        ...state[filtersKey],
        count: action.payload.count,
    }
});

const PAGE_COMMON_KEYS = ['items', 'page', 'count', 'limit'];

const getIncludesFromBody = (action) => Object.entries(action.payload).reduce((o, [key, value]) => {
    if (PAGE_COMMON_KEYS.indexOf(key) === -1) {
        o[key] = value;
    }
    return o;
}, {});

const getCurrentPage = (filterKey, page) => (state) => getOnPath(state, [filterKey, 'pages', page]);

const updateFor = (action, callback) => {
    const {page, ...searchParams} = {...action.params[1]};
    delete searchParams.page;
    const filtersKey = stringifyObjectKeyAndValues(searchParams);

    return callback({
        setLastUpdate: setLastUpdate(filtersKey),
        setPage: setPage(filtersKey, page),
        setCount: setCount(filtersKey),
        getCurrentPage: getCurrentPage(filtersKey, page)
    });
};

const pageInitialState = {
    isFetching: false,
    isRefreshing: false,
    isSuccess: false,
    isFailed: false,
    lastUpdateTime: null,
    elements: [],
    includes: {}
};

const setCountAndLastUpdate = (context, state, action) => context.setCount(context.setLastUpdate(state), action);
const setCountAndLastUpdateIfCount = (context, state, action) => (action.params[1].count !== false)
    ? setCountAndLastUpdate(context, state, action)
    : context.setLastUpdate(state);

const isRefreshing = (state) => state && state.isSuccess === true;

export const dataSourceReducer = (types, initialState = {}) => {
    const {
        FETCH_INIT,
        FETCH_SUCCESS,
        FETCH_FAILURE,
        FETCH_RESET,
        COUNT_FETCH_INIT,
        COUNT_FETCH_SUCCESS,
        COUNT_FETCH_FAILURE,
    } = types;

    const typesArray = Object.values(types);

    return (state = initialState, action) => {
        if (typesArray.indexOf(action.type) === -1) {
            return state;
        }

        switch (action.type) {
            case FETCH_INIT:
                return updateFor(action, (context) => {
                    const current = context.getCurrentPage(state);
                    return context.setPage(
                        context.setLastUpdate(state),
                        action,
                        {
                            ...pageInitialState,
                            isRefreshing: current && current.isSuccess,
                            isFetching: true,
                            lastUpdateTime: Date.now(),
                            elements: current ? current.elements : []
                        });
                });

            case FETCH_SUCCESS:
                return updateFor(action, (context) => context.setPage(
                    setCountAndLastUpdateIfCount(context, state, action),
                    action,
                    {
                        ...pageInitialState,
                        isSuccess: true,
                        lastUpdateTime: Date.now(),
                        elements: action.payload.items,
                        includes: getIncludesFromBody(action)
                    }));

            case COUNT_FETCH_SUCCESS:
                return updateFor(action, (context) => context.setCount(
                    context.setLastUpdate(state),
                    action
                ));
            case FETCH_FAILURE:
                return updateFor(action, (context) => context.setPage(
                    context.setLastUpdate(state),
                    action,
                    {
                        ...pageInitialState,
                        isFailed: true,
                        lastUpdateTime: Date.now(),
                        elements: []
                    }));

            case FETCH_RESET:
                return {};

            default:
                return state;
        }
    }
};