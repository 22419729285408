import {Modal} from "@fik/ui";
import handleClick from "@fik/utils/handleClick";
import React, {useCallback, useMemo} from "react";
import {Form} from "@mouseover/react-form";
import {TextInput} from "@fik/form";

const ConfirmOrderModal = ({entity, modal, orderOffer}) => {

    const handleSubmit = useCallback((values, valid) => {
        if (valid) {
            orderOffer(entity.id, values);
            modal.hide();
        }
    }, [orderOffer, modal, entity]);

    const values = useMemo(() => ({customContractId: entity.customContractId}), [entity]);

    return  <Modal  {...modal}
                    renderHeader={() => <>
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true"
                                onClick={handleClick(modal.hide)}>×
                        </button>
                        <h4 className="modal-title">Objednat</h4>
                    </>}
                   >
        <Form
            values={values}
            validationRules={{}}
            onSubmit={handleSubmit}
            render={(form =>
                    <TextInput form={form}
                              name='customContractId'
                              label='(NEPOVINNÉ) Zadejte případné číslo objednávky, které se propíše na fakturu'
                    />
            )}>
            <button onClick={handleClick(modal.hide)} className='btn btn-warning block pull-left m-b'>Zpět</button>
            <button type='submit' className='btn btn-primary block pull-right m-b'>OBJEDNAT</button>
            <div className='clearfix' />
        </Form>
    </Modal>
};

export default ConfirmOrderModal;

