import {dataSourceReducer} from "./dataSource";
import {createActionNames} from "@fik/api";

export const createDataSourceReducer = (storeName, resource = 'data-source') => {
    const actionNames = createActionNames([storeName, resource]);
    const countActionNames = createActionNames([storeName, resource, 'count']);
    return {
        [resource]: dataSourceReducer({
            ...actionNames,
            COUNT_FETCH_INIT: countActionNames.FETCH_INIT,
            COUNT_FETCH_SUCCESS: countActionNames.FETCH_SUCCESS,
            COUNT_FETCH_FAILURE: countActionNames.FETCH_FAILURE,
        }, {})
    }
};

export default createDataSourceReducer;