import React from 'react';
import {NavigationItem} from "@fik/navigation";

const LicenseOrderLink = ({item, children, ...props}) =>
    <NavigationItem {...props}
            to="order:create"
            query={{licenseEPLI: item.licenseEPLI}}>
        {children || "Objednat"}
    </NavigationItem>;

export default LicenseOrderLink;