import React from "react";
import PropTypes from 'prop-types';
import {Form, FormField} from "@mouseover/react-form";

const CustomerSearchForm = ({onSubmit, children, values}) => {
    return <Form
        values={values}
        validationRules={{}}
        onSubmit={onSubmit}
        render={(form) => (
            <>
                <div className="row">
                    <div className="col-sm-12">
                        <FormField form={form}
                                   name='pattern'
                                   elementType='input'
                                   elementConfig={{
                                       placeholder: 'Název, e-mail, IČ nebo ID licence'
                                   }}
                                   //message='Pokud hledáte podle NÁZVU subjektu, hledáte pouze nad vlastními zákazníky. Pokud je to pro vás nový subjekt, je potřeba hledat podle IČ nebo ID licence.'
                                   renderPrepend={() => <span className='input-group-addon'><i className='fa fa-search' /> Existující</span>}
                                   renderAppend={() => (<span className="input-group-btn">
                                        <button type="submit" className="btn btn-primary">Hledat</button>
                                    </span>)}
                        />
                    </div>
                </div>
                {children}
            </>
        )}/>;
};

CustomerSearchForm.propTypes = {
    onSubmit:PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
    values: PropTypes.object
};

export default CustomerSearchForm;