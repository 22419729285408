import React from 'react';
import {Email, Phone} from "@fik/ui";

const Contact = (props) => {
    return (<React.Fragment>
        <div>{props.contactSurname} {props.contactFirstname}</div>
        {props.contactEmail ? (<div><Email email={props.contactEmail} /></div>) : null}
        {props.contactPhone ? (<div><Phone phone={props.contactPhone} /></div>) : null}
    </React.Fragment>);
};

export default Contact;