export const arrayToDictionary = (array, key, label) => {
    return array && Array.isArray(array)
        ? array.reduce(
            (obj, item) => {
                obj[item[key]] = item[label];
                return obj;
            },
            {}
        ) : {};
};

export default arrayToDictionary;