export const createRequestState = (actions, type) => {
    switch (type) {
        case actions.FETCH_INIT:
            return {
                isFetching: true,
                isSuccess: false,
                isFailed: false
            };

        case actions.FETCH_SUCCESS:
            return {
                isFetching: false,
                isSuccess: true,
                isFailed: false
            };

        case actions.FETCH_FAILURE:
            return {
                isFetching: false,
                isSuccess: false,
                isFailed: true,
            };

        case actions.FETCH_RESET:
            return {
                isFetching: false,
                isSuccess: false,
                isFailed: false,
            };

        default:
            return {};
    }
};