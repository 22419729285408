import React, {useCallback, useEffect} from "react";
import {Box, Col, Row} from "@fik/ui";
import {useEntityAction, usePureEntity} from "@fik/entity";
import {ContactForm} from "@fik/components";
import {useSelector} from "react-redux";

const ContactEdit = ({id}) => {

    // contact to edit
    const [{entity: values}, load] = usePureEntity({name: 'contact', params: {id}});
    // save
    const [saveResult, save] = useEntityAction({name: 'contact', action: 'save', params: {id}});
    // resend login
    const [, resendLogin] = useEntityAction({name: 'contact', params: {id}, action: 'resendLogin'});

    //- needed for checking if edited contact is current user
    const {userId} = useSelector(state => state.auth);

    //- load on first render (load callback should not change)
    useEffect(() => load(), [load]);

    //- refresh after save
    useEffect(() => {
        if (saveResult.isSuccess) {
            load();
        }
    }, [load, saveResult.isSuccess]);

    const onSubmit = useCallback((values, valid) => {
        save({values});
    }, [save]);

    return <Row>
        <Col lg={6} sm={12}>
            <Box>
                {saveResult.isSuccess ? <div className='alert alert-info'>Uloženo</div> : null}
                {saveResult.isFailed ? <div className='alert alert-error'>Nepodařilo se uložit</div> : null}
                <ContactForm
                    values={values}
                    selfEdit={values && userId === values.contactId}
                    onResendLoginRequested={resendLogin}
                    onSubmit={onSubmit}/>
            </Box>
        </Col>
    </Row>;
};

export default ContactEdit;