import React from "react";
import PropTypes from "prop-types";

const EvidenceItemPromo = ({itemPromoDiscount}) => <>
    {itemPromoDiscount || '--'}
</>;

EvidenceItemPromo.propTypes = {
    itemPromoDiscount: PropTypes.number
};

export default EvidenceItemPromo;
