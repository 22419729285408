import {useReducer, useCallback} from 'react';
import shallowEqual from "react-redux/es/utils/shallowEqual";
import isEmptyChannel from "../utils/isEmptyChannel";

/**
 * User confirm changes after owner/customer edit
 *
 * @type {string}
 */
const CONFIRM_CUSTOMER_CHANGE = 'CONFIRM_CUSTOMER_CHANGE';

/**
 * User confirm changes after partner edit
 *
 * @type {string}
 */
const CONFIRM_PARTNER_CHANGE = 'CONFIRM_PARTNER_CHANGE';

/**
 * Sets initial document values
 *
 * @type {string}
 */
const SET_DOCUMENT = 'SET_DOCUMENT';

/**
 * Reset customer to initial values (from SET_DOCUMENT action values)
 *
 * @type {string}
 */
const RESET_CUSTOMER = 'RESET_CUSTOMER';

/**
 * Some document values was changed by user
 *
 * @type {string}
 */
const CHANGE_VALUES = 'CHANGE_VALUES';

/**
 * User invokes concrete item removal
 *
 * @type {string}
 */
const REMOVE_EXISTING_EVIDENCE_ITEM = 'REMOVE_EXISTING_EVIDENCE_ITEM';

/**
 * User confirm existing evidence item new values
 *
 * @type {string}
 */
const REPLACE_EVIDENCE_ITEM = 'REPLACE_EVIDENCE_ITEM';

/**
 * User confirm new evidence item values
 *
 * @type {string}
 */
const ADD_EVIDENCE_ITEM = 'ADD_EVIDENCE_ITEM';

/**
 * User adds attachment
 *
 * @type {string}
 */
const ADD_ATTACHMENT = 'ADD_ATTACHMENT';

/**
 * User adds attachments
 *
 * @type {string}
 */
const ADD_ATTACHMENTS = 'ADD_ATTACHMENTS';

/**
 * User removes attachment
 *
 * @type {string}
 */
const REMOVE_ATTACHMENT = 'REMOVE_ATTACHMENT';

const initialState = {
    shouldValidate: false,
    document: null,
    initialized: false,
    existing: false,
    emptyDocument: {owner: {}},
    potentialOwner: false
};

const replaceDocumentEvidenceItem = (document, oldItem, newItem) => {
    const evidenceItems = Array.isArray(document.evidenceItems) ? [...document.evidenceItems] : [];
    const index = evidenceItems.indexOf(oldItem);
    if (index >= 0) {
        evidenceItems[index] = newItem;
    }
    return {...document, evidenceItems};
};

/**
 * Checks if new document values actualy change state.
 * If so return new state. If not return old (no state changes, no refresh, no render, no validation)
 *
 * @param state
 * @param newDocumentValues
 * @returns {{document, shouldValidate: boolean}|*}
 */
const changeDocumentWithShouldValidateCheck = (state, newDocumentValues) => {
    const document = {...state.document, ...newDocumentValues};
    if (!shallowEqual(state.document, document)) {
        return {
            ...state,
            shouldValidate: !newDocumentValues.hasOwnProperty('customContractId'),
            document
        }
    }

    return state;
};

const documentReducer = (state, action) => {
    switch (action.type) {
        case SET_DOCUMENT:
            return {
                ...state,
                initialized: true,
                shouldValidate: !!action.existing,
                existing: !!action.existing,
                document: {...action.document},
                potentialOwner: false,
                emptyDocument: {...action.document} // TODO with prefiled licence etc is not true
            };

        case CONFIRM_CUSTOMER_CHANGE:
            const newOwner = {...action.owner};
            if (newOwner.addressBook && newOwner.contact) {
                //- pokud to neni firma nevyplnuje se name
                if (!newOwner.addressBook.companyId) {
                    newOwner.addressBook.email = newOwner.contact.contactEmail;
                    newOwner.addressBook.phone = newOwner.contact.contactPhone;
                    newOwner.addressBook.name = newOwner.contact.contactSurname + ' ' + newOwner.contact.contactFirstname;
                } else if (!newOwner.addressBook.addressBookId) {
                    newOwner.addressBook.email = newOwner.contact.contactEmail;
                    newOwner.addressBook.phone = newOwner.contact.contactPhone;
                }
            }
            const newState = changeDocumentWithShouldValidateCheck(state, {owner: newOwner});
            newState.potentialOwner = false;
            if (newState === state && !state.existing) {
                return {
                    ...newState,
                    existing: true
                }
            }
            return newState;

        case CONFIRM_PARTNER_CHANGE:
            const newPartner = {...action.partner};
            return changeDocumentWithShouldValidateCheck(state, {partner: newPartner});

        case RESET_CUSTOMER:
            return {
                ...state,
                existing: false,
                potentialOwner: false,
                document: {...state.document, owner: state.emptyDocument.owner},
            };

        case ADD_EVIDENCE_ITEM:
            const evidenceItems = Array.isArray(state.document.evidenceItems) ? [...state.document.evidenceItems] : [];
            evidenceItems.push(action.evidenceItem);
            return {
                ...state,
                shouldValidate: true,
                potentialOwner: isEmptyChannel(state.document.owner) && evidenceItems.length === 1 && action.evidenceItem.itemLicenseIdCodes,
                document: {...state.document, evidenceItems},
            };

        case ADD_ATTACHMENT:
            const attachments = Array.isArray(state.document.attachments) ? [...state.document.attachments] : [];
            attachments.push(action.attachment);
            return {
                ...state,
                shouldValidate: true,
                document: {...state.document, attachments},
            };

        case ADD_ATTACHMENTS:

            return {
                ...state,
                shouldValidate: true,
                document: {
                    ...state.document,
                    //- kdyz uz nejake ma tak se spoji jinak se jen pridaji
                    attachments: Array.isArray(state.document.attachments) ? [...state.document.attachments, ...action.attachments] : action.attachments
                },
            };

        case REMOVE_ATTACHMENT:
            const currentAttachments = [...state.document.attachments];
            currentAttachments.splice(currentAttachments.indexOf(action.attachment), 1);

            return {
                ...state,
                shouldValidate: true,
                document: {
                    ...state.document,
                    attachments: currentAttachments
                }
            };


        case REPLACE_EVIDENCE_ITEM:
            return {
                ...state,
                shouldValidate: true,
                document: replaceDocumentEvidenceItem(state.document, action.oldItem, action.newItem),
            };

        case CHANGE_VALUES:
            return changeDocumentWithShouldValidateCheck(state, action.values);

        case REMOVE_EXISTING_EVIDENCE_ITEM:
            const currentEvidenceItems = [...state.document.evidenceItems];
            currentEvidenceItems.splice(currentEvidenceItems.indexOf(action.evidenceItem), 1);

            return {
                ...state,
                shouldValidate: true,
                document: {
                    ...state.document,
                    evidenceItems: currentEvidenceItems
                }
            };

        default:
            return state;
    }
};

export const useDocument = ({validate, isEpc}) => {
    const [state, dispatch] = useReducer(documentReducer, initialState);

    const confirmCustomerChange = useCallback((owner) => {
        dispatch({type: CONFIRM_CUSTOMER_CHANGE, owner});
    }, [dispatch]);

    const confirmPartnerChange = useCallback((partner) => {
        dispatch({type: CONFIRM_PARTNER_CHANGE, partner});
    }, [dispatch]);

    const setDocument = useCallback((document, existing = false) => {
        dispatch({type: SET_DOCUMENT, document, existing});
    }, [dispatch]);

    const resetCustomer = useCallback(() => {
        dispatch({type: RESET_CUSTOMER});
    }, [dispatch]);

    const addEvidenceItem = useCallback((evidenceItem) => {
        dispatch({type: ADD_EVIDENCE_ITEM, evidenceItem});
    }, [dispatch]);

    const removeExitingEvidenceItem = useCallback((evidenceItem) => {
        dispatch({type: REMOVE_EXISTING_EVIDENCE_ITEM, evidenceItem});
    }, [dispatch]);

    const changeValues = useCallback((values) => {
        dispatch({type: CHANGE_VALUES, values});
    }, [dispatch]);

    const addAttachment = useCallback((attachment) => {
        dispatch({type: ADD_ATTACHMENT, attachment});
    }, [dispatch]);

    const removeAttachment = useCallback((attachment) => {
        dispatch({type: REMOVE_ATTACHMENT, attachment});
    }, [dispatch]);

    const addAttachments = useCallback((attachments) => {
        dispatch({type: ADD_ATTACHMENTS, attachments});
    }, [dispatch]);

    const replaceEvidenceItem = useCallback((oldItem, newItem) => {
        dispatch({type: REPLACE_EVIDENCE_ITEM, oldItem, newItem});
    }, [dispatch]);

    return {
        initialDocument: state.emptyDocument,
        document: state.document,
        isCustomerFilled: state.document && !isEmptyChannel(state.document.owner),
        isPartnerFilled: state.document && !isEmptyChannel(state.document.partner),
        existing: state.existing,
        initialized: state.initialized,
        shouldValidate: validate === true ? state.shouldValidate : false,
        setDocument,
        confirmCustomerChange,
        confirmPartnerChange,
        resetCustomer,
        changeValues,
        addAttachment,
        addAttachments,
        removeAttachment,
        removeExitingEvidenceItem,
        replaceEvidenceItem,
        addEvidenceItem,
        potentialOwner: state.potentialOwner,
        isEpc
    }
};

export default useDocument;