import React from 'react';
import PropTypes from 'prop-types';

const Address = ({street, zipCode, city}) => {
    if (!street && !zipCode && !city) {
        return null;
    }

    return (<>
        {street ? (<><span>{street}</span><br /></>): null}
        {zipCode ? <span>{zipCode}</span> : null} {city ? <span>{city}</span>: null}{zipCode || city ? <br /> : null}
    </>);
};

Address.propTypes = {
    street: PropTypes.string,
    zipCode: PropTypes.string,
    city: PropTypes.string
};
export default Address;