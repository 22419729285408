import {AUTH_SUCCESS, AUTH_INIT, AUTH_LOGOUT, AUTH_CHECK, AUTH_DONE} from "./actionTypes";

export const authMiddleware = model => {
    const authenticationModel = model.authentication();
    return () => next => action => {
        if (action) {
            switch (action.type) {
                case AUTH_SUCCESS:
                    authenticationModel.setAuthorizationToken(action.payload.token);
                    next({...action, type: AUTH_DONE});
                    break;

                case AUTH_INIT:
                    next(authInit(authenticationModel)(action.actions, action.payload.credentials));
                    break;

                case AUTH_LOGOUT:
                    authenticationModel.logOut();
                    clearAuthorizationHeader(authenticationModel);
                    break;

                case AUTH_CHECK:
                    next(authCheck(authenticationModel, action.actions));
                    break;

                default:
                    // nothing to do
                    break;
            }
        }

        return next(action);
    };
};

const clearAuthorizationHeader = (authenticationModel) => {
    authenticationModel.setAuthorizationToken(null);
};

const authInit = authenticationModel => (actions, credentials) => dispatch => {
    const [authSuccess, checkOfTimeout, authFailed] = actions;
    authenticationModel.authenticate(credentials)
        .then(body => {
            dispatch(authSuccess(body));
            dispatch(checkOfTimeout(body.expiresIn));
            return body;
        })
        .catch((error) => {
            dispatch(authFailed(error));
        });
};


const authCheck = (authenticationModel, actions) => dispatch => {
    const [logOut, authSuccess, checkOfTimeout] = actions;

    if (authenticationModel.authTokenCheck()) {
        const credentials = authenticationModel.getCredentials();
        dispatch(authSuccess(credentials));
        dispatch(checkOfTimeout(credentials.expiresIn));
    } else {
        dispatch(logOut());
    }
};