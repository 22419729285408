import React from 'react';
import Library from "./Library/Library";
import Faqs from "./Faqs/Faqs";

const DatePage = ({type, ...props}) => {

    switch (type) {
        case 'library':
            return <Library {...props} />;
        case 'faqs':
            return <Faqs {...props} />;
        default:
            return null;
    }

};

export default DatePage;