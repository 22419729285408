import React from "react";
import {useEntityAction} from "@fik/entity";
import {Box} from "@fik/ui";
import handleClick from "@fik/utils/handleClick";

const LicensesDownload = () => {
    const [{isSuccess}, requestLicenses] = useEntityAction({name: 'license', action: 'requestLicenses'});
    return <Box>
        {isSuccess
            ? <div className='alert alert-info'>Přehled licencí Vám bude zaslán e-mailem. Zpracování probíhá na pozadí, může to pár minut trvat.</div>
            : <button className='btn btn-primary' onClick={handleClick(requestLicenses)}>Vyžádat zaslání licencí</button>
        }
    </Box>
};

export default LicensesDownload;