import React, {useEffect} from 'react';
import 'react-dates/initialize';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Homepage from './containers/Homepage/Homepage';
import Auth from './containers/Auth/Auth';
import {useDispatch} from "react-redux";
import {Logout} from "@fik/auth";
import {PageRoute, useSetPageInfo} from "@fik/page";
import {PageParams} from "@fik/navigation";
import {Downloads, Page} from "@fik/cms";
import Offers from "./containers/Offers/Offers";
import Deals from "./containers/Deals/Deals";
import ProjectPrices from "./containers/ProjectPrices/ProjectPrices";
import Invoices from "./containers/Invoices/Invoices";
import Settings from "./containers/Profile/Settings/Settings";
import Contacts from "./containers/Profile/Contacts/Contacts";
import Reports from "./containers/Profile/Reports/Reports";
import Orders from "./containers/Orders/Orders";
import Offer from "./containers/Offers/Offer";
import Order from "./containers/Orders/Order";
import Invoice from "./containers/Invoices/Invoice";
import OrderCreate from "./containers/Orders/OrderCreate";
import CalculatorAndPriceLists from "./containers/CalculatorAndPriceLists/CalculatorAndPriceLists";
import {mergeDeep} from "@mouseover/js-utils";
import OfferCreate from "./containers/Offers/OfferCreate";
import ProjectPriceCreate from "./containers/ProjectPrices/ProjectPriceCreate";
import {ValidatorProvider, defaultRuleSet} from "@mouseover/js-validation-hook";
import {checkState} from "@fik/auth/actions";
import OrderNfr from "./containers/OrderNfr";
import BoxEshop from "./containers/BoxEshop";
import MarketingEshop from "./containers/MarketingEshop";
import NewDeal from "./containers/Deals/NewDeal";
import Article from "./containers/Article/Article";
import {LoadingSpinnerOverlay} from "@fik/loading";
import EditDeal from "./containers/Deals/EditDeal";
import LicensesDownload from "./containers/License/LicensesDownload";
import EsetContacts from "./containers/EsetContacts/EsetContacts";
import ContactEdit from "./containers/Contact/ContactEdit/ContactEdit";
import ContactCreate from "./containers/Contact/ContactCreate/ContactCreate";
import CertificationRequest from "./containers/Contact/CertificationRequest/CertificationRequest";
import Password from "./containers/Profile/Password/Password";
import CertificationDownload from "./containers/CertificationDownload/CertificationDownload";
import Certifications from "./containers/Profile/Certifications/Certifications";
import {useNavigation} from "@fik/navigation";
import ExpiringLicenses from "./containers/License/ExpiringLicenses";
import LeakedLicenses from "./containers/License/LeakedLicenses";
import ResellerNfrLicenses from "./containers/License/ResellerNfrLicenses";
import NewestLicenses from "./containers/License/NewestLicenses";
import License from "./containers/License/License";
import useIsAuthenticated from "@fik/auth/useIsAuthenticated";
import useIsAuthInitialized from "@fik/auth/useIsAuthInitialized";
import Consignment from "./containers/Consignment/Consignment";
import DataPage from "./components/DataPage";
import SelfContact from "./containers/Contact/ContactEdit/SelfContact";
import ProcessedCourses from "./containers/ProcessedCourses/ProcessedCourses";
import {Col, Row} from "@fik/ui";
import AuthToken from "./containers/Auth/AuthToken";
import LeakedLicensesInfo from "./containers/License/LeakedLicensesInfo";
import ExpiringLicensesInfo from "./containers/License/ExpiringLicensesInfo";
import NewestLicensesInfo from "./containers/License/NewestLicensesInfo";
import ExpiredLicenses from "./containers/License/ExpiredLicenses";
import {QueryParamsProvider} from "@fik/url-query";
import CmsNavigationProvider from "./components/CmsNavigationProvider";
import FileManagerProvider from "./components/FileManagerProvider";
import ExpiredLicensesInfo from "./containers/License/ExpiredLicensesInfo";
import ForgotPassword from "./containers/ForgotPassword/ForgotPassword";
import MinimalLayout from "./components/MinimalLayout/MinimalLayout";
import PasswordSet from "./containers/PasswordSet/PasswordSet";
import Sso from "./containers/Sso";
import EshopDone from "./containers/EshopDone";
import OrderInfo from "./containers/Orders/OrderInfo";
import ResellerPlan from "./containers/ResellerPlan";
import Expiring90dLicensesInfo from "./containers/License/Expiring90dLicensesInfo";
import Expiring90dLicenses from "./containers/License/Expiring90dLicenses";

const appRuleSet = mergeDeep(
    {...defaultRuleSet},
    {
        required: {
            message: 'Toto pole je vyžadováno!'
        },
        minLength: {
            message: 'Minimální počet znaků je {0}.'
        },
        maxLength: {
            message: 'Maximální počet znaků je {0}.'
        },
        min: {
            message: 'Zadejte hodnotu rovnou nebo vyšší než {0}.'
        },
        max: {
            message: 'Zadejte hodnotu rovnou nebo nižší než {0}.'
        },
        email: {
            message: 'Zadejte validní emailovou adresu'
        },
        pattern: {
            message: 'Zadejte validní hodnotu'
        }
    }
);

const blocks = {
    homepage: ['banners']
};

const LICENSES_ALLOWED_KEYS = ['licenseEPLI', 'licensePublicId', 'licenseKey', 'licenseUsername', 'evidenceItem', 'itemProductTypeId', 'itemProductFamilyId', 'itemProductPeriodId', 'itemAmount'];

const createPageContents = (result) => {
    const contents = [];
    if (result.data) {
        contents.push(<DataPage key="data" {...result.data}/>);
    }
    return contents;
};

const App = () => {

    const isAuthReady = useIsAuthInitialized();
    const isAuthenticated = useIsAuthenticated();
    const dispatch = useDispatch();
    const {routes} = useNavigation();

    useEffect(() => {
        dispatch(checkState());
    }, [dispatch]);

    const setPageInfo = useSetPageInfo();

    let layout = null;
    if (!isAuthReady) {
        return null;
    }

    if (isAuthenticated) {
        document.body.classList.add('eset-skin');
        document.body.classList.add('fixed-nav');
        document.body.classList.remove('gray-bg');
        layout = (
            <Layout
                renderInfo={<Switch>
                    <PageRoute {...routes.license.expiring} component={ExpiringLicensesInfo}/>
                    <PageRoute {...routes.license.expired} component={ExpiredLicensesInfo}/>
                    <PageRoute {...routes.license.leaked} component={LeakedLicensesInfo}/>
                    <PageRoute {...routes.license.expiring90d} component={Expiring90dLicensesInfo}/>
                    <PageRoute {...routes.license.newest} component={NewestLicensesInfo}/>
                    <PageRoute {...routes.order.list} component={OrderInfo}/>
                </Switch>}
                >
                <Row>
                    <Col lg={12} className="no-padding">
                            <LoadingSpinnerOverlay>
                                <Switch>
                                    <PageRoute {...routes.signToken}>
                                        <PageParams>
                                            {({token}) => <AuthToken token={decodeURIComponent(token)}/>}
                                        </PageParams>
                                    </PageRoute>
                                    <PageRoute {...routes.signIn} component={Auth}/>
                                    <PageRoute {...routes.signOut} component={Logout}/>
                                    <Route {...routes.sso}>
                                        <PageParams>
                                            {({path}) => <Sso path={decodeURIComponent(path)}/>}
                                        </PageParams>
                                    </Route>
                                    <PageRoute {...routes.order.create}>
                                        <PageParams allowed={LICENSES_ALLOWED_KEYS}>
                                            {(props) => <OrderCreate {...props} />}
                                        </PageParams>
                                    </PageRoute>

                                    <PageRoute {...routes.offer.create}>
                                        <PageParams allowed={LICENSES_ALLOWED_KEYS}>
                                            {(props) => <OfferCreate {...props} />}
                                        </PageParams>
                                    </PageRoute>

                                    <PageRoute {...routes.projectPrice.create}>
                                        <PageParams allowed={LICENSES_ALLOWED_KEYS}>
                                            {(props) => <ProjectPriceCreate {...props} />}
                                        </PageParams>
                                    </PageRoute>

                                    <PageRoute {...routes.order.list} component={Orders}/>

                                    <PageRoute {...routes.order.detail}>
                                        <PageParams>
                                            {({id}) => <Order id={decodeURIComponent(id)}/>}
                                        </PageParams>
                                    </PageRoute>

                                    <PageRoute {...routes.offer.detail}>
                                        <PageParams>
                                            {({id}) => <Offer id={decodeURIComponent(id)}/>}
                                        </PageParams>
                                    </PageRoute>

                                    <PageRoute {...routes.offer.list} component={Offers}/>

                                    <PageRoute {...routes.projectPrice.list} component={ProjectPrices}/>

                                    <PageRoute {...routes.invoice.list} component={Invoices}/>

                                    <PageRoute {...routes.invoice.detail}>
                                        <PageParams>
                                            {({id}) => <Invoice id={decodeURIComponent(id)}/>}
                                        </PageParams>
                                    </PageRoute>

                                    <PageRoute {...routes.calculator} component={CalculatorAndPriceLists}/>
                                    <PageRoute  {...routes.deal.list} component={Deals}/>

                                    <PageRoute {...routes.deal.create}>
                                        <PageParams>
                                            {({offerId}) => <NewDeal offerId={offerId ? decodeURIComponent(offerId) : null}/>}
                                        </PageParams>
                                    </PageRoute>

                                    <PageRoute {...routes.deal.createNewLicence}>
                                        <PageParams>
                                            {({offerId}) => <NewDeal initialFormType='new' offerId={offerId ? decodeURIComponent(offerId) : null}/>}
                                        </PageParams>
                                    </PageRoute>

                                    <PageRoute {...routes.deal.createRenewLicence}>
                                        <PageParams>
                                            {({offerId}) => <NewDeal initialFormType='renew' offerId={offerId ? decodeURIComponent(offerId) : null}/>}
                                        </PageParams>
                                    </PageRoute>

                                    <PageRoute {...routes.deal.detail}>
                                        <PageParams>
                                            {({id}) => <EditDeal id={decodeURIComponent(id)}/>}
                                        </PageParams>
                                    </PageRoute>

                                    <PageRoute {...routes.license.detail}>
                                        <PageParams>
                                            {({id}) => <License id={id}/>}
                                        </PageParams>
                                    </PageRoute>

                                    <PageRoute {...routes.license.expiring} component={ExpiringLicenses}/>
                                    <PageRoute {...routes.license.expiring90d} component={Expiring90dLicenses}/>
                                    <PageRoute {...routes.license.expired} component={ExpiredLicenses}/>
                                    <PageRoute {...routes.license.leaked} component={LeakedLicenses}/>
                                    <PageRoute {...routes.license.nfr}  component={ResellerNfrLicenses}/>
                                    <PageRoute {...routes.license.download}  component={LicensesDownload}/>
                                    <PageRoute {...routes.license.newest}  component={NewestLicenses}/>

                                    <PageRoute {...routes.profile.settings} component={Settings}/>
                                    <PageRoute {...routes.profile.contacts} component={Contacts}/>
                                    <PageRoute path={'/profile/contact'} render={() => <Redirect to={'/profile/contacts'}/>}/>
                                    <PageRoute {...routes.profile.createContact} component={ContactCreate}/>
                                    <PageRoute {...routes.profile.editContact}>
                                        <PageParams>
                                            {({id}) => <ContactEdit id={id}/>}
                                        </PageParams>
                                    </PageRoute>
                                    <PageRoute {...routes.profile.editSelfContact}>
                                        <PageParams>
                                            {() => <SelfContact />}
                                        </PageParams>
                                    </PageRoute>
                                    <PageRoute {...routes.profile.certificationRequest}>
                                        <PageParams>
                                            {({id}) => <CertificationRequest contactId={id}/>}
                                        </PageParams>
                                    </PageRoute>
                                    <PageRoute {...routes.profile.reports} component={Reports}/>
                                    <PageRoute {...routes.partnerstvi.resellerPlan} component={ResellerPlan}/>
                                    <PageRoute {...routes.profile.password}  component={Password}/>
                                    <PageRoute {...routes.profile.certification}  component={Certifications}/>
                                    <PageRoute {...routes.profile.certificationDownload}  component={CertificationDownload}/>
                                    <PageRoute {...routes.profile.processedCourses}  component={ProcessedCourses}/>

                                    <PageRoute {...routes.nfrOrder} component={OrderNfr}/>
                                    <PageRoute {...routes.boxEshop} component={BoxEshop}/>
                                    <PageRoute {...routes.boxEshopOrdered} component={EshopDone}/>
                                    <PageRoute {...routes.downloads}  component={Downloads}/>
                                    <PageRoute {...routes.profile.nfrOrder}  component={OrderNfr}/>
                                    <PageRoute {...routes.marketingEshop} component={MarketingEshop}/>
                                    <PageRoute {...routes.marketingEshopOrdered} component={EshopDone}/>
                                    <PageRoute {...routes.consignment} component={Consignment}/>

                                    <PageRoute {...routes.page}>
                                        <PageParams>
                                            {({category, page}) => <Page setPageInfo={setPageInfo} category={category} page={page} createContents={createPageContents}/>}
                                        </PageParams>
                                    </PageRoute>

                                    <PageRoute {...routes.cms}>
                                        <PageParams allowed={['category', 'page']}>
                                            {(props) => <Page setPageInfo={setPageInfo} {...props} createContents={createPageContents} />}
                                        </PageParams>
                                    </PageRoute>

                                    <PageRoute {...routes.article.detail}>
                                        <PageParams>
                                            {({id}) => <Article id={id}/>}
                                        </PageParams>
                                    </PageRoute>

                                    <PageRoute {...routes.article.old}>
                                        <PageParams>
                                            {({id}) => <Article id={id}/>}
                                        </PageParams>
                                    </PageRoute>

                                    <PageRoute {...routes.contacts} component={EsetContacts}/>

                                    <PageRoute path={routes.homepage.path} exact component={Homepage} blocks={blocks.homepage}/>
                                </Switch>
                            </LoadingSpinnerOverlay>
                    </Col>
                </Row>
            </Layout>
        );
    } else {
        document.body.classList.remove('eset-skin');
        document.body.classList.remove('fixed-nav');
        document.body.classList.add('gray-bg');
        layout = (
            <MinimalLayout>
                <Switch>
                    <PageRoute {...routes.signToken}>
                        <PageParams>
                            {({token}) => <AuthToken token={decodeURIComponent(token)}/>}
                        </PageParams>
                    </PageRoute>
                    <Route {...routes.signIn} component={Auth}/>
                    <Route {...routes.sso}>
                        <PageParams>
                            {({path}) => <Sso path={decodeURIComponent(path)}/>}
                        </PageParams>
                    </Route>
                    <Route {...routes.forgottenPassword} component={ForgotPassword}/>
                    <Route {...routes.passwordSet}>
                        <PageParams>
                            {({id}) => <PasswordSet id={id ? decodeURIComponent(id) : null}/>}
                        </PageParams>
                    </Route>
                    <Route path={routes.homepage.path} component={Auth}/>
                </Switch>
            </MinimalLayout>
        );
    }
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <QueryParamsProvider>
                <ValidatorProvider ruleSet={appRuleSet}>
                    <CmsNavigationProvider>
                        <FileManagerProvider>
                            {layout}
                        </FileManagerProvider>
                    </CmsNavigationProvider>
                </ValidatorProvider>
            </QueryParamsProvider>
        </BrowserRouter>
    );
};


export default App;
