import React from 'react';
import PropTypes from 'prop-types';
import {NavigationItem} from "@fik/navigation";

const DealLink = ({item, children, className}) => {
    return (<NavigationItem className={className} to="deal:detail" params={item}>{children}</NavigationItem>);
};

DealLink.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number
    }),
    children: PropTypes.oneOfType([PropTypes.array,PropTypes.object,PropTypes.string]),
    className: PropTypes.string
};

export default DealLink;