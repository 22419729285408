import React, {useCallback, useEffect} from 'react';
import {Document} from "@fik/document";
import {Box, Date} from "@fik/ui";
import {useDocumentOperation} from "@fik/document-edit";
import handleClick from "@fik/utils/handleClick";
import {DownloadLink} from "@fik/ui";
import DocumentSaved from "@fik/document-edit/components/DocumentSaved";
import usePureDataSource from "@fik/data-source/usePureDataSource";

const OfferDocument = (props) => {
    const {entity, modal, isOrdered} = props;
    const {perform: createVariants, isSuccess: isVariantsCreated} = useDocumentOperation('createAllOfferVariants');
    const {reset} = usePureDataSource({name: 'offer'});

    useEffect(() => {
        if (isVariantsCreated) {
            reset();
        }
    }, [reset, isVariantsCreated]);

    const handleOrder = useCallback(() => {
        modal.show();
    }, [modal]);

    if (isOrdered) {
        return <div className='row'>
            <div className="col-sm-12">
                <Box>
                    <h1>Úspěšně odesláno</h1>
                    <DocumentSaved documentType='order'/>
                </Box>
            </div>
        </div>;
    }

    if (isVariantsCreated) {
        return <div className='row'>
            <div className="col-sm-12">
                <Box>
                    <h1>Varianty vytvořeny</h1>
                    <DocumentSaved documentType='offers'/>
                </Box>
            </div>
        </div>;
    }

    if (!entity) {
        return null;
    }

    const {actions = {}, isPdf, expirationDate} = entity;

    return <>
        <Document
            {...props}
            renderInfo={() => <>
                <div><strong>Platnost:</strong> <Date date={expirationDate}/></div>
            </>}
        />
        <div className='btn-group-spaces'>
            {actions.order ? <>
                <button className="btn btn-primary btn-lg" onClick={handleClick(handleOrder)}>Objednat hned</button>
            </> : null}
            {actions.createVariants ? <>
                <button className="btn btn-primary btn-lg" onClick={handleClick(() => createVariants(entity.id))}>Vygenerovat varianty</button>
            </> : null}
            {isPdf ? <>
                <DownloadLink resource='offer' params={{id: entity.id, type: 'partner'}} className="btn btn-primary btn-lg"><i className="fa fa-file-pdf-o"/> PDF pro zákazníka</DownloadLink>
                <DownloadLink resource='offer' params={{id: entity.id, type: 'partnerInternal'}} className="btn btn-primary btn-lg"><i className="fa fa-file-pdf-o"/> PDF s nákupními</DownloadLink>
            </> : null}
        </div>
    </>;
};

export default OfferDocument;