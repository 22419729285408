import React from "react";

const LicenseUser = ({license}) => {
    if (!license) {
        return null;
    }

    const {licensePrimaryName, licenseEmail, licensePhone} = license;

    return <table className="table">
        <thead>
        <tr>
            <td>Subjekt</td>
            <td>{licensePrimaryName}</td>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>E-mail</td>
            <td>{licenseEmail}</td>
        </tr>
        <tr>
            <td>Telefon</td>
            <td>{licensePhone}</td>
        </tr>
        </tbody>
    </table>;
};

export default LicenseUser;