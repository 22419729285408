import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Box} from "@fik/ui";
import handleClick from "@fik/utils/handleClick";
import {Errors} from "@fik/ui";
import {CustomerEdit, DEFAULT_VIEWS, useCustomerContext} from "@fik/customer-edit";
import {Channel} from "@fik/document";

const DocumentCustomer = ({visibleViews, messages}) => {

    const {isCustomerFilled, isEditingCustomer, customer, edit, cancelEdit} = useCustomerContext();

    return <Box title="Zákazník"
                tools={() => (!isEditingCustomer ?
                        <>
                            <button onClick={handleClick(edit)} className='btn btn-xs btn-primary m-r-xs'>
                                Upravit <i className='fa fa-pencil'/>
                            </button>
                        </>
                        : (isCustomerFilled
                            ? <button onClick={handleClick(cancelEdit)} className='btn btn-xs btn-danger m-r-xs'>
                                Zrušit <i className='fa fa-trash'/>
                            </button>
                            : null)
                )}>

        {isEditingCustomer
            ? <CustomerEdit visibleViews={visibleViews} /> : <>
                <Errors messages={messages}/>
                <Channel {...customer} isCustomer={true}/>
            </>}
    </Box>;
};

DocumentCustomer.propTypes = {
    visibleViews: PropTypes.array
};

export default DocumentCustomer;