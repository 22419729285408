import React from 'react';
import {Table, useRowDetail} from "@fik/table";
import {
    DocumentBasePrice,
    DocumentCreatedDate,
    DocumentEPC, DocumentOwner,
    DocumentPartner,
    DocumentStatus,
    DocumentType,
    DocumentResellerContact
} from "@fik/document";
import OrderLink from "./OrderLink";
import OrdersTableRowDetail from "./OrdersTableRowDetail";
import {OrderMenu} from "./index";



const OrdersTable = ({rows, statesDict, typesDict}) => {
    const {toggleRow, isVisibleRow} = useRowDetail();

    return <Table striped responsive
                  rows={rows}
                  renderCellProps={{statesDict, typesDict}}
                  renderRowMessages={({row, primaryValue})=>(isVisibleRow(primaryValue) ?
                      <tr>
                          <td colSpan='9' className="no-padding no-borders">
                              <OrdersTableRowDetail order={row} />
                          </td>
                      </tr> : null)}
                  primary='id'>

        <OrderLink header='ID' />
        <DocumentOwner header='Klient' />
        <DocumentPartner header='Partner' />
        <DocumentEPC header='EPC' />
        <DocumentResellerContact header='Obchodník'/>
        <DocumentType header='Typ objednávky' />
        <DocumentStatus header='Stav' />
        <DocumentBasePrice header='Cena' />
        <DocumentCreatedDate header='Vytvořeno' />
        <OrderMenu toggleRow={toggleRow} isVisibleRow={isVisibleRow}/>

    </Table>;
};

export default OrdersTable;