import React from "react";
import PropTypes from "prop-types";
import {Price} from "@fik/ui";

const EvidenceItemDiscount = ({itemDiscount, itemDiscountName, itemDiscountCompetitor, itemDiscountCompetitorName, itemDiscountCompetitorPrice}) => <>
    {itemDiscount ? <>
        <div>{itemDiscountName} {itemDiscount}%</div>
        {itemDiscountCompetitor && <>
        <div className="row">
            <div className="col-sm-12 font-bold">Informace o současném řešení</div>
        </div>
        <div className='row'>
            <div className="col-xs-4 order-item-value-label">Výrobce řešení:</div>
            <div className="col-xs-8 order-item-value">{itemDiscountCompetitor}</div>
        </div>
        <div className='row'>
            <div className="col-xs-4 order-item-value-label">Název produktu:</div>
            <div className="col-xs-8 order-item-value">{itemDiscountCompetitorName}</div>
        </div>
        <div className='row'>
            <div className="col-xs-4 order-item-value-label">Cena:</div>
            <div className="col-xs-8 order-item-value">{itemDiscountCompetitorPrice ? <Price price={parseFloat(itemDiscountCompetitorPrice)} /> : null}</div>
        </div>
        </>}
    </> : '--'}
</>;

EvidenceItemDiscount.propTypes = {
    itemDiscount: PropTypes.number
};

export default EvidenceItemDiscount;
