import React from 'react';
import {Address} from '@fik/ui';

const BranchOffice = (props) => {
    return (<React.Fragment>
        <span>{props.name}</span><br />
        <Address {...props}></Address>
    </React.Fragment>);
};

export default BranchOffice;