import stringifyObjectKeyAndValues from "@fik/utils/stringifyObjectKeyAndValues";

export const selectCurrentBySearchParams = (state, params, defaults = null) => {
    const searchParams = {...params};
    delete searchParams.page;
    const filtersKey = stringifyObjectKeyAndValues(searchParams);
    return state && state[filtersKey] ? state[filtersKey] : defaults;
};

export const getPages = (state, defaults = {}) => state.pages || defaults;
export const getCount = (state, defaults = null) => state.count || defaults;
export const getPage = (pages, pageNumber, defaults = {}) => pages[pageNumber] || defaults;
export const getPageElements = (page) => page.elements;
export const getPageIncludes = (page) => page.includes || {};
export const getPageIsLoading = (page) => page.isFetching;
export const getPageLoadingState = (page) => ({isFetching: page.isFetching, isFailed: page.isFailed, isSuccess: page.isSuccess, isRefreshing: page.isRefreshing});


