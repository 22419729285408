import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {OfferDocument} from '@fik/offer';
import {useEntity} from "@fik/entity";
import {useDials} from "@fik/dials";
import ConfirmOrderModal from "./ConfirmOrderModal";
import {useModal} from "@fik/ui";
import {useDocumentOperation} from "@fik/document-edit";
import useIsEpc from "../../useIsEpc";

const Offer = ({id}) => {
    const {offer} = useEntity({name: 'offer'}, id);
    const dials = useDials({name: 'offer'});
    const modal = useModal();
    const {perform: orderOffer, isSuccess: isOrdered} = useDocumentOperation('orderOffer');

    return  <>
        {offer ? <ConfirmOrderModal orderOffer={orderOffer} entity={offer} modal={modal}/> : null}
        <OfferDocument
            isEpc={useIsEpc()}
            isOrdered={isOrdered}
            modal={modal}
            entity={offer}
            dials={dials}
    />

    </>;
};

Offer.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Offer;