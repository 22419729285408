import React, {useCallback} from "react";
import {Form} from "@mouseover/react-form";
import {PasswordInput, TextInput} from "@fik/form";


const PasswordForm = ({username, onSubmit}) => {
    const handleSubmit = useCallback((values, valid, form) => {
        if (valid) {
            onSubmit(values);
            form.setValues({password: null, oldPassword: null, passwordCheck: null});
        }
    }, [onSubmit]);

    return <Form
        values={{username: username, password: null, oldPassword: null, passwordCheck: null}}
        validationRules={{}}
        onSubmit={handleSubmit}
        render={(form) => (<>
            <TextInput label="Uživatelské jméno" elementConfig={{disabled: true}} form={form} name="username" />
            <PasswordInput label="Současné heslo" form={form} name="oldPassword" />
            <PasswordInput label="Nové heslo" form={form} name="password" />
            <PasswordInput label="Nové heslo pro kontrolu" form={form} name="passwordCheck" />
        </>)}
    >
        <button type='submit' className="btn btn-primary block m-b">Změnit heslo</button>
    </Form>;
};

export default PasswordForm;