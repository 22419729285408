import {useCallback, useContext, useMemo} from "react";
import {getOnPath, isString} from "@mouseover/js-utils/src";
import NavigationContext from "./NavigationContext";

const useNavigation = () => {
    const {navigation} = useContext(NavigationContext);
    const paths = useMemo(() => byPaths(navigation), [navigation]);
    const getItemOnPath = useCallback((path) => getOnPath(navigation, isString(path) ? path.split(':') : path) || null, [navigation]);
    return {
        routes: useMemo(() => navigation, [navigation]),
        paths,
        getByUrlPath: useCallback((urlPath) => {
            const path = getOnPath(paths, [urlPath]);
            return path ? getItemOnPath(path) : null;
        }, [paths, getItemOnPath]),
        getItemOnPath
    }
};

export default useNavigation;


const byPaths = (navigation, prefix = '') =>
    navigation
        ? Object.entries(navigation).reduce(
        (a, [key, value]) => (
            {
                ...a,
                ...(value && value.path ? {[value.path]: prefix + key} : byPaths(value, key + ':'))
            }
        ),
        []
        ) : {};