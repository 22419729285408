import React from "react";
import {useDials} from "@fik/dials";
import {EnumValue} from "@fik/ui";
import arrayToDictionary from "@fik/utils/arrayToDictionary";

const EvidenceItemPromoWithName = ({itemPromoDiscount, itemPromoId, itemPromoName}) => {
    const {promos} = useDials({name: 'esetEvidenceItem'});
    return itemPromoId ? <div>
        {itemPromoDiscount && <div className='pull-right'>{itemPromoDiscount} %</div>}
        <EnumValue value={itemPromoId} enum={arrayToDictionary(promos, 'promoId', 'promoName')} />
    </div>: null;
}

export default EvidenceItemPromoWithName;
