import React from 'react';
import {useDataSource, useDataSourceWithQueryParams} from "@fik/data-source";
import {Box, DateTime, Paginator} from "@fik/ui";
import {Table} from "@fik/table";

const ProcessedCourses = () => {
    const {pagination, items, handlePageChange, refresh} = useDataSourceWithQueryParams(
        {name: 'processedCourse', initialSearchParams: {}}
    );

    return <Box>
        <Table striped responsive
               rows={items}
               primary='id'>
            <LessonName header='Kurz'/>
            <Contact header='Osoba'/>
            <CreatedDate header='Datum spuštění'/>
            <ModifiedDate header='Datum ukončení'/>
            <LessonStatus header='Status'/>
        </Table>
        <Paginator {...pagination}
                   onPageChange={handlePageChange}/>
    </Box>;
};


export default ProcessedCourses;

const LessonName = ({lessonName}) => lessonName;
const Contact = ({contact}) => contact ? <>{contact.contactFirstname} {contact.contactSurname}</> : null;
const CreatedDate = ({created}) => <DateTime date={created}/>;
const ModifiedDate = ({modified}) => <DateTime date={modified}/>;
const LessonStatus = ({lessonStatus}) => lessonStatus;