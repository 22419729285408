import React, {useCallback, useEffect, useMemo} from 'react';
import LicensesTable from './LicensesTable';
import {Paginator} from "@fik/ui";
import {Box} from "@fik/ui";
import LicensesFilter from "./LicensesFilter";
import {useDials} from "@fik/dials";
import {useDataSourceWithQueryParams} from "@fik/data-source";

const DEFAULT_PARAMS = {
    fulltext: null,
    type: "0",
    ago: null,
    status: null
};

const Licenses = ({partnerVisible, query}) => {
    const {statuses, types} = useDials({name: 'license'});

    const {pagination, items, setSearchParams, handlePageChange, queryParams, refresh, handleSearch} = useDataSourceWithQueryParams(
        {
            name: 'license',
            initialSearchParams: useMemo(() => ({query}), [query]),
            defaultParams: DEFAULT_PARAMS
        }
    );

    return (
        <Box>
            <LicensesFilter onSubmitSearch={handleSearch}
                            statuses={statuses}
                            types={types}
                            queryParams={queryParams}
            />

            <LicensesTable striped responsive
                           onInvalidate={refresh}
                           partnerVisible={partnerVisible}
                           rows={items}
            />

            <Paginator {...pagination}
                       onPageChange={handlePageChange}/>
        </Box>
    );
};

export default Licenses;