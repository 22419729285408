import React, {useCallback, useEffect, useMemo, useState} from "react";
import handleClick from "@fik/utils/handleClick";
import {Box} from "@fik/ui";
import {Form, FormField} from "@mouseover/react-form";
import {useDocumentContext} from "@fik/document-edit";
export const validationRules = {
    contactFirstname: {
        required: true
    },
    contactSurname: {
        required: true
    },
    contactPosition: {
        required: true
    },
    contactEmail: {
        required: true,
        email: true
    },
    contactPhone: {
        required: false,
        pattern: {
            message: 'Špatný formát telefonu (+420...)',
            params: '^(\\+420)[0-9]{9}$'
        }
    }
};
const DealResponsiblePerson = ({isSubmitted}) => {
    const {
        document,
        initialDocument,
        changeValues,
    } = useDocumentContext();

    const [isSameAsContact, setIsSameAsContact] = useState(initialDocument.responsiblePersonIsSameAsContact);

    useEffect(() => {
        if (initialDocument && initialDocument.responsiblePersonIsSameAsContact !== undefined) {
            setIsSameAsContact(initialDocument.responsiblePersonIsSameAsContact);
        }
    }, [initialDocument]);

    const handleCheck = useCallback(() => {
        setIsSameAsContact((current) => !current);
    }, [setIsSameAsContact]);



    const values = useMemo(() => document && document.responsiblePerson, [document]);

    const handleValuesChanges = useCallback((responsiblePerson, valid) => {
        if (valid) {
            changeValues({responsiblePerson});
        }
    }, [changeValues, values]);

    useEffect(() => {
        if (isSameAsContact) {
            changeValues({responsiblePerson: null});
        } else {
            changeValues({responsiblePerson: {contactFirstname: null, contactSurname: null, contactEmail: null, contactPhone: null}});
        }
    }, [isSameAsContact, changeValues])


    return <Box title="Osoba s rozhodujicí pravomocí"
                tools={() => <>
                    <button onClick={handleClick(handleCheck)} className='btn btn-md btn-white m-r-xs no-borders'>
                        <i className={'fa ' + (isSameAsContact ? 'fa-check-square-o' : 'fa-square-o')}/> Shodná jako kontaktní
                    </button>
                </>}>
        {!isSameAsContact ? <Form
            values={values}
            validationRules={validationRules}
            onChange={handleValuesChanges}
            isSubmitted={isSubmitted}
            render={(form => {
                return <>
                    <div className="row">
                        <div className="col-sm-4">
                            <FormField form={form}
                                       name='contactFirstname'
                                       label='Jméno'
                                       elementType='input'
                            />
                        </div>
                        <div className="col-sm-4">
                            <FormField form={form}
                                       name='contactSurname'
                                       label='Příjmení'
                                       elementType='input'
                            />
                        </div>
                        <div className="col-sm-4">
                            <FormField form={form}
                                       name='contactPosition'
                                       label='Pozice'
                                       elementType='input'
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <FormField form={form}
                                       name='contactEmail'
                                       label='Email'
                                       elementType='input'
                            />
                        </div>
                        <div className="col-sm-4">
                            <FormField form={form}
                                       name='contactPhone'
                                       label='Telefon'
                                       elementType='input'
                            />
                        </div>
                    </div>
                </>
            })}/> : null}
    </Box>;
}

export default DealResponsiblePerson;