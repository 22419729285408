import React from "react";
import {FormField} from "@mouseover/react-form";

const DiscountCompetitors = ({competitors = [], form}) => {
    return (<div className="row border-bottom m-b-sm">
        <div className="col-sm-12 border-bottom m-b-sm"><strong>Informace o současném řešení</strong></div>
        <div className="col-sm-12 col-md-12 col-xl-3">
            <FormField
                label="Název produktu"
                name='itemDiscountCompetitorName'
                form={form}
                elementType="input"
                elementConfig={{
                    type: 'text'
                }}
                valueType="text"
            />
        </div>
        <div className="col-sm-12 col-md-6 col-xl-3">
            <FormField
                name='itemDiscountCompetitor'
                form={form}
                label="Výrobce řešení"
                elementType="select"
                options={[{value: '', label: 'Vyberte konkurenci'}, ...competitors.map(item => ({
                    value: item,
                    label: item
                }))]}
                valueType="text"
            />
        </div>
        <div className="col-sm-12 col-md-6 col-xl-3">
            <FormField
                label="Cena"
                name='itemDiscountCompetitorPrice'
                form={form}
                elementType="input"
                elementConfig={{
                    type: 'number',
                    placeholder: 'Cena',
                }}
                valueType="number"
            />
        </div>
    </div>);
};

export default DiscountCompetitors;