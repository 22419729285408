import React from 'react';
import {Price} from "@fik/ui";
import PropTypes from "prop-types";
import {Date} from "@fik/ui";

const Preview = ({evidenceItem}) => {

    if (!evidenceItem) {
        return null;
    }

    return (<React.Fragment>
        <ul className="list-group clear-list m-t">
            <li className="list-group-item fist-item">
                <strong>{evidenceItem.itemName}</strong> <div className="pull-right">{evidenceItem.itemAmount || '--'}x</div>
                {evidenceItem.itemLicenseIdCodes ? (<div>{evidenceItem.itemLicenseIdCodes}</div>) : null}
                {evidenceItem.itemRansomExpirationDate ? (<div>ESET výkupné do: <Date date={evidenceItem.itemRansomExpirationDate}/></div>) : null}
                {evidenceItem.itemExpirationDate ? (<div>Datum expirace: <Date date={evidenceItem.itemExpirationDate}/></div>) : null}
                {evidenceItem.itemExtraDays ? (<div>Dny navíc: {evidenceItem.itemExtraDays}</div>) : null}
            </li>
            {evidenceItem.itemDiscountId
                ? <li className="list-group-item">
                    <React.Fragment>Sleva</React.Fragment>
                    <div className="pull-right">{evidenceItem.itemDiscount}%</div>
                </li> : null}
            {evidenceItem.owner
                ? <li className="list-group-item">
                    <React.Fragment>Doporučená <abbr title="Doporučená koncová cena">EUP</abbr> bez DPH</React.Fragment>
                    <div className="pull-right"><Price price={evidenceItem.owner.itemPrice} currency={evidenceItem.itemCurrency}/></div>
                </li> : null}
            {evidenceItem.partner ?
                <li className="list-group-item">
                    <React.Fragment>Cena partner bez DPH</React.Fragment>
                    <div className="pull-right"><Price price={evidenceItem.partner.itemPrice} currency={evidenceItem.itemCurrency}/></div>
                </li> : null}
            {evidenceItem.epc ?
                <li className="list-group-item">
                    <React.Fragment>Cena EPC bez DPH</React.Fragment>
                    <div className="pull-right"><Price price={evidenceItem.epc.itemPrice} currency={evidenceItem.itemCurrency}/></div>
                </li> : null}
        </ul>
    </React.Fragment>);
};

Preview.propTypes = {
    evidenceItem: PropTypes.object
};


export default Preview;