import {useEffect, useMemo} from 'react';
import {usePureEntity} from "./index";

export const useEntity = (props, id, inputParams, deps = []) => {

    //- memoize params - their should not change!
    const memoizedAllParams = useMemo(() => (id ? {...inputParams, id} : {...inputParams}), deps);

    const [resourceSelection, callRequest] = usePureEntity(props);

    useEffect(() => {
        callRequest(memoizedAllParams);
    }, [memoizedAllParams, callRequest]);

    return resourceSelection;
};

export default useEntity;