import {useResourceContext} from "@fik/model";
import {useDispatch} from "react-redux";
import {useCallback, useRef, useState} from "react";
import {useHookToLoading} from "@fik/loading";
import useResourceEntitySelector from "./useResourceEntitySelector";
import {isObject} from "@mouseover/js-utils";

function paramsOrEmptyObject(params) {
    return params || {};
}

function createCurrentParams(defaultParamsRef, params) {
    if (!isObject(params) && params) {
        return params;
    }
    return defaultParamsRef.current
        ? {...defaultParamsRef.current, ...paramsOrEmptyObject(params)}
        : paramsOrEmptyObject(params);
}

const useCallRequestEntity = ({name, validate, method, ...props}, action, defaultParams) => {
    const context = useResourceContext(name);
    const dispatch = useDispatch();
    const [currentParams, setCurrentParams] = useState(null);

    //- ref to default params - their should not change! only on purpose with changeDefaultParams
    const defaultParamsRef = useRef(defaultParams);
    const changeDefaultParams = useCallback((newParams) => defaultParamsRef.current = newParams, []);

    const callback = useCallback((params, content) => {
        const newParams = createCurrentParams(defaultParamsRef, params);
        if (!validate || validate(newParams)) {
            setCurrentParams(newParams);
            dispatch(action({entity: name, ...context, ...props}, newParams, content));
        }
    }, [action, context, dispatch, validate]);

    const resourceSelection = useResourceEntitySelector(context, currentParams, method, []);

    useHookToLoading(name, resourceSelection.loadingState);

    return [resourceSelection, callback, changeDefaultParams];
};

export default useCallRequestEntity;