import React, {useCallback, useEffect, useState} from 'react';
import {Form} from "@mouseover/react-form";
import {useDocumentContext} from "../useDocumentContext";

const DocumentCheckboxes = () => {
    const {
        document,
        changeValues,
        validation: {availableButtons}
    } = useDocumentContext();

    const [visibleElements, setVisibleElements] = useState({sendOrderToCustomer: false, generateVariants: false, dealConsent: false});

    useEffect(() => {
        setVisibleElements({
            sendOrderToCustomer: availableButtons.find(({documentType}) => documentType === 'order'),
            generateVariants: availableButtons.find(({documentType}) => documentType === 'offer' || documentType === 'projectPrice' || documentType === 'project-price' ),
            dealConsent: availableButtons.find(({documentType}) =>  documentType === 'projectPrice' || documentType === 'project-price' ),
        })
    }, [availableButtons]);

    const handleValuesChanges = useCallback((values, valid) => {
        if (valid) {
            changeValues(values);
        }
    }, [changeValues]);

    return  document && document.note !== undefined
        ? <Form
            values={{
                note: document.note,
                customContractId: document.customContractId
            }}
            onChange={handleValuesChanges}
            controls={{
                sendOrderToCustomer: {
                    label: 'Odeslat licenční e-mail i na koncového uživatele',
                    elementType: 'checkbox',
                    groupClass: 'checkbox',
                    renderInputElement: (props, elementProps) => <input {...elementProps} type='checkbox' checked={elementProps.value}
                                                                        value={'ODESLAT_LIC_UZIVATEL'}/>,
                    visible: () => visibleElements.sendOrderToCustomer
                },
                generateVariants: {
                    label: 'V případě nabídky vytvořit automaticky i na 1, 2, 3 roky',
                    elementType: 'checkbox',
                    groupClass: 'checkbox',
                    renderInputElement: (props, elementProps) => <input {...elementProps} type='checkbox' checked={elementProps.value}
                                                                        value={'GENEROVAT_VARIANTY'}/>,
                    visible: () => visibleElements.generateVariants
                },
                dealConsent: {
                    label: 'Beru na vědomí, že pro zpracování projektové ceny a možnosti získání maximální možné provize dle partnerské kategorie, je nutné řádně vyplnit registraci obchodního případu.',
                    elementType: 'checkbox',
                    groupClass: 'checkbox',
                    renderInputElement: (props, elementProps) => <input {...elementProps} type='checkbox' checked={elementProps.value}
                                                                        value={'DEAL_CONFIRM'}/>,
                    visible: () => visibleElements.dealConsent
                }
            }}/>
        : null;
};


export default DocumentCheckboxes;