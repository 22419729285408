import * as actions from "./actionTypes";
import updateObject from "@fik/utils/updateObject";

const initialState = {
    token: null,
    userId: null,
    username: null,
    error: false,
    initialized: false,
    loading: false,
    redirectPath: '/'
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.AUTH_INIT:
            return updateObject(state, {initialized: true, loading: true, error: false});

        case actions.AUTH_DONE:
            return updateObject(state, {
                initialized: true,
                loading: false,
                error: false,
                userId: action.payload.userId,
                token: action.payload.token,
                username: action.payload.username
            });

        case actions.AUTH_FAILED:
            return updateObject(state, {initialized: true, loading: false, error: action.payload.error});

        case actions.AUTH_LOGOUT:
            return updateObject(state, {initialized: true, token: null, userId: null});

        case actions.AUTH_SET_REDIRECT_PATH:
            return updateObject(state, {redirectPath: action.payload.path});

        default:
            return state;
    }
};

export default reducer;