import React, {useMemo} from "react";
import {FileManagerContext} from "@fik/file-manager";
import {useSelector} from "react-redux";

const FileManagerProvider = (props) => {
    const token = useSelector((state) => state.auth.token, []);
    const model = useMemo(() => ({
            createPath: (path) => `/files/download/${path}?token=${token}`
        })
        , [token]);
    return <FileManagerContext.Provider value={model} {...props} />
}

export default FileManagerProvider;