// Hook
import {useCallback, useEffect, useRef, useState} from "react";

function useStorage(storage, key, defaultValue) {

    // defaultValue is never used directly, only as ref (change of it will not trigger refresh of effect below)
    const defaultValueRef = useRef(defaultValue);

    const [currentValue, setCurrentValue] = useState(defaultValueRef.current);

    // triggers when key change
    useEffect(() => {
        const item = storage.getItem(key);
        setCurrentValue((prevValue) => item ? JSON.parse(item) : defaultValueRef.current);
    }, [key, storage, defaultValueRef]);

    const setValue = useCallback(value => {
        try {
            // mimic useState api
            setCurrentValue((prevValue) => {
                // determine new value
                const newValue = value instanceof Function ? value(prevValue) : value;

                // store in storage
                storage.setItem(key, JSON.stringify(newValue));

                return newValue;
            })
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    }, [setCurrentValue, storage]);

    return [currentValue, setValue];
}

export default useStorage;