import React from 'react';
import PropTypes from "prop-types";
import {AddressBookDetail, BranchOfficeDetail, ContactDetail} from "@fik/components";

const Channel = ({addressBook, contact, branchOffice, categoryInfo, viewType, isCustomer}) => {
    return (<div className="row">
        <AddressBookDetail viewType={viewType} isCustomer={isCustomer} addressBook={addressBook} label={isCustomer ? 'Adresa' : 'Firma'}/>
        {branchOffice || !isCustomer ? <BranchOfficeDetail viewType={viewType} branchOffice={branchOffice}/> : null}
        <ContactDetail viewType={viewType} contact={contact} label={isCustomer ? 'Kontakt' : 'Obchodník'} />
        {categoryInfo ? <div className="col-sm-4">{categoryInfo.name}<br/>{categoryInfo.commission}</div> : null}
    </div>);
};

Channel.propTypes = {
    viewType: PropTypes.string,
    isCustomer: PropTypes.bool,
    addressBook: PropTypes.object,
    categoryInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    branchOffice: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    contact: PropTypes.object,
};

export default Channel;