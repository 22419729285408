import {useDispatch, useSelector} from "react-redux";
import {useCallback} from "react";
import {authenticate} from "./actions";

const useAuth = () => {
    const dispatch = useDispatch();

    const handleAuthenticate = useCallback((credentials) => {
        dispatch(authenticate(credentials));
    }, [dispatch]);

    const selection = useSelector(state => ({
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        redirectPath: state.auth.redirectPath
    }));

    return {
        authenticate: handleAuthenticate,
        ...selection
    }
};

export default useAuth;