const models = (authenticationModel) => ({
    license: {
        resetCredentials: ({id}, adapter) => adapter().put({id, action: 'reset-credentials'}),
        resendLicenseEmail: ({id, values}, adapter) => adapter().put({id, action: 'resend-license-email'}, {id, ...values}),
        requestLicenses: (params, adapter) => adapter().post({action: 'send-csv'}),
        saveResellerNote: ({id, values}, adapter) => adapter().post({id, action: 'reseller-note'}, {id, ...values}),
    },
    resellerProfile: {
        consignment: (params, adapter) => adapter().post({action: 'consignment', ...params}),
        resetPassword: ({values}, adapter) => adapter().post({action: 'reset-password'}, values),
        saveAddressBook: ({values}, adapter) => adapter().post({action: 'address-book'}, values),
        saveBranchOffice: ({values}, adapter) => adapter().post({action: 'branch-office'}, values),
        contactCertificationRequest: ({values}, adapter) =>  adapter().post({action: 'contact-certification-request'}, values),
    },
    contact: {
        create: ({values}, adapter) => adapter().post({}, values),
        save: ({id, values}, adapter) => adapter().post({id}, values),
        resendLogin: ({id, values}, adapter) => adapter().post({id, action: 'resend-login'}, values),
    },
    authentication: {
        forgotPassword: (values) => authenticationModel.forgotPassword(values),
        validateResetPassword: authenticationModel.validateResetPassword,
        resetPassword: authenticationModel.resetPassword
    }
});

export default models