import React from 'react';
import {Bar} from 'react-chartjs-2'
import {Box, Col, Price, Row} from "@fik/ui";
import ReportTable from "./ReportTable/ReportTable";
import {useEntity} from "@fik/entity";

const Reports = () => {

    const {
        entity: resellerProfile
    } = useEntity({name: 'profile'}, null);

    const {entity:loyalties} = useEntity({name: 'loyalties'});


    if (!resellerProfile && !loyalties) {
        return null;
    }

    const {
        stats,
        turnovers
    } = resellerProfile;

    let turnoversTable = {};
    if (stats && stats.turnovers && stats.turnovers.ownerPrices) {
        if (stats.turnovers.ownerPrices.previousYear) {
            turnoversTable = {...turnoversTable, ...stats.turnovers.ownerPrices.previousYear.groupedOwnerPrice};
        }

        if (stats.turnovers.ownerPrices.thisYear) {
            turnoversTable = {...turnoversTable, ...stats.turnovers.ownerPrices.thisYear.groupedOwnerPrice};
        }
    }

    return (
        <>
            <Row className="m-b-md">
                <Col lg={8}>
                    <Box title='Věrnost zákazníků v rámci vašeho subjektu (%)'>
                        {loyalties ? <Bar data={loyalties}/> : null}
                        {stats ? (
                            <ReportTable stats={stats.loyalties}
                                         formatValue={(month) => (<span>{month && month.percent ? month.percent : '-'}</span>)}/>
                        ) : null}
                    </Box>
                </Col>
            </Row>

            <Row>
                <Col lg={8}>
                    <Box title='Obraty v doporučených koncových cenách (Kč bez DPH)'>
                        {turnovers ? <Bar data={turnovers}/> : null}
                        {turnoversTable ? (
                            <ReportTable stats={turnoversTable} formatValue={(value) => (<span>{value ? <Price price={value} decimalLength={0} /> : '-'}</span>)}/>
                        ) : null}
                    </Box>
                </Col>
            </Row>
        </>
    )
};

export default Reports;