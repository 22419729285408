import React from 'react';

const ChannelContact = ({contact}) => {
    if (!contact) {
        return null;
    }

    const {contactSurname, contactFirstname, contactEmail} = contact;
    return (
        <>
            <div className="text-bold">{contactSurname} {contactFirstname}</div>
            <div>{contactEmail}</div>
        </>
    );
};

export default ChannelContact;