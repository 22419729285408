import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Route, useLocation} from 'react-router-dom';
import {useSetPageLocation} from "../index";

const PageRoute = ({title, section, blocks, ...props}) => {
    const setPageLocation = useSetPageLocation();
    const location = useLocation();
    useEffect(() => {
        setPageLocation({title, section, blocks, path: location.pathname});
    }, [title, section, setPageLocation, location.pathname, blocks]);

    return <Route {...props}/>
};

PageRoute.propTypes = {
    title: PropTypes.string,
    section: PropTypes.string,
    blocks: PropTypes.array
};

export default PageRoute;