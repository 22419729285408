import {useAppStorage} from "@fik/storage";
import {useCallback, useMemo} from "react";
import useIdentity from "@fik/auth/useIdentity";

const useEvidenceItemsStorage = () => {
    const identity = useIdentity();
    const key = useMemo(() => identity ? identity.contactId : null, [identity]);
    const [evidenceItems, setEvidenceItems] = useAppStorage('calculator/' + key, []);

    const clearEvidenceItems = useCallback(() => {
        setEvidenceItems([]);
    }, [setEvidenceItems])

    const addEvidenceItem = useCallback((item) => {
        if (item) {
            setEvidenceItems(items => [item, ...items]);
        }
    }, [setEvidenceItems]);

    const removeEvidenceItem = useCallback((item) => {
        if (item) {
            setEvidenceItems(items => {
                const index = items.indexOf(item);
                if (index > -1) {
                    const newItems = [...items];
                    newItems.splice(index, 1);
                    return newItems;
                }
                return items;
            });
        }
    }, [setEvidenceItems]);

    return useMemo(() => (
        {
            evidenceItems,
            clearEvidenceItems,
            addEvidenceItem,
            removeEvidenceItem
        }
    ), [clearEvidenceItems, addEvidenceItem, removeEvidenceItem, evidenceItems]);
}

export default useEvidenceItemsStorage;