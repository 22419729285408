import React from 'react';
import {FilterBar} from "@fik/ui";

const OffersFilter = (props) => {
    return (
        <FilterBar onSubmitSearch={props.onSubmitSearch}
                   controls={{
                       fulltext: {
                           elementType: 'input',
                           elementConfig: {
                               type: 'text',
                               placeholder: 'Zadejte název, IČ, e-mail, ...',
                           },
                           value: '',
                           valid: false,
                           touched: false,
                           validation: {
                               minLength: 3,
                           }
                       },
                       state: {
                           elementType: 'select',
                           options: props.states
                               ? [{value: '', label: 'Všechny stavy'}, ...props.states.map(item => ({value: item.id, label: item.label}))]
                               : [],
                           value: '',
                           valid: true,
                           touched: false,
                       },
                   }}
        />
    );
};

export default OffersFilter;