import React from 'react';
import PropTypes from 'prop-types';
import {Box} from "@fik/ui";

import {useEntity} from "@fik/entity";
import {useDials} from "@fik/dials";
import {OrderDocument} from "@fik/order";
import useIsEpc from "../../useIsEpc";

const Order = ({id}) => {

    const {order} = useEntity({name: 'order'}, id);
    const dials = useDials({name: 'order'});

    return (
        <Box>
                <OrderDocument
                    isEpc={useIsEpc()}
                    entity={order}
                    dials={dials}
                />
        </Box>
    )
};

Order.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Order;