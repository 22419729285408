import {useEffect, useState} from "react";
import CollapseItem from "./components/CollapseItem";
import LinkItem from "./components/LinkItem";
import TextItem from "./components/TextItem";

const useMenuItems = (content) => {
    const [activeItem, setActiveItem] = useState(null);
    const [menuItems, setMenuItems] = useState([]);

    useEffect(() => {
        const menuItems = content.filter((item) => item.label).map((item, index) => {
            const itemProps = {
                ...item,
                MenuItem: null
            };

            if (item.content) {
                itemProps.MenuItem = CollapseItem;
            } else if (item.path) {
                itemProps.MenuItem = LinkItem;
            } else if (item.to) {
                itemProps.MenuItem = LinkItem;
            } else {
                itemProps.MenuItem = TextItem;
            }

            return itemProps;
        });

        setMenuItems(menuItems);
    }, [content, activeItem]);

    return [menuItems, activeItem, setActiveItem];
}

export default useMenuItems;