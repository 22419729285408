const TableRow = ({children, render, ...props}) => {
    if (children) {
        return children(props);
    }

    if (render) {
        return render(props);
    }

    return null;
}

export default TableRow;