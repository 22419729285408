import React, {useState} from 'react';
import DealsPanel from "../../components/DealsPanel";
import handleClick from "@fik/utils/handleClick";

const VIEWS = {
    NEW: 'NEW',
    RENEW: 'RENEW'
};

const Deals = () => {
    const [view, setView] = useState(VIEWS.NEW);
    return (
        <>
            <div className="tabs-container">
                <ul className="nav nav-tabs">
                    <li className={view === VIEWS.NEW ? 'active' : ''}>
                        <a data-toggle="tab" className='nav-link'
                           onClick={handleClick(() => setView(VIEWS.NEW))}>Nové licence</a>
                    </li>
                    <li className={view === VIEWS.RENEW ? 'active' : ''}>
                        <a data-toggle="tab" className='nav-link'
                           onClick={handleClick(() => setView(VIEWS.RENEW))}>Existující licence</a></li>
                </ul>
                <div className="tab-content no-padding no-margins">
                    {view === VIEWS.NEW ? <div id="tab-new" className="tab-pane active">
                        <DealsPanel renew={false}/>
                    </div> : null}
                    {view === VIEWS.RENEW ? <div id="tab-renew" className="tab-pane active">
                        <DealsPanel renew={true}/>
                    </div> : null}
                </div>
            </div>
            <div className="clearfix"/>
        </>
    )
};

export default Deals;