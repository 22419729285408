import React from "react";
import {Date} from "@fik/ui";

const LicenseSolution = ({license}) => {
    if (!license) {
        return null;
    }

    const {
        licenseBusinessStatus,
        licenseBusinessStatusLabelStyle,
        licenseTypeName,
        licenseProductName,
        licenseQuantity,
        licenseDate,
        licenseExpiration,
        licensePackProductName,
        licensePackQuantity
    } = license;

    return <table className="table">
        <thead>
        <tr>
            <td>Stav</td>
            <td><span className={`label label-default label-${licenseBusinessStatusLabelStyle}`}>{licenseBusinessStatus}</span>
            </td>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>Typ</td>
            <td>{licenseTypeName}</td>
        </tr>
        <tr>
            <td>Produkt</td>
            <td>{licenseProductName}</td>
        </tr>
        <tr>
            <td>Počet</td>
            <td>{licenseQuantity}</td>
        </tr>
        <tr>
            <td>Od</td>
            <td><Date date={licenseDate}/></td>
        </tr>
        <tr>
            <td>Do (expirace)</td>
            <td><Date date={licenseExpiration}/></td>
        </tr>
        {licensePackProductName
            ? <tr>
                <td>Název bundlu</td>
                <td>{licensePackProductName}</td>
            </tr> : null}
        {licensePackQuantity
            ? <tr>
                <td>Velikost bundlu</td>
                <td>{licensePackQuantity}</td>
            </tr> : null}
        </tbody>
    </table>;

};

export default LicenseSolution;