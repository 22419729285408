import React from "react";
import PropTypes from 'prop-types';
import usePageBlock from "./../usePageBlocks";
import {TextPage} from "@fik/cms";

const PageBlock = ({name, container:Container = React.Fragment}) => {
    const block = usePageBlock(name);
    return block ? <Container><TextPage html={block} /></Container> : null;
};

PageBlock.propTypes = {
    name: PropTypes.string.isRequired,
    container: PropTypes.func
};

export default PageBlock;