import React, {useEffect, useMemo} from "react";
import {useEntity, usePureEntity} from "@fik/entity";
import {Box, Col, DownloadLink, Price, Row} from "@fik/ui";
import {Table, TableRow} from "@fik/table";

const ResellerPlan = () => {
    const [{entity: resellerPlanStats, isFetching}, load] = usePureEntity({name: 'resellerPlan'}, null);
    const {
        entity: resellerProfile,
        loadingState: {
            isSuccess: isProfileLoaded
        }
    } = useEntity({name: 'profile'}, null);

    const hasActivePlan = useMemo(() => {
        return (resellerProfile && resellerProfile.resellerPlanEnabled) === true;
    }, [resellerProfile])

    useEffect(() => {
        if (hasActivePlan) {
            load();
        }
    }, [hasActivePlan, load]);

    return <Row>
        <Col lg={12}>
            <Box>
                <p>Individuální roční plán slouží k nastavení ročních cílů pro konkrétního partnera a je součástí podmínek elitních
                    kategorií
                    partnerství. Individuální plán definuje obchodní a marketingové aktivity, obratové cíle, úroveň vzdělání a certifikací
                    partnera
                    pro kalendářní rok.</p>

                <p>Vyhodnocení Individuálního plánu partnera probíhá během ledna společně s ročním vyhodnocením partnerské kategorie
                    následující
                    kalendářní rok. Nový Individuální plán je k dispozici od 1.2. a jeho průběžné plnění nastavených cílů pro kalendářní
                    rok, lze
                    sledovat v přehledu níže na této stránce.</p>

                <p>Sestavení Individuálního ročního plánu vždy konzultujte se svým obchodím zástupcem. Věříme, že jasné stanovení cílů a
                    priorit
                    pomůže naplnit společná očekávání a rozvoj vzájemné spolupráce. Individuální roční plán je pro nás závazek maximální
                    podpory
                    partnera s přednostním zajištěním zdrojů u plánovaných aktivit nebo nastavených cílů.</p>

                {hasActivePlan && <p>
                    <DownloadLink resource='reseller-plan' className="btn btn-primary btn-xs"><i className="fa fa-file-pdf-o"/> Individuální roční plán ke stažení v PDF</DownloadLink>
                </p>}

                {isProfileLoaded && !hasActivePlan && <div className='alert alert-warning'>
                    Individuální plán není momentálně stanoven
                </div>}
            </Box>
            {hasActivePlan && resellerPlanStats &&  <Box>
                <Table striped responsive rows={resellerPlanStats.filter((item) => item.plan !== null)}>
                    <TableRow header=''>{({name}) => name}</TableRow>
                    <TableRow header='Roční cíl partnera'>{({plan}) => <Price price={plan} />}</TableRow>
                    <TableRow header='Průběžné plnění'>{({stats}) => <Price price={stats} />}</TableRow>
                </Table>
                <p className='font-italic'>Tabulka se aktualizuje po fakturaci.</p>
            </Box>}
        </Col>
    </Row>;
}

export default ResellerPlan;