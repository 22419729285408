import React, {useEffect} from "react";
import PropTypes from 'prop-types';
import {ChannelPicker} from "@fik/components";
import CustomerForm from "./CustomerForm";
import useCustomerEdit, {views} from "./../useCustomerEdit";
import CustomerType from "./CustomerType";
import {isEmpty} from "@mouseover/js-utils";
import {useCustomerContext} from "../index";

const validateParams = (params) => {
    return params.pattern && !isEmpty(params.pattern);
};

const pickerContext = {name: 'customer', params: {action: 'search'}, validate: validateParams};

const CustomerEdit = ({visibleViews}) => {

    const {
        viewType,
        values,
        customerType,
        pickNewCompany,
        pickNewExisting,
        pickNewHomeUser,
        confirmEdit,
        cancelEdit,
        params,
        existingChoosed,
        prefilledValue
    } = useCustomerContext();

    return <>
        <CustomerType
            pattern={prefilledValue}
            visibleViews={visibleViews}
            customerType={customerType}
            pickNewCompany={pickNewCompany}
            pickNewExisting={pickNewExisting}
            pickNewHomeUser={pickNewHomeUser}
        />

        {viewType === views.EXISTING || viewType === views.PREPARING ? <ChannelPicker entityContext={pickerContext} params={params} onPick={existingChoosed}/> : null}
        {viewType === views.EDIT
            ? <CustomerForm
                isCompany={customerType === views.COMPANY}
                values={values}
                onSubmit={confirmEdit}
                onReset={cancelEdit}
            /> : null}
    </>;
};

CustomerEdit.propTypes = {
    visibleViews: PropTypes.array,
    channelValues: PropTypes.object,
    confirmCustomerChange: PropTypes.func,
    cancelCustomerEdit: PropTypes.func
};

export default CustomerEdit;