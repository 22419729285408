import React, {useEffect, useMemo, useState} from "react";
import {DealForm} from "@fik/deal";
import {DocumentProvider} from "@fik/document-edit";
import useIsEpc from "../../useIsEpc";
import {Col, Row} from "@fik/ui";
import {useEntity, usePureEntity} from "@fik/entity";
import handleClick from "@fik/utils/handleClick";

const defaultDocument = {
    id: null,
    dateCreated: null,
    dateExpected: null,
    dateExpiration: null,
    phaseId: null,
    successProbability: null,
    statusId: null,
    closedId: null,
    opportunityId: null,
    dealClosed: null,
    dealPhase: null,
    dealStatus: null,
    staffId: null,
    note: null,
    opportunity: null,
    owner: null,
    partner: null,
    epc: null,
    documentType: 'deal',
    expectedSolutions: null,
    businessStatus: null,
    responsiblePerson: {
        contactFirstname: null,
        contactSurname: null,
        contactPosition: null,
        contactEmail: null,
        contactPhone: null
    }
};


const NewDeal = ({offerId, initialFormType = null}) => {

    const isEpc = useIsEpc();
    const [{entity: offer}, load] = usePureEntity({name: 'offer'});
    const [formType, setFormType] = useState(initialFormType);

    useEffect(() => {
        if (offerId) {
            load({id: offerId});
        }
    }, [offerId, load]);

    const document = useMemo(() => ({...defaultDocument, owner: offer ? offer.owner : null, renew: formType === 'renew'}), [offer, formType]);

    if (offerId && !offer) {
        return null;
    }
    return <DocumentProvider document={document} isEpc={isEpc} validate={false}>
        <Row className="row">
            {formType === null && <>
                <Col lg={6}>
                    <div className="widget style1 navy-bg calc-click-box" onClick={handleClick(setFormType, 'new')}
                         id="test_2c81w">
                        <div className="row">
                            <div className="col-xs-4">
                                <i className="fa fa-plus fa-5x"/>
                            </div>
                            <div className="col-xs-8 text-right">
                                <h2 className="font-bold">Registrace NOVÉ<br /> licence</h2>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="widget style1 lazur-bg calc-click-box" onClick={handleClick(setFormType, 'renew')}
                         id="test_oxmof">
                        <Row>
                            <Col xs={4}>
                                <i className="fa fa-search fa-5x"/>
                            </Col>
                            <Col xs={8} className="text-right">
                                <h2 className="font-bold">Registrace EXISTUJÍCÍ<br /> licence</h2>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </>}
            {formType && <Col sm={12} lg={9}><DealForm formType={formType}/></Col>}
        </Row>
    </DocumentProvider>;
};

export default NewDeal;