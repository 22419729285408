import React, {useCallback, useEffect} from "react";
import FooterText from "../../components/Navigation/Footer/FooterText";
import {useEntityAction} from "@fik/entity";
import {Form} from "@mouseover/react-form";
import {PasswordInput, TextInput} from "@fik/form";
import {NavigationItem} from "@fik/navigation";
import {Errors} from "@fik/ui";
import {isString, normalizeToString} from "@mouseover/js-utils";

const validatePassword = (value) => {
    const s = normalizeToString(value);
    return isString(s) && s.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,}$/) !== null;
};

const VALIDATION = {
    username: {
        required: {
            params: true,
            message: "Vyplňte prosím uživatelské jméno."
        },
        minLength: 3
    },
    password: {
        required: {
            params: true,
            message: "Vyplňte heslo."
        },
        minLength: 7,
        password: {
            validate: validatePassword,
            message: "Heslo musí obsahovat číslici, velká a malá písmena"
        }
    },
    passwordCheck: {
        required: {
            params: true,
            message: "Vyplňte heslo pro kontrolu."
        },
        minLength: 7,
        password: {
            validate: validatePassword,
            message: "Heslo musí obsahovat číslici, velká a malá písmena"
        }
    }
};

const PasswordForm = ({onSubmit}) => {
    const handleSubmit = useCallback((values, valid, form) => {
        if (valid) {
            onSubmit(values);
            form.setValues({password: null, passwordCheck: null});
        }
    }, [onSubmit]);

    return <Form
        values={{username: null, password: null, passwordCheck: null}}
        validationRules={VALIDATION}
        onSubmit={handleSubmit}
        render={(form) => (<>
            <TextInput label="Uživatelské jméno" form={form} name="username"/>
            <PasswordInput label="Nové heslo" form={form} name="password"/>
            <PasswordInput label="Nové heslo pro kontrolu" form={form} name="passwordCheck"/>
        </>)}
    >
        <button type='submit' className="btn btn-primary block m-b">Změnit heslo</button>
    </Form>;
};


const PasswordSetForm = ({id}) => {
    // save
    const [{isSuccess, isFailed, isFetching, error}, resetPassword] = useEntityAction({name: 'authentication', action: 'resetPassword'});

    const handleSubmit = useCallback((values) => {
        resetPassword({...values, id});
    }, [resetPassword, id]);

    return <>
        <div className="row">
            <div className="col-lg-12">
                {isFailed && <div className={'alert alert-error'}>Nepodařilo se změnit heslo!</div>}
                {error && error.messages && <Errors messages={error.messages.map((item) => ({message: item, type: 'error'}))} />}
                {isSuccess && <>
                    <div className={'alert alert-info'}>Heslo změněno.</div>
                    <NavigationItem to="signIn">
                        <small>Přihlásit</small>
                    </NavigationItem>
                </>}
                {id && !isFetching && !isSuccess && <PasswordForm onSubmit={handleSubmit} />}
            </div>
        </div>
    </>;
};

const PasswordSetContent = ({id}) => {
    const [{isSuccess, isFailed}, validateResetPassword] = useEntityAction({name: 'authentication', action: 'validateResetPassword'});
    useEffect(() => {
        validateResetPassword({id});
    }, [id, validateResetPassword]);
    return <>
        {isSuccess && <PasswordSetForm id={id}/>}
        {isFailed && <ErrorPage>Neplatný reset hesla!</ErrorPage>}
    </>;
};

const ErrorPage = ({children}) => <p className='alert alert-error'>{children}</p>

const PasswordSet = ({id}) => <div className="passwordBox animated fadeInDown">
    <div className="row">
        <div className="col-md-12">
            <div className="ibox-content">
                <h2 className="font-bold">Nastavení hesla</h2>
                {id ? <PasswordSetContent id={id}/> : <ErrorPage>Neplatný reset hesla!</ErrorPage>}
            </div>
        </div>
    </div>
    <hr/>
    <div className="row">
        <div className="col-md-12">
            <div>
                <FooterText/>
            </div>
        </div>
    </div>
</div>;

export default PasswordSet;