import React, {useCallback, useEffect} from 'react';
import {BranchOfficeForm} from "@fik/components";
import {useEntityAction, usePureEntity} from "@fik/entity";

const BranchOffice = () => {
    // contact to edit
    const [{entity: branchOffice}, load] = usePureEntity({name: 'resellerProfile', params: {action: 'branch-office'}});
    // save
    const [{isSuccess}, save] = useEntityAction({name: 'resellerProfile', action: 'saveBranchOffice'});

    //- load on first render (load callback should not change)
    useEffect(() => load(), [load]);

    //- refresh after save
    useEffect(() => {
        if (isSuccess) {
            load();
        }
    }, [load, isSuccess]);

    const handleSubmit = useCallback((values) => {
        save({values});
    }, [save]);

    if (!branchOffice) {
        return null;
    }

    return <BranchOfficeForm branchOffice={branchOffice} onSubmit={handleSubmit} />;
};

export default BranchOffice;