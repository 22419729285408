import {shallowEqual, useSelector} from "react-redux";
import {selectResourceState, selectSimpleResourceState} from "./selectors";

export const useResourceSelector = ({storeName, name: resource}, callback, deps) => useSelector(
    selectSimpleResourceState(storeName, resource)(callback),
    deps
);
export const useResourceSelectorWithParams = ({storeName, name: resource}, params, callback, method) => useSelector(
    selectResourceState(storeName, resource, params, method)(callback),
    shallowEqual
);