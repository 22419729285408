import React, {useMemo} from 'react';
import PropTypes from "prop-types";
import {NavigationItem} from "@fik/navigation";

const DocumentSaved = ({documentType, document}) => {
    switch (documentType) {
        case 'order':
        case 'nfr-order':
            return <div className="alert alert-success">
                <i className="fa fa-check-circle"/> Váš požadavek byl úspěšně odeslán ke zpracování. Během několika minut naleznete
                příslušné licence v sekci <NavigationItem to="license:newest">Licence</NavigationItem>.
            </div>;

        case 'offer':
            return <div className="alert alert-success">
                <i className="fa fa-check-circle"/> Váš požadavek byl úspěšně odeslán ke zpracování. Nabídku naleznete v sekci <NavigationItem to="offer:list">Cenové nabídky</NavigationItem>.
                <br />
                <br />
                <strong>Nabídka s aplikovanou slevou podléhá ručnímu schválení, aktuální stav najdete v sekci <NavigationItem to="offer:list">Cenové nabídky</NavigationItem>.</strong>
            </div>;

        case 'offers':
            return <div className="alert alert-success">
                <i className="fa fa-check-circle"/> Váš požadavek byl úspěšně odeslán ke zpracování. Nabídky naleznete v sekci <NavigationItem to="offer:list">Cenové nabídky</NavigationItem>.
                <br />
                <br />
                <strong>Nabídka s aplikovanou slevou podléhá ručnímu schválení, aktuální stav najdete v sekci <NavigationItem to="offer:list">Cenové nabídky</NavigationItem>.</strong>
            </div>;

        case 'projectPrice':
           return <ProjectPriceSaved document={document} />;


        case 'deal':
            return <div className="alert alert-success">
                <i className="fa fa-check-circle"/> Váš požadavek byl úspěšně odeslán ke zpracování. Svou žádost a její stav naleznete v sekci <NavigationItem to="deal:list">Obchodní případy</NavigationItem>.
            </div>;

        default:
            return <div className="alert alert-success">
                <i className="fa fa-check-circle"/> Váš požadavek byl úspěšně odeslán ke zpracování.
            </div>;
    }
};

DocumentSaved.propTypes = {
    documentType: PropTypes.string,
    document: PropTypes.object
};

export default DocumentSaved;

const ProjectPriceSaved = ({document}) => {

    const hasNewItem = useMemo(() => {
        return document && document.evidenceItems && document.evidenceItems.find((item) => item.itemProductTypeId === 1) !== undefined;
    }, [document]);

    if (hasNewItem) {
        return <div className="alert alert-success">
            <i className="fa fa-check-circle"/> Váš požadavek byl úspěšně odeslán ke zpracování.
            Pokud jste ještě nezažádali o registraci obchodního případu, učiňte tak prosím nyní <NavigationItem to="deal:createNewLicence" query={{offerId: document.id}}>ZDE</NavigationItem>.
            <ProjectPriceInfo />
        </div>;
    } else {
        return <div className="alert alert-success">
            <i className="fa fa-check-circle"/> Váš požadavek byl úspěšně odeslán ke zpracování.
            Pokud jste ještě nezažádali o registraci obchodního případu, učiňte tak prosím nyní <NavigationItem to="deal:createRenewLicence" query={{offerId: document.id}}>ZDE</NavigationItem>.
            <ProjectPriceInfo />
        </div>;
    }
}

const ProjectPriceInfo = () => <>
    <br />
    <br />
    <strong>Přidělení projektové ceny probíhá do 10ti dnů společně s vyhodnocením registrace obchodního případu. Stav Vaší žádosti je možné ověřit v sekci <NavigationItem to="offer:list">Projektové ceny</NavigationItem>.</strong>
    </>;