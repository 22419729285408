import {useCallback, useReducer} from "react";

const reducer = (state, action) => {
    const newState = {...state, resources: {...state.resources, [action.resource]: action.state}};
    const fetching = Object.entries(newState.resources).filter(([, item]) => item.isFetching);
    newState.currentlyLoading = fetching.map(([resource]) => resource);
    newState.isLoading = false;
    newState.isRefreshing = false;
    return fetching.reduce((acc, [, item]) => {
        if (item.isFetching && !item.isRefreshing) {
            acc.isLoading = true;
        }
        if (item.isRefreshing) {
            acc.isRefreshing = true;
        }

        return acc;
    }, newState);
};

const initialState = {
    resources: {},
    isLoading: false,
    isRefreshing: false
};

const useLoading = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const changeLoadingState = useCallback((resource, state) => {
        dispatch({resource, state});
    }, []);

    return {
        isLoading: state.isLoading,
        isRefreshing: state.isRefreshing,
        resources: state.resources,
        changeLoadingState
    }
};

export default useLoading;