import React from "react";
import handleClick from "@fik/utils/handleClick";

const ToggleRowDetailButton = ({primaryValue, isVisibleRow, toggleRow, closeIcon='fa-level-up', openIcon='fa-info-circle', ...props}) =>
    <button
        className="btn btn-sm btn-default" {...props}
        title={ (!isVisibleRow(primaryValue) ? 'Rychlý detail' : 'Schovat rychlý detail')}
        onClick={handleClick(() => toggleRow(primaryValue))}>
        <i className={"fa " + (!isVisibleRow(primaryValue) ? openIcon : closeIcon)}/>
    </button>;

export default ToggleRowDetailButton;