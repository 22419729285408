import {useReducer, useCallback, useEffect, useMemo, useRef} from 'react';
import useRefOn from "@fik/utils/useRefOn";
import isEmptyChannel from "@fik/utils/isEmptyChannel";

const actions = {
    CANCEL_EDIT: 'CANCEL_EDIT',
    CONFIRM_EDIT: 'CONFIRM_EDIT',
    DOCUMENT_INITIALIZED: 'DOCUMENT_INITIALIZED',
    PICK_NEW_HOME_USER: 'PICK_NEW_HOME_USER',
    PICK_NEW_COMPANY: 'PICK_NEW_COMPANY',
    CUSTOMER_IS_HOME_USER: 'CUSTOMER_IS_HOME_USER',
    CUSTOMER_IS_COMPANY: 'CUSTOMER_IS_COMPANY',
    PICK_NEW_EXISTING: 'PICK_NEW_EXISTING',
    EXISTING_CHOOSED: 'EXISTING_CHOOSED',
    EDIT: 'EDIT'
};

export const views = {
    PICK: 'pick',
    VIEW: 'view',
    HOME: 'homeUser',
    COMPANY: 'company',
    EDIT: 'edit',
    EXISTING: 'existing',
    PREPARING: 'none'
};

export const DEFAULT_VIEWS = [views.HOME, views.COMPANY, views.EXISTING];

const initialState = {
    initialValues: null,
    values: null,
    previousViewType: views.PREPARING,
    viewType: views.PREPARING,
    params: null,
    prefilledValue: null
};

const emptyValues = {
    addressBook: {name: null, street: null, city: null, zipcode: null, companyId: null},
    contact: {contactFirstname: null, contactSurname: null, contactEmail: null, contactPhone: null}
};

function prepareValues(channelValues) {
    return channelValues ? {
        ...emptyValues,
        ...channelValues,
        contact: channelValues && channelValues.contact ? channelValues.contact : emptyValues.contact
    } : {...emptyValues};
}

function isOnOneOfCustomerEditView(view) {
    return view === views.EDIT || view === views.PICK || view === views.EXISTING || view === views.HOME || view === views.COMPANY;
}

const documentCustomerReducer = (state, action) => {
    switch (action.type) {
        case actions.PICK_NEW_HOME_USER:
            return {
                ...state,
                values: {...emptyValues},
                previousViewType: state.viewType,
                viewType: views.EDIT,
                customerType: views.HOME,
                params: null
            };

        case actions.PICK_NEW_COMPANY:
            return {
                ...state,
                values: {...emptyValues},
                previousViewType: state.viewType,
                viewType: views.EDIT,
                customerType: views.COMPANY,
                params: null
            };

        case actions.CUSTOMER_IS_HOME_USER:
            return {
                ...state,
                customerType: views.HOME,
            };

        case actions.CUSTOMER_IS_COMPANY:
            return {
                ...state,
                customerType: views.COMPANY,
                params: null
            };

        case actions.EDIT:
            return {
                ...state,
                viewType: views.EDIT,
                values: action.values ? action.values : state.values
            };


        case actions.PICK_NEW_EXISTING:
            return {
                ...state,
                values: null,
                previousViewType: state.viewType,
                viewType: views.EXISTING,
                customerType: null,
                params: action.params
            };

        case actions.EXISTING_CHOOSED:
            return {
                ...state,
                values: action.values,
                previousViewType: state.viewType,
                viewType: views.EDIT,
                customerType: action.values.addressBook && action.values.addressBook.companyId ? views.COMPANY : views.HOME,
                params: null
            };

       case actions.CANCEL_EDIT:
            return {
                ...state,
                values: null,
                viewType: views.PICK,
                customerType: null,
                params: null
            };

        case actions.CONFIRM_EDIT:
            return {
                ...state,
                values: action.values,
                previousViewType: state.viewType,
                viewType: views.VIEW,
                customerType: action.values.addressBook && action.values.addressBook.companyId ? views.COMPANY : views.HOME,
                params: null
            };

        case actions.DOCUMENT_INITIALIZED:
            return {
                ...state,
                previousViewType: action.viewType,
                viewType: action.viewType,
                customerType:  action.viewType === views.EDIT ? action.values.addressBook && action.values.addressBook.companyId ? views.COMPANY : views.HOME : null,
                params: null,
                values: action.values,
                initialValues: action.initialValues,
                prefilledValue: action.prefilledValue
            };

        default:
            return state;
    }
};


const useCustomerEdit = ({channelValues, confirmCustomerChange, itemLicenseIdCodes, onViewChange}) => {

    const [state, dispatch] = useReducer(documentCustomerReducer, initialState);

    const cancelEdit = useCallback(() => {
        dispatch({type: actions.CANCEL_EDIT});
    }, [dispatch]);

    const confirmEdit = useCallback((values) => {
        confirmCustomerChange(values);
        dispatch({type: actions.CONFIRM_EDIT, values});
    }, [dispatch, confirmCustomerChange]);

    const edit = useCallback((values) => {
        dispatch({type: actions.EDIT, values});
    }, [dispatch, confirmCustomerChange]);

    const pickNewHomeUser = useCallback(() => {
        dispatch({type: state.viewType === views.EDIT ? actions.CUSTOMER_IS_HOME_USER : actions.PICK_NEW_HOME_USER});
    }, [dispatch, state.viewType]);

    const pickNewCompany = useCallback(() => {
        dispatch({type: state.viewType === views.EDIT ? actions.CUSTOMER_IS_COMPANY : actions.PICK_NEW_COMPANY});
    }, [dispatch, state.viewType]);

    const pickNewExisting = useCallback((params) => dispatch({type: actions.PICK_NEW_EXISTING, params}), [dispatch]);
    const existingChoosed = useCallback((values) => dispatch({type: actions.EXISTING_CHOOSED, values}), [dispatch]);

    useEffect(() => {
        if (state.viewType === views.PREPARING) {
            dispatch({
                type: actions.DOCUMENT_INITIALIZED,
                initialValues: channelValues,
                values: prepareValues(channelValues),
                prefilledValue: itemLicenseIdCodes || null,
                viewType: isEmptyChannel(channelValues) ? views.PICK : views.EDIT});

            if (itemLicenseIdCodes) {
                pickNewExisting({pattern: itemLicenseIdCodes});
            }
        }
    }, [channelValues, itemLicenseIdCodes, state.viewType, pickNewExisting]);

    useEffect(() => {
        if (itemLicenseIdCodes && state.viewType === views.PICK && isEmptyChannel(channelValues)) {
            pickNewExisting({pattern: itemLicenseIdCodes});
        }
    }, [itemLicenseIdCodes, pickNewExisting, state.viewType, channelValues]);

    const isCustomerFilled = useMemo(() => !isEmptyChannel(channelValues), [channelValues]);

    const refOnViewChange = useRefOn(onViewChange);

    useEffect(() => {
        if (refOnViewChange.current) {
            refOnViewChange.current(state.viewType, isOnOneOfCustomerEditView(state.viewType));
        }
    }, [refOnViewChange, state.viewType]);


    return {
        edit,
        cancelEdit,
        confirmEdit,
        pickNewHomeUser,
        pickNewCompany,
        pickNewExisting,
        existingChoosed,
        viewType: state.viewType,
        customerType: state.customerType,
        values: state.values,
        params: state.params,
        prefilledValue: state.prefilledValue,
        isEditingCustomer: useMemo(
            () => isOnOneOfCustomerEditView(state.viewType) || !isCustomerFilled,
            [state.viewType, isCustomerFilled]
        ),
        isCustomerFilled,
        customer: channelValues
    }
};

export default useCustomerEdit;