import React from 'react';
import PropTypes from 'prop-types';
import {FormField} from "@mouseover/react-form";


const Promo = ({promos = [], form}) => {
    return (<div className="row">
        <div className="col-sm-8">
            <FormField
                name='itemPromoId'
                form={form}
                label="Promo"
                elementType="select"
                options={[{value: '', label: 'Žádné promo'}, ...promos.map(item => ({
                    value: item.promoId,
                    label: item.promoName
                }))]}
                valueType="integer"
            />
        </div>
        <div className="col-sm-4">
            <FormField
                name='itemBonusCode'
                form={form}
                label="Bonus kód"
                elementType="input"
                elementConfig={{
                    type: 'text'
                }}
            />
        </div>
    </div>);
};

Promo.propTypes = {
    promos: PropTypes.array,
    form: PropTypes.object
};

export default Promo;