import React, {useCallback} from "react";
import {Box, Col, Row} from "@fik/ui";
import {useEntityAction} from "@fik/entity";
import {ContactForm} from "@fik/components";
import {Redirect} from "react-router-dom";

const EMPTY_CONTACT = {};

const ContactCreate = () => {

    // contact
    const values = EMPTY_CONTACT;

    // save
    const [{isSuccess}, save] = useEntityAction({name: 'contact', action: 'create'});

    const onSubmit = useCallback((values, valid) => {
        if (valid) {
            save({values});
        }
    }, [save]);

    if (isSuccess) {
        return <Redirect to='/profile/contacts'/>;
    }

    return <Row>
        <Col lg={6} md={12}>
            <Box>
                <ContactForm
                    values={values}
                    selfEdit={true}
                    onSubmit={onSubmit}/>
            </Box>
        </Col>
    </Row>;
};

export default ContactCreate;