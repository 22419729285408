import Link from "./Link";
import React from "react";
import Icon from "./Icon";

const LinkItem = ({to, icon, label, children, ...props}) => (<Link link={to} {...props}>
    {icon ? <Icon icon={icon}/> : null}
    {label || null}
    {children || null}
</Link>);


export default LinkItem;