import React, {useEffect, useMemo} from 'react';
import PageContext from "@fik/page/PageContext";
import usePage from "@fik/page/usePage";
import {useCmsPageBlocks} from "@fik/cms";

const ResellerzonePageProvider = (props) => {
    const {page, setPageInfo, setPageLocation} = usePage();
    const [blocks, initPage] = useCmsPageBlocks();

    useEffect(() => {
        initPage(page.path, page.blocks);
    }, [initPage, page]);

    const value = useMemo(() => ({page, setPageInfo, setPageLocation, blocks}), [page, setPageInfo, setPageLocation, blocks]);

    return <PageContext.Provider value={value} {...props} />;
};

export default ResellerzonePageProvider;