import React, {useCallback, useMemo} from "react";
import {Box} from "@fik/ui";
import {SelectInput} from "@fik/form";
import {Form} from "@mouseover/react-form";
import {useDocumentContext} from "../useDocumentContext";

const DocumentBranchOffice = ({branchOffices}) => {
    const {
        document,
        changeValues,
    } = useDocumentContext();

    const values = useMemo(() => document.partner, [document]);

    const handleValuesChanges = useCallback(({branchOfficeId}, valid) => {
        if (valid && values.branchOfficeId !== branchOfficeId) {
            changeValues({partner: {...values, branchOfficeId: branchOfficeId, branchOffice: {branchOfficeId}}});
        }
    }, [changeValues, values]);

    return <Box title="Pobočka">
        <Form
            values={values}
            validationRules={{}}
            onChange={handleValuesChanges}
            render={(form => {
                return  <SelectInput
                                     name={['branchOfficeId']}
                                     form={form}
                                     options={[{value: '', label: '---'}, ...(branchOffices ? branchOffices.map((item) => ({
                                         label: item.name,
                                         value: item.branchOfficeId
                                     })) : [])]}/>
            })} />
    </Box>;
}

export default DocumentBranchOffice;