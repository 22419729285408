const mapResourceParamsToRequestParams = (resource, params = {}, content = null) => {

    const requestParams = {...params};

    if (resource === 'profile') {
        requestParams.path = '/api/profile';
    }

    if (resource === 'loyalties') {
        requestParams.path = '/api/loyalties';
    }


    let path;
    if (requestParams.path) {
        path = requestParams.path;
        delete requestParams.path;
    } else {
        path = `api/resource/${resource}`;
    }

    if (requestParams.id) {
        path += `/${encodeURIComponent(requestParams.id)}`;
        delete requestParams.id;
    }

    if (requestParams.action) {
        path += `/action/${requestParams.action}`;
        delete requestParams.action;
    }

    return {
        url: path,
        params: requestParams,
        data: content
    }
};

const isInvalidResponse = (res) => (!res.data.body || !res.data.success);

const mapResponse = (res) => res.data.body;

const axiosRequest = async (axiosInstance, requestParameters) => {
    return axiosInstance(requestParameters)
        .then(res => {
            if (isInvalidResponse(res)) {
                const e = Error('Invalid response');
                e.data = res;
                throw e;
            }
            return mapResponse(res);
        });
};

const wrapWithStore = (store, key, promise) => {
    const stored = store.get(key);

    if (stored) {
        return new Promise((resolve) => resolve(stored));
    }

    return promise.then((result) => {
        store.save(key, result);
        return result;
    });
};

const resourceRequestConfig = (resource, method, params, content) => {
    const requestParams = mapResourceParamsToRequestParams(resource, params, content);
    requestParams.method = method;
    return requestParams;
};

export const resourceAdapter = (axiosInstance) => (resource) => {
    return {
        get: (params) => axiosRequest(axiosInstance, resourceRequestConfig(resource, 'GET', params)),
        put: (params, content) => axiosRequest(axiosInstance, resourceRequestConfig(resource, 'PUT', params, content)),
        post: (params, content) => axiosRequest(axiosInstance, resourceRequestConfig(resource, 'POST', params, content)),
        remove: (params) => axiosRequest(axiosInstance, resourceRequestConfig(resource, 'REMOVE', params)),
    }
};

export const cmsAdapter = (axiosInstance, store) => () => {
    return {
        getMenu: () => axiosRequest(axiosInstance, {url: 'api/cms/content/menu'}),
        getPage: (category, page) => axiosRequest(axiosInstance, {url: 'api/cms/content/page/' + encodeURIComponent(category) + '/' + encodeURIComponent(page)}),
        getPageBlocks: (category, page, blocks) => wrapWithStore(
            store,
            `${category}-${page}-${blocks.join('-')}`,
            axiosRequest(axiosInstance, {url: 'api/cms/content/blocks/' + encodeURIComponent(category) + '/' + encodeURIComponent(page) + '?blocks=' + blocks.join(',')}),
        ),
        getFiles: () => axiosRequest(axiosInstance, {url: 'api/cms/files'}),
        getFeeds: (feedGroupId) => axiosRequest(axiosInstance, {url: 'api/cms/resource/article/action/feeds?tagGroupId=' + feedGroupId}),
        getArticle: (id) => axiosRequest(axiosInstance, {url: 'api/cms/resource/article/' + encodeURIComponent(id)}),
    }
};

export const authenticationAdapters = (axiosInstance) => () => {
    return {
        authenticate: (credentials) => axiosRequest(axiosInstance, {
            url: credentials.username ? '/api/authenticate/user' : '/api/authenticate/token',
            data: credentials,
            method: 'POST'
        })
            .then((response) => {
                return {
                    ...response,
                    userId: response.contactId
                }
            }),
        forgotPassword: (credentials) => axiosRequest(axiosInstance, {
                url: '/api/authenticate/forgot-password',
                data: credentials,
                method: 'POST'
            }
        ),
        validateResetPassword: ({id}) => axiosRequest(axiosInstance, {
                url: '/api/authenticate/validate-reset-password',
                data: {id},
                method: 'POST'
            }
        ),
        resetPassword: (values) => axiosRequest(axiosInstance, {
                url: '/api/authenticate/reset-password',
                data: values,
                method: 'POST'
            }
        ),
        getUserByToken: (accessToken) => axiosRequest(axiosInstance, {
                url: '/api/authenticate/token',
                data: {access_token: accessToken},
                method: 'POST'
            }
        )
            .then((response) => {
                return {
                    ...response,
                    userId: response.contactId
                }
            }),
        setAuthorizationToken: (token) => axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }
};

export const identityAdapter = (axiosInstance, credentials) => (resource) => {
    return {
        get: (token) => {
            return authenticationAdapters(axiosInstance)().getUserByToken(token)
        },
    }
};

export const dataSourceAdapter = (axiosInstance, action = 'data-source') => (resource) => {
    return {
        getDataSource: (params) => {
            return resourceAdapter(axiosInstance)(resource).get({action: action, ...params})
        }
    }
};