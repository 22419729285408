import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Box} from "@fik/ui";
import handleClick from "@fik/utils/handleClick";
import {Errors} from "@fik/ui";
import {useDocumentContext} from "../useDocumentContext";
import {CustomerEdit, CustomerProvider, DEFAULT_VIEWS} from "@fik/customer-edit";
import {DisabledOverlay} from "@fik/ui";
import {Channel} from "@fik/document";
import DocumentCustomer from "./DocumentCustomer";

const DocumentCustomerContainer = ({visibleViews}) => {

    const memoVisibleViews = useMemo(() => visibleViews || DEFAULT_VIEWS, [visibleViews]);

    const {
        view: {enableCustomer, handleCustomerViewChange},
        document,
        potentialOwner,
        isCustomerFilled,
        confirmCustomerChange,
        validation: {validationMessages}
    } = useDocumentContext();

    if (!document) {
        return null;
    }

    return <CustomerProvider
        itemLicenseIdCodes={potentialOwner}
        channelValues={document.owner}
        confirmCustomerChange={confirmCustomerChange}
        onViewChange={handleCustomerViewChange}
    >
        <DisabledOverlay on={!enableCustomer}>
            <DocumentCustomer
                visibleViews={memoVisibleViews}
                messages={validationMessages.filter(message => message.path.indexOf('owner') === 0)}
            />
        </DisabledOverlay>
    </CustomerProvider>;

};

DocumentCustomerContainer.propTypes = {
    visibleViews: PropTypes.array
};

export default DocumentCustomerContainer;