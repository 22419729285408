import React, {useCallback} from 'react';
import PropTypes from "prop-types";

import {Form} from "@mouseover/react-form";

const EMPTY_RULES = {};

const FilterBar = ({onSubmitSearch, onChange, controls, render, button = true, values}) => {

    const handleFormSubmit = useCallback((values, valid) => {
        if (onSubmitSearch && valid) {
            onSubmitSearch(values, valid);
        }
    }, [onSubmitSearch]);

    return (
        <Form
            values={values}
            validationRules={EMPTY_RULES}
            className="row no-padding m-l-n-sm m-r-n-sm"
            onSubmit={handleFormSubmit}
            onChange={onChange}
            defaultGroupClass='form-group col-sm-3'
            controls={controls}
            render={render}>

            {button
                ? <div className="col-sm-3">
                    <button type="submit" className="btn btn-primary block full-width m-b">Filtrovat</button>
                </div>
                : null}
        </Form>
    );
};

FilterBar.propTypes = {
    onSubmitSearch: PropTypes.func,
    onChange: PropTypes.func,
    controls: PropTypes.object,
    render: PropTypes.func,
};


export default FilterBar;