import React from "react";
import {useCallback, useMemo} from "react";
import {getValue, pathWithChildren} from "@mouseover/js-utils";
import {updateValue} from "@mouseover/js-utils/src";
import {useFormContainer} from "@mouseover/react-form";
import SolutionFields from "./SolutionFields";
import {useValidator} from "@mouseover/js-validation-hook";

const SolutionContainer = ({path, form, ...props}) => {
    const container = useFormContainer(path, form);
    const isChecked = useMemo(() => form.values[path].checked, [form.values[path]])
    return <SolutionFields form={container} {...props} isChecked={isChecked}/>
}

const SolutionsContainer = ({form, validationRules, ...props}) => {
    const collectionContainer = useFormCollection('expectedSolutions', form, validationRules);
    return <>{
        collectionContainer.values && collectionContainer.values.map(
            (item, index) => <SolutionContainer
                {...props}
                key={index}
                form={collectionContainer}
                path={index}/>)
    }</>;
}

export default SolutionsContainer;


const useFormCollection = (path, form, validationRules) => {
    const {values = {}, validation = {}, defaultValues = {}, setValue, handleSubmit} = form;
    const pathMemo = useMemo(() => Array.isArray(path) ? path : [path], [path]);

    const addItem = useCallback((item) => {
        const collection = getValue(values, pathMemo) || [];
        setValue([...collection, item], pathMemo);
    }, [setValue, values, pathMemo]);

    const setValueAt = useCallback((value, index) => {
        const collection = [...(getValue(values, pathMemo) || [])];
        if (Array.isArray(index)) {
            const [currentIndex, ...currentPath] = index;
            collection.splice(currentIndex, 1, updateValue(collection[currentIndex], currentPath, value));
        } else {
            collection.splice(index, 1, value);
        }

        setValue(collection, pathMemo);
    }, [values, pathMemo, setValue]);

    const validator = useValidator(validationRules);
    const currentValidation = useMemo(() => validator.validateObject(values), [values, validator]);

    return {
        isSubmitted: form.isSubmitted,
        values: getValue(values, pathMemo) || [],
        setValue: setValueAt,
        addItem,
        validation: getValue(currentValidation, pathWithChildren(pathMemo)) || {children: {}, valid: true},
        defaultValues: getValue(defaultValues, pathMemo) || [],
        setValues: useCallback((values) => {
            setValue(values, pathMemo);
        }, [setValue, pathMemo]),
        handleSubmit
    }
};
