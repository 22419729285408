import React from 'react';
import PropTypes from "prop-types";

import {NavLink} from "react-router-dom";

const Link = (props) => (
    <NavLink
        exact={props.exact}
        activeClassName='active'
        className={props.className}
        to={props.link}
    >{props.children}</NavLink>
);

Link.propTypes = {
    link: PropTypes.string.isRequired,
    active: PropTypes.bool,
    className: PropTypes.string
};


export default Link;
