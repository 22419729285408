import React from 'react';
import {FormField} from "@mouseover/react-form";
import PropTypes from "prop-types";

const Discount = ({discounts = [], form}) => {
    return (<div className="row">
            <div className="col-sm-8">
                <FormField
                    name='itemDiscountId'
                    form={form}
                    label="Sleva"
                    elementType="select"
                    options={[{value: '', label: 'Žádná sleva'}, ...discounts.map(item => ({
                        value: item.productDiscountId,
                        label: item.productDiscountName
                    }))]}
                    valueType="integer"
                />
            </div>
            <div className="col-sm-4">
                <FormField
                    label="Výše slevy"
                    name='itemDiscount'
                    form={form}
                    elementType="input"
                    elementConfig={{
                        type: 'number',
                        placeholder: 'Výše slevy',
                    }}
                    valueType="number"
                />
            </div>
        </div>);
};

Discount.propTypes = {
    discounts: PropTypes.array,
    form: PropTypes.object
};

export default Discount;