import React from "react";
import PropTypes from "prop-types";

const EvidenceItemAmount = ({itemAmount}) => <>{itemAmount || '--'}</>;

EvidenceItemAmount.propTypes = {
    itemAmount: PropTypes.number
};

export default EvidenceItemAmount;
