import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import QueryParamsContext from "../QueryParamsContext";
import {shallowEqual} from "@mouseover/js-utils";

export const searchParamsToObject = (urlSearchParams) => {
    if (!urlSearchParams) {
        return {};
    }
    const returnParams = {};
    urlSearchParams.forEach(function(value, key) {
        returnParams[key] = value;
    });
    return returnParams;
}

/**
 * Poskytuje jednotne api pro zmenu url query parametru
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const QueryParamsProvider = (props) => {
    const history = useHistory();
    const location = useLocation();
    const searchParams = useMemo(() => searchParamsToObject(new URLSearchParams(location.search)), [location.search]);

    const currentSearchParams = useRef(searchParams);
    const newSearchParams = useRef(searchParams);

    const [reload, setReload] = useState(false);

    const change = useCallback((args) => {
        setReload(true);
        newSearchParams.current = typeof args === 'function' ? args(newSearchParams.current) : args;
    }, [newSearchParams, setReload]);

    useEffect(() => {
        if (reload) {
            setReload(false);
        }
    }, [reload, setReload]);

    useEffect(() => {
        return () => {
            const params = newSearchParams.current;
            if (!shallowEqual(currentSearchParams.current, params)) {
                const locationSearchParams = new URLSearchParams();
                for (const key in params) {
                    if (params.hasOwnProperty(key)) {
                        locationSearchParams.append(key, params[key]);
                    }
                }
                history.push({search: locationSearchParams.toString()});
                currentSearchParams.current = params;
            }
       }
    });

    const getSearchParams = useCallback(() => currentSearchParams.current, [currentSearchParams]);
    const value = useMemo(() => ({getSearchParams, change, searchParams}), [history, searchParams, getSearchParams]);
    return <QueryParamsContext.Provider value={value} {...props}/>;
}

export default QueryParamsProvider;

