import React from "react";
import {NavigationItem} from "@fik/navigation";
import {WithoutCode} from "@fik/ui";

const LicenseIdentification = ({license}) => {
    if (!license) {
        return null;
    }

    const {
        licenseMainId,
        licenseKey,
        licenseUsername,
        licensePassword,
        licenseOrderId,
        licenseProductKey,
        licenseProxyId,
        licenseDeslockProductId
    } = license;

    return <table className="table">
        <thead>
        <tr>
            <td>ID licence</td>
            <td>{licenseMainId}</td>
        </tr>
        {licenseDeslockProductId ? <DeslockLicense {...license}/> : <NormalLicense {...license} />}
        </thead>
        <tbody>
        {licenseOrderId
            ? <tr>
                <td>Objednávka</td>
                <td>
                    <NavigationItem to="order:detail" params={{id: licenseOrderId}}>
                        <WithoutCode>{licenseOrderId}</WithoutCode>
                    </NavigationItem>
                </td>
            </tr>
            : null}
        </tbody>
    </table>;
};

export default LicenseIdentification;

const NormalLicense = ({licenseKey, licenseProductKey, licenseProxyId, licenseUsername, licensePassword}) => <>
    {licenseKey ? <tr>
        <td>Licenční klíč</td>
        <td>{licenseKey}</td>
    </tr> : null}
    {licenseProductKey ? <tr>
        <td>Product Key</td>
        <td>{licenseProductKey}</td>
    </tr> : null}
    {licenseProxyId ? <tr>
        <td>Proxy ID</td>
        <td>{licenseProxyId}</td>
    </tr> : null}
    <tr>
        <td><i>Uživatelské jméno</i></td>
        <td><i>{licenseUsername}</i></td>
    </tr>
    <tr>
        <td><i>Heslo</i></td>
        <td><i>{licensePassword}</i></td>
    </tr>
</>;

const DeslockLicense = ({licenseDeslockProductId, licenseDeslockProductKey}) => <>
    {licenseDeslockProductId ? <tr>
        <td>Product ID</td>
        <td>{licenseDeslockProductId}</td>
    </tr> : null}
    {licenseDeslockProductKey ? <tr>
        <td>Product Key </td>
        <td>{licenseDeslockProductKey}</td>
    </tr> : null}
</>;