import React, {useCallback} from "react";
import {Box, Col, Row} from "@fik/ui";
import {useEntityAction} from "@fik/entity";
import {PasswordForm} from "@fik/components";
import {useSelector} from "react-redux";

const Password = () => {
    const {username} = useSelector(state => state.auth);

    // save
    const [{isSuccess, isFailed}, resetPassword] = useEntityAction({name: 'resellerProfile', action: 'resetPassword'});

    const handleSubmit = useCallback((values) => {
        resetPassword({values});
    }, [resetPassword]);

    return <Row>
        <Col sm={12} md={4}>
            {isFailed ? <div className={'alert alert-error'}>Nepodařilo se změnit heslo!</div> : null}
            {isSuccess ? <div className={'alert alert-info'}>Heslo změněno.</div> : null}
            <Box>
                {username ? <PasswordForm
                    username={username}
                    onSubmit={handleSubmit}
                /> : null}
            </Box>
        </Col>
    </Row>;
};

export default Password;