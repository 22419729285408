import React from 'react';
import PropTypes from 'prop-types';
import Salesman from "../../components/Profile/Salesman/Salesman";
import ResellerInfo from '../../components/Profile/ResellerInfo/ResellerInfo';
import {useEntity} from "@fik/entity";
import {Box, Col, Row} from "@fik/ui";
import ContactEmailsSubscriptions from "../Profile/ContactEmailsSubscriptions/ContactEmailsSubscriptions";
import {useSelector} from "react-redux";
import {NavigationItem} from "@fik/navigation";
import {RenewLicenseShortcut} from "@fik/components";

const ResellerDashboardContent = ({
                          addressBook,
                          branchOffice,
                          categoryInfo,
                          privilegeStatus,
                          turnovers,
                          certInfo,
                          staff,
                          stats,
                          resellerPlanEnabled
                      }) => {

    const {userId} = useSelector(state => state.auth);

    return (<Row>
        <Col lg={4}>
            <h3 className="border-bottom">Váš obchodník</h3>
            <Salesman salesman={staff} path={process.env.REACT_APP_API_URL}/>
            <h3 className="border-bottom">Prodloužení / rozšíření stávající licence</h3>
            <RenewLicenseShortcut />
        </Col>
        <Col lg={4}>
            <h3 className="border-bottom">Partnerství</h3>
            <ResellerInfo reseller={{
                addressBook,
                branchOffice,
                categoryInfo,
                privilegeStatus,
                turnovers,
                certInfo,
                stats,
                resellerPlanEnabled
            }}/>
        </Col>
        <Col lg={4}>
            <h3 className="border-bottom">Jaké zprávy dostáváte aktuálně do e-mailu?</h3>
            <ContactEmailsSubscriptions/>
            <h4>Nastavte si odběr <NavigationItem
                to="profile:editContact"
                params={{id:userId}}>ve Vašem profilu</NavigationItem>!</h4>
        </Col>
    </Row>);
};

ResellerDashboardContent.propTypes = {
    addressBook: PropTypes.object,
    branchOffice: PropTypes.object,
    categoryInfo: PropTypes.object,
    privilegeStatus: PropTypes.string,
    turnovers: PropTypes.object,
    certInfo: PropTypes.array,
    staff: PropTypes.object,
    stats: PropTypes.object
};

const ResellerDashboard = () => {
    const {
        entity: resellerProfile
    } = useEntity({name: 'profile'}, null);

    return (<Box className="border-bottom">
        {resellerProfile && resellerProfile.addressBook ? <ResellerDashboardContent {...resellerProfile} />: null}
    </Box>);
};

export default ResellerDashboard;