import React from 'react';
import {Table, useRowDetail} from "@fik/table";
import {
    DocumentBasePrice, DocumentCreatedDate,
    DocumentEPC,
    DocumentOwner,
    DocumentPartner,
    DocumentResellerContact,
    BusinessStatus, DocumentType,
} from '@fik/document';

import OfferLink from "./OfferLink";
import OffersTableRowDetail from "./OffersTableRowDetail";
import {OfferExpirationDate, OfferTodayDate, OfferViewMenu} from "./index";

const OffersTable = ({statesDict, typesDict, rows, epcVisible = false, partnerVisible = true}) => {
    const {toggleRow, isVisibleRow} = useRowDetail();

    return <Table striped responsive
                  renderCellProps={{statesDict, typesDict}}
                  rows={rows}
                  renderRowMessages={({row, primaryValue})=>(isVisibleRow(primaryValue) ?
                      <tr>
                          <td colSpan='11div c' className="no-padding no-borders">
                              <OffersTableRowDetail offer={row} />
                          </td>
                      </tr> : null)}
                  primary='id'>
        <OfferLink header='ID' />
        <DocumentOwner header='Klient' />
        {partnerVisible && <DocumentPartner header='Partner' />}
        {epcVisible && <DocumentEPC header='EPC' />}
        <DocumentResellerContact header='Obchodník'/>
        <DocumentType header='Typ' />
        {/*<DocumentStatus header='Stav' />*/}
        <BusinessStatus header='Stav' />
        <DocumentBasePrice header='Cena' />
        <DocumentCreatedDate header='Vytvořeno' />
        <OfferExpirationDate header='Platnost' />
        <OfferTodayDate header='K budoucímu datu' />
        <OfferViewMenu toggleRow={toggleRow} isVisibleRow={isVisibleRow} />
    </Table>;
};

export default OffersTable;