import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Box, Errors} from "@fik/ui";
import {useDocumentContext, DocumentSaved, DocumentCustomer} from "@fik/document-edit";
import {DealFormDetail, DealFormSolutions} from "@fik/deal";
import {useDials} from "@fik/dials";
import {CUSTOMER_VIEWS} from "@fik/customer-edit";
import {validateObject} from "@mouseover/js-validation/src";
import handleClick from "../../utils/handleClick";
import DocumentPartnerContainer from "../../documentEdit/components/DocumentPartnerContainer";
import usePureDataSource from "@fik/data-source/usePureDataSource";
import {shallowEqual} from "@mouseover/js-utils";
import DealResponsiblePerson, {validationRules as responsiblePersonValidationRules} from "./DealResponsiblePerson";
import DealFormDetailNew from "./DealFormDetailNew";
import DealFormDetailRenew from "./DealFormDetailRenew";
import DealBusinessStatus from "./DealBusinessStatus";

const localValidationRules = {
    owner: {
        required: {message: 'Není zadán koncový zákazník'},
    },
    dateExpected: {
        required: true
    },
    phaseId: {
        required: true,
    },
    successProbability: {
        required: true,
    },
    expectedSolutions: {
        required: true,
    },
    note: {
        minLength: 50
    },
    notePosition: {
        minLength: 50
    },
    noteImplementation: {
        minLength: 50
    }
};

const solutionsValidationRules = (solutions, currentSolutions) => currentSolutions
    ? currentSolutions.reduce((rules, item, index) => {
        rules[index] = {
            children: {
                value: {
                    required: true,
                    min: item.minAmount
                }
            },
            id: item.id
        }
        return rules;
    }, {}) : {};


function getValidationRules(validationRules, solutions, document, formType) {
    return {
        ...validationRules,
        note: {
            minLength: 50,
            required: formType === 'new'
        },
        notePosition: {
            minLength: 50,
            required: formType === 'new'
        },
        noteImplementation: {
            minLength: 50,
            required: formType === 'new'
        },
        responsiblePerson: document && document.responsiblePerson ? { required: true, children: responsiblePersonValidationRules } : {},
        expectedSolutions: {...validationRules.expectedSolutions, children: solutionsValidationRules(solutions, document.expectedSolutions)}
    };
}

const DealForm = ({formType = 'new'}) => {
    const {products, propabilities, phases, contacts, solutions, renew} = useDials({name: 'deal', params: {contacts: true}});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const {
        document,
        validation: {valid, validationMessages},
        saving: {saveDocument, isSaved, messages}
    } = useDocumentContext();

    const [validationRules, setValidationRules] = useState({});

    useEffect(() => {
        const current = getValidationRules(localValidationRules, solutions, document || {}, formType);
        setValidationRules((before) => shallowEqual(before, current) ? before : current);
    }, [setValidationRules, solutions, document, formType]);

    const validationResult = useMemo(() => validateObject(document || {}, validationRules), [document, validationRules]);

    const handleSave = useCallback(() => {
        setIsSubmitted(true);
        if (validationResult && validationResult.valid) {
            saveDocument('deal', document);
        }
    }, [saveDocument, document, validationResult, setIsSubmitted]);

    const {reset} = usePureDataSource({name: 'deal'});

    useEffect(() => {
        if (isSaved) {
            reset();
        }
    }, [reset, isSaved]);

    if (isSaved) {
        return <Box>
            <h1>Úspěšně odesláno</h1>
            <DocumentSaved documentType="deal"/>
        </Box>;
    }

    return <>
        {messages && <Errors messages={messages}/>}
        <DocumentPartnerContainer/>
        <DocumentCustomer
            visibleViews={[CUSTOMER_VIEWS.COMPANY, CUSTOMER_VIEWS.EXISTING]}
            isSubmitted={isSubmitted}
        />
        <DealResponsiblePerson isSubmitted={isSubmitted}/>
        <DealBusinessStatus/>
        <Box title="Detail registrace">
            <DealFormDetail
                contacts={contacts}
                phases={phases}
                propabilities={propabilities}
                validation={validationRules}
                isSubmitted={isSubmitted}
            />
            {formType === 'new' && <DealFormDetailNew isSubmitted={isSubmitted} validation={validationRules}/>}
            {formType === 'renew' && renew &&
                <DealFormDetailRenew isSubmitted={isSubmitted} validation={validationRules} elements={renew}/>}
        </Box>
        <Box title="Poptávaná řešení">
            <DealFormSolutions
                solutions={solutions}
                products={products}
                validationRules={validationRules}
                isSubmitted={isSubmitted}
            />
        </Box>
        <div className="row">
            <div className="col-sm-12 text-right">
                <button type="button" onClick={handleClick(handleSave)}
                        className={"btn btn-lg " + (!validationResult || !validationResult.valid ? "btn-warning" : "btn-success")}>Odeslat
                </button>
            </div>
        </div>
    </>
};

export default DealForm;