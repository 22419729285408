import React, {useEffect} from "react";
import IdentityContext from "../IdentityContext";
import {usePureEntity} from "@fik/entity";
import {useSelector} from "react-redux";
import {useModel} from "@fik/model";
import {SpinnerCircle} from "@fik/loading";

const AuthIdentityProvider = ({children,...props}) => {

    const [{entity: identity}, load] = usePureEntity({name: 'identity'});
    const isAuthenticated = useSelector(state => state.auth.token !== null);

    const token = useModel(
        (model) => isAuthenticated ? model.authentication().authStorage.getAccessToken() : null,
        [isAuthenticated]
    );

    useEffect(() => {
        if (token) {
            load(token);
        }
    }, [token, load]);

    return <IdentityContext.Provider value={identity} {...props}>{children}</IdentityContext.Provider>;
};

export default AuthIdentityProvider;