import {useCallback, useState} from "react";
import arrayRemoveAny from "@fik/utils/arrayRemoveAny";

const useRowDetail = () => {
    const [visibleRows, setVisibleRows] = useState([]);

    const toggleRow = useCallback((key) => {
        let newlyVisible;
        if (visibleRows.indexOf(key) !== -1) {
            newlyVisible = arrayRemoveAny(visibleRows, key);
        } else {
            newlyVisible = [...visibleRows, key];
        }
        setVisibleRows(newlyVisible);
    }, [setVisibleRows,visibleRows]);

    return {
        toggleRow,
        isVisibleRow: useCallback((row) => visibleRows.indexOf(row) !== -1, [visibleRows])
    }
};

export default useRowDetail;