import React from 'react';
import {Table} from "@fik/table";
import {Channel} from "@fik/components";
import {Date} from "@fik/ui";
import DealLink from "./DealLink";

const DealsTable = (props) => {
    const columns = [
        {
            key: 'id',
            title: 'ID',
            render: ({row:item}) => (
                <span>{item.id}</span>
            )
        },
        {
            key: 'owner',
            title: 'Klient',
            render: ({row:item}) => (item.owner ? <Channel {...item.owner} /> : null)
        },
        {
            key: 'partner',
            title: 'Partner',
            render: ({row:item}) => (item.partner ? <Channel {...item.partner} /> : null)
        },
        {
            key: 'epc',
            title: 'EPC',
            render: ({row:item}) => (item.epc ? <Channel {...item.epc} /> : null)
        },
        {
            key: 'dealPhaseName',
            title: 'Fáze',
            render: ({row:item}) => (
                <span>{item.dealPhase && item.dealPhase.name}</span>
            )
        },
        {
            key: 'dealStatusName',
            title: 'Stav',
            render: ({row:item}) => (
                <span>{item.businessStatus}</span>
            )
        },
        {
            key: 'successProbability',
            title: 'Pravděpodobnost',
            render: ({row:item}) => ( <span>{item.successProbability} %</span>)
        },
        {
            key: 'dates',
            title: 'Datum',
            render: ({row:item}) => (
                <React.Fragment>
                    <Date date={item.dateCreated}/><br />
                    <Date date={item.dateExpected}/><br />
                    <Date date={item.dateExpiration}/>
                </React.Fragment>
            )
        },
        {
            key: 'menu',
            title: '',
            render: ({row:item}) => {
                return (
                    <React.Fragment>
                        <DealLink item={item}
                            className="btn btn-default btn-sm m-r-sm">
                            Zobrazit
                        </DealLink>
                    </React.Fragment>
                )
            }
        }
    ];

    return <Table striped responsive
                  columns={columns}
                  rows={props.rows}
                  primary='id'/>;
};

export default DealsTable;