import 'react-dates/initialize';
import {FormField} from "@mouseover/react-form";
import PropTypes from 'prop-types';
import React, {useMemo, useState} from "react";
import {SingleDatePicker} from "react-dates";
import moment from "moment";
import {isObject} from "@mouseover/js-utils";

const DataPickerWrapper = ({input}) => {
    const [focused, setFocused] = useState(false);
    const date = useMemo(() => input && input.value ? moment(isObject(input.value) ? input.value.date : input.value) : null, [input]);
    return <div>
        <SingleDatePicker
            // showClearDate={true}
            customInputIcon={
                <i className="fa fa-calendar"/>
            }
            inputIconPosition="after"
            small={true}
            block={false}
            numberOfMonths={1}
            displayFormat="DD.MM.YYYY"
            date={date}
            onDateChange={(date) => input.setValue(date ? date.format('YYYY-MM-DD') : null)}
            focused={focused}
            keepFocusOnInput={false}
            onFocusChange={({focused}) => setFocused(focused)}
            openDirection="down"
            hideKeyboardShortcutsPanel={true}
            isOutsideRange={() => false}
            placeholder=""
        />
    </div>
};

DataPickerWrapper.propTypes = {
    input: PropTypes.object
};

export const DatePicker = (props) => <FormField {...props} renderInputElement={(props, elementProps, input) => <DataPickerWrapper input={input}/>}/>;