const createDocumentModel = (adapter) => {

    return {
        validate: (resource, document) => {
            return adapter(resource).post({action: 'validate'}, document);
        },
        save: (resource, document) => {
            return adapter(resource).post({}, document);
        },
        createAllOfferVariants: (offerId) => {
            return adapter('offer').post({action: 'create-all-variants'}, {id: offerId});
        },
        orderOffer: (offerId, values) => {
            return adapter('offer').post({action: 'order'}, {id: offerId, ...values});
        }
    }
};

export default createDocumentModel;