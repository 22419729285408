const documentViews = {
    EDIT_CUSTOMER: 'EDIT_CUSTOMER',
    EDIT_CUSTOMER_PICK: 'EDIT_CUSTOMER_PICK',
    EDIT_CUSTOMER_EDIT: 'EDIT_CUSTOMER_EDIT',
    PICK_PARTNER: 'PICK_PARTNER',
    EDIT_PARTNER: 'EDIT_PARTNER',
    EDIT_EVIDENCE_ITEMS: 'EDIT_EVIDENCE_ITEMS',
    VIEW: 'VIEW'
};

export default documentViews;
