import {useCallback, useReducer} from 'react';
import {createActionNames, createRequestState} from "@fik/api";
import {useModel, promiseWrapper} from "@fik/model";
import {useHookToLoading} from "@fik/loading";

const actionTypes = createActionNames('save');

const initialState = {
    result: null,
    args: null,
    isFetching: false,
    isSuccess: false,
    isFailed: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case actionTypes.FETCH_INIT:
            return {
                ...state,
                result: null,
                documentType: action.documentType,
                ...createRequestState(actionTypes, action.type)

            };

        case actionTypes.FETCH_SUCCESS:
            return {
                ...state,
                result: {
                    ...action.payload,
                },
                ...createRequestState(actionTypes, action.type)

            };

        case actionTypes.FETCH_FAILURE:
            return {
                ...state,
                result: {
                    ...action.payload,
                },
                ...createRequestState(actionTypes, action.type)
            };

        default:
            return state;
    }
};

const createDocumentOperation = (documentModel) => async (operation, dispatch, ...args) => {
    dispatch({type: actionTypes.FETCH_INIT, args});

    const {isSuccess, data} = await promiseWrapper(documentModel[operation], ...args);

    if (isSuccess) {
        dispatch({type: actionTypes.FETCH_SUCCESS, payload: data, args});
    } else {
        dispatch({type: actionTypes.FETCH_FAILURE, payload: data, args});
    }
};

export const useDocumentOperation = (operation) => {
    const dispatchOperation = useModel(
        (model) => createDocumentOperation(model.document()),
        []
    );

    const [state, dispatch] = useReducer(reducer, initialState);

    useHookToLoading(`documentOperation-${operation}`, state);

    const perform = useCallback((...args) => {
        dispatchOperation(operation, dispatch, ...args);
    }, [dispatchOperation, operation, dispatch]);

    return {
        isFetching: state.isFetching,
        isSuccess: state.isSuccess,
        isFailed: state.isFailed,
        result: state.result,
        perform
    }
};

export default useDocumentOperation;