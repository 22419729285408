import {useEffect} from 'react';
import {createActionNames, createRequestState} from "@fik/api";
import wrapAsyncCallForDispatch from "@fik/utils/wrapAsyncCallForDispatch";
import {useModel} from "@fik/model";
import {useReducerWithThunk} from "@fik/utils/useReducerWithThunk";

const filesActions = createActionNames('cmsFiles');

const filesReducer = (state, action) => {
    switch (action.type) {
        case filesActions.FETCH_INIT:
            return {
                ...state,
                ...createRequestState(filesActions, action.type),
            };
        case filesActions.FETCH_SUCCESS:

            return {
                ...state,
                filesTree: action.payload,
                ...createRequestState(filesActions, action.type),
            };
        case filesActions.FETCH_FAILURE:
            return {
                ...state,
                ...createRequestState(filesActions, action.type),
            };
        default:
            return state;
    }
};

const useCmsFiles = () => {

    const loadCmsFiles = useModel(
        (model) =>  wrapAsyncCallForDispatch(model.cms().getFiles)(filesActions),
        []
    );

    const [state, dispatch] = useReducerWithThunk(filesReducer, {
            isFetching: false,
            isSuccess: false,
            isFailed: false,
            filesTree: null
        }
    );

    useEffect(() => {
        dispatch(loadCmsFiles());
    }, [loadCmsFiles, dispatch]);

    return state;
};

export default useCmsFiles;