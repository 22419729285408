const createCmsModel = (adapter) => {

    return {
        getMenu: () => {
            return adapter().getMenu();
        },
        getPage: (category, page) => {
            return adapter().getPage(category, page);
        },
        getPageBlocks: (category, page, blocks) => {
            return adapter().getPageBlocks(category, page, blocks);
        },
        getFiles: () => adapter().getFiles(),
        getFeeds: (feedGroupId) => adapter().getFeeds(feedGroupId),
        getArticle: (id) => adapter().getArticle(id),
    }
};

export default createCmsModel;