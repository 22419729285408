import {navigationToMenuItem} from "@fik/navigation/menu";

const sideBarMenu = (navigation, tags) => {
    const salesMenuItems = [
        {
            icon: null,
            ...navigationToMenuItem(navigation.order.create)
        },
        navigationToMenuItem(navigation.offer.create),
      //  navigationToMenuItem(navigation.projectPrice.create),
        //{...navigationToMenuItem(navigation.order.list), child: [navigationToMenuItem(navigation.order.detail)]},
        navigationToMenuItem(navigation.offer.list),
        navigationToMenuItem(navigation.order.list),
     //   navigationToMenuItem(navigation.projectPrice.list),
        navigationToMenuItem(navigation.invoice.list),
//        navigationToMenuItem(navigation.calculator),
        navigationToMenuItem(navigation.deal.list),
        navigationToMenuItem(navigation.deal.create),
        navigationToMenuItem(navigation.nfrOrder),
        navigationToMenuItem(navigation.boxEshop)
    ];

    if (tags && tags.indexOf('PI_KOMISE') !== -1) {
        salesMenuItems.push(navigationToMenuItem(navigation.consignment));
    }

    return [
        {
            icon: 'fa fa-home',
            label: 'Home',
            to: '/',
        },
        {
            icon: 'fa fa-briefcase',
            label: 'Prodej',
            content: salesMenuItems,
        },
        {
            icon: 'fa fa-barcode',
            label: 'Správa licencí',
            content: [
                {
                    label: 'Nejnovější',
                    to: navigation.license.newest.path,
                },
                {
                    label: 'Expirující vše',
                    to: navigation.license.expiring.path,
                },
                {
                    label: 'Expirující projektovky',
                    to: navigation.license.expiring90d.path,
                },
                {
                    label: 'Expirované',
                    to: navigation.license.expired.path,
                },
                {
                    label: 'Zneužité',
                    to: navigation.license.leaked.path,
                },
                {
                    label: 'Partnerské NFR',
                    to: navigation.license.nfr.path,
                },
                {
                    label: 'Zaslat vše v CSV',
                    to: navigation.license.download.path,
                }
            ]
        },
        {
            icon: 'fa fa-download',
            ...navigationToMenuItem(navigation.downloads)
        },
        {
            icon: 'fa fa-user',
            label: 'Profil',
            content: [
                navigationToMenuItem(navigation.profile.settings),
                navigationToMenuItem(navigation.profile.contacts),
                navigationToMenuItem(navigation.profile.reports),
                navigationToMenuItem(navigation.profile.password),
                navigationToMenuItem(navigation.profile.certificationDownload),
                navigationToMenuItem(navigation.profile.certification),
                navigationToMenuItem(navigation.profile.processedCourses),
            ]
        },
        {
            icon: 'fa fa-line-chart',
            label: 'Partnerství',
            content: [
                navigationToMenuItem(navigation.partnerstvi.resellerPlan),
            ]
        },
        {
            icon: 'fa fa-usd',
            label: 'Ceníky',
            content: [
                navigationToMenuItem(navigation.calculator),
            ]
        },
        {
            icon: 'fa fa-envelope',
            label: 'Kontakty',
            to: navigation.contacts.path,
        },

    ];
};


export default sideBarMenu;