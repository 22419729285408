import React, {useCallback, useEffect} from "react";
import {useEntityAction} from "@fik/entity";
import handleClick from "@fik/utils/handleClick";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const LicenseResetCredentialsButton = ({id, onReset}) => {
    //- reset credentials hook
    const [{isSuccess}, resetCredentials] = useEntityAction({name: 'license', action: 'resetCredentials', params: {id}});

    //- resend credentials
    const handleResetCredentials = useCallback(() => {

            confirmAlert({
                title: "",
                message: "Skutečně chcete přegenerovat licenční údaje? Dojde k nevratné změně licenčních údajů a tím i odříznutí klientů od nových aktualizací!",
                buttons: [
                    {
                        label: 'Ano',
                        onClick: resetCredentials
                    },
                    {
                        label: 'Ne'
                    }
                ]
            });

    }, [resetCredentials]);

    useEffect(() => {
        if (onReset && isSuccess) {
            onReset();
        }
    }, [onReset, isSuccess]);

    return <button className="btn btn-warning btn-block" onClick={handleClick(handleResetCredentials)}>Přegenerovat licenční údaje</button>
};

export default LicenseResetCredentialsButton;