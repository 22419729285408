import React, {useContext, useEffect, useMemo} from "react";
import FileManagerContext from "./FileManagerContext";

const FileDownloadLink = ({path, children, ...props}) => {
    const fileManagerContext = useContext(FileManagerContext);
    const filePath = useMemo(() => {
        return fileManagerContext ? fileManagerContext.createPath(path) : `/files/download/${path}`;
    }, [fileManagerContext, path]);
    return <a {...props} href={filePath}>{children}</a>;
}

export default FileDownloadLink;