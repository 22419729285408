import React, {useCallback} from "react";
import {useDropzone} from "react-dropzone";
import {useDocumentContext} from "..//useDocumentContext";
import {useLoadingContext} from "@fik/loading";
import handleClick from "@fik/utils/handleClick";
import {FileSize} from "@fik/ui";

const processFile = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onabort = () => {
            reject();
        };

        reader.onerror = () => {
            reader.abort();
            reject();
        };

        reader.onload = (event) => {
            resolve({
                attachmentName: file.name,
                attachmentSize: file.size,
                attachmentContentType: file.type,
                attachmentContent: event.target.result
            });
        };

        reader.readAsDataURL(file);
    });
};

const processFiles = (files) => Promise.all(files.map(file => processFile(file)));

const DocumentAttachments = () => {

    const {
        document: {attachments},
        addAttachments,
        removeAttachment
    } = useDocumentContext();

    const {changeLoadingState} = useLoadingContext();

    const onDrop = useCallback(acceptedFiles => {

        changeLoadingState('attachments', {isFetching: true, isSuccess: false, isFailed: false});
        processFiles(acceptedFiles)
            .then((attachments) => {
                changeLoadingState('attachments', {isFetching: false, isSuccess: true, isFailed: false});
                addAttachments(attachments);
                return attachments;
            }).catch(() => changeLoadingState('attachments', {isFetching: false, isSuccess: false, isFailed: true}));


    }, [addAttachments, changeLoadingState]);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: 'image/jpeg, image/png, application/pdf',
        maxSize: 5242880
    });


    if (!document) {
        return null;
    }

    return (
        <>
            <div {...getRootProps({className: 'dropzone', style: {minHeight: '40px'}})}>
                <div className="fallback">
                    <input {...getInputProps()} />
                </div>
                {
                    isDragActive ?
                        <p>Přetáhněte sem soubory ...</p> :
                        <p>Přetáhněte sem soubory, nebo klikněte pro výběr</p>
                }
            </div>
            <div className="table table-striped files files-previews">

                {attachments
                    ? attachments.map((attachment, index) => <div key={index} className="file-row files-template">
                        <div>
                            <p className="name">{attachment.attachmentName}</p>
                        </div>
                        <div>
                            <p className="size"><FileSize>{attachment.attachmentSize}</FileSize></p>
                        </div>
                        <div>
                            <button className="btn btn-danger delete pull-right" onClick={handleClick(() => removeAttachment(attachment))}>
                                <i className="glyphicon glyphicon-trash"/>
                                <span>Odebrat</span>
                            </button>
                        </div>
                    </div>)
                    : null}
            </div>
        </>
    )
};

export default DocumentAttachments;