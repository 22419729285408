import React, {useCallback, useState} from "react";
import PropTypes from 'prop-types';
import {Price, WithoutCode} from "@fik/ui";
import handleClick from "@fik/utils/handleClick";
import {useSelector} from "react-redux";


const Product = ({product, onAddProduct}) => {
    const {attachmentPath, productBasePrice, productUnit, productName} = product;
    const [quantity, setQuantity] = useState(1);

    const onAddButtonClick = useCallback(() => {
        if (onAddProduct) {
            onAddProduct({...product, quantity});
        }
        setQuantity(1);
    }, [onAddProduct, product, quantity]);

    const token = useSelector((state) => {
            return state.auth.token;
        },
        []);

    return <div className="col-md-3">
        <div className="ibox">
            <div className="ibox-content product-box">
                {attachmentPath
                    ? <div className="cart-product-imitation"><img srcSet={(process.env.REACT_APP_API_URL === "/" ? "" : process.env.REACT_APP_API_URL)+ attachmentPath + '?token=' + token} alt={productName}/></div>
                    : <div className="cart-product-imitation"><i>náhled není k dispozici</i></div>}

                <div className="product-desc">
                    <div className="product-price">
                        <Price price={productBasePrice}/> / <WithoutCode>{productUnit ? productUnit.toLowerCase() : productUnit}</WithoutCode>
                    </div>
                    <div style={{height: '46px'}}>
                        <p className="product-name">{productName}</p>
                    </div>
                    <div className="small m-t-xs">
                        <div className="row">
                            <div className="col-md-6">
                                Počet:<br/><input className="form-control" type="number" min="1" placeholder="1"
                                                  value={quantity} onChange={(event) => setQuantity(parseInt(event.target.value))}/>
                            </div>
                            <div className="col-md-6">
                                <div className="m-t text-righ">
                                    <button className="btn btn-success"
                                            onClick={handleClick(onAddButtonClick)}><i
                                        className="fa fa-shopping-basket"/> Přidat do košíku
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

Product.propTypes = {
    product: PropTypes.object,
    onAddProduct: PropTypes.func
};

export default Product;