import {createResponseReducer} from "@fik/api";
import {isObject} from "@mouseover/js-utils";
import {createDataSourceReducer} from "@fik/data-source";
import {combineReducers} from "redux";

export const TYPE_DATASOURCE = 'dataSource';
export const TYPE_ENTITY = 'entity';
export const TYPE_REDUCER = 'reducer';

const createSpecificReducer = (type, {name, storeName, resetResult = true}) => {
    const definition = isObject(type) ? type : {type};
    switch (definition.type) {
        case TYPE_ENTITY:
            return createResponseReducer(storeName, name, definition.initialState, resetResult);
        case TYPE_DATASOURCE:
            return createDataSourceReducer(storeName, definition.resource || 'data-source');
        case TYPE_REDUCER:
            return {[definition.name]: definition.reducer};
        default:
            throw new Error('Unknown reducer type ' + type);
    }
};

const createResourceReducers = (resourceConfig, def) => {
    return def.reduce((reducers, current) => {
        return {...reducers, ...createSpecificReducer(current, resourceConfig) };
    }, {});
};

const createResourcesReducers = (model, definitions) => {
    return Object.entries(definitions).reduce((reducers, [key, def]) => {
        const resourceConfig = model.resources[key];
        reducers[resourceConfig.storeName] = combineReducers(createResourceReducers(resourceConfig, def));
        return reducers;
    }, {});
};

export default createResourcesReducers;