import useCalculator from "./useCalculator";
import {useForm} from "@mouseover/react-form";
import {useEffect, useMemo} from "react";

const useEvidenceItemCalculator = ({initialSelection, onEvidenceItemChange, channels}) => {

    const {
        selection,
        selectPartner,
        evidenceItem,
        changeValue,
        loading,
        ...props
    } = useCalculator(initialSelection, channels);

    const form = useForm({
            values: selection,
            onValueChange: changeValue,
            validationRules: useMemo(() => ({
                itemAmount: {min: 1},
                itemDiscount: {
                    min: 1,
                    max: 100
                }
            }), [])
        }
    );

    useEffect(() => {
        if (typeof onEvidenceItemChange === 'function') {
            onEvidenceItemChange(evidenceItem && evidenceItem.itemName ? evidenceItem : null);
        }
    }, [evidenceItem, onEvidenceItemChange]);

    return {
        ...props,
        selectPartner,
        form,
        evidenceItem: evidenceItem && evidenceItem.itemName ? evidenceItem : null,
        itemMessages: evidenceItem && evidenceItem.itemMessages ? evidenceItem.itemMessages : null,
        loading
    };
};

export default useEvidenceItemCalculator;