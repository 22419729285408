import React from 'react';
import handleClick from "@fik/utils/handleClick";
import GridItem from "../Library/GridItem";
import {Col, Row} from "@fik/ui";

const Grid = ({items, onSelectItem, colsNumber = 2}) => {

    return <Row>{
        items.map((item, index) => {
               const attrs = {};
               if (!item.link) {
                   attrs.onClick = handleClick(() => onSelectItem(item))
               }
               return <Col key={"grid-item-" + index} className={"col-lg-" + (12 / colsNumber)} {...attrs}>
                    <GridItem {...item} />
                </Col>
            }
        )}
    </Row>;
};

export default Grid;