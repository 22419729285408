import React from 'react';
import {LoadingOverlay} from "@fik/loading";
import PropTypes from 'prop-types';

const Box = (props) => {
    let header = null;
    if (props.title || props.tools) {
        let tools = null;
        if (props.tools) {
            tools = <div className="ibox-tools">{typeof props.tools === 'function' ? props.tools() : props.tools}</div>
        }
        header = <div className="ibox-title border-bottom"><h5>{props.title}</h5>{tools}</div>;
    }
    const options = props.options || {};
    return (
        <div className={"ibox" + (props.className ? ' ' + props.className : '')} {...options}>
            {header}
            {props.children && <LoadingOverlay className={"ibox-content" + (props.contentClassName ? ' ' + props.contentClassName : '')}
                            isLoading={props.isLoading}>
                {props.children}<div className="clearfix" />
            </LoadingOverlay>}
        </div>
    );
};

Box.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string, PropTypes.func]),
    contentClassName: PropTypes.string,
    isLoading: PropTypes.bool,
    tools: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string, PropTypes.func]),
    options: PropTypes.object
};

export default Box;