import React from 'react';
import YouTubeDetail from "../Library/YouTubeDetail";

const detailFactory = {
    youtube: (item) => <YouTubeDetail {...item} />
};



const Detail = ({item}) => {
    return detailFactory[item.type](item);
};

export default Detail;