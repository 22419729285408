import React, {useCallback, useEffect} from "react";
import PropTypes from 'prop-types';
import ResellerRidForm from "./ResellerRidForm";
import {usePureEntity} from "@fik/entity";
import {Channel} from "@fik/document";
import {Col, Row} from "@fik/ui";
import handleClick from "@fik/utils/handleClick";

const ResellerByRidFinder = ({channelValues, onConfirm, autoConfirm}) => {
    const [{entity: resellerProfile}, load] = usePureEntity({name: 'resellerProfile', params: {action: 'profile'}});

    const find = useCallback((params) => {
        load(params);
    }, [load]);

    useEffect(() => {
        if (autoConfirm && resellerProfile) {
            onConfirm(resellerProfile);
        }
    }, [resellerProfile, onConfirm, autoConfirm]);

    const confirmProfileChange = useCallback(() => {
        onConfirm(resellerProfile);
    }, [onConfirm, resellerProfile]);

    return <>
        <ResellerRidForm onSubmit={find}/>
        {resellerProfile ? <Channel {...resellerProfile} /> : null}
        {channelValues && resellerProfile ? <Row>
            <Col sm={12} className='text-right'>
                <button className="btn btn-success" onClick={handleClick(confirmProfileChange)} disabled={!resellerProfile}>Nastavit</button>
            </Col>
        </Row> : null}
    </>;
};

ResellerByRidFinder.propTypes = {
    channelValues: PropTypes.object,
    confirmCustomerChange: PropTypes.func,
    cancelCustomerEdit: PropTypes.func
};

export default ResellerByRidFinder;