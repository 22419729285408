import React, {useCallback, useEffect, useState} from 'react';
import {Selector, useEvidenceItemCalculator} from "@fik/calculator";
import {useDocumentSave} from "@fik/document-edit";
import {Box, Col, Errors, Row} from "@fik/ui";
import {ResellerByRidFinder} from "@fik/reseller-edit";
import DocumentSaved from "@fik/document-edit/components/DocumentSaved";

const defaultProps = {initialSelection: {selectType: 'nfr'}};

const NfrOrderForm = () => {
    const [document, setDocument] = useState({documentType: 'nfr-order', evidenceItems: []});

    const {
        types,
        products,
        periods,
        evidenceItem,
        form,
        selectPartner,
        minimum,
        maximum,
        itemMessages
    } = useEvidenceItemCalculator(defaultProps);

    const {
        saveDocument,
        isSaved,
        messages,
        documentType
    } = useDocumentSave();

    useEffect(() => {
        setDocument((current) => {
            if (evidenceItem && (current.evidenceItems.length === 0 || current.evidenceItems[0] !== evidenceItem)) {
                return {...current, evidenceItems: [evidenceItem]};
            } else if (!evidenceItem && current.evidenceItems.length > 0) {
                return {...current, evidenceItems: []};
            }
            return current;
        });
    }, [evidenceItem, document]);

    const handleSave = useCallback(() => {
        saveDocument(document.documentType, document);
    }, [document, saveDocument]);

    const handleChangeReseller = useCallback((partner) => {
        setDocument(current => ({...current, partner}));
    }, []);

    if (isSaved) {
        return  <Row>
            <Col sm={12}>
                <Box>
                    <h1>Úspěšně odesláno</h1>
                    <DocumentSaved documentType={documentType}/>
                </Box>
            </Col>
        </Row>;
    }

    return <>
        {messages ? <Errors messages={messages}/> : null}
        <div className='row'>
            {selectPartner
                ? <div className='col-sm-6'>
                    <Box title="Partner">
                    <ResellerByRidFinder onChange={handleChangeReseller} />
                    </Box>
                </div>
                : null}
            <div className='col-sm-6'>

                <Box title="Výběr licence">
                    {itemMessages ? <Errors messages={itemMessages}/> : null}
                    <Selector
                        form={form}
                        selectType='nfr'
                        types={types} isFailed
                        products={products}
                        periods={periods}
                        minimum={minimum}
                        maximum={maximum}
                    />
                    {evidenceItem ? <button onClick={handleSave} className="btn btn-success btn-block btn-lg">Objednat</button> : null}
                    <div className="panel panel-warning m-t-md">
                        <div className="panel-heading">
                            <i className="fa fa-info-circle"/> NFR licenci produktu ESET PROTECT Advanced, ESET Secure Authetication, nebo ESET Endpoint Encryption generujeme v maximálním počtu odpovídající partnerské kategorii.
                            Nelze tedy objednat více menších licencí. V případě že požadujete pro vlastní ochranu, nebo testy využívat vyšší firemní řešení, nebo vyšší počet licencí, než je určeno pro partnerskou kategorii,
                            informace o podmínkách <a href="https://resellerzone.eset.cz/p/partnerstvi/licence-zdarma" target="_blank">najdete zde</a>. Po odeslání vašeho požadavku vás budeme kontaktovat do dvou pracovních dnů.
                        </div>
                    </div>
                </Box>
            </div>
        </div>
    </>;
};

export default NfrOrderForm;