import React from "react";
import {NavigationItem} from "@fik/navigation";

const LeakedLicensesInfo = () => <p className='m-t-md'>
    <i className="fa fa-info-circle"></i> Níže uvedený výpis obsahuje licence, které jsou pravděpodobně zneužity. Hodnota "Běží na PC: xx"
    uvádí, na kolika stanicích se licence používá, vůči původnímu zakoupenému počtu. Výpis zneužitých licencí je aktualizován jednou týdně
    (stanice, které během 14 dnů nestáhnou aktualizaci, nejsou v seznamu zahrnuty). Na detailu licence je možné přístupové údaje
    přegenerovat.  <NavigationItem to="page" params={{category: 'partnerstvi', page: 'zneuzite-licence'}}>Více informací</NavigationItem>.<br/>
</p>;

export default LeakedLicensesInfo;