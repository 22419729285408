import {useCallback, useContext, useMemo} from 'react';
import {requestGetEntity} from "./actions";
import {ModelContext} from "@fik/model";
import useCallRequestEntity from "./useCallRequestEnetity";

export const usePureEntity = ({params:defaultParams, ...props}) => {

    const model = useContext(ModelContext);

    const actionCallback = useCallback((context, actionParams) => {
        return requestGetEntity(model)(context)(actionParams)
    }, [model]);

    const memoizedParams = useMemo(() => (defaultParams || {}), [defaultParams]);

    const [resourceSelection, callRequest] = useCallRequestEntity(
        {...props, method: 'get'},
        actionCallback,
        memoizedParams
    );

    return [resourceSelection, callRequest];
};

export default usePureEntity;