import {useCallback, useReducer} from "react";
import {useNavigation} from "@fik/navigation";

const PAGE_ACTIONS = {
    CHANGE_LOCATION: 'CHANGE_LOCATION',
    SET_INFO: 'CHANGE_INFO',
};

const reducer = (state, action) => {
    switch (action.type) {
        case PAGE_ACTIONS.CHANGE_LOCATION:
            return {
                ...defaultState,
                ...action.payload
            }
        case PAGE_ACTIONS.SET_INFO:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}
const defaultState = {
    title: null,
    section: null,
    path: null
}
const usePage = () => {
    const [state, dispatch] = useReducer(reducer, defaultState);
    const {getByUrlPath} = useNavigation();
    const handleChangePage = useCallback((current) => {

        if (current.path) {
            const def = getByUrlPath(current.path);
            dispatch({type: PAGE_ACTIONS.CHANGE_LOCATION, payload: def || current});
        } else {
            dispatch({type: PAGE_ACTIONS.SET_INFO, payload: current});
        }

    }, [dispatch, getByUrlPath]);

    const setInfo = useCallback((info) => {
        dispatch({type: PAGE_ACTIONS.SET_INFO, payload: info});
    }, [dispatch]);

    return {
        page: state,
        setPageLocation: handleChangePage,
        setPageInfo: setInfo
    }
};

export default usePage;