import React from "react";
import {SelectInput} from "@fik/form";

const ContactSelector = ({name, contacts, form}) => {
    return <SelectInput label="Kontakt" name={name} form={form}
                        options={[{value: '', label: '---'}, ...(contacts ? contacts.map((item) => ({
                            label: item.contactSurname + " " + item.contactFirstname,
                            value: item.contactId
                        })) : [])]}/>
}

export default ContactSelector;