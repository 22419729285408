import {Date, Price} from "@fik/ui";
import React from "react";
import {Status, Type} from "../index";
import Channel from "@fik/components/Channel/Channel";
import {ChannelContact, ChannelName, StaffName} from "@fik/components";

export const DocumentOwner = ({owner}) => owner ? <Channel {...owner} /> : null;
export const DocumentPartner = ({partner}) => partner ? <Channel {...partner} /> : null;
export const DocumentEPC = ({epc}) => epc ? <Channel {...epc} /> : null;
export const DocumentType = ({documentType, typesDict}) => <Type value={documentType} enum={typesDict}/>;
export const DocumentStatus = ({status, statesDict}) => <Status value={status} enum={statesDict}/>;
export const BusinessStatus = ({businessStatus}) => <div>{businessStatus}</div>;
export const DocumentBasePrice = ({basePrice}) => <Price price={basePrice}/>;
export const DocumentPrices = ({partner, owner, epc}) => <>
    {owner ? <div className='font-bold' title='Cena klient'><Price price={owner.basePrice}/></div> : null}
    {partner ? <div title='Cena partner'><Price price={partner.basePrice}/></div> : null}
    {epc ? <div title='Cena EPC'><Price price={epc.basePrice}/></div> : null}
</>;
export const DocumentCreatedDate = ({dateCreated}) => <Date date={dateCreated}/>;
export const DocumentResellerContact = ({partner, epc}) => <ChannelContact {...(epc||partner)} />;
export const DocumentPartnerAndEpc = ({partner, epc}) => <>
    <div>{partner ? <ChannelName {...partner.addressBook} /> : null}</div>
    <div>{epc ? <ChannelName {...epc.addressBook} /> : null}</div>
</>;

export const DocumentStaff = ({staffId}) => {
    return <StaffName>{staffId}</StaffName>
};
