import {useEffect, useCallback, useMemo, useState} from 'react';
import {createActionNames} from "@fik/api";
import shallowEqual from "react-redux/es/utils/shallowEqual";
import {createRequestState} from "@fik/api";
import wrapAsyncCallForDispatch from "@fik/utils/wrapAsyncCallForDispatch";
import {useModel} from "@fik/model";
import {useReducerWithThunk} from "@fik/utils/useReducerWithThunk";
import {useHookToLoading} from "@fik/loading";
import {getOnPath} from "@mouseover/js-utils/src";

const initialState = {
    selector: {
        isFetching: false,
        isSuccess: false,
        isFailed: false,
        selectType: 'new',
        results: {
            selection: {
                isCompany: false,
                selectType: 'new',
                itemProductTypeId: null,
                itemProductFamilyId: null,
                itemProductPeriodId: null,
                itemAmount: null,
                itemBonusCode: null,
                itemDiscount: null,
                itemDiscountId: null,
                itemDiscountCompetitor: null,
                itemDiscountCompetitorName: null,
                itemDiscountCompetitorPrice: null,
                itemMessages: [],
            },
            types: [],
            products: [],
            periods: [],
            possibleDiscounts: [],
            possiblePromos: [],
            itemPromoId: null
        }
    },
    promoAutoSelected: false,
    recalculate: {
        isFetching: false,
        isSuccess: false,
        isFailed: false,
        results: {}
    }
};


const selectActions = createActionNames('select');
const recalculateActions = createActionNames('recalculate');
const RESET_EVIDENCE_ITEM = 'reset_evidence_item';

const createReducer = (initialState) => (state = initialState, action) => {
    switch (action.type) {
        case selectActions.FETCH_INIT:
            return {
                ...state,
                selector: {
                    ...state.selector,
                    selectType: action.params.selectType,
                    results: {...state.selector.results, selection: {...state.selector.results.selection, ...action.params}},
                    ...createRequestState(selectActions, action.type),
                }
            };

        case selectActions.FETCH_SUCCESS:
            const currentSelection = {...state.selector.results.selection, ...action.payload.selection};
            let promoAutoSelected = state.promoAutoSelected;
            if (!promoAutoSelected && action.payload.itemPromoId) {
                currentSelection.itemPromoId = action.payload.itemPromoId;
                promoAutoSelected = true;
            }
            return {
                ...state,
                promoAutoSelected,
                selector: {
                    ...state.selector,
                    results: {...action.payload, selection: currentSelection},
                    ...createRequestState(selectActions, action.type),
                }
            };

        case selectActions.FETCH_FAILURE:
            return {
                ...state,
                selector: {
                    ...state.selector,
                    ...createRequestState(selectActions, action.type)
                }
            };

        case recalculateActions.FETCH_INIT:
            return {
                ...state,
                recalculate: {
                    ...state.recalculate,
                    selection: action.params,
                    ...createRequestState(recalculateActions, action.type),
                }
            };

        case recalculateActions.FETCH_SUCCESS:
            return {
                ...state,
                recalculate: {
                    ...state.recalculate,
                    selection: action.params,
                    results: action.payload,
                    ...createRequestState(recalculateActions, action.type),
                }
            };

        case recalculateActions.FETCH_FAILURE:
            return {
                ...state,
                recalculate: {
                    ...state.recalculate,
                    selection: action.params,
                    ...createRequestState(recalculateActions, action.type),
                },
            };

        case RESET_EVIDENCE_ITEM:
            return {
                ...state,
                promoAutoSelected: false,
                recalculate: {
                    ...state.recalculate,
                    evidenceItem: null
                }
            };
        default:
            return state;
    }
};

const useCalculator = (initialSelection, channels) => {

    const [state, dispatch] = useReducerWithThunk(createReducer(initialState), initialState);
    const [select, recalculate] = useModel((model) => {
        const evidenceItemsModel = model.evidenceItems();
        return [
            wrapAsyncCallForDispatch(evidenceItemsModel.select)(selectActions),
            wrapAsyncCallForDispatch(evidenceItemsModel.recalculate)(recalculateActions)
        ]
    }, []);

    //- initial select - fetch from api to get
    useEffect(() => {
        dispatch(select(initialSelection || {}));
    }, [select, initialSelection, dispatch]);

    const {selection, possibleDiscounts, finalProduct} = state.selector.results;

    const changeSelectionCallback = useCallback((params) => {
        if (!shallowEqual(params, selection)) {
            dispatch(select(params));
        }
    }, [select, selection, dispatch]);

    useEffect(() => {
        if (!shallowEqual(selection, getOnPath(state.recalculate.selection, ['item']))) {
            if (finalProduct) {
                dispatch(recalculate({item: selection, channels}));
            } else {
                dispatch({type: RESET_EVIDENCE_ITEM});
            }
        }
    }, [recalculate, selection, finalProduct, dispatch, state.recalculate.selection, channels]);

    const changeValueCallback = useCallback((name, value) => {

        if (value === selection[name]) {
            return;
        }

        switch (name) {
            case 'itemProductTypeId':
                changeSelectionCallback({
                    ...selection,
                    itemProductTypeId: value
                });
                break;

            case 'itemProductFamilyId':
                changeSelectionCallback({
                    ...selection,
                    itemProductFamilyId: value // set current value
                });
                break;

            case 'itemProductPeriodId':
                changeSelectionCallback({
                    ...selection,
                    itemProductPeriodId: value,
                });
                break;

            case 'itemAmount':
                changeSelectionCallback({
                    ...selection,
                    itemAmount: value
                });
                break;

            case 'itemDiscountId':
                if (value !== selection.itemDiscountId) {
                    const discount = possibleDiscounts ? possibleDiscounts.find((item) => item.productDiscountId === value) : null;
                    changeSelectionCallback({...selection, [name]: value, itemDiscount: discount ? discount.productDiscountValue : null});
                } else {
                    changeSelectionCallback({...selection, [name]: value, itemDiscount: null});
                }
                break;

            default:
                changeSelectionCallback({...selection, [name]: value});
                break;
        }

    }, [changeSelectionCallback, selection, possibleDiscounts]);

    useHookToLoading('evidence-item-selector', state.selector);
    useHookToLoading('evidence-item-recalculate', state.recalculate);

    return {
        ...state.selector.results,
        selectType: state.selector.selectType,
        evidenceItem: state.recalculate.results,
        loading: state.selector.isFetching || state.recalculate.isFetching,
        changeSelection: changeSelectionCallback,
        changeValue: changeValueCallback
    };
};

export default useCalculator;
