import {Checkbox, DatePicker, NumberInput, SelectInput} from "@fik/form";
import React, {useEffect, useMemo} from "react";

const SolutionFields = ({name, unit, id, products, minAmount, form, onChange}) => {
    const checked = useMemo(() => form.values.checked, [form.values]);
    useEffect(() => {
        if (!checked && form.values.value) {
            form.setValue(null, 'value');
        }
    }, [checked, form.values.value, form.setValue]);
    return <div className="row">
        <div className="col-sm-3 m-b-xs">
            <Checkbox form={form} label={form.values.name} name='checked' />
        </div>

        {checked
            ? <>
                <div className="col-sm-3 m-b-xs form-inline">
                    <div className="form-group">
                        <NumberInput
                            elementConfig={{style: {maxWidth: '80px'}, type: 'number', min: minAmount || 0}}
                            name='value'
                            form={form}/>
                        <label className='m-l-xs'>{unit}</label>
                    </div>
                </div>
                <div className="col-sm-3 m-b-xs">
                    <SelectInput
                        name='product'
                        form={form}
                        options={[{value: '', label: '---'}, ...(products ? products.map((item) => ({
                            label: item.name,
                            value: item.name
                        })) : [])]}/>
                </div>
                <div className="col-sm-3 m-b-xs">
                    <DatePicker name="expiration" form={form}/>
                </div>
            </>
            : null
        }

    </div>;
}

export default SolutionFields;