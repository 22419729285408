import {useReducer, useCallback, useEffect, useMemo} from 'react';

const CANCEL = 'CANCEL';
const CONFIRM = 'CONFIRM';
const DOCUMENT_INITIALIZED = 'DOCUMENT_INITIALIZED';
const PICK = 'PICK';

export const views = {
    PICK: 'pick',
    VIEW: 'view',
    EXISTING: 'existing',
    PREPARING: 'none'
};

const initialState = {
    initialValues: null,
    values: null,
    valid: false,
    previousViewType: views.PREPARING,
    viewType: views.PREPARING,
    params: null
};

const emptyValues = {
    addressBook: {name: null, street: null, city: null, zipcode: null, companyId: null},
    contact: {contactFirstname: null, contactSurname: null, contactEmail: null, contactPhone: null}
};

function prepareValues(channelValues) {
    return channelValues ? {
        ...emptyValues,
        ...channelValues,
        contact: channelValues && channelValues.contact ? channelValues.contact : emptyValues.contact
    } : {...emptyValues};
}

function isEmptyChannel(channelValues) {
    return !(channelValues && channelValues.addressBook && channelValues.addressBook.name);
}

const reducer = (state, action) => {
    switch (action.type) {

        case PICK:
            return {
                ...state,
                values: null,
                valid: false,
                previousViewType: state.viewType,
                viewType: views.EXISTING,
                params: action.params
            };

        case CONFIRM:
            return {
                ...state,
                values: action.values,
                valid: true,
                previousViewType: null,
                viewType: views.VIEW,
                params: null
            };

       case CANCEL:
            return {
                ...state,
                values: null,
                valid: false,
                viewType: state.previousViewType,
                params: null
            };

        case DOCUMENT_INITIALIZED:
            return {
                ...state,
                previousViewType: action.viewType,
                viewType: action.viewType,
                params: null,
                values: action.values,
                initialValues: action.initialValues,
                valid: false
            };

        default:
            return state;
    }
};


const useResellerEdit = ({channelValues, onConfirm, onCancel, onChange}) => {

    const [state, dispatch] = useReducer(reducer, initialState);

    const cancel = useCallback(() => {
        onCancel();
        dispatch({type: CANCEL});
    }, [onCancel]);

    const confirm = useCallback((values) => {
        if (onConfirm) {
            onConfirm(values);
        }
        dispatch({type: CONFIRM, values});
    }, [dispatch, onConfirm]);

    const pick = useCallback((params) => dispatch({type: PICK, params}), [dispatch]);

    useEffect(() => {
        if (state.viewType === views.PREPARING) {
            dispatch({
                type: DOCUMENT_INITIALIZED,
                initialValues: channelValues,
                values: prepareValues(channelValues),
                viewType: isEmptyChannel(channelValues) ? views.PICK : views.VIEW});
        }
    }, [channelValues, state.viewType]);

    useEffect(() => {
        if (onChange) {
            onChange(state.values);
        }
    }, [state.values, onChange]);

    return useMemo(() => ({
        cancel,
        confirm,
        pick,
        viewType: state.viewType,
        values: state.values,
        params: state.params
    }), [cancel, confirm, pick, state]);
};

export default useResellerEdit;