import React, {useMemo} from 'react';
import {DealsTable} from "@fik/deal";
import {Paginator} from "@fik/ui";
import {Box} from "@fik/ui";
import {useDataSource, useDataSourceWithQueryParams} from "@fik/data-source";

const DealsPanel = ({renew = false}) => {
    const {pagination, items, handlePageChange} = useDataSourceWithQueryParams(
        {name: 'deal', initialSearchParams: {renew}}
    );

    return (
        <Box>
            <DealsTable striped responsive rows={items} primary='id'/>
            <Paginator {...pagination}
                       onPageChange={handlePageChange}/>
        </Box>
    )
};

export default DealsPanel;