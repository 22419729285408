import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ShipmentForm from "./ShipmentForm";
import {Box} from "@fik/ui";
import Cart from "./Cart";
import useCart from "./../useCart";
import {useEntity} from "@fik/entity";
import ProductsGrid from "./ProductsGrid";
import {useDocumentSave} from "@fik/document-edit";
import {Errors} from "@fik/ui";

const CategoryContent = ({category, cart, level}) => {
    if (category) {
        let products = null;
        let categories = null;
        if (category.products) {
            products = <ProductsGrid products={category.products} onAddProduct={cart.addProduct}/>
        }
        if (category.subNodes) {
            categories = category.subNodes.map((category) => <Category key={"category-" + category.id} level={level + 1} cart={cart}
                                                                       category={category}/>);
        }
        return <>
            {products}
            {categories}
        </>;
    }
    return null;
};

CategoryContent.propTypes = {
    category: PropTypes.shape({
        products: PropTypes.array,
        subNodes: PropTypes.array,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    cart: PropTypes.object,
    level: PropTypes.number
};

const Category = ({category, cart, level}) => {
    if (category) {
        const Heading = `h${level}`;
        return <>
            <Heading>{category.name}</Heading>
            <CategoryContent cart={cart} level={level} category={category}/>
        </>;
    }
    return null;
};

Category.propTypes = {
    category: PropTypes.object,
    cart: PropTypes.object,
    level: PropTypes.number
};

const Eshop = ({code, onLoading, onSaved}) => {
    const {
        entity: resellerProfile,
        loading: resellerProfileLoading
    } = useEntity({name: 'resellerProfile', params: {action: 'reseller-profile-for-eshop'}}, null);

    const {entity: rootCategory, loading: productTreeLoading} = useEntity({
        name: 'productTree',
    }, code);

    const cart = useCart();
    const [valid, setValid] = useState(false);
    const [profile, setProfile] = useState(null);

    const {
        saveDocument,
        isSaving,
        isSaved,
        messages
    } = useDocumentSave();

    useEffect(() => {
        if (isSaved && onSaved) {
            onSaved();
        }
    }, [isSaved, onSaved]);

    const handleSave = useCallback(() => {
        saveDocument('eshop-order', {cart: cart.products, profile: profile});
    }, [cart.products, profile, saveDocument]);

    const handleValuesChange = useCallback((newValues, isValid) => {
        setValid(isValid);
        setProfile(newValues);
    }, [setValid]);

    useEffect(() => {
        if (!profile && resellerProfile) {
            setValid(true);
            setProfile(resellerProfile)
        }
    }, [resellerProfile, profile]);

    useEffect(() => {
        if (onLoading) {
            onLoading(isSaving || productTreeLoading || resellerProfileLoading);
        }
    }, [onLoading, isSaving, productTreeLoading, resellerProfileLoading]);

    return <div className='estore'>
        {messages ? <Errors messages={messages}/> : null}
        <CategoryContent category={rootCategory} level={1} cart={cart}/>
        <Box title="Košík">
            {cart.products && cart.products.length > 0
                ? <Cart products={cart.products} onChangeQuantity={cart.changeQuantity} onRemoveProduct={cart.removeProduct}
                        total={cart.total}/>
                : <div className="alert alert-info">Pusto a prázdno.</div>}
        </Box>

        <Box title="Údaje pro doručení">
            {resellerProfile ? <ShipmentForm values={resellerProfile} onValuesChange={handleValuesChange}/> : null}
        </Box>

        {valid && cart.products && cart.products.length > 0 ?
            <button className="btn btn-success btn-block btn-lg" onClick={handleSave}>Objednat</button> : null}
    </div>
};

Eshop.propTypes = {
    code: PropTypes.string.isRequired,
    onLoading: PropTypes.func
};

export default Eshop;