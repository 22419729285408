import React, {useCallback, useState} from 'react';
import {Button} from "@fik/ui";
import {Form} from "@mouseover/react-form";

const rulesForMainAddress = (rules) => {
   return  (values) => {
        return !values.mainAddress ? rules : {};
    }
};

const VALIDATION_RULES = {
    name: {
        required: true,
        minLength: 4
    },
    street: {
        required: true,
        minLength: 4
    },
    city: {
        required: true,
        minLength: 4
    },
    zipCode: {
        required: true,
        minLength: 5,
        maxLength: 5
    },
    postName:  rulesForMainAddress({
        required: true,
        minLength: 4
    }),
    postStreet:rulesForMainAddress({
        required: true,
        minLength: 4
    }),
    postCity:  rulesForMainAddress({
        required: true,
        minLength: 4
    }),
    postZipCode:  rulesForMainAddress({
        required: true,
        minLength: 5,
        maxLength: 5
    }),
    email: {
        required: true,
        minLength: 4
    },
    phone: {
        required: true,
        minLength: 9
    },
    mobile: {
        required: false,
        minLength: 9
    },
    web: {
        required: false,
        minLength: 3
    },
};

const AddressBookForm = ({addressBook, onSubmit}) => {
    const [validationRules, setValidationRules] = useState(VALIDATION_RULES);
    const handleChange = useCallback((values, valid) => {
        const newRules = {...VALIDATION_RULES};
        if (values.mainAddress) {
            delete newRules.postName;
            delete newRules.postStreet;
            delete newRules.postCity;
            delete newRules.postZipCode;
        }

        if (newRules.postName !== validationRules.postName) {
            setValidationRules(newRules);
        }
    }, [validationRules, setValidationRules]);

    if (!addressBook) {
        return null;
    }

    return  <Form
        validationRules={validationRules}
        onChange={handleChange}
        controls={{
            name: {
                label: 'Název',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Název',
                },
                value: '',
                valid: false,
                touched: false
            },
            street: {
                label: 'Ulice',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Ulice',
                },
                value: '',
                valid: false,
                touched: false
            },
            city: {
                label: 'Město',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Město',
                },
                value: '',
                valid: false,
                touched: false
            },
            zipCode: {
                label: 'PSČ',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'PSČ',
                },
                value: '',
                valid: false,
                touched: false
            },
            mainAddress: {
                elementType: 'checkbox',
                elementConfig: {autoComplete: 'off'},
                label: 'Dodací adresa shodná jako fakturační',
                groupClass: 'form-group m-b-xs m-t-m',
                value: false,
                valid: false,
                touched: false,
                invalidate: ['postAddress', 'postName', 'postStreet', 'postCity', 'postZipCode']
            },
            store: {
                elementType: 'checkbox',
                elementConfig: {autoComplete: 'off'},
                label: 'Kamenná prodejna',
                groupClass: 'form-group m-b-xs m-t-m',
                value: false,
                valid: false,
                touched: false,
            },
            sendOrderToCustomer: {
                elementType: 'checkbox',
                elementConfig: {autoComplete: 'off'},
                label: 'Odesílat licenční e-mail i na koncového uživatele',
                groupClass: 'form-group m-b-xs m-t-m',
                value: false,
                valid: false,
                touched: false,
            },
            postAddress: {
                label: 'Dodací adresa',
                elementType: 'heading',
                visible: (results) => (!results.mainAddress)
            },
            postName: {
                label: 'Název',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Název',
                },
                value: '',
                valid: false,
                touched: false,
                visible: (results) => (!results.mainAddress)
            },
            postStreet: {
                label: 'Ulice',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Ulice',
                },
                value: '',
                valid: false,
                touched: false,
                visible: (results) => (!results.mainAddress)
            },
            postCity: {
                label: 'Město',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Město',
                },
                value: '',
                valid: false,
                touched: false,
                visible: (results) => (!results.mainAddress)
            },
            postZipCode: {
                label: 'PSČ',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'PSČ',
                },
                value: '',
                valid: false,
                touched: false,
                visible: (results) => (!results.mainAddress)
            },
            email: {
                label: 'E-mail',
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'E-mail',
                },
                value: '',
                valid: false,
                touched: false
            },
            phone: {
                label: 'Telefon 1',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Telefon 1',
                },
                value: '',
                valid: false,
                touched: false
            },
            mobile: {
                label: 'Telefon 2',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Telefon 2',
                },
                value: '',
                valid: false,
                touched: false
            },
            web: {
                label: 'Webová adresa',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Webová adresa',
                },
                value: '',
                valid: false,
                touched: false
            },
        }}
        onSubmit={onSubmit}
        values={addressBook}
        render={(form) => {
            return (
                <Button
                    btnType="primary"
                    type="submit"
                    disabled={!form.validation.valid}
                >Uložit</Button>
            )
        }} />;
};

export default AddressBookForm;