import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {FilterBar} from "@fik/ui";
import {SelectInput} from "@fik/form";
import {useFormContainer} from "@mouseover/react-form";
import {useEntity} from "@fik/entity";
import {StaffInput} from "@fik/components";
import useIdentity from "@fik/auth/useIdentity";
import {isString, shallowEqual} from "@mouseover/js-utils";
import {INITIAL_SEARCH_PARAMS} from "./useOpportunities";
import deepShallowEqueal from "@fik/utils/deepShallowEqueal";

const renderLabel = ({label}) => <><small>{label}</small><br/></>;

const FilterSelect = (props) => <SelectInput renderLabelElement={renderLabel}
                                             groupContainer={CustomGroupContainer}
                                             elementConfig={{className: 'input-sm form-control input-s-sm inline'}} {...props}/>;

const CustomGroupContainer = ({children}) => <label className='inline'>{children}</label>;

const TagsContainer = ({form: parentForm, channels, tiers, solutionList, operationList, phaseList, businessTypes, visibleFilters}) => {
    const form = useFormContainer('tags', parentForm);
    return <>
        {visibleFilters.phase ? <FilterSelect label="Fáze přiležitosti"
                      name='phase'
                      form={form}
                      options={phaseList
                          ? [{value: '', label: 'Všechny'}, ...phaseList.map(item => ({
                              value: item.tagId,
                              label: item.tagDescription
                          }))]
                          : []}/> : null}
        {visibleFilters.operation ? <FilterSelect label="Typ licence"
                      name='operation'
                      form={form}
                      options={operationList
                          ? [{value: '', label: 'Všechny'}, ...operationList.map(item => ({
                              value: item.tagId,
                              label: item.tagDescription
                          }))]
                          : []}/> : null}
        {visibleFilters.solution ? <FilterSelect label="Skupina produktů"
                      name='solution'
                      form={form}
                      options={solutionList
                          ? [{value: '', label: 'Všechny'}, ...solutionList.map(item => ({
                              value: item.tagId,
                              label: item.tagDescription
                          }))]
                          : []}/> : null}
        {visibleFilters.businessType ? <FilterSelect label="Obchodní typ"
                      name='businessType'
                      form={form}
                      options={businessTypes
                          ? [{value: '', label: 'Všechny'}, ...businessTypes.map(item => ({
                              value: item.tagId,
                              label: item.tagDescription
                          }))]
                          : []}/> : null}
        {visibleFilters.tier ? <FilterSelect label="Velikost / tier"
                      name='tier'
                      form={form}
                      options={tiers
                          ? [{value: '', label: 'Všechny'}, ...tiers.map(item => ({
                              value: item.tagId,
                              label: item.tagDescription
                          }))]
                          : []}/> : null}
        {visibleFilters.channel ? <FilterSelect label="Kanál"
                      name='channel'
                      form={form}
                      options={channels
                          ? [{value: '', label: 'Všechny'}, ...channels.map(item => ({
                              value: item.tagId,
                              label: item.tagDescription
                          }))]
                          : []}/> : null}
    </>;
};

export const VISIBLE_FILTERS = {
    status: true,
    type: true,
    staffId: true,
    resellerStaffId: true,
    phase: true,
    operation: true,
    solution: true,
    businessType: true,
    tier: true,
    channel: true
};

const TAG_GROUP_MAP = {
    'code:PRILEZITOST_FAZE':'phase',
    'code:PRILEZITOST_OPERACE':'operation',
    'code:PRILEZITOST_SKUPINY':'solution',
    'code:PRILEZITOST_TYP':'businessType',
    'code:TIERY':'tier',
    'code:PRILEZITOST_KANAL':'channel'
};

function toTagTagGroupMap(list, map) {
    return list ? list.reduce((current, item) => ({...current, [item.tagId]: TAG_GROUP_MAP[item.tagGroup] || item.tagGroup}), map) : map;
}

function filterNull(object) {
    return Object.entries(object).reduce((o, [key, value]) => {
        if (value) {
            return {...o, [key]: value};
        }
        return o;
    }, {});
}

function filterManaged(object, allowed) {
    return Object.entries(object).reduce((o, [key, value]) => {
        if (allowed.hasOwnProperty(key)) {
            return {...o, [key]: value};
        }
        return o;
    }, {});
}

const EMPTY_VALUES = {...INITIAL_SEARCH_PARAMS, tags: {phase: null, operation: null, solution: null, businessType: null, tier: null, channel: null}};

const OpportunitiesFilter = ({onSubmitSearch, statuses, types, visibleFilters = {}, singleStaffOnly, values, ...props}) => {

    const identity = useIdentity();
    const {id:currentStaff} = identity || {};
    const [currentValues, setCurrentValues] = useState(EMPTY_VALUES);
    const {phaseList, operationList, solutionList, businessTypes, tiers, channels} = props;
    const tagPropsMap = useMemo(() => {
        let map = {};
        map = toTagTagGroupMap(phaseList, map);
        map = toTagTagGroupMap(operationList, map);
        map = toTagTagGroupMap(solutionList, map);
        map = toTagTagGroupMap(businessTypes, map);
        map = toTagTagGroupMap(tiers, map);
        map = toTagTagGroupMap(channels, map);
        return map;
    }, [phaseList, operationList, solutionList, businessTypes, tiers, channels]);

    const currentVisibleFilters = useMemo(() => ({...VISIBLE_FILTERS, ...visibleFilters}), [visibleFilters]);

    useEffect(() => {
        if (values) {
            let newValues = {...EMPTY_VALUES, ...filterManaged(values, EMPTY_VALUES)};
            if (newValues.tags && isString(newValues.tags)) {
                newValues.tags = newValues.tags.split(',');
            }
            if (Array.isArray(newValues.tags)) {
                const tags = newValues.tags.reduce((o, tag) => {
                    if (tagPropsMap[tag]) {
                        return ({...o, [tagPropsMap[tag]]: tag})
                    } else {
                        return o;
                    }
                }, {...EMPTY_VALUES.tags});

                newValues = Object.entries({...newValues, tags}).reduce((o, [key, value]) => {
                    if (o.hasOwnProperty(key)) {
                        return {...o, [key]: value};
                    }
                    return o;
                }, EMPTY_VALUES);
            } else {
                newValues.tags = {...EMPTY_VALUES.tags};
            }

            if (!deepShallowEqueal(newValues, currentValues)) {
                setCurrentValues(newValues);
            }
        }
    }, [values, tagPropsMap, setCurrentValues, currentValues])

    const handleChange = useCallback((changedValues, valid) => {
        if (onSubmitSearch && !deepShallowEqueal(changedValues, currentValues)) {
            const newCurrentValues = (
                {
                    ...EMPTY_VALUES,
                    ...changedValues,
                    tags: (changedValues.tags && Object.values(changedValues.tags).length > 0 ? Object.values(changedValues.tags).filter(item => item) : []).join(',') || null
                }
            );
            setCurrentValues(changedValues);
            onSubmitSearch(newCurrentValues, valid);
        }
    }, [onSubmitSearch, currentValues, setCurrentValues]);

    const {entity: staffList} = useEntity({name: 'staff', params: {action: 'staff-list'}});

    if (!staffList) {
        return null;
    }

    return (
        <FilterBar onChange={handleChange}
                   values={currentValues}
                   button={false}
                   render={(form) => {
                       return <>
                           <div className='col-sm-4 m-b-xs form form-inline'>
                               {currentVisibleFilters.status ? <StatusFilter form={form} statuses={statuses} /> : null}
                               {currentVisibleFilters.type ? <TypesFilter form={form} types={types} /> : null}
                               {currentVisibleFilters.staffId ? <StaffInput
                                   renderLabelElement={renderLabel}
                                   groupContainer={CustomGroupContainer}
                                   elementConfig={{className: 'input-sm form-control input-s-sm inline'}}
                                   singleStaffOnly={singleStaffOnly}
                                   label="Zodpovědná osoba"
                                             name='staffId'
                                             form={form}
                                   staffList={staffList}
                                   currentStaff={currentStaff}
                                             options={staffList
                                                 ? [{value: '', label: 'Všichni'}, ...staffList.map(item => ({
                                                     value: item.id,
                                                     label: item.name
                                                 }))]
                                                 : []}/> : null}
                               {currentVisibleFilters.resellerStaffId ? <StaffInput
                                   renderLabelElement={renderLabel}
                                   groupContainer={CustomGroupContainer}
                                   elementConfig={{className: 'input-sm form-control input-s-sm inline'}}
                                   label="Obchodník partnera"
                                   name='resellerStaffId'
                                   form={form}
                                   staffList={staffList}
                                   currentStaff={currentStaff}
                                   options={staffList
                                       ? [{value: '', label: 'Všichni'}, ...staffList.map(item => ({
                                           value: item.id,
                                           label: item.name
                                       }))]
                                       : []}/> : null}
                           </div>
                           <div className='col-sm-8 m-b-xs form form-inline'>
                               <div className='pull-right'>
                                    <TagsContainer visibleFilters={currentVisibleFilters} form={form} {...props} />
                               </div>
                           </div>
                       </>
                   }}
        />
    );
};

export default OpportunitiesFilter;

const StatusFilter = ({statuses, form}) => <FilterSelect label="Status"
                                                         name='status'
                                                         form={form}
                                                         options={useMemo(() => statuses
                                                             ? [{value: '', label: 'Všechny stavy'}, ...statuses.map(item => ({
                                                                 value: item.id,
                                                                 label: item.name
                                                             }))]
                                                             : [], [statuses])}/>;

const TypesFilter = ({types, form}) => <FilterSelect label="Typ"
                                             name='type'
                                             form={form}
                                             options={useMemo(() => types
                                                 ? [{value: '', label: 'Všechny'}, ...types.map(item => ({
                                                     value: item.filter,
                                                     label: item.name
                                                 }))]
                                                 : [], [types])}/>;