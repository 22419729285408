import React from 'react';
import PropTypes from 'prop-types';
import {Table} from "@fik/table";
import {useEntity} from "@fik/entity";
import {NavigationItem} from "@fik/navigation";

const tagsMap = {
    'ROLE_OBCHODNIK': {label: 'obchodník', className: 'default'},
    'ROLE_FAKTURACE': {label: 'fakturace', className: 'default'},
    'ROLE_TECHNIK': {label: 'technik', className: 'default'},
    'ROLE_NOTALLOWED_RZ': {label: 'zakázané přihlášení do PZ', className: 'warning'},
    'NEAKTIVNI': {label: 'neaktivní', className: 'danger'},
};

const Tag = ({tag}) => {
    const def = tagsMap[tag] || null;
    return (def ? (<span className={"btn btn-rounded btn-xs btn-" + def.className}>{def.label}</span>) : null);
};

Tag.propTypes = {
    tag: PropTypes.string
};


const ContactList = () => {

    const {contact: contacts} = useEntity({name: 'contact'}, null);

    if (!contacts) {
        return null;
    }

    return <Table striped responsive
                  columns={[
                      {
                          key: 'username',
                          title: 'Přihlašovací jméno',
                      },
                      {
                          key: 'contactSurname',
                          title: 'Příjmení',
                      },
                      {
                          key: 'contactFirstname',
                          title: 'Jméno',
                      },
                      {
                          key: 'contactPhone',
                          title: 'Telefon',
                      },
                      {
                          key: 'contactEmail',
                          title: 'E-mail',
                      },
                      {
                          key: 'tags',
                          title: 'Role / Stav',
                          render: ({row}) => (row.contactTags.map((tag) => (<Tag key={tag} tag={tag}/>))),
                      },
                      {
                          key: 'menu',
                          render: function renderMenu({row: {contactId}}) {
                              return (
                                  contactId ? <>
                                      <NavigationItem
                                          className="btn btn-success btn-xs m-r-xs"
                                          to="profile:editContact"
                                          params={{id: contactId}}>
                                          <i className="fa fa-paste"/> Upravit
                                      </NavigationItem>

                                      <NavigationItem
                                          className="btn btn-success btn-xs"
                                          to="profile:certificationRequest"
                                          params={{id: contactId}}>
                                          <i className="fa fa-certificate"/> Zažádat o certifikaci
                                      </NavigationItem>

                                  </> : null
                              );
                          }
                      },
                  ]}
                  rows={contacts}
                  primary='username'/>;
};

export default ContactList;