import React, {useCallback, useMemo} from "react";
import {Form, FormField} from "@mouseover/react-form";
import {useDials} from "@fik/dials";
import {getValue} from "@mouseover/js-utils";
import handleClick from "@fik/utils/handleClick";
import {NavigationItem} from "@fik/navigation";
import arrayRemoveAny from "@fik/utils/arrayRemoveAny";

const TagGroupCheckboxes = ({tags, name, form, disabled = false}) => {

    const usedTags = useMemo(() => getValue(form.values, name) || [], [form.values, name]);

    const setChecked = useCallback((tag, checked) => {

        let currentValue = [...usedTags];
        if (checked) {
            currentValue.push(tag.tagCode);
        } else {
            currentValue = arrayRemoveAny(currentValue, tag.tagCode);
        }
        form.setValue(currentValue, name);
    }, [form, name, usedTags]);
    return tags.map((item, index) => (
        <div className='checkbox' key={index}><label><input disabled={disabled} key={index} checked={usedTags.indexOf(item.tagCode) !== -1}
                                                            type="checkbox"
                                                            onChange={(event) => setChecked(item, event.target.checked)}/> {item.tagDescription}
        </label></div>))
};
const VALIDATION_RULES = {
    contactSurname: {
        required: {
            params: true,
            message: "Vyplňte prosím příjmení."
        },
        minLength: 2
    }, contactFirstname: {
        required: {
            params: true,
            message: "Vyplňte prosím jméno."
        },
        minLength: 2
    }, contactPhone: {
        required: {
            params: true,
            message: "Vyplňte prosím telefon."
        },
        minLength: 9,
        pattern: {
            message: 'Vyplňte prosím telefon ve formátu +420XXXXXXXXX.',
            params: '(^[\+][0-9]{12}$)'
        }
    }, contactEmail: {
        required: {
            params: true,
            message: "Vyplňte prosím e-mailovou adresu."
        },
        email: true
    }
};

const ContactForm = ({values, onSubmit, onResendLoginRequested, selfEdit = false}) => {
    const dials = useDials({name: 'contact'});

    if (!values || !dials.roles) {
        return null;
    }

    return <Form
        values={values}
        validationRules={VALIDATION_RULES}
        onSubmit={onSubmit}
        render={(form) => (
            <>
                <div className="row">
                    <div className="col-sm-6">
                        <FormField form={form}
                                   label="Příjmení"
                                   elementConfig={{disabled: !selfEdit}}
                                   name='contactSurname'
                                   elementType='input'
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField form={form}
                                   label="Jméno"
                                   elementConfig={{disabled: !selfEdit}}
                                   name='contactFirstname'
                                   elementType='input'
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <FormField form={form}
                                   label="Telefon"
                                   elementConfig={{disabled: !selfEdit}}
                                   name='contactPhone'
                                   elementType='input'
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField form={form}
                                   label="E-mail"
                                   elementConfig={{disabled: !selfEdit}}
                                   name='contactEmail'
                                   elementType='input'
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <h3 className="font-bold">Role kontaktu</h3>
                        <TagGroupCheckboxes form={form} name='contactTags' tags={dials.roles}/>
                        <h3 className="font-bold">Další nastavení</h3>
                        <p>
                            Pokud je volba zatržena, kontakt se nebude moci přihlásit do zóny.
                        </p>
                        <TagGroupCheckboxes form={form} name='contactTags' tags={dials.others}/>
                    </div>
                    <div className="col-sm-6">
                        <h3 className="font-bold">Odběr novinek</h3>
                        <p>
                            Zatrhněte prosím kategorie novinek, které chcete posílat e-mailem. Více informací k jednotlivým sekcím obsahu
                            najdete <NavigationItem to="page" params={{category: 'napoveda', page: 'faq'}}
                                                    anchor="faq28">zde</NavigationItem>.
                        </p>
                        <TagGroupCheckboxes form={form} name='contactTags' disabled={!selfEdit} tags={dials.newsletters}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-2">
                        <button type='submit' className="btn btn-primary block m-b">Uložit</button>
                    </div>
                    {onResendLoginRequested
                        ? <div className="col-lg-2">
                            <button type='button' onClick={handleClick(onResendLoginRequested)} className="btn btn-warning block m-b">Znovu
                                zaslat
                                přihlašovací údaje
                            </button>
                        </div>
                        : null}
                </div>
            </>
        )}/>;
};

export default ContactForm;