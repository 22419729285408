import {useEffect} from 'react';
import {createActionNames, createRequestState} from "@fik/api";
import wrapAsyncCallForDispatch from "@fik/utils/wrapAsyncCallForDispatch";
import {useModel} from "@fik/model";
import {useReducerWithThunk} from "@fik/utils/useReducerWithThunk";

const pageActions = createActionNames('cmsMenu');

const pageReducer = (state, action) => {
    switch (action.type) {
        case pageActions.FETCH_INIT:
            return {
                ...state,
                result: null,
                ...createRequestState(pageActions, action.type),
            };
        case pageActions.FETCH_SUCCESS:
            return {
                ...state,
                result: action.payload,
                ...createRequestState(pageActions, action.type),
            };
        case pageActions.FETCH_FAILURE:
            return {
                ...state,
                result: null,
                ...createRequestState(pageActions, action.type),
            };
        default:
            return state;
    }
};

const useCmsPage = (category, page) => {
    const loadPage = useModel(
        (model) =>  wrapAsyncCallForDispatch(model.cms().getPage)(pageActions),
        []
    );

    const [state, dispatch] = useReducerWithThunk(pageReducer, {
            isFetching: false,
            isSuccess: false,
            isFailed: false,
            result: null
        }
    );

    useEffect(() => {
        dispatch(loadPage(category, page));
    }, [loadPage, dispatch, category, page]);

    return state;
};

export default useCmsPage;