import React from 'react';
import Number from "../Number/Number";
import WithoutCode from "../WithoutCode/WithoutCode";
import {isEmpty} from "@mouseover/js-utils";

const Price = ({price, currency, ...props}) => {
    if (isEmpty(price)) {
        return (<>-</>);
    }
    return (
        <>
            <Number number={price} {...props} /> {currency && <WithoutCode>{currency}</WithoutCode>}
        </>
    )
};

export default Price;