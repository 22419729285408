import React, {useCallback} from "react";
import {Form} from "@mouseover/react-form";
import {useEntityAction} from "@fik/entity";

const LicenseInfoSend = ({id, email}) => {

    //- resend credentials hook
    const [{isSuccess, isFailed, result: sendedToEmail}, resendCredentials] = useEntityAction({name: 'license', action: 'resendLicenseEmail', params: {id}});

    //- resend credentials
    const handleResendCredentials = useCallback((values, valid) => {
        if (valid) {
            resendCredentials({
                id,
                values
            });
        }
    }, [id, resendCredentials]);


    if (!id) {
        return null;
    }

    return <>
        {isSuccess ? <div className='alert alert-info'>Odesláno na {sendedToEmail}</div> : null}
        {isFailed ? <div className='alert alert-error'>Nepodařilo se odeslat</div> : null}
        <Form className='form'
              onSubmit={handleResendCredentials}
              values={{email}}
              controls={{
                  email: {
                      elementType: 'input',
                      validation: {
                          required: true,
                      },
                      renderAppend: () => <span className='input-group-btn'>
                    <button
                        type="submit"
                        className="btn btn-primary"
                    >Odeslat
                    </button>
                </span>
                  }
              }}
        >
        </Form>
    </>;
};

export default LicenseInfoSend;