import React from "react";
import LinkItem from "./LinkItem";
import useNavigationLink from "../useNavigationLink";

const NavigationItem = ({to, params, anchor, query, Container = null, ...props}) => {
    const path = useNavigationLink({to, params, anchor, query});

    if (!path) {
        return props.label || props.children || null;
    }

    const content = path.indexOf('http') === 0 ? <a href={path} {...props} /> : <LinkItem to={path} {...props}/>;
    return  Container ? <Container path={path}>{content}</Container> : content;
};


export default NavigationItem;