import queryString from 'query-string'

const parseQueryString = (string, allowedParams) => {
    const params = queryString.parse(string);
    if (allowedParams) {
        return Object.entries(params).reduce((obj, [key, value]) => {
            if (allowedParams.indexOf(key) >= 0) {
                obj[key] = value;
            }
            return obj;
        }, {})
    }
    return params;
};

export default parseQueryString;