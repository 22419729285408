import React from 'react';
import PropTypes from "prop-types";
import handleClick from "@fik/utils/handleClick";

const Cell = ({column, row, primaryValue, renderCellProps, index}) => {

    let content = null;
    if (column.render) {
        content = column.render({...renderCellProps, row, primaryValue, column, index});
    } else if (row.hasOwnProperty(column.key)) {
        content = row[column.key];
    }

    return (
        <td className={column.className || null}>
            {content}
        </td>
    );
};

const filterVisible = column => {
    if (!column.hasOwnProperty('visible')) {
        return true;
    }

    if (typeof column.visible === 'function') {
        return column.visible();
    }

    return column.visible;
};

const Cells = ({columns, ...props}) => {
    return columns
        .filter(filterVisible)
        .map((column, key) => (<Cell key={key} column={column} {...props}/>))
};

const Row = props => {
    const {row, columns, primaryValue, index, renderCellProps = {}, onRowClick} = props;
    const rowContent = props.render({row, columns, primaryValue, renderCellProps, index}) || <tr onClick={onRowClick ? handleClick(() => onRowClick(row)) : null}><Cells columns={columns} row={row} primaryValue={primaryValue} index={index} renderCellProps={renderCellProps}/></tr>;
    if (props.renderRowMessages) {
        return <>
            {rowContent}
            {props.renderRowMessages({...props, columnsLength: columns.length})}
        </>
    }
    return rowContent;
};

const getChildren = (children) => React.isValidElement(children) ? getChildren(children.props.children) : children;

const Table = (props) => {
    const tableClasses = ['table'];

    if (props.striped) {
        tableClasses.push('table-striped');
    }

    if (props.bordered) {
        tableClasses.push('table-bordered');
    }

    if (props.condensed) {
        tableClasses.push('table-condensed');
    }

    if (props.hover) {
        tableClasses.push('table-hover');
    }
    const containerClasses = [];

    if (props.containerClassName) {
        containerClasses.push(props.containerClassName);
    }

    if (props.responsive) {
        containerClasses.push('table-responsive');
    }

    const renderRow = props.renderRow || (() => null);

    let columns = props.columns;

    if (props.children) {
        const children = getChildren(props.children);
        if (children) {
            columns = children.filter(child => React.isValidElement(child)).map((child, index) => ({
                key: index,
                title: child.props.title,
                header: child.props.header,
                className: child.props.headerClassName,
                render: ({row, ...props}) => React.cloneElement(child, {row, ...row, ...props})
            }))
        }
    }

    const rows = props.rows
        ? (props.rows.map((row, index) => {
            const primaryValue = props.primary ? (typeof props.primary === 'function' ? props.primary(row) : row[props.primary]) : index;

            return (<Row
                key={'row-' + index + '-' + (primaryValue || index)}
                index={index}
                primaryValue={primaryValue}
                onRowClick={props.onRowClick}
                row={row}
                columns={columns}
                renderCellProps={props.renderCellProps}
                render={renderRow}
                renderRowMessages={props.renderRowMessages} />);
        })) : null;



    return (
        <div className={containerClasses.join(' ')}>
            <table className={tableClasses.join(' ')}>
                <TableHead columns={columns} filterVisible={filterVisible} />
                <tbody>
                {rows}
                </tbody>
            </table>
        </div>
    )
};
const TableHead = ({columns, filterVisible}) => <thead>
<tr>
    {columns
        .filter(filterVisible)
        .map(item => (<th key={item.key} className={item.className || null} title={item.title || null}>{item.header  ? (typeof item.header === 'function' ? item.header() : item.header): item.title}</th>))}
</tr>
</thead>;

Table.propTypes = {
    striped: PropTypes.bool,
    bordered: PropTypes.bool,
    condensed: PropTypes.bool,
    hover: PropTypes.bool,
    responsive: PropTypes.bool,
    columns: PropTypes.array,
    rows: PropTypes.array,
    primary: PropTypes.string,
    renderRow: PropTypes.func,
    renderRowMessages: PropTypes.func,
};

export default Table;