import React, {useState, useCallback} from 'react';
import FaqQuestion from "./FaqQuestion";
import FaqAnswer from "./FaqAnswer";

const FaqItem = ({question, answer}) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen, setIsOpen]);

    return <div className="faq-item">
        <FaqQuestion question={question} onClick={toggle}/>
        <FaqAnswer answer={answer} isOpen={isOpen}/>
    </div>
};

export default FaqItem;