import React from "react";
import NfrCreditInfo from "../../components/NfrOrder/NfrCreditInfo";
import LicensesTable, {
    LicenseDate,
    LicenseEPC,
    LicenseIdWithNoteColumn, LicenseOrder,
    LicenseCustomerInfo,
    LicensePartner,
    LicenseProduct, LicenseStatus
} from "@fik/license/LicensesTable";
import {Box, Paginator} from "@fik/ui";
import {useDataSource, useDataSourceWithQueryParams} from "@fik/data-source";
import useIsEpc from "../../useIsEpc";

const LicenseNfrSpend = ({row}) => row.nfrPrice;

const ResellerNfrLicenses = ({epcVisible = false}) => {
    const {pagination, items, handlePageChange, refresh} = useDataSourceWithQueryParams(
        {name: 'license', initialSearchParams: {query: 'nfr'}}
    );

    return <>
        <NfrCreditInfo/>
        <Box>
            <LicensesTable striped responsive
                           onInvalidate={refresh}
                           rows={items}
            >
                <LicenseIdWithNoteColumn title='ID' />
                <LicenseCustomerInfo title='Klient' />
                {useIsEpc() && <LicensePartner title='Partner' />}
                {epcVisible ? <LicenseEPC title='EPC' /> : null}
                <LicenseProduct title='Produkt' />
                <LicenseDate title='Platnost od/do' />
                <LicenseStatus title='Stav' />
                <LicenseNfrSpend title='Odčerpáno z NFR kreditu' />
            </LicensesTable>

            <Paginator {...pagination}
                       onPageChange={handlePageChange}/>
        </Box>
    </>;
};

export default ResellerNfrLicenses;
