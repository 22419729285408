import React from 'react';
import {Col, Row} from "@fik/ui";

const FaqQuestion = ({question, ...props}) => {
    return <Row>
        <Col md={12}>
            <a data-toggle="collapse" className="faq-question" {...props}>{question}</a>
        </Col>
    </Row>;
};

export default FaqQuestion;