const wrapAsyncCallForDispatch = (fce, method) => (actions, shouldCallback) => (...args) => async (dispatch, getState) => {
    if (typeof shouldCallback=== 'function' && !shouldCallback(args, getState)){
        return Promise.resolve();
    }

    let params = null;

    if (method === 'put' || method === 'post') {
        params = args[0] || null;
    } else {
        params = args.length === 1 && typeof args === 'object' ? args[0] : args;
    }

    dispatch({type: actions.FETCH_INIT, params, method});
    let payload;
    try {
        payload = await fce(...args);
    } catch (error) {
        dispatch({type: actions.FETCH_FAILURE, params, method, error: error.response && error.response.data ? error.response.data : error});
        return;
    }
    dispatch({type: actions.FETCH_SUCCESS, payload, params, method});
};

export default wrapAsyncCallForDispatch;