import {useDataSource} from "./useDataSource";
import {useCallback, useEffect, useMemo, useState} from "react";
import {shallowEqual} from "@mouseover/js-utils";
import {useQueryParams} from "@fik/url-query";
import usePureDataSource from "./usePureDataSource";

const PAGINATION = {
    page: 1
}

const useDataSourceWithQueryParams = ({initialSearchParams, defaultParams, ...props}) => {
    const [queryParams, setQueryParams] = useQueryParams(defaultParams);
    const [paginationQueryParams, setPaginationQueryParams] = useQueryParams(PAGINATION);
    const [queryParamsProcessed, setQueryParamsProcessed] = useState(false);
    const [paginationQueryParamsProcessed, setPaginationQueryParamsProcessed] = useState(false);
    const [firstRun, setFirstRun] = useState(true);

    //- je nutne zaznamenat ze se zmenilo url query
    useEffect(() => {
        setQueryParamsProcessed(false);
    }, [setQueryParamsProcessed, queryParams]);

    //- je nutne zaznamenat ze se zmenilo page
    useEffect(() => {
        setPaginationQueryParamsProcessed(false);
    }, [setPaginationQueryParamsProcessed, paginationQueryParams]);

    const dataSource = usePureDataSource(
        {...props, initialSearchParams: queryParams}
    );

    const {pagination, setSearchParams, setPage, searchParams} = dataSource;

    // zmena filtrovani
    dataSource.handleSearch = useCallback((values, valid) => {
        if (valid) {
            const params = {...searchParams, ...values};
            setSearchParams(params);
            setQueryParams({...params});
        }
    }, [searchParams, setSearchParams, setQueryParams]);

    // zmena stranky
    dataSource.handlePageChange = useCallback((page) => {
        setPage(page);
        setPaginationQueryParams({page})
    }, [setPaginationQueryParams, setPage]);

    // osetreni zmeny query vyhledavani
    useEffect(() => {
        if (!queryParamsProcessed) {
            const newParams = firstRun ? Object.entries(queryParams).reduce((o, [key, value])=> {
                if (!defaultParams.hasOwnProperty(key) || defaultParams[key] !== value) {
                    o[key] = value;
                }
                return o;
            }, {...initialSearchParams}) : {...initialSearchParams, ...queryParams};

            if (!shallowEqual(searchParams, newParams)) {
                setSearchParams(newParams);
            }
            setFirstRun(false);
            setQueryParamsProcessed(true);
        }
    }, [searchParams, firstRun, queryParams,  setFirstRun, setSearchParams, queryParamsProcessed, setQueryParamsProcessed]);

    // osetreni query page
    useEffect(() => {
        if (!paginationQueryParamsProcessed) {
            const page = parseInt(paginationQueryParams.page);
            if (pagination.page !== page) {
                setPage(page);
            }
            setPaginationQueryParamsProcessed(true);
        }
    }, [pagination, paginationQueryParams, setPage, paginationQueryParamsProcessed, setPaginationQueryParamsProcessed]);

    useEffect(() => {
        if (queryParamsProcessed) {
            dataSource.load();
        }
    }, [queryParamsProcessed, paginationQueryParamsProcessed, dataSource.load]);

    dataSource.queryParams = useMemo(() => ({...initialSearchParams, ...queryParams}), [initialSearchParams, queryParams]);
    return dataSource;
}

export default useDataSourceWithQueryParams;