import {getOnPath} from "@mouseover/js-utils/src";

const DOCUMENT_RESOURCES = ['order', 'offer', 'project-price'];

const createEntityModel = (adapters, defaultAdapter = 'entity', actionsModel = {}) => {

    const getAdapter = (adapter) => adapters[adapter || defaultAdapter];

    return {
        getEntity: ({name: resource, adapterName}, params = {}) => {
            if (DOCUMENT_RESOURCES.indexOf(resource) !== -1 && params.id === 'new') {
                params.action = 'new';
                delete params.id;
            }

            return getAdapter(adapterName)(resource).get(params);
        },
        postEntity: ({name: resource, adapterName}, params = {}, content = {}) => {
            return getAdapter(adapterName)(resource).post(params, content);
        },
        putEntity: ({name: resource, adapterName}, params = {}, content = {}) => {
            return getAdapter(adapterName)(resource).put(params, content);
        },
        deleteEntity: ({name: resource, adapterName}, params = {}) => {
            return getAdapter(adapterName)(resource).delete(params);
        },
        action: ({entity, name: resource, adapterName, storeName}, action, params) => {
            const def = getOnPath(actionsModel, [entity, action]);
            if (def) {
                return def(params, () => getAdapter(adapterName)(resource));
            }
        }
    }
};

export default createEntityModel;

