import {EvidenceItems} from '@fik/evidence-item';
import React from "react";

const OrdersTableRowDetail = ({order: {evidenceItems}}) => {
    return <>
        <div className="table-responsive  m-l-lg  m-r-md">
            {evidenceItems ? <EvidenceItems evidenceItems={evidenceItems}/> : null}
        </div>
    </>
};

export default OrdersTableRowDetail;