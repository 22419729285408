import React, {useEffect} from "react";
import {
    LicenseSolution,
    LicenseIdentification,
    LicenseUser,
    LicenseNote,
    LicenseOrderLink,
    LicenseOfferLink,
    LicenseInfoSend,
    LicenseResetCredentialsButton
} from "@fik/license";
import {Box, Col, Row} from "@fik/ui";
import {usePureEntity} from "@fik/entity";

const License = ({id}) => {
    const [{license}, load] = usePureEntity({name: 'license', params: {action: 'by-epli'}});

    //- load on first render (load callback should not change)
    useEffect(() => load({id}), [load, id]);

    return license ? <Row>
        <Col sm={12} md={4}>
            <Box title="Identifikační údaje">
                <LicenseIdentification license={license}/>
                {license.resellerResetAllowed && license.leakedInfo ?
                    <LicenseResetCredentialsButton id={license.licenseId} onReset={load}/> : null}
            </Box>
            <Box title="Znovu zaslat informace o licenci e-mailem">
                <LicenseInfoSend id={license.licenseEPLI} email={license.licenseEmail}/>
            </Box>
        </Col>
        <Col sm={12} md={4}>
            <Box title="Řešení">
                <LicenseSolution license={license}/>
                <LicenseOrderLink className="btn btn-success btn-sm m-r-sm" item={license}/>
                <LicenseOfferLink className="btn btn-warning btn-sm" item={license}/>
            </Box>
        </Col>
        <Col sm={12} md={4}>
            <Box title="Vlastník">
                <LicenseUser license={license}/>
            </Box>
            <Box title="Poznámka">
                <LicenseNote id={license.licenseId} resellerNote={license.resellerNote} onSave={load}/>
            </Box>
        </Col>
    </Row> : null;
};

export default License;