import React from "react";
import {Price} from "@fik/ui";
import PropTypes from "prop-types";

const EvidenceItemPrice = ({channel, itemCurrency, ...props}) => <>
    {props[channel] ? (<Price price={props[channel].itemPrice} currency={itemCurrency}/>) : '--'}
</>;

EvidenceItemPrice.propTypes = {
    itemPrice: PropTypes.number,
    itemCurrency: PropTypes.string,
    channel: PropTypes.string
};


export default EvidenceItemPrice;
