import React from 'react';
import {getOnPath} from "@mouseover/js-utils/src";
import {Price} from "@fik/ui";
import arraySort from "@fik/utils/arraySort";
import PropTypes from "prop-types";
import {NavigationItem} from "@fik/navigation";

const RowContainer = ({children}) => <tr>{children}</tr>;
const RowLabel = ({children}) => <td><strong>{children}</strong></td>;
const RowContent = ({children}) => <td>{children}</td>;
const RowWideContent = ({children}) => <td colSpan="2">{children}</td>;

const ResellerAddressBookRow = ({name}) => <RowContainer>
    <RowLabel>Firma</RowLabel>
    <RowContent>{name} (<NavigationItem to="profile:settings">nastavení profilu</NavigationItem>)</RowContent>
</RowContainer>;

const ResellerBranchOfficeRow = ({name}) => <RowContainer>
    <RowLabel>Pobočka</RowLabel>
    <RowContent>{name}</RowContent>
</RowContainer>;

const ResellerCategoryRow = ({name, commission}) => <RowContainer>
    <RowLabel>Kategorie</RowLabel>
    <RowContent>{name} ({commission})</RowContent>
</RowContainer>;

const ResellerNfrCreditRow = ({nfrCredit}) => <RowContainer>
    <RowLabel>Výše NFR kreditu</RowLabel>
    <RowContent>{nfrCredit}</RowContent>
</RowContainer>;

const ResellerPrivilegeStatusRow = ({privilegeStatus}) => <RowContainer>
    <RowLabel>Režim přihlášení</RowLabel>
    <RowContent>{privilegeStatus}</RowContent>
</RowContainer>;

const ResellerTurnoversRow = ({amount, subtitle}) => <RowContainer>
    <RowLabel><abbr title="Obrat je v doporučených koncových cenách bez DPH">Obrat</abbr> - {subtitle}</RowLabel>
    <RowContent><Price price={amount}/></RowContent>
</RowContainer>;

const ResellerLoyaltyRow = ({loyalties}) => <RowContainer>
    <RowLabel>Věrnost</RowLabel>
    <RowContent><NavigationItem to="profile:reports">přehledy</NavigationItem></RowContent>
</RowContainer>;

const ResellerRidRow = ({addressBook, branchOffice}) => {
    let rid = addressBook.publicExternalId;

    if (branchOffice && branchOffice.publicExternalId) {
        rid = branchOffice.publicExternalId + ' ' + rid;
    }
    return <RowContainer>
        <RowLabel>RID</RowLabel>
        <RowContent>{rid}</RowContent>
    </RowContainer>;
};

const ResellerCertificationMessage = ({status, message}) => {
    switch (status) {
        case 'red':
            return <div className="alert alert-danger">{message}</div>;
        case 'yellow':
            return <div className="alert alert-warning">{message}</div>;
        case 'green':
        default:
            return null;
    }
};

const ResellerCertificationRow = ({certInfo}) => <RowContainer>
    <RowWideContent>
        {certInfo.map((item, index) => <ResellerCertificationMessage key={index} {...item} />)}
    </RowWideContent>
</RowContainer>;

const ResellerPlanRow = ({certInfo}) => <RowContainer>
    <RowLabel>Individuální plán</RowLabel>
    <RowContent><NavigationItem to="partnerstvi:resellerPlan">AKTIVNÍ</NavigationItem></RowContent>
</RowContainer>;


const ResellerLoyalties = ({loyalties}) => {
    if (loyalties) {
        // sort by years and return last year
        const lastYear = arraySort(Object.values(loyalties), (a, b) => parseInt(a.year) - parseInt(b.year)).pop();
        // sort by months and return last month
        const lastMonth = lastYear ? arraySort(Object.values(lastYear), (a, b) => parseInt(a.month) - parseInt(b.month)).pop() : null;
        // return percentage of lastMoth
        if (lastMonth && lastMonth.percent !== undefined) {
            return lastMonth.percent + '%';
        }
    }
    return 'n/a';
};

const ResellerInfo = ({reseller}) => <table className="table table-striped">
    <tbody>

    <ResellerAddressBookRow {...reseller.addressBook}/>
    {reseller.branchOffice ? <ResellerBranchOfficeRow {...reseller.branchOffice} /> : null}
    <ResellerPrivilegeStatusRow privilegeStatus={reseller.privilegeStatus}/>
    {reseller.categoryInfo ? <ResellerCategoryRow {...reseller.categoryInfo} /> : null}
    <ResellerTurnoversRow amount={getOnPath(reseller, ['stats', 'turnovers', 'ownerPrices', 'thisYear', 'sumOwnerPrice'])}
                          subtitle='tento rok'/>
    <ResellerTurnoversRow amount={getOnPath(reseller, ['stats', 'turnovers', 'ownerPrices', 'previousYear', 'sumOwnerPrice'])}
                          subtitle='předchozí rok'/>
    {reseller.resellerPlanEnabled && <ResellerPlanRow />}
    {reseller.stats
        ? <>
            <ResellerLoyaltyRow {...reseller.stats}/>
        </>
        : null}
    <ResellerRidRow addressBook={reseller.addressBook} branchOffice={reseller.branchOffice}/>
    <ResellerCertificationRow certInfo={reseller.certInfo}/>
    </tbody>
</table>;

ResellerInfo.propTypes = {
    addressBook: PropTypes.object,
    branchOffice: PropTypes.object,
    categoryInfo: PropTypes.object,
    privilegeStatus: PropTypes.string,
    turnovers: PropTypes.object,
    certInfo: PropTypes.object,
    stats: PropTypes.object
};


export default ResellerInfo;