import {useMemo} from "react";
import useDocument from "./useDocument";
import useDocumentValidator from "./useDocumentValidator";
import useDocumentSave from "./useDocumentSave";
import useDocumentEditEvidenceItems from "./useDocumentEditEvidenceItems";
import documentViews from "./documentViews";
import useDocumentEditView from "./useDocumentEditView";
import useDocumentEditInit from "./useDocumentInit";

const useDocumentEdit = ({validate, documentType, document, evidenceItem, existing, isEpc, defaultView = documentViews.VIEW}) => {
    const modelProps = useMemo(() => ({
        validate: validate !== undefined ? validate : true,
        isEpc
    }), [validate, isEpc]);

    const documentModel = useDocument(modelProps);
    const {setDocument} = documentModel;

    documentModel.documentType = documentType;
    documentModel.validation = useDocumentValidator(documentModel);
    documentModel.saving = useDocumentSave();
    documentModel.view = useDocumentEditView({document: documentModel.document, defaultView});

    useDocumentEditInit({document, existing, documentModel, evidenceItem});

    documentModel.evidenceItemEdit = useDocumentEditEvidenceItems({
        setView: documentModel.view.setView,
        isCompany: !!(document && document.owner && document.owner.addressBook && document.owner.addressBook.companyId),
        documentModel
    });

    return documentModel;
};

export default useDocumentEdit;