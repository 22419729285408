const section = {
    sales: 'Prodej',
    license: 'Licence'
};

const navigation = {
    homepage: {path: '/',  blocks: ['banners', 'popup']},
    signIn: {path: '/sign/in'},
    signToken: {path: '/sign/token/:token'},
    signOut: {path: '/sign/out'},
    sso: {path: '/sso'},
    forgottenPassword: {path: '/sign/forgot-password'},
    passwordSet: {path: '/sign/password-set/:id'},
    calculator: {section: 'Ceníky', title: 'Kalkulátor', path: '/sales/calculator', atIndex: 1},
    consignment: {section: section.sales, title: 'Komise', path: '/sales/consignment'},
    order: {
        detail: {section: section.sales, title: 'Objednávka', path: '/sales/order/:id'},
        list: {section: section.sales, title: 'Čekající objednávky', path: "/sales/orders"},
        create: {section: section.sales, title: 'Nová objednávka', path: "/sales/create-order"}
    },
    offer: {
        detail: {section: section.sales, title: 'Nabídka', path: '/sales/offer/:id'},
        list: {section: section.sales, title: 'Cenové nabídky/projektovky', path: "/sales/offers"},
        create: {section: section.sales, title: 'Nová nabídka/projektovka', path: "/sales/create-offer"}
    },
    projectPrice: {
        list: {section: section.sales, title: 'Projektové ceny', path: "/sales/project-prices"},
        create: {section: section.sales, title: 'Nová projektová cena', path: "/sales/create-project-price"}
    },
    invoice: {
        detail: {section: section.sales, title: 'Faktura', path: '/sales/invoice/:id'},
        list: {section: section.sales, title: 'Faktury', path: "/sales/invoices"}
    },
    deal: {
        detail: {section: section.sales, title: 'Registrace obchodního případu', path: '/sales/deal/:id'},
        list: {section: section.sales, title: 'Obchodní případy', path: "/sales/deals"},
        create: {section: section.sales, title: 'Nová registrace obchodního případu', path: "/sales/create-deal"},
        createNewLicence: {section: section.sales, title: 'Nová registrace obchodního případu', path: "/sales/create-deal-new"},
        createRenewLicence: {section: section.sales, title: 'Nová registrace obchodního případu', path: "/sales/create-deal-renew"}
    },
    license: {
        detail: {section: section.license, title: 'Licence', path: '/licenses/view/:id'},
        expiring: {section: section.license, title: 'Expirující vše', path: '/licenses/expiring'},
        expiring90d: {section: section.license, title: 'Expirující projektovky', path: '/licenses/expiring-90-d'},
        expired: {section: section.license, title: 'Expirované licence', path: '/licenses/expired'},
        leaked: {section: section.license, title: 'Zneužité licence', path: '/licenses/leaked'},
        nfr: {section: section.license, title: 'Partnerské NFR', path: '/licenses/nfr'},
        newest: {section: section.license, title: 'Nejnovější licence', path: '/licenses'},
        download: {section: section.license, title: 'Zaslat licence', path: '/licenses/download'}
    },
    profile: {
        settings: {title: "Nastavení profilu", section: "Profil", path: "/profile/reseller"},
        password: {title: "Změna hesla", section: "Profil", path: "/profile/password"},
        certification: {title: "Certifikace", section: "Profil", path: "/profile/certifications"},
        processedCourses: {title: "Výpis elearningových kurzů", section: "Profil", path: "/profile/processed-courses"},
        certificationDownload: {title: "Stažení certifikátu", section: "Profil", path: "/profile/certification-download"},
        certificationRequest: {title: "Zažádat o certifikaci", section: "Profil", path: "/profile/certification-request/:id"},
        orderNfr: {title: "Objednat NFR licenci", section: "Profil", path: "/profile/order-nfr"},
        reports: {title: "Přehledy", section: "Profil", path: "/profile/reports"},
        editSelfContact: {title: "Úprava kontaktu", section: "Profil", path: "/profile/edit-contact"},
        editContact: {title: "Úprava kontaktu", section: "Profil", path: "/profile/edit-contact/:id"},
        createContact: {title: "Vytvoření kontaktu", section: "Profil", path: "/profile/create-contact"},
        contacts: {title: "Osoby", section: "Profil", path: "/profile/contacts"},
        nfrOrder: {section: section.sales, title: 'NFR pro vlastní potřebu', path: '/profile/order-nfr'},
    },
    partnerstvi: {
        resellerPlan: {title: "Individuální plán", section: "Partnerství", path: "/partnerstvi/reseller-plan", atIndex: 2},
    },
    downloads: {title: "Ke stažení", section: "Profil", path: "/downloads"},
    marketingEshop: {title: "Reklamní materiály", section: "Prodej", path: "/p/prodejni-nastroje/objednavka-all"},
    marketingEshopOrdered: {title: "Reklamní materiály", section: "Prodej", path: "/p/prodejni-nastroje/objednavka-all-objednano"},
    nfrOrder: {section: section.sales, title: 'NFR pro vlastní potřebu', path: '/sales/order-nfr'},
    boxEshop: {section: section.sales, title: 'Objednávka krabic', path: '/sales/krabice'},
    boxEshopOrdered: {section: section.sales, title: 'Objednávka krabic', path: '/sales/krabice-objednany'},
    contacts: {title: "Kontakty", path: "/contact"},
    article: {
        detail: {path: '/article/:id'},
        old: {path: '/homepage/rss-detail/:id'},
    },
    cms: {path: '/c-m-s'},
    page: {path: '/p/:category/:page'}
};

export default navigation;