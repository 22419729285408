import React from "react";
import {Form} from "@mouseover/react-form";

const CertificationBranch = ({item, form}) => (
    <div className="radio">
        <label>
            <input type="radio" className="i-checks"
                   checked={item.id === form.values.branchId}
                   onChange={() => form.setValue(item.id, 'branchId')}
            /> {item.name}
        </label>
    </div>
);

const CertificationRequestForm = ({certificationBranches, contactId, onSubmit}) => (<Form
    values={{contactId: contactId, branchId: null}}
    validationRules={{}}
    onSubmit={onSubmit}
    render={(form) => certificationBranches.map((item, index) => <CertificationBranch key={index} item={item} form={form}/>)}
>
    <button type='submit' className="btn btn-primary block m-b">Zažádat</button>
</Form>);

export default CertificationRequestForm;