import {getOnPath} from "@mouseover/js-utils/src";
import stringifyObjectKeyAndValues from "@fik/utils/stringifyObjectKeyAndValues";

export const selectApiState = (storeName, resource, params, method = 'get') => selectResourceState(storeName, resource, params, method)((state) => {
    return [
        getLoading(state),
        getLoaded(state),
        getResults(state),
        getLastUpdateTime(state)
    ]
});

const defaultState = {isFetching: false, isSuccess: false, isError: false, results: null};

export const selectSimpleResourceState = (storeName, resource) => (selector) => state => selector(getStoreResourceState(storeName, resource)(state));

export const selectResourceState = (storeName, resource, params, method = 'get') => (selector) => state => {
    const selectedState = getParamsState(
        params,
        getMethodState(method, getStoreResourceState(storeName, resource, params, method)(state))
    );
    return selector(selectedState || defaultState);
};

export const getParamsState = (params, state) => {
    if (params) {
        const key = stringifyObjectKeyAndValues(params || {});
        return state && state[key] ? state[key]: null;
    }
    return state;
};

export const getMethodState = (method, state) => {
    return state && state[method] ? state[method]: null;
};

export const getStoreResourceState = (storeName, resource) => (state) => getOnPath(state, [storeName, resource]);

export const getLoading = (state) => {
    return state.isFetching;
};

export const getLoaded = (state) => {
    return state.isSuccess;
};

export const getFailed = (state) => {
    return state.isFailed;
};

export const getResults = (state) => {
    return state.results;
};

export const getRefreshing = (state) => {
    return state.isRefreshing;
};

export const getLastUpdateTime = (state) => {
    return state.lastUpdateTime;
};