import React from 'react';
import PropTypes from 'prop-types';
import {FormField} from "@mouseover/react-form";

const AddressFields = ({form}) => (<>
    <FormField form={form}
               name='street'
               label='Ulice'
               elementType='input'
    />
    <FormField form={form}
               name='city'
               label='Město'
               elementType='input'
    />
    <FormField form={form}
               name='zipCode'
               label='PSČ'
               elementType='input'
    />
</>);

AddressFields.propTypes = {
    form: PropTypes.object
};

export default AddressFields;