import React from "react";

import {DownloadLink, Paginator} from "@fik/ui";
import {Table} from "@fik/table";
import {DateTime} from "@fik/ui";

const columns = [
    {
        key: 'type',
        title: 'Typ certifikace',
        render: ({row: {course}}) => (course ? <strong style={{color: course.actual ? '#333333' : 'red'}}>{course.name}</strong> : '-')
    },
    {
        key: 'contactName',
        title: 'Jméno',
        render: ({row: {contactName}}) => contactName
    },
    {
        key: 'endOn',
        title: 'Dokončení testu',
        render: ({row: {endOn}}) => <DateTime date={endOn}/>
    },
    {
        key: 'points',
        title: 'Body',
        render: ({row: {actualPoints, maximumPoints}}) => <>{actualPoints}/{maximumPoints}</>
    },
    {
        key: 'verdictPercent',
        title: 'Dosažených procent',
        render: ({row: {verdictPercent}}) => <>{verdictPercent} %</>
    },
    {
        key: 'verdict',
        title: 'Splněno',
        render: ({row: {verdict}}) => <span className={"label " + (verdict ? "label-success" : "label-danger")}>{verdict ? 'ANO' : 'NE'}</span>
    },
    {
        key: 'pdf',
        title: 'PDF',
        render: ({row: {verdict, id}}) => verdict
            ? <DownloadLink  resource='certification-pdf'
                             params={{certTestVerdictId:id}}
                             className='btn btn-success btn-xs'><i className="fa fa-file-pdf-o"/></DownloadLink>
            : null
    }
];

const CertificationList = ({items = [], pagination = {}, onPageChange}) => (<>
    <Table striped responsive
           columns={columns}
           rows={items}
           primary='id'/>

    <Paginator {...pagination}
               onPageChange={onPageChange}/>
</>);

export default CertificationList;