import React from 'react';
import logo from '@fik/inspinia/dist/img/eset-logo-67x26.png';
import {NavigationItem} from "@fik/navigation";
import handleClick from "@fik/utils/handleClick";

const Toolbar = ({username, userId, drawerToggleClicked}) => (
    <nav className="navbar navbar-fixed-top white-bg" style={{'marginBottom': 0}}>
        <NavigationItem className="navbar-brand" to="homepage">
            <img title="ESET :: Partnerská zóna"
                 alt="Logo ESET software"
                 width="67"
                 height="26"
                 src={logo}/><span>Partnerská zóna</span>
        </NavigationItem>

        <div className="navbar-header">
            <button
                className="navbar-minimalize minimalize-styl-2 btn btn-primary visible-xs"
                onClick={handleClick(drawerToggleClicked)}>
                <i className="fa fa-bars"/></button>
        </div>

        <ul className="nav navbar-top-links navbar-right">
            <li>
                <NavigationItem className="header-profile-name" to="profile:editContact" params={{id: userId}}>
                    <i className="fa fa-user"/><strong className="hidden-xs">{username}</strong>
                </NavigationItem>
            </li>
            <li>
                <NavigationItem to="signOut">
                    <i className="fa fa-sign-out"/> <span className="hidden-xs">Odhlásit se</span>
                </NavigationItem>
            </li>
        </ul>
    </nav>
);

export default Toolbar;