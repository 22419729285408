import React from 'react';
import PropTypes from 'prop-types';

import {Form, FormField} from "@mouseover/react-form";
import {AddressFields, ContactFields} from "@fik/components";

const validationRules= {

};

const ShipmentForm = ({values, onValuesChange}) => {
    return <Form
        values={values}
        validationRules={validationRules}
        onChange={onValuesChange}
        render={(form =>
            <>
                <div className="row">
                    <div className="col-lg-6">
                        <h3>Dodací adresa</h3>
                        <FormField form={form}
                                   name='name'
                                   label='Název'
                                   elementType='input'
                        />
                        <AddressFields form={form}/>
                    </div>
                    <div className="col-lg-6">
                        <h3>Kontaktní osoba</h3>
                        <ContactFields form={form}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <FormField form={form}
                                   name='note'
                                   label='Poznámka'
                                   elementType='textarea'
                        />
                    </div>
                </div>
            </>
    )}>
    </Form>
};

ShipmentForm.propTypes = {
    values: PropTypes.object,
    onValuesChange: PropTypes.func
};

export default ShipmentForm;