import React from "react";
import {SelectInput} from "@fik/form";

const BranchOfficeSelector = ({name, branchOffices, form}) => {
    return <SelectInput label="Pobočka" name={name} form={form}
                        options={[{value: '', label: '---'}, ...(branchOffices ? branchOffices.map((item) => ({
                            label: item.name,
                            value: item.branchOfficeId
                        })) : [])]}/>
}

export default BranchOfficeSelector;