import React from "react";
import {Date} from "@fik/ui";
import PropTypes from "prop-types";

const EvidenceItemName = ({itemCode, itemName, itemLicenseIdCodes, itemRansomExpirationDate, itemExpirationDate, itemExtraDays}) => <>
    <div>{itemCode} {itemName}</div>
    {itemLicenseIdCodes ? (<div>{itemLicenseIdCodes}</div>) : null}
    {itemRansomExpirationDate ? (<div>ESET výkupné do: <Date date={itemRansomExpirationDate}/></div>) : null}
    {itemExpirationDate ? (<div>Datum expirace: <Date date={itemExpirationDate}/></div>) : null}
    {itemExtraDays ? (<div>Dny navíc: {itemExtraDays}</div>) : null}
</>;

EvidenceItemName.propTypes = {
    itemCode: PropTypes.string,
    itemLicenseIdCodes: PropTypes.string,
    itemRansomExpirationDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    itemExpirationDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    itemExtraDays: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default EvidenceItemName;
