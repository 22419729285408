import React, {useCallback, useMemo} from "react";
import PropTypes from 'prop-types';
import {useDocumentContext} from "@fik/document-edit";
import {Form} from "@mouseover/react-form";
import {DatePicker, SelectInput, TextArea} from "@fik/form";
import {shallowEqual} from "@mouseover/js-utils";


const DealFormDetail = ({contacts, phases, propabilities, validation, isSubmitted, children}) => {
    const {
        document,
        initialDocument: {dateExpected, partner, phaseId, successProbability},
        changeValues,
    } = useDocumentContext();

    const handleValuesChanges = useCallback((values, valid) => {
        const {dateExpected,partner,phaseId,successProbability,note} = document;
        if (!shallowEqual({dateExpected,partner,phaseId,successProbability,}, values)) {
            changeValues(values);
        }
    }, [document, changeValues]);

    const validationRules = useMemo(() => ({
        dateExpected: validation.dateExpected,
        phaseId: validation.phaseId,
        successProbability: validation.successProbability,
    }), [validation]);

    return document
        ? <Form
            values={{dateExpected, partner, phaseId, successProbability}}
            validationRules={validationRules}
            onChange={handleValuesChanges}
            isSubmitted={isSubmitted}
            render={(form) => <div className="row form">
                <div className="col-sm-4">
                    <DatePicker label="Očekávaný termín realizace" name="dateExpected" form={form}/>
                    <SelectInput label="Obchodník" name={['partner', 'contact', 'contactId']} form={form}
                                 options={[{value: '', label: '---'}, ...(contacts ? contacts.map((item) => ({
                                label: item.name,
                                value: item.id
                            })) : [])]}/>
                </div>
                <div className="col-sm-8">
                    <div className="row">
                        <div className="col-sm-7">
                            <SelectInput label="Fáze projektu" name="phaseId" valueType="number" form={form}
                                         options={[{value: '', label: '---'}, ...(phases ? phases.map((item) => ({
                                        label: item.name,
                                        value: item.id
                                    })) : [])]}/>
                        </div>
                        <div className="col-sm-5">
                            <SelectInput label="Pravděpodobnost úspěchu" name="successProbability" valueType="number" form={form} options={[{
                                value: '',
                                label: '---'
                            }, ...(propabilities ? propabilities.map((item) => ({label: item.label, value: item.value})) : [])]}/>
                        </div>
                    </div>
                    {children}
                </div>

            </div>}/>
        : null;
};

DealFormDetail.propTypes = {
    propabilities: PropTypes.array,
    phases: PropTypes.array,
    contacts: PropTypes.array
};

export default DealFormDetail;