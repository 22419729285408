import React, {useCallback} from 'react';
import Box from "@fik/ui/Box/Box";
import {TextArea} from "@fik/form";
import {Form} from "@mouseover/react-form";
import {useEntityAction} from "@fik/entity";
import {Col, Row} from "@fik/ui";

const Consignment = () => {
    const [{result}, save] = useEntityAction({name: 'resellerProfile', action: 'consignment'});
    const handleSubmit = useCallback((values) => {
        save(values);
    }, [save]);

    return <Row>
        <Col sm={12} md={6}>
            <Box title='Komisní prodej'>
                <Form
                    onSubmit={handleSubmit}
                    render={(form =>
                            <TextArea form={form}
                                      name='serialNumbers'
                                      label='Vložte sériová čísla krabic, které byly prodány zákazníkům'
                                      elementConfig={{
                                          cols: 10,
                                          rows: 10
                                      }}
                            />
                    )}>
                    <button type='submit' className='btn btn-primary block m-b'>Uložit</button>
                </Form>
            </Box>
        </Col>
        {result
            ? <Col sm={12} md={6}>
                <Box title='Výsledek'>
                    <div className="table-responsive">
                        <table className="table" id="test_u7z5f">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Sériové číslo</th>
                                <th>Stav</th>
                            </tr>
                            </thead>
                            <tbody id="test_l2w5k">
                            {result.map(({sn, status}, index) => (<tr key={index}>
                                <td>{index+1}</td>
                                <td>{sn}</td>
                                <td>{status}</td>
                            </tr>))}
                            </tbody>
                        </table>
                    </div>
                </Box>
            </Col>
            : null}
    </Row>;
};

export default Consignment;