import {Date} from "@fik/ui";
import React from "react";
import {ToggleRowDetailButton} from "@fik/table";
import OfferLink from "./OfferLink";
import {OpportunityId} from "@fik/opportunity";
import {DocumentStatus} from "@fik/document";
import OfferSettingsDropdown from "./OfferSettingsDropdown";
import OfferPreviewPdf from "./OfferPreviewPdf";
import OfferSend from "./OfferSend";
import OfferCancelButton from "./OfferCancelButton";

export {default as OfferDocument} from "./OfferDocument";
export {default as OfferLink} from "./OfferLink";
export {default as OfferNewVariant} from "./OfferNewVariant";
export {default as OffersFilter} from "./OffersFilter";
export {default as OffersList} from "./OffersList";
export {default as OffersTable} from "./OffersTable";
export {default as OffersTableRowDetail} from "./OffersTableRowDetail";
export {default as OfferTogglePrimary} from "./OfferTogglePrimary";

export const OfferExpirationDate = ({expirationDate}) => <Date date={expirationDate}/>;
export const OfferTodayDate = ({todayDate}) => <Date date={todayDate}/>;
export const OfferViewMenu = ({id, primaryValue, isVisibleRow, toggleRow}) => <div className='btn-group btn-group-sm pull-right'>
    <ToggleRowDetailButton className="btn btn-default"
                           isVisibleRow={isVisibleRow}
                           primaryValue={primaryValue}
                           toggleRow={toggleRow}/>
    <OfferLink className="btn btn-default" id={id}>Zobrazit</OfferLink>
</div>;

export const OfferActionsMenu = ({id, actions, isResellerDocument, primaryValue, isVisibleRow, toggleRow}) => <>
    <div className='btn-group btn-group-xs text-left'>
        <ToggleRowDetailButton className="btn btn-default"
                               isVisibleRow={isVisibleRow}
                               primaryValue={primaryValue}
                               toggleRow={toggleRow}/>
    </div>
    <OfferSettingsDropdown id={id}/>
    <OfferPreviewPdf id={id} isResellerDocument={isResellerDocument}/>
    <OfferSend id={id} isResellerDocument={isResellerDocument}/>
    <div>
        {!actions.edit ? <OfferLink title="Detail" className="btn btn-default" id={id}><i className="fa fa-eye"/></OfferLink> : null}
        {actions.edit ? <OfferLink title="Upravit" className="btn btn-primary" id={id}><i className="fa fa-edit"/></OfferLink> : null}
        {actions.cancel ? <OfferCancelButton className="btn btn-danger" id={id} title="Storno"><i className="fa fa-trash-o"/></OfferCancelButton> : null}
    </div>
</>;

export const OfferLinkWithOpportunity = ({id, code, opportunityId}) => <>
    <div><OfferLink id={id} code={code}/></div>
    {opportunityId ? <div><OpportunityId id={opportunityId} code={opportunityId.replace('code:', '')}/></div> : null}
</>;

export const OfferStatus = ({businessStatusLabelStyle, businessStatus}) => <div className={'label label-' + businessStatusLabelStyle}>{businessStatus}</div>

