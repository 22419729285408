import {createActionNames} from "./createActionNames";
import stringifyObjectKeyAndValues from "@fik/utils/stringifyObjectKeyAndValues";
import {getOnPath, withChangeOnPath} from "@mouseover/js-utils";

const setLastUpdate = (state) => ({
    ...state,
    lastUpdateTime: Date.now()
});

const setResult = (state, action, newState) => ({
    ...state,
    ...newState
});

const defaultState = {
    isFetching: false,
    isSuccess: false,
    isFailed: false,
    isRefreshing: false,
    lastUpdateTime: null,
    results: null
};

const resetResultIfNeeded = (state, reset, initialResultState = null) => {
    if (reset) {
        return {
            ...state,
            results: initialResultState
        }
    } else {
        return state;
    }
};

const getPath = (action) => {
    const method = action.method || 'get';
    const key = stringifyObjectKeyAndValues(action.params || {});
    return [method, key];
};

const emptyState = {};

export const responseReducer = (types, resetResult = true, initialResultState = null) => {
    const {FETCH_INIT, FETCH_SUCCESS, FETCH_FAILURE} = types;
    const typesArray = Object.values(types);
    const initialState = {...defaultState, results: initialResultState};

    return (state = emptyState, action) => {
        if (typesArray.indexOf(action.type) === -1) {
            return state;
        }

        const path = getPath(action);
        const currentState = getOnPath(state, path) || initialState;

        switch (action.type) {
            case FETCH_INIT:
                const res = withChangeOnPath(
                    state,
                    path,
                    setResult(
                        resetResultIfNeeded(setLastUpdate(currentState, action), !(currentState && currentState.isSuccess) && resetResult, initialResultState),
                        action,
                        {
                            isRefreshing: currentState && currentState.isSuccess,
                            isFetching: true,
                            isSuccess: false,
                            isFailed: false,
                            lastUpdateTime: Date.now(),
                        }));

                return res;
            case FETCH_SUCCESS:
                return withChangeOnPath(
                    state,
                    path,
                    setResult(
                        setLastUpdate(currentState, action),
                        action,
                        {
                            isFetching: false,
                            isSuccess: true,
                            isFailed: false,
                            isRefreshing: false,
                            lastUpdateTime: Date.now(),
                            results: action.payload
                        }));

            case FETCH_FAILURE:
                return withChangeOnPath(
                    state,
                    path,
                    setResult(
                        setLastUpdate(currentState, action),
                        action,
                        {
                            isFetching: false,
                            isSuccess: false,
                            isFailed: true,
                            isRefreshing: false,
                            lastUpdateTime: Date.now(),
                            results: initialResultState
                        }));

            default:
                return state;
        }
    }
};

export const createReducer = (resource, reducer) => ({[resource]: reducer});

export const createResponseReducer = (storeName, resource = null, initialResultsState = null, resetResult = true) => {
    const actionNames = createActionNames([storeName, resource]);
    return createReducer(resource, responseReducer(actionNames, resetResult, initialResultsState));
};