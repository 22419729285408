import * as actionTypes from './actionTypes';

export const authInit = (credentials) => {
    return {
        type: actionTypes.AUTH_INIT,
        payload: {
            error: false,
            loading: true,
            credentials
        },
        actions: [authSuccess, checkOfTimeout, authFailed]
    }
};
export const authSuccess = (payload) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        payload: {
            ...payload,
            error: false,
            loading: false
        }
    }
};
export const authFailed = (error) => {
    return {
        type: actionTypes.AUTH_FAILED,
        payload: {
            error: error,
            loading: false
        }
    }
};

export const authenticate = (credentials) => {
    return dispatch => {
        const authData = {
            ...credentials,
            returnSecureToken: true
        };

        dispatch(authInit(authData));
    }
};

export const logOut = () => {
    return {
        type: actionTypes.AUTH_LOGOUT
    }
};

export const checkOfTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logOut());
        }, expirationTime * 1000)
    }
};

export const setRedirectPath = (path) => {
    return {
        type: actionTypes.AUTH_SET_REDIRECT_PATH,
        payload: {
            path: path
        }
    };
};

export const checkState = () => {
    return {
        type: actionTypes.AUTH_CHECK,
        actions: [logOut, authSuccess, checkOfTimeout]
    };
};