import {useEffect} from 'react';
import {createActionNames, createRequestState} from "@fik/api";
import wrapAsyncCallForDispatch from "@fik/utils/wrapAsyncCallForDispatch";
import {useModel} from "@fik/model";
import {useReducerWithThunk} from "@fik/utils/useReducerWithThunk";

const articleActions = createActionNames('cmsMenu');


const pageReducer = (state, action) => {
    switch (action.type) {
        case articleActions.FETCH_INIT:
            return {
                ...state,
                result: null,
                ...createRequestState(articleActions, action.type),
            };
        case articleActions.FETCH_SUCCESS:
            return {
                ...state,
                result: action.payload,
                ...createRequestState(articleActions, action.type),
            };
        case articleActions.FETCH_FAILURE:
            return {
                ...state,
                result: null,
                ...createRequestState(articleActions, action.type),
            };
        default:
            return state;
    }
};

const useCmsArticle = (id) => {
    const load = useModel(
        (model) =>  wrapAsyncCallForDispatch(model.cms().getArticle)(articleActions),
        []
    );

    const [state, dispatch] = useReducerWithThunk(pageReducer, {
            isFetching: false,
            isSuccess: false,
            isFailed: false,
            result: null
        }
    );

    useEffect(() => {
        dispatch(load(id));
    }, [load, dispatch, id]);

    return state;
};

export default useCmsArticle;