import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import thunk from "redux-thunk";

import authReducer from '@fik/auth/reducer';
import {authMiddleware} from "@fik/auth/authMiddleware";
import createResourcesReducers, {TYPE_DATASOURCE, TYPE_ENTITY} from "@fik/model/createResourcesReducers";
import rootReducer from "@fik/auth/rootReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const prepareStore = model => createStore(
    rootReducer( combineReducers({
            auth: authReducer,
            ...createResourcesReducers(
                model,
                {
                    'contact': [TYPE_ENTITY],
                    'branchOffice': [TYPE_ENTITY],
                    'customer': [TYPE_ENTITY],
                    'product': [TYPE_ENTITY],
                    'deal': [TYPE_DATASOURCE, TYPE_ENTITY],
                    'invoice': [TYPE_DATASOURCE, TYPE_ENTITY],
                    'license': [TYPE_DATASOURCE, TYPE_ENTITY],
                    'offer': [TYPE_DATASOURCE, TYPE_ENTITY],
                    'order': [TYPE_DATASOURCE, TYPE_ENTITY],
                    'loyalties': [TYPE_ENTITY],
                    'profile': [{
                        type: TYPE_ENTITY, initialState: {
                            addressBook: null,
                            branchOffice: null,
                            categoryInfo: null,
                            privilegeStatus: null,
                            turnovers: null,
                            certification: null,
                            staff: null,
                            stats: null,
                            loyalties: null
                        }
                    }],
                    'resellerProfile': [TYPE_ENTITY, {type: TYPE_DATASOURCE, resource: 'certification-data-source'}],
                    'resellerPlan': [TYPE_ENTITY],
                    'productTree': [TYPE_ENTITY],
                    'projectPrice': [TYPE_DATASOURCE, TYPE_ENTITY],
                    'identity': [TYPE_ENTITY],
                    'processedCourse': [TYPE_DATASOURCE],
                    'esetEvidenceItem': [TYPE_ENTITY],
                }
            )
        }
    )),
    composeEnhancers(applyMiddleware(authMiddleware(model), thunk))
);

export default prepareStore;
