import {useCallback, useContext} from "react";
import {ModelContext, useModel, useResourceContext} from "@fik/model";
import {createActionNames, createRequestState} from "@fik/api";
import {useReducerWithThunk} from "@fik/utils/useReducerWithThunk";
import wrapAsyncCallForDispatch from "@fik/utils/wrapAsyncCallForDispatch";
import {useHookToLoading} from "@fik/loading";

const actionNames = createActionNames('useEntityAction');

const entityActionReducer = (state, action) => {
    switch (action.type) {
        case actionNames.FETCH_INIT:
            return {
                ...state,
                result: null,
                error: null,
                ...createRequestState(actionNames, action.type),
            };
        case actionNames.FETCH_SUCCESS:
            return {
                ...state,
                result: action.payload,
                ...createRequestState(actionNames, action.type),
            };
        case actionNames.FETCH_FAILURE:
            return {
                ...state,
                result: null,
                error: action.error,
                ...createRequestState(actionNames, action.type),
            };
        case actionNames.FETCH_RESET:
            return {
                ...state,
                result: null,
                error: null,
                ...createRequestState(actionNames, action.type),
            };
        default:
            return state;
    }
};
export const useEntityAction = ({name, action, params: defaultParams, validate}) => {
    const model = useContext(ModelContext);

    const wrappedModelActionCall = useModel(
        (model) => wrapAsyncCallForDispatch(model.entity().action)(actionNames),
        []
    );

    const [state, dispatch] = useReducerWithThunk(entityActionReducer, {
            isFetching: false,
            isSuccess: false,
            isFailed: false,
            result: null,
            error: null
        }
    );

    const context = useResourceContext(name);
    const request = useCallback((params) => {
        dispatch(wrappedModelActionCall(
            {entity: name, ...context},
            action,
            defaultParams ? {...defaultParams, ...params} : params
        ));
    }, [dispatch, wrappedModelActionCall]);

    const reset = useCallback(() => {
        dispatch({type: actionNames.FETCH_RESET});
    },[dispatch]);

    useHookToLoading(name, state);

    return [state, request, reset];
};

export default useEntityAction;