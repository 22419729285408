import {createAuthStorage} from "@fik/auth/authStorage";

const tokenCheck = (authStorage) => {
    const token = authStorage.getAccessToken();

    if (!token) {
        return false
    }

    const expireAt = new Date(authStorage.getExpireAt());
    const expiresIn = (expireAt.getTime() - (new Date()).getTime()) / 1000; // we need amount of seconds

    return expiresIn > 0;
};

const createAuthenticationModel = (storage, adapter) => {
    const authStorage = createAuthStorage(storage);
    return {
        authStorage,
        authenticate: (credentials) => adapter().authenticate(credentials).then((response) => {
            authStorage.persistAuthResponse(response);
            return response;
        }),
        getCredentials: () => {
            const expireAt = new Date(authStorage.getExpireAt());
            return {
                username: authStorage.getUserName(),
                userId: authStorage.getUserId(),
                token: authStorage.getAccessToken(),
                expireAt: authStorage.getExpireAt(),
                expiresIn: (expireAt.getTime() - (new Date()).getTime()) / 1000
            };
        },
        authTokenCheck: () => tokenCheck(authStorage),
        logOut: authStorage.clearStorage,
        forgotPassword:  adapter().forgotPassword,
        validateResetPassword: adapter().validateResetPassword,
        resetPassword: adapter().resetPassword,
        setAuthorizationToken: (token) => adapter().setAuthorizationToken(token)
    }
};

export default createAuthenticationModel;