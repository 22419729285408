import React from 'react';
import PropTypes from 'prop-types';

const LoadingInfo = ({show, children, ...props}) => show ? <div {...props}>{children}</div> : null;

LoadingInfo.propTypes = {
    className: PropTypes.string,
    show: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.array,PropTypes.object,PropTypes.string])
};

export default LoadingInfo;