import parseQueryString from "@fik/utils/parseQueryString";

const createLoginUrl = (nonce, {state, url, response_type, clientId, redirectUrl, scope}) => {
    return new Promise((resolve, reject) => {
        resolve(
            url
            + "?response_type="
            + response_type
            + "&client_id="
            + encodeURIComponent(clientId)
            + "&state="
            + encodeURIComponent(state ? nonce + ";" + state : nonce)
            + "&redirect_uri="
            + encodeURIComponent(redirectUrl)
            + "&scope="
            + encodeURIComponent(scope)
        );
    });
};

const getFragment = () => {
    if (window.location.hash.indexOf("#") === 0) {
        return parseQueryString(window.location.hash.substr(1));
    } else {
        return {};
    }
};


const checkoutHash = (savedNonce) => {
    // parsnem s url vse za hashem #access_token=xxx,state=xxx,expires_in=xxx
    const {access_token, state, expires_in, refresh_token, username, user_id} = getFragment();

    // pokud neni access_token a state tak to neni navratove url
    if (!access_token || !state) return null;

    // porovname - nechceme aby se dalo prihlasit bez nonce
    const stateParts = state.split(';');
    const [nonceInState] = stateParts;
    if (savedNonce === nonceInState) {
        return {
            token: access_token,
            refreshToken: refresh_token,
            state,
            expiresIn: expires_in,
            username,
            userId: user_id
        };
    }

    return null;
};

export const createRedirectAuthenticator = (authentication, {url, response_type = 'token', clientId, scope, redirectUrl, userUrl}) => {

    const initImplicitFlow = (additionalState) => {
        authentication.authStorage.createNonce()
            .then((nonce) => {
                return createLoginUrl(nonce, {state: additionalState, url, response_type, clientId, scope, redirectUrl})
                    .then((url) => {
                        window.location.href = url;
                    });
            });
    };

    return (additionalState = "") => {

        // mame validni access_token netreba niceho
        if (authentication.authTokenCheck()) {
            authentication.setAuthorizationToken(authentication.authStorage.getAccessToken());
            return true;
        }

        // zkontrolujeme jestli je v url hash cast vracena s sso
        const validHashParams = checkoutHash(authentication.authStorage.getNonce());

        //pokud ano
        if (validHashParams) {
            // odstranime hash cast v url
            window.location.hash = '';

            // ulozime v session
            authentication.authStorage.persistAuthResponse(validHashParams);
            authentication.setAuthorizationToken(authentication.authStorage.getAccessToken());
            return true;
        } else {
            // neni a tim padem musime prejit na prihlaseni
            initImplicitFlow(additionalState);
            return false;
        }
    };
};

