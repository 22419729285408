import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";

import App from './App';
import * as serviceWorker from './serviceWorker';

import "bootstrap/dist/css/bootstrap.min.css";
import 'font-awesome/css/font-awesome.css';
import '@fik/inspinia/dist/css/style.css';
import '@fik/inspinia/dist/css/datepicker.css';

import prepareStore from './store';

import createResellerZoneModel from "./resellerzone";
import {ModelProvider} from "@fik/model";
import {AuthIdentityProvider} from "@fik/auth";
import ResellerzoneNavigationProvider from "./ResellerzoneNavigationProvider";
import {LoadingProvider} from "@fik/loading";
import ResellerzonePageProvider from "./components/ResellerzonePageProvider";

import * as adapters from "./resellerzone/adapter";
import models from "./resellerzone/models";
import {AppStorageProvider} from "@fik/storage";
import moment from "moment";
import "./locale/cs";
import {createRedirectAuthenticator} from "@fik/auth/redirectAuthenticator";

const model = createResellerZoneModel({baseURL: process.env.REACT_APP_API_URL, adapters, models});
const store = prepareStore(model);
moment.locale('cs');

const resellerAuthenticate = createRedirectAuthenticator(model.authentication(), {
    clientId: '00c48e24-f0ce-5f40-b2f1-94e9302718a2',
    redirectUrl: window.location.origin,
    scope: 'fik',
    url: process.env.REACT_APP_SSO_URL + '/sign'
});

ReactDOM.render(
    <>
    <ModelProvider model={model}>
        <Provider store={store}>
            <AppStorageProvider storage={window.sessionStorage}>
                <LoadingProvider>
                    <AuthIdentityProvider>
                        <ResellerzoneNavigationProvider navigation={model.navigation()} menu={model.menu}>
                            <ResellerzonePageProvider>
                                <App/>
                            </ResellerzonePageProvider>
                        </ResellerzoneNavigationProvider>
                    </AuthIdentityProvider>
                </LoadingProvider>
            </AppStorageProvider>
        </Provider>
    </ModelProvider>
    </>,
    document.getElementById('wrapper')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();