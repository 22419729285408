import React from "react";
import PropTypes from "prop-types";

const EvidenceItemBonusCode = ({itemBonusCode}) => <>{itemBonusCode || '--'}</>;

EvidenceItemBonusCode.propTypes = {
    itemBonusCode: PropTypes.string
};

export default EvidenceItemBonusCode;
