import React from 'react';
import ProptTypes from 'prop-types';
import {useCmsArticle, TextPage} from "@fik/cms";
import {DateTime} from "@fik/ui";

const Article = ({id}) => {
    const {result: article} = useCmsArticle(id);

    if (!article){
        return null;
    }

    const {title, date, author, html} = article;

    return <div className="col-lg-6" >
        <div className="ibox float-e-margins">
            <div className="ibox-title">
                <h5>{title}</h5>
            </div>
            <div className="ibox-content">
                <div>
                    <div className="chat-activity-list">
                        <div className="chat-element">
                            <div className="media-body ">
                                <TextPage html={html} />
                                <small className="text-muted"><DateTime date={date}/></small>
                                <small className="pull-right text-navy">{author}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

Article.propTypes = {
    id: ProptTypes.string
};

export default Article;