import React from 'react';
import GridItemYouTube from "../Library/GridItemYouTube";
import GridItemCourse from "../Library/GridItemCourse";

const blockFactory = {
    youtube: (item) => <GridItemYouTube {...item} />,
    course: (item) => <GridItemCourse {...item} />
};


const GridItem = (item) => {
    return blockFactory[item.type](item);
};

export default GridItem;