import React from 'react';
import {OrdersTable} from "@fik/order";
import {FilterBar, Paginator} from "@fik/ui";
import {Box} from "@fik/ui";
import {useDials} from "@fik/dials";
import {useDataSourceWithQueryParams} from "@fik/data-source";
import arrayToDictionary from "@fik/utils/arrayToDictionary";

const DEFAULT_PARAMS = {
    fulltext: null,
    state: 'stavDoklObch.schvaleno'
};

const INITIAL_SEARCH_PARAMS = {};

const Orders = () => {

    const {types, states} = useDials({name: 'order'});
    const {pagination, items, setSearchParams, handlePageChange, queryParams, refresh, handleSearch} = useDataSourceWithQueryParams(
        {
            name: 'order',
            initialSearchParams: INITIAL_SEARCH_PARAMS,
            defaultParams: DEFAULT_PARAMS
        }
    );

    return (
        <Box>
            <OrdersFilter onSubmitSearch={handleSearch}
                          states={states}
            />
            <OrdersTable striped responsive
                         statesDict={arrayToDictionary(states, 'id', 'label')}
                         typesDict={arrayToDictionary(types, 'id', 'label')}
                         rows={items}
            />

            <Paginator {...pagination}
                       onPageChange={handlePageChange}/>
        </Box>
    );
};

export default Orders;

const OrdersFilter = (props) => {
    return (
        <FilterBar onSubmitSearch={props.onSubmitSearch}
                   controls={{
                       fulltext: {
                           elementType: 'input',
                           elementConfig: {
                               type: 'text',
                               placeholder: 'Zadejte název, IČ, e-mail, ...',
                           },
                           value: '',
                           valid: false,
                           touched: false,
                           validation: {
                               minLength: 3,
                           }
                       }
                   }}
        />
    );
};