import React from 'react';
import PropTypes from 'prop-types';
import {Price} from "@fik/ui";

const Cart = ({products, onChangeQuantity, onRemoveProduct, total}) => {

    if (!products) {
        return null;
    }

    return <div className="table-responsive">
        <table className="table shopping-cart-table">
            <thead>
            <tr>
                <th>Název</th>
                <th className="text-right">Jednotková cena [Kč]</th>
                <th className="col-sm-1 text-right">Množství</th>
                <th className="col-sm-1 text-right">Celkem [Kč]</th>
                <th className="text-right"/>
            </tr>
            </thead>
            <tbody>
            {products.map(({productName, productBasePrice, quantity}, index) => (<tr key={index}>
                <td className="desc">
                    <h3>{productName}</h3>
                </td>
                <td className="text-right"><Price price={productBasePrice}/></td>
                <td className="text-right">
                    <input className="form-control" type="number" min="1" placeholder="1" value={quantity}
                           onChange={(event) => onChangeQuantity(index, parseInt(event.target.value))}/>
                </td>
                <td className="text-right text-bold">
                    <Price price={quantity * productBasePrice}/>
                </td>
                <td>
                    <button className="btn btn-md btn-outline btn-danger" title="Odstranit z košíku"
                            onClick={() => onRemoveProduct(index)}><i className="fa fa-remove"/></button>
                </td>
            </tr>))}

            <tr>

                <td colSpan="3" className="text-right">
                    <h4>Celkem:</h4>
                </td>
                <td className="text-right">
                    <h4><Price price={total}/></h4>
                </td>
                <td>&nbsp;</td>
            </tr>
            </tbody>
        </table>
    </div>
};

Cart.propTypes = {
    products: PropTypes.array,
    onChangeQuantity: PropTypes.func,
    onRemoveProduct: PropTypes.func,
    total: PropTypes.number
};

export default Cart;