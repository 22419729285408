import React from 'react';
import {Box, Col, Row} from "@fik/ui";
import ContactList from "../../Contact/ContactList/ContactList";
import {NavigationItem} from "@fik/navigation";

const Contacts = () => (
    <Row>
        <Col lg={12}>
            <Box>
                <ContactList />
            </Box>
            <NavigationItem
                to="profile:createContact"
                className='btn btn-info'><i className="fa fa-plus"/> Přidat nového obchodníka</NavigationItem>
        </Col>
    </Row>
);

export default Contacts;