import React from 'react';
import {NavigationItem} from "@fik/navigation";

const LicenseOfferLink = ({item, children, ...props}) =>
    <NavigationItem {...props}
                    to="offer:create"
                    query={{licenseEPLI: item.licenseEPLI}}>
        {children || "Nabídnout"}
    </NavigationItem>;

export default LicenseOfferLink;