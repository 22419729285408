import React from 'react';
import FooterText from "./FooterText";

const Footer = () => {
    const date = new Date();
    return (
        <div className="footer">
            <div>
               <FooterText />
            </div>
        </div>
    );
};

export default Footer;