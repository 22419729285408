import React from "react";
import ReactDOM from 'react-dom';
import handleClick from "@fik/utils/handleClick";

const Modal = ({visible, hide, children, renderHeader, renderFooter, bodyClassName, dialogClassName, ...props}) => {
    return visible ? ReactDOM.createPortal(<>
        <div className='modal in visible-modal' tabIndex="-1" role="dialog" aria-hidden="true">
            <div className={'modal-dialog'+(dialogClassName ? ' ' + dialogClassName : '') + ' modal-dialog-scrollable'} role="document">
                <div className='modal-content'>
                    {renderHeader ? <div className='modal-header'>{renderHeader()}</div> : null}
                    <div className={'modal-body'+(bodyClassName ? ' ' + bodyClassName : '')}>{children}</div>
                    {renderFooter ? <div className='modal-footer'>{renderFooter()}</div> : null}
                </div>
            </div>
        </div>
        {hide ? <div className="modal-backdrop in" onClick={handleClick(hide)}/> : null}
    </>, document.body) : null;
};

export default Modal;