import React from 'react';
import handleClick from "@fik/utils/handleClick";
import {Errors} from "@fik/ui";
import {EvidenceItems} from "@fik/evidence-item";
import {Box} from "@fik/ui";
import {useDocumentContext} from "../useDocumentContext";

const DocumentEvidenceItems = () => {

    const {
        document,
        validation: {validationMessages},
        evidenceItemEdit,
        removeExitingEvidenceItem,
    } = useDocumentContext();

    const {
        startEditNewEvidenceItem,
        startEditNewRenewEvidenceItem,
        startEditExistingEvidenceItem,
    } = evidenceItemEdit;



    const evidenceItemsMessages = validationMessages.filter(message => message.path.indexOf('evidenceItems') === 0);

    return <Box
        title="Položky"
        tools={() => <>
            <button onClick={handleClick(startEditNewEvidenceItem)} className='btn btn-xs btn-primary m-r-xs'>
                <i className="fa fa-plus"/> Nová licence
            </button>
            <button onClick={handleClick(startEditNewRenewEvidenceItem)} className='btn btn-xs btn-primary'>
                <i className="fa fa-search"/> Existující licence
            </button>
        </>
        }>
        <Errors messages={evidenceItemsMessages.filter(message => message.path.indexOf('evidenceItems') !== 0)}/>
        <EvidenceItems
            evidenceItems={document.evidenceItems}
            itemMenu={(item) => (
                <>
                    <button onClick={handleClick(removeExitingEvidenceItem, item.row)}
                            className='btn btn-xs btn-warning m-r-xs'> Odebrat
                    </button>
                    <button onClick={handleClick(startEditExistingEvidenceItem, item.row)} className='btn btn-xs btn-primary'>Upravit</button>
                </>
            )}
            renderRowMessages={({columns, index}) => {
                const messages = evidenceItemsMessages.filter(message => message.path.indexOf(index) === 1);
                if (messages.length > 0) {
                    return <tr>
                        <td colSpan={columns.length} className="no-padding no-borders">
                            <div className="m-l-lg"><Errors messages={messages}/></div>
                        </td>
                    </tr>
                }
                return null;
            }}
        /> </Box>
};

export default DocumentEvidenceItems;