import {useCallback, useEffect, useRef, useState} from "react";
import {getOnPath} from "@mouseover/js-utils";
import useEventListener from "@fik/utils/useEventListener";

const useDropdown = () => {
    const element = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const toggle = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen, setIsOpen]);

    useEffect(() => {
        if (element.current) {
            const classList = getOnPath(element, ['current', 'parentElement', 'classList']);
            if (classList) {
                classList.add('dropdown');
                if (isOpen) {
                    classList.add('open');
                } else {
                    classList.remove('open');
                }
            }
        }
    }, [isOpen, element]);

    const handleMouseDown = useCallback((event) => {
        if (getOnPath(event, ['target', 'parentElement', 'parentElement']) !== element.current) {
            setIsOpen(false);
        }
        return true;
    }, [setIsOpen, element]);

    useEventListener('mouseup', handleMouseDown, document);

    return {
        element,
        setIsOpen,
        isOpen,
        toggle
    }
}

export default useDropdown;