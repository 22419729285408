import React from 'react';
import {useEntity} from "@fik/entity";
import {NavigationItem} from "@fik/navigation";

const NfrCreditInfo = () => {
    const {
        entity: nfrCredit
    } = useEntity({name: 'resellerProfile', alwaysFetch: true, params: {action: 'nfr-credit'}, timeToRefresh: -1}, null);

    if (nfrCredit) {
        return <div></div>
    }
    return null;

};

export default NfrCreditInfo;