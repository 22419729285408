import {matchPath} from "react-router-dom";

export const navigationToMenuItem = (navigationItem) => ({
    to: navigationItem.path,
    label: navigationItem.title,
    atIndex: navigationItem.atIndex
});

const isMatching = (currentLocation, to) => currentLocation ? matchPath(currentLocation, to) : null;

export const matchActiveContent = (content, path) => content.reduce((acc, item, index) => {
    const content = item.content || item.child;
    if (isMatching(path, item.to) || (content && matchActiveContent(content, path).isActive)) {
        acc.isActive = true;
        acc.activeItemIndex = index;
    }
    return acc;
}, {isActive: false, activeItemIndex: null});