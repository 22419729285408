import {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useRouteMatch} from 'react-router-dom';
import useQueryParams from "./../useQueryParams";

const PageParams = ({children, allowed}) => {
    const routeMatch = useRouteMatch();
    const queryParams = useQueryParams(allowed);

    const props = useMemo(() => ({...(routeMatch.params || {}), ...queryParams}), [queryParams, routeMatch]);

    if (typeof children === "function" ) {
        return children(props);
    } else {
        throw Error('PageParams accept only render function as child!');
    }
};

PageParams.propTypes = {
    children: PropTypes.func,
    allowed: PropTypes.array
};

export default PageParams;