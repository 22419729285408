import React from 'react';

const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

const reportTable = (props) => {
    const data = [];
    for (let key in props.stats) {
        if (props.stats.hasOwnProperty(key)) {
            data.push({year: key, months: props.stats[key]});
        }
    }

    return (
        <table className="table table-hover no-margins table-responsive table-graph">
            <thead>
            <tr>
                <th>&nbsp;</th>
                {months.map(month => (<th key={month}>{month}</th>))}
            </tr>
            </thead>
            <tbody>
            {data.map((row) => (
                <tr key={row.year}>
                    <td>{row.year}</td>
                    {months.map(month => (<td key={row.year + '-' + month}>{props.formatValue(row.months[month]) || '-'}</td>))}
                </tr>
            ))}
            </tbody>
        </table>
    )
};

export default reportTable;