import React, {useState, useCallback, useEffect} from 'react';
import {Box, Col, Row} from "@fik/ui";
import {Calculator, useEvidenceItemsStorage} from "@fik/calculator";
import handleClick from "@fik/utils/handleClick";
import {emptyItem} from "@fik/model/createEvidenceItemModel";
import {NavigationItem} from "@fik/navigation";
import {EvidenceItems} from "@fik/evidence-item";
import {useNavigate} from "@fik/page";
import {Redirect} from "react-router-dom";
import {isEmpty} from "@mouseover/js-utils/src";

const CalculatorAndPriceLists = () => {
    const [calculationData, setCalculationData] = useState(null);

    const handleShowCalculator = useCallback((selectType) => {
        setCalculationData({
            initialSelection: {
                ...emptyItem,
                selectType
            }
        });
    }, [setCalculationData]);

    const [evidenceItem, setEvidenceItem] = useState(null);
    const [orderEvidenceItem, setOrderEvidenceItem] = useState(null);
    const {addEvidenceItem, removeEvidenceItem, clearEvidenceItems, evidenceItems} = useEvidenceItemsStorage();

    useEffect(() => {
        clearEvidenceItems();
    }, [clearEvidenceItems])

    const navigate = useNavigate();

    const handleOrderEvidenceItem = useCallback((item) => {
        if (item) {
            let index = evidenceItems.indexOf(item);
            if  (index < 0) {
                addEvidenceItem(item);
                index = 0;
            }

            setOrderEvidenceItem(index);
        }
    }, [evidenceItems]);

    const handleEvidenceItem = useCallback((evidenceItem) => {
        setEvidenceItem(evidenceItem);
    }, [setEvidenceItem]);

    if (!isEmpty(orderEvidenceItem)) {
        return <Redirect to={'/sales/create-order?evidenceItem=' + orderEvidenceItem} />
    }

    return <>
        <Box title='Kalkulator'>
            <Row>
                <Col lg={4}>
                    <div className="widget style1 navy-bg calc-click-box" onClick={handleClick(handleShowCalculator, 'new')}
                         id="test_2c81w">
                        <div className="row">
                            <div className="col-xs-4">
                                <i className="fa fa-plus fa-5x"/>
                            </div>
                            <div className="col-xs-8 text-right">
                                <span> Vypočítat cenu nové licence </span>
                                <h2 className="font-bold">Nová licence</h2>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg={4}>
                    <div className="widget style1 lazur-bg calc-click-box" onClick={handleClick(handleShowCalculator, 'renew')}
                         id="test_oxmof">
                        <Row>
                            <Col xs={4}>
                                <i className="fa fa-search fa-5x"/>
                            </Col>
                            <Col xs={8} className="text-right">
                                <span> Update, enlarge, upgrade stávající licence </span>
                                <h2 className="font-bold">Existující licence</h2>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col lg={4}>
                    <NavigationItem to='page' params={{category: 'ceniky', page: 'aktualni-ceniky'}}>
                        <div className="widget style1 yellow-bg calc-click-box" id="test_2qhwt">
                            <div className="row">
                                <div className="col-xs-4">
                                    <i className="fa fa-download fa-5x"/>
                                </div>
                                <div className="col-xs-8 text-right">
                                    <span>Ceníky ve formátu PDF a XLS ke stažení</span>
                                    <h2 className="font-bold">Všechny ceníky</h2>
                                </div>
                            </div>
                        </div>
                    </NavigationItem>
                </Col>
            </Row>
            {calculationData ?
                <Calculator onEvidenceItemChange={handleEvidenceItem} calculationData={calculationData}/> : null}
            <Row>
                <Col lg={4} lgOffset={8} className='text-right'>
                    {evidenceItem ? <div className='btn-group'>
                        <button className='btn btn-success' onClick={handleClick(addEvidenceItem, evidenceItem)}>Do porovnání</button>
                        <button className='btn btn-info' onClick={handleClick(handleOrderEvidenceItem, evidenceItem)}>Objednat</button>
                    </div> : null}
                </Col>
            </Row>
        </Box>

        {evidenceItems.length > 0 ?
            <Box
                title='Porovnání'
                tools={() => <button className='btn btn-danger btn-xs' onClick={handleClick(clearEvidenceItems)}>Vyprázdnit</button>}
            >
                <EvidenceItems
                    evidenceItems={evidenceItems}
                    itemMenu={(item) => (
                        <>
                            <button onClick={handleClick(removeEvidenceItem, item.row)}
                                    className='btn btn-xs btn-warning m-r-xs'> Odebrat
                            </button>
                            <button onClick={handleClick(handleOrderEvidenceItem, item.row, item.index)}
                                    className='btn btn-xs btn-primary'>Objednat
                            </button>
                        </>
                    )}
                />
            </Box> : null}
    </>;
};

export default CalculatorAndPriceLists;