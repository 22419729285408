import React, {useCallback, useMemo} from "react";
import {CmsNavigationContext} from "@fik/cms";
import {useSelector} from "react-redux";
import {useNavigate} from "@fik/page";
import {parseUrl, stringify} from "query-string";

const CmsNavigationProvider = (props) => {
    const token = useSelector((state) => state.auth.token, []);
    const navigate = useNavigate();
    const handleNavigationClick = useCallback((event) => {
        let href = event.target.getAttribute('href');
        if (href && !href.startsWith('http')){// && !href.startsWith('/files')) {
            event.preventDefault();

            if (href.startsWith('/lms') || href.startsWith('/files')) {
                const url = parseUrl(href);
                url.query.token = token;
                window.open(url.url + '?' + stringify(url.query), '_blank');
            } else {
                navigate(href);
            }
        }
    }, [token]);
    const cms = useMemo(() => ({onNavigationClick: handleNavigationClick}), [token]);
    return <CmsNavigationContext.Provider value={cms} {...props} />
}

export default CmsNavigationProvider;