import {useEffect} from "react";
import useLoadingContext from "./useLoadingContext";

const useHookToLoading = (resource,loadingState) => {

    const  {
        isFetching,
        isSuccess,
        isFailed,
        isRefreshing
    }= loadingState;

    const {changeLoadingState} = useLoadingContext();

    useEffect(() => {
        if (changeLoadingState) {
            changeLoadingState(resource, {isFetching, isSuccess, isFailed, isRefreshing});
        }
        return () => changeLoadingState && changeLoadingState(resource, {isFetching: false, isSuccess: false, isFailed: false, isRefreshing: false})
    }, [isFetching, isSuccess, isFailed, isRefreshing, changeLoadingState, resource]);
};

export default useHookToLoading;