import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {useFormContainer, Form} from "@mouseover/react-form";
import {ContactFields, AddressFields} from "@fik/components";
import {mergeDeep} from "@mouseover/js-utils";
import handleClick from "@fik/utils/handleClick";
import PropTypes from "prop-types";
import CompanyFields from "./CompanyFields";
import {Col, Row} from "@fik/ui";

const Controls = ({form}) => {
    const contact = useFormContainer('contact', form);
    const addressBook = useFormContainer('addressBook', form);

    useEffect(() => {
        if (contact.values.contactEmail !== addressBook.values.email) {
            addressBook.setValue(contact.values.contactEmail, 'email');
        }
        if (contact.values.contactPhone !== addressBook.values.phone) {
            addressBook.setValue(contact.values.contactPhone, 'phone');
        }
    }, [contact.values, addressBook]);

    return (
        <>
            <div className="row">
                <div className="col-sm-6">
                    <h3>Kontakt</h3>
                    <ContactFields form={contact}/>
                </div>
                <div className="col-sm-6">
                    <h3>Adresa</h3>
                    <AddressFields form={addressBook}/>
                </div>
            </div>
        </>
    )
};

Controls.propTypes = {
    form: PropTypes.object
};

const CustomerCompanyControls = ({form, isCompany}) => {
    const addressBook = useFormContainer('addressBook', form);

    useIsCompany({form, isCompany});

    return form.values && form.values.isCompany ? <>
            <Row>
                <Col lg={12}>
                    <h3>Firma</h3>
                </Col>
            </Row>
            <div className='row'>
                <CompanyFields form={addressBook}/>
            </div>
        </>
        : null;
};

CustomerCompanyControls.propTypes = {
    form: PropTypes.shape({
        values: PropTypes.object
    }),
    isCompany: PropTypes.bool
};

const validationRules = {
    contact: {
        children: {
            contactFirstname: {
                required: true
            },
            contactSurname: {
                required: true
            },
            contactEmail: {
                required: true,
                email: true
            },
            contactPhone: {
                required: false,
                pattern: {
                    message: 'Špatný formát telefonu (+420...)',
                    params: '^(\\+420)[0-9]{9}$'
                }
            }
        }
    },
    addressBook: {
        children: {
            zipCode: {
                required: false,
                minLength: 5,
                maxLength: 5,
                pattern: {
                    message: 'PSČ musí být číslo',
                    params: '([0-9]\s*){5}'
                }
            }
        }
    }
};

const companyValidationRules = mergeDeep(
    validationRules, {
        addressBook: {
            children: {
                name: {
                    required: true
                },
                companyId: {
                    required: true
                },
            }
        }
    });


const useIsCompany = ({form, isCompany}) => {
    const {values, setValue} = form;
    const isCompanyRef = useRef(values.isCompany);

    useEffect(() => {
        isCompanyRef.current = values.isCompany;
    }, [values]);

    useEffect(() => {
        if (isCompanyRef.current !== isCompany) {
            setValue(isCompany, 'isCompany');
        }
    }, [isCompanyRef, setValue, isCompany]);
};

const CustomerForm = ({values, onChange, onSubmit, onReset, isCompany}) => {
    const handleSubmit = useCallback((values, valid) => {
        if (valid) {
            onSubmit(values, valid);
        }
    }, [onSubmit]);

    return (<div>
        <Form
            autoComplete='off'
            values={values}
            validationRules={useMemo(() => isCompany ? companyValidationRules : validationRules, [isCompany])}
            onChange={onChange}
            onSubmit={handleSubmit}
            render={(form) => (<>
                <CustomerCompanyControls form={form} isCompany={isCompany}/>
                <Controls form={form}/>
                <div className='form-group'>
                    <div className='pull-right'>
                        <button onClick={handleClick(onReset)} className="btn btn-danger m-r-sm">Zrušit</button>
                        <button
                            type="submit"
                            className={"btn " + (!form.validation.valid ? "btn-warning" : "btn-success")}
                        >Pokračovat
                        </button>
                    </div>
                    <div className='clearfix'/>
                </div>
            </>)}/>
    </div>)
};
CustomerForm.propTypes = {
    values: PropTypes.object,
    isCompany: PropTypes.bool,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onReset: PropTypes.func,
};

export default CustomerForm;