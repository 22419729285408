import React from 'react';
import FileDownloadLink from "./FileDownload";

const FileBox = ({file, folder}) => {
    const {icon, title, date} = file;

    return <div className="file-box">
        <div className="file">
            <FileDownloadLink path={folder.path +file.file}>
                <span className="corner"/>
                <div className="icon">
                    <i className={"fa " + icon} />
                </div>
                <div className="file-name">
                    {title}
                    <br/>
                    <small>{date}</small>
                </div>
            </FileDownloadLink>
        </div>
    </div>;
};

export default FileBox;