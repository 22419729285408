import React, {useCallback, useEffect} from "react";
import handleClick from "@fik/utils/handleClick";
import useMenuItems from "../useMenuItems";
import {matchActiveContent} from "../menu";

const Menu = ({content = [], className = 'nav', currentPath}) => {
    const [menuItems, activeItem, setActiveItem] = useMenuItems(content);

    const handleItemClick = useCallback((index) => {
        setActiveItem(index);
    }, [setActiveItem]);

    useEffect(() => {
        if (currentPath) {
            const result = matchActiveContent(content, currentPath);
            if (result.activeItemIndex !== null) {
                setActiveItem(result.activeItemIndex);
            }
        }
    }, [content, currentPath, setActiveItem]);

    return (
        <ul className={className}>
            {menuItems.map(({MenuItem, ...itemProps}, index) => (<li key={index} className={activeItem === index ? 'active' : ''}>
                <MenuItem onClick={handleClick(() => handleItemClick(index))} currentPath={currentPath} {...itemProps} isActive={activeItem === index}/>
            </li>))}
        </ul>
    );
};

export default Menu;