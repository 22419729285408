import React from 'react';
import PropTypes from 'prop-types';
import Spinner from "./Spinner";

const LoadingOverlay = ({className, isLoading, children, ...props}) => <div
    className={"loading-overlay " + (className ? ' ' + className : '') + (isLoading ? ' sk-loading' : '')} {...props}>
    {isLoading ? <Spinner/> : null}
    {children}
</div>;

LoadingOverlay.propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.array,PropTypes.object,PropTypes.string])
};

export default LoadingOverlay;