import React from "react";
import PartnerProvider from "../partner/PartnerProvider";
import {DisabledOverlay} from "@fik/ui";
import DocumentPartner from "./DocumentPartner";
import {useDocumentContext} from "../useDocumentContext";

const DocumentPartnerContainer = () => {
    const {
        document,
        view: {enablePartner, handlePartnerViewChange},
        validation: {validationMessages},
        isEpc,
        confirmPartnerChange
    } = useDocumentContext();

    return document && isEpc && <PartnerProvider channel={document.partner} onPartnerChange={confirmPartnerChange} onViewChange={handlePartnerViewChange}>
        <DisabledOverlay on={!enablePartner}>
            <DocumentPartner messages={validationMessages.filter(message => message.path.indexOf('partner') === 0)}/>
        </DisabledOverlay>
    </PartnerProvider>;
}

export default DocumentPartnerContainer;