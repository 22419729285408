import React from 'react';
import Category from "../Library/Category";

const Categories = ({categories, selected, onSelectCategory}) => {
    return <ul className="list-group elements-list">{categories.map((category) =>
        <Category
            category={category.key}
            selected={selected}
            onSelectCategory={onSelectCategory}
            key={category.key} {...category}
        />)}
    </ul>;
};

export default Categories;