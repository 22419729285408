import React, {useCallback} from 'react';
import handleClick from "@fik/utils/handleClick";

const Category = ({category, label, onSelectCategory, selected, sublabel}) => {

    const handleCategoryClick = useCallback(() => onSelectCategory(category), [category, onSelectCategory]);

    return <li className={"list-group-item padding-l-sm" + (selected === category ? ' active' : '')}>
        <a aria-expanded="true" href={'#' + category} onClick={handleClick(handleCategoryClick)}>{label}{sublabel ? <small><br /> {sublabel}</small> : null}</a>
    </li>;

};

export default Category;