import {useCallback, useEffect} from 'react';
import {createActionNames, createRequestState} from "@fik/api";
import wrapAsyncCallForDispatch from "@fik/utils/wrapAsyncCallForDispatch";
import {useModel} from "@fik/model";
import {useReducerWithThunk} from "@fik/utils/useReducerWithThunk";

const feedsActions = createActionNames('cmsFiles');
feedsActions.SELECT_CATEGORY = 'SELECT_CATEGORY';

const feedsReducer = (state, action) => {
    switch (action.type) {
        case feedsActions.FETCH_INIT:
            return {
                ...state,
                ...createRequestState(feedsActions, action.type),
            };
        case feedsActions.FETCH_SUCCESS:
            const feedsState = {tags: [], feeds: {}};

            const feeds = action.payload.reduce((obj, item) => {
                obj.tags.push(item.tag);
                obj.feeds[item.tag.code] = item.items;
                return obj;
            }, feedsState);

            return {
                ...state,
                ...feeds,
                selected: feeds.tags.length > 0 ? 0 : null,
                ...createRequestState(feedsActions, action.type),
            };
        case feedsActions.FETCH_FAILURE:
            return {
                ...state,
                ...createRequestState(feedsActions, action.type),
            };

        case feedsActions.SELECT_CATEGORY:
            return {
                ...state,
               selected: action.index
            };
        default:
            return state;
    }
};

const useCmsArticlesFeeds = (group) => {

    const loadFeeds = useModel(
        (model) => wrapAsyncCallForDispatch(model.cms().getFeeds)(feedsActions),
        []
    );

    const [state, dispatch] = useReducerWithThunk(feedsReducer, {
            isFetching: false,
            isSuccess: false,
            isFailed: false,
            tags: null,
            feeds: null,
            selected: null
        }
    );

    useEffect(() => {
        dispatch(loadFeeds(group));
    }, [loadFeeds, dispatch, group]);

    const selectCategory = useCallback((index) => {
        dispatch({type: feedsActions.SELECT_CATEGORY, index});
    }, [dispatch]);

    return {
        categories: state.tags,
        selected: selectCurrentTag(state),
        articles: selectCurrentArticles(state),
        selectCategory
    };
};

const hasSelectedTag = (state) => state.selected !== null;
const selectCurrentTag = (state) => state.selected !== null ? state.tags[state.selected] : null;

const selectCurrentArticles = (state) => {
    if (hasSelectedTag(state)) {
        return state.feeds[selectCurrentTag(state).code];
    }
    return null;
};



export default useCmsArticlesFeeds;