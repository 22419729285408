import React from 'react';
import {useCmsMenu} from "@fik/cms";
import {useNavigationMenu, Menu} from "@fik/navigation";
import {usePageInfo} from "@fik/page";


const Sidebar = ({visible}) => {
    const sidebarMenu = useNavigationMenu('sideBarMenu');
    const menu = useCmsMenu(sidebarMenu);
    const pageInfo = usePageInfo();

    return (
        <nav className={"navbar-default navbar-static-side menu-opened" + (visible ? ' open' : '')}>
            <div className="sidebar-collapse">
                <Menu content={menu} currentPath={pageInfo.path}/>
            </div>
        </nav>
    );
};

export default Sidebar;