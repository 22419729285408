import React from "react";
import {NavigationItem} from "@fik/navigation";
const ExpiredLicensesInfo = () => <p className='m-t-md'>
    <i className="fa fa-info-circle"/> Tento výpis obsahuje licence, které expirovaly před více než 30 dny a nedošlo k jejich prodloužení. Výpis lze filtrovat i na licence, které expirovaly přibližně před rokem, 2 roky či 3 roky.<br />
    Lze tak teoreticky oslovit bývalé zákazníky, u nichž lze očekávat, že jim bude expirovat konkurenční produkt.<br />
    <i className="fa fa-info-circle"/> Kliknutím na tlačítko <button type="button" className="btn btn-xs btn-default"><span
    className="glyphicon glyphicon-list-alt"/></button> si můžete k licenci zapsat libovolnou poznámku. Pro zobrazení poznámky stačí
    najet myší na zelenou podobu tlačítka: <button type="button" className="btn btn-xs btn-success"><span
    className="glyphicon glyphicon-list-alt"/></button>
</p>;
export default ExpiredLicensesInfo;