import {useCallback, useContext, useEffect, useRef} from "react";
import {useNavigate} from "@fik/page";
import CmsNavigationContext from "./CmsNavigationContext";

const useCmsNavigationRef = ({onContentChange}) => {
    const ref = useRef(null);

    const navigate = useNavigate();
    const cmsNavigationContext = useContext(CmsNavigationContext);

    const handleClick = useCallback((event) => {
        const href = event.target.getAttribute('href');
        if (cmsNavigationContext && cmsNavigationContext.onNavigationClick) {
            cmsNavigationContext.onNavigationClick(event);
        } else if (href && !href.startsWith('http') && !href.startsWith('/files') && !href.startsWith('/lms')) {
            event.preventDefault();
            navigate(href);
        }
    }, [navigate]);

    useEffect(() => {
        const current = ref.current;
        if (current) {
            current.querySelectorAll('a').forEach(a => {
                a.addEventListener('click', handleClick);
            });

            if (onContentChange) {
                onContentChange(current);
            }
        }
        return () => {
            if (current) {
                current.querySelectorAll('a').forEach(a => {
                    a.removeEventListener('click', handleClick);
                });
            }
        }
    }, [handleClick, onContentChange]);

    return ref;
};

export default useCmsNavigationRef;