import React, {useMemo} from 'react';
import {FilterBar} from "@fik/ui";

const LicensesFilter = ({types, onSubmitSearch, statuses, queryParams}) => {
    const typesList = types ? types.map(item => ({value: item.id, label: item.name})) : [];
    const statusesList = statuses ? [{value: '', label: 'Vše'}, ...statuses.map(item => ({value: item.id, label: item.name}))] : [];
    const {query} = queryParams;

    const controls = useMemo(() => {
        const c = {
            fulltext: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Zadejte ID licence, jméno zákazníka, ...',
                },
                value: '',
                valid: false,
                touched: false,
                validation: {
                    minLength: 3,
                }
            },
            type: {
                elementType: 'select',
                options: typesList,
                value: '',
                valid: true,
                touched: false,
            }
        };

        if (query === 'expired') {
            c.ago = {
                elementType: 'select',
                options: [
                    {value: 'all', label: 'všechny'},
                    {value: 'year', label: 'expirovaly přibližně 1 rok dozadu'},
                    {value: 'two_years', label: 'expirovaly přibližně 2 roky dozadu'},
                    {value: 'three_years', label: 'expirovaly přibližně 3 roky dozadu'},
                ],
                value: '',
                valid: true,
                touched: false,
            };
        } else {
            c.status = {
                elementType: 'select',
                    options: statusesList,
                    value: '',
                    valid: true,
                    touched: false,
            };
        }

        return c;
    }, [query, typesList, statusesList]);

    return (
        <FilterBar onSubmitSearch={onSubmitSearch}
                   values={queryParams}
                   controls={controls}
        />
    );
};

export default LicensesFilter;