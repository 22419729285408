import {useOpportunityDials} from "./index";
import {useEffect, useMemo} from "react";
import {useDataSourceWithQueryParams} from "@fik/data-source";

const DEFAULT_PARAMS = {status: null, tags: null, type: null, resellerStaffId: null, tiers: null, staffId: null, source: null, includes: 'counts'};
export const INITIAL_SEARCH_PARAMS = {tags: null, status: null, type: null, staffId: null, resellerStaffId: null, tiers: null, source: null};

const useOpportunities = ({source = null, status = null, template = null, limit = 20, staffId = null, partnerId = null, ownerId = null, epcId = null}) => {

    const dials = useOpportunityDials();

    const {
        load,
        pagination,
        items,
        setSearchParams,
        handlePageChange,
        handleSearch,
        queryParams,
        searchParams,
        includes: {counts},
        loadingState
    } = useDataSourceWithQueryParams({
        name: 'opportunity',
        limit,
        defaultParams: useMemo(() => {
            return ({...DEFAULT_PARAMS})
        }, []),
        initialSearchParams:  useMemo(() => ({...DEFAULT_PARAMS,
            staffId: staffId || null,
            source: source || null,
            status: status || null,
            template: template || null,
            partnerId: partnerId || null,
            ownerId: ownerId || null,
            epcId: epcId || null
        }), [staffId, source, status, template])
    });

    const {statusesDict, typesDict, phaseList} = dials;

    return {
        load,
        dials,
        pagination,
        items,
        statusesDict,
        typesDict,
        phaseList,
        counts,
        handlePageChange,
        handleSearch,
        queryParams,
        searchParams
    }
};

export default useOpportunities;