import React from 'react';
import {Box} from "@fik/ui";
import {OffersList, OffersFilter} from "@fik/offer";
import {useDials} from "@fik/dials";
import {useDataSource, useDataSourceWithQueryParams} from "@fik/data-source";
import arrayToDictionary from "@fik/utils/arrayToDictionary";

const DEFAULT_PARAMS = {
    fulltext: null,
    state: null
};

const ProjectPrices = () => {

    const {types, states} = useDials({name: 'projectPrice'});
    const {pagination, items, setSearchParams, handlePageChange, queryParams, refresh, handleSearch} = useDataSourceWithQueryParams(
        {
            name: 'ProjectPrice',
            initialSearchParams: {type: 'all'},
            defaultParams: DEFAULT_PARAMS
        }
    );

    return (
        <Box>
            <OffersFilter onSubmitSearch={handleSearch}
                          states={states}
            />

            <OffersList
                statesDict={arrayToDictionary(states, 'id', 'label')}
                typesDict={arrayToDictionary(types, 'id', 'label')}
                items={items}

                pagination={pagination}
                onPageChange={handlePageChange}/>

        </Box>
    );
};


export default ProjectPrices;