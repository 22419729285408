const createNonce = () => {

    return new Promise((resolve, reject) => {

        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (let i = 0; i < 40; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        resolve(text);
    });
};

const createAndSaveNonce = (storage) => {
    return createNonce()
        .then((nonce) => {
            storage.setItem("nonce", nonce);
            return nonce;
        });
};


const clearLocalStorage = (storage) => {
    storage.removeItem('token');
    storage.removeItem('expireAt');
    storage.removeItem('userId');
    storage.removeItem('username');
};

const persistInLocalStorage = (storage, body) => {
    const expireAt = new Date(new Date().getTime() + body.expiresIn * 1000);
    storage.setItem('token', body.token || body.accessToken);
    storage.setItem('expireAt', expireAt);
    storage.setItem('userId', body.userId);
    storage.setItem('username', body.username);
};


export const createAuthStorage = (storage) => {

    return {
        clearLocalStorage: () => clearLocalStorage(storage),
        getAccessToken: () => storage.getItem("token"),
        getUserName: () => storage.getItem("username"),
        getExpireAt: () => storage.getItem("expireAt"),
        getUserId: () => storage.getItem("userId"),
        getNonce: () => storage.getItem("nonce"),
        getIdentity: () => JSON.parse(storage.getItem("identity")),
        createNonce: () => createAndSaveNonce(storage),
        clearStorage: () => clearLocalStorage(storage),
        persistAuthResponse: (body) => persistInLocalStorage(storage, body)
    }
};