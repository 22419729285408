import React, {useState, useCallback} from 'react';
import Categories from "../Library/Categories";
import {Box, Col, Row} from "@fik/ui";
import Grid from "../Library/Grid";
import Header from "../Library/Header";
import Detail from "../Library/Detail";

const Library = ({categories, items, defaultCategory, colsNumber = 2}) => {
    const [selectedCategory, setSelectedCategory] = useState(defaultCategory);
    const [selectedItem, setSelectedItem] = useState(null);

    const handleSelectCategory = useCallback((categoryKey) => {
        setSelectedCategory(categoryKey);
        setSelectedItem(null);
    }, [setSelectedItem, setSelectedCategory]);

    const handleSelectItem = useCallback((item) => {
        setSelectedItem(item);
        window.scrollTo(0, 0);
    }, [setSelectedItem]);

    return <Box>
        <Row>
            <Col sm={12} md={3} className="no-padding m-l-n-xs m-t-n-xs">
                <Categories categories={categories} selected={selectedCategory} onSelectCategory={handleSelectCategory}/>
            </Col>
            <Col sm={12} md={9}>
                <div>
                    <Header category={selectedCategory ? categories.find((category) => category.key === selectedCategory) : null}/>
                    {selectedItem ? <Detail item={selectedItem} items={items}/> : null}
                </div>

                <Grid
                    colsNumber={colsNumber}
                    onSelectItem={handleSelectItem}
                    category={selectedCategory}
                    items={items.filter((item) => (item.category === selectedCategory))}
                />
            </Col>
        </Row>
    </Box>;
};

export default Library;