import React, {useEffect} from 'react';
import useCmsPage from "./../useCmsPage";
import TextPage from "./TextPage";

const Page = ({category, page, setPageInfo, createContents = null}) => {
    const {result, isSuccess} = useCmsPage(category, page);
    useEffect(() => {
        if (result && result.title) {
            setPageInfo({title: result.title, section: result.section});
        }
    }, [isSuccess, result, setPageInfo]);

    if (!isSuccess) {
        return null;
    }

    const contents = [];

    if (result.page) {
        contents.push(<TextPage key="page" html={result.page}/>);
    }

    if (createContents) {
        const createdContents = createContents(result);
        if (createdContents) {
            contents.push(...createdContents);
        }
    }

    return contents;
};

export default Page;