import {useEffect, useState} from "react";

const useCompetitors = ({evidenceItem, discounts}) => {
    const [competitors, setCompetitors] = useState();

    useEffect(() => {
        let current = null;
        if (evidenceItem && evidenceItem.itemDiscountId) {
            const discount = discounts.find(element => element.productDiscountId === evidenceItem.itemDiscountId);
            if (discount && discount.competitors) {
                current = discount.competitors;
            }
        }
        setCompetitors((prevState) => prevState !== current ? current : prevState);
    }, [evidenceItem, discounts, setCompetitors]);

    return competitors;
}

export default useCompetitors;