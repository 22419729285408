import React from 'react';
import {ChannelName} from "../index";

const Channel = ({addressBook}) => {
    if (!addressBook) {
        return null;
    }

    const {companyId, email} = addressBook;

    return (
        <React.Fragment>
            <div className="text-bold"><ChannelName {...addressBook} /></div>
            <div className="description">
                {companyId ? <div>IČ: {companyId}</div> : null}
                {email
                    ? (<div className="icon-nowrap-block inline"><i className="fa fa-envelope"/> <span>{email}</span></div>)
                    : null}
            </div>
        </React.Fragment>
    );
};

export default Channel;