import React from 'react';
import PropTypes from 'prop-types';
import handleClick from "@fik/utils/handleClick";
import {views} from "./../useCustomerEdit";
import CustomerSearchForm from "./CustomerSearchForm";

const CustomerType = ({customerType, pickNewHomeUser, pickNewCompany, pickNewExisting, visibleViews, pattern}) => {

    return (
        <div className="row">
            <div className='col-sm-4'>
                <div className='btn-group btn-group-justified'>
                    {visibleViews.indexOf(views.HOME) !== -1 ?
                        <a title="Domácnost"
                           onClick={handleClick(pickNewHomeUser)}
                           className={'btn ' + (customerType === views.HOME ? 'btn-primary' : 'btn-default')}>
                            <i className='fa fa-home'/> Domácnost
                        </a> : null}
                    {visibleViews.indexOf(views.COMPANY) !== -1 ?
                        <a title="Firma"
                           onClick={handleClick(pickNewCompany)}
                           className={'btn ' + (customerType === views.COMPANY ? 'btn-primary' : 'btn-default')}>
                            <i className='fa fa-suitcase'/> Firma
                        </a> : null}
                </div>
            </div>

            {visibleViews.indexOf(views.EXISTING) !== -1 ? <div className="col-sm-8">
                <CustomerSearchForm values={{pattern}} onSubmit={pickNewExisting}/>
            </div> : null}
        </div>
    )
};

CustomerType.propTypes = {
    pickNewHomeUser: PropTypes.func,
    pickNewCompany: PropTypes.func,
    pickNewExisting: PropTypes.func,
    visibleViews: PropTypes.array,
    customerType: PropTypes.string
};

export default CustomerType;