import React from "react";
import {NavigationItem} from "@fik/navigation";

const Expiring90dLicensesInfo = () => <p className='m-t-md'>
    <i className="fa fa-info-circle"/> Níže uvedený výpis obsahuje licence, které budou expirovat v nejbližší budoucnosti, maximálně ale za 90 dní.
    Výpis je omezen jen na licence, které nárokují založení registrace obchodního případu, tedy obvykle v počtu 50+. Bližší informace o celém expiračním
    procesu naleznete <NavigationItem to="page" params={{category: 'partnerstvi', page: 'exspiracni-proces'}}>zde</NavigationItem>.<br/>
    <i className="fa fa-info-circle"/> Kliknutím na tlačítko <button type="button" className="btn btn-xs btn-default"><span
    className="glyphicon glyphicon-list-alt"/></button> si můžete k licenci zapsat libovolnou poznámku. Pro zobrazení poznámky stačí
    najet myší na zelenou podobu tlačítka: <button type="button" className="btn btn-xs btn-success"><span
    className="glyphicon glyphicon-list-alt"/></button>
</p>;

export default Expiring90dLicensesInfo;