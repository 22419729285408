import React from 'react';
import PropTypes from 'prop-types';
import {Box} from "@fik/ui";
import {InvoiceDetail} from "@fik/invoice";
import {useDials} from "@fik/dials";
import {useEntity} from "@fik/entity";

const Invoice = ({id}) => {
    const {invoice} = useEntity({name: 'invoice'}, id);
    const dials = useDials({name: 'invoice'});

    return (
        <Box>
            <InvoiceDetail
                entity={invoice}
                dials={dials}
            />
        </Box>
    )
};

Invoice.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};


export default Invoice;