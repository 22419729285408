import {useCallback, useEffect, useState} from "react";
import documentViews from "./documentViews";
import {emptyItem} from "@fik/model/createEvidenceItemModel";

const SELECT_TYPE_NEW = 'new';
const SELECT_TYPE_RENEW = 'renew';
const EMPTY_CONTEXT = {selection: null, evidenceItem: null};

const useDocumentEditEvidenceItems = ({isCompany, setView, documentModel}) => {
    const [evidenceItemEditContext, setEvidenceItemEditContext] = useState({type: null, evidenceItem: null});

    const editEvidenceItem = useCallback((context) => {
        setEvidenceItemEditContext(context);
        setView(documentViews.EDIT_EVIDENCE_ITEMS);
    }, [setView]);

    const startEditNewEvidenceItem = useCallback((evidenceItem) => {
        editEvidenceItem({
            selection: {
                ...(evidenceItem || emptyItem),
                selectType: SELECT_TYPE_NEW,
                isCompany: isCompany
            }
        });
    }, [editEvidenceItem, isCompany]);

    const startEditNewRenewEvidenceItem = useCallback((evidenceItem = {}) => {
        editEvidenceItem({
            selection: {
                ...emptyItem,
                ...evidenceItem,
                selectType: SELECT_TYPE_RENEW,
                isCompany: isCompany
            }
        });
    }, [editEvidenceItem, isCompany]);

    const startEditExistingEvidenceItem = useCallback((evidenceItem) => {
        editEvidenceItem({
            evidenceItem,
            selection: {
                ...evidenceItem,
                selectType: evidenceItem.itemLicenseIdCodes ? SELECT_TYPE_RENEW : SELECT_TYPE_NEW,
                isCompany: isCompany
            }
        });
    }, [editEvidenceItem, isCompany]);

    const leaveEvidenceItemEdit = useCallback(() => {
        setEvidenceItemEditContext({selection: null, evidenceItem: null});
        setView(documentViews.VIEW);
    }, [setView]);

    const confirmEvidenceItemEdit = useCallback((evidenceItem) => {

        if (evidenceItemEditContext.evidenceItem) {
            documentModel.replaceEvidenceItem(evidenceItemEditContext.evidenceItem, evidenceItem);
            leaveEvidenceItemEdit();
        } else {
            documentModel.addEvidenceItem(evidenceItem);
        }

        setEvidenceItemEditContext({...EMPTY_CONTEXT});
        setView(documentViews.VIEW);

    }, [evidenceItemEditContext.evidenceItem, leaveEvidenceItemEdit, documentModel, setView]);

    useEffect (() => {
        if (documentModel.document && documentModel.document.owner) {
            const owner = documentModel.document.owner;
            if (owner.licenseCode && documentModel.document.evidenceItems && documentModel.document.evidenceItems.length < 1) {
                startEditNewRenewEvidenceItem({itemLicenseIdCodes: owner.licenseCode});
                setView(documentViews.EDIT_EVIDENCE_ITEMS);
            }
        }
    }, [documentModel.document, startEditNewRenewEvidenceItem]);

    return {
        ...evidenceItemEditContext,
        startEditNewEvidenceItem,
        startEditNewRenewEvidenceItem,
        startEditExistingEvidenceItem,
        confirmEvidenceItemEdit,
        cancelEvidenceItemEdit: leaveEvidenceItemEdit
    }
};

export default useDocumentEditEvidenceItems;