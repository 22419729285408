import React from 'react';
import EvidenceItems from "./EvidenceItems";
import {Date} from "@fik/ui";
import {Status} from "@fik/document";
import {Price} from "@fik/ui";
import {Box} from "@fik/ui";

const InvoiceDetail = ({entity, dials}) => {

    if (!entity) {
        return null;
    }

    return (<>
        <h3 className="border-bottom">{entity.code}</h3>
        <div className="row">
            <div className="col-sm-3">
                <div><strong>Typ:</strong> {entity.documentType}</div>
                <div><strong>VS:</strong> {entity.variableSym}</div>
                <div><strong>Typ platby:</strong> {entity.paymentMethod}</div>
                <div><strong>Stav:</strong> <Status value={entity.paymentStatus} enum={dials.paymentStatusDict}/></div>
            </div>
            <div className="col-sm-4">
                <div>{entity.companyName}</div>
                <div>{entity.companyId}</div>
                <div>{entity.contactEmail}</div>
            </div>
            <div className="col-sm-5 text-right">
                <div><strong>Vystaveno:</strong> <Date date={entity.dateCreated}/></div>
                <div><strong>Datum splatnosti:</strong> <Date date={entity.dueDate}/></div>
                <div><strong>Datum uskutečnění zdanitelného plnění:</strong> <Date date={entity.dateTaxableSupply}/></div>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-12">
                <EvidenceItems evidenceItems={entity.evidenceItems}/>
                <Box>
                    <table className="table invoice-total">
                        <tbody>
                        <tr>
                            <td><strong>Cena bez DPH :</strong></td>
                            <td><Price price={entity.basePrice}/></td>
                        </tr>
                        <tr>
                            <td><strong>Cena s DPH :</strong></td>
                            <td><Price price={entity.vatPrice}/></td>
                        </tr>
                        <tr>
                            <td><strong>Zbývá uhradit:</strong></td>
                            <td><Price price={entity.leftToPay}/></td>
                        </tr>
                        </tbody>
                    </table>
                </Box>
            </div>
        </div>
    </>)
};

export default InvoiceDetail;