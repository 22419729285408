import React from "react";

const DEVICE_SIZES = ['lg', 'md', 'sm', 'xs'];
const VARIANTS = ['', 'offset', 'push', 'pull'];

const popProperty = (property, properties) => {
    if (properties.hasOwnProperty(property)) {
        const newProperties = {...properties};
        delete newProperties[property];
        return [newProperties, properties[property]];
    }
    return [properties, undefined];
}
const convertSizeToStyle = (props) => {
    const classNames = [];
    DEVICE_SIZES.forEach((size) => {
        VARIANTS.forEach((variant) => {
            const [newProps, value] = popProperty(size + (variant ? variant.charAt(0).toUpperCase() + variant.slice(1) : ''), props);
            if (value !== undefined) {
                classNames.push('col-' + size + (variant ? '-' + variant : '') + '-' + value);
                props = newProps;
            }
        })
    })
    return [props, classNames];
};
const Col = ({className, ...props}) => {
    const [newProps, classNames] = convertSizeToStyle(props);
    return <div className={classNames.join(' ') + (className ? ' ' + className : '')} {...newProps} />;
}

export default Col;