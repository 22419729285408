import React, {useCallback, useMemo} from "react";
import PropTypes from 'prop-types';
import {useDocumentContext} from "@fik/document-edit";
import {Form} from "@mouseover/react-form";
import {DatePicker, SelectInput, TextArea} from "@fik/form";
import {shallowEqual} from "@mouseover/js-utils";


const DealFormDetailNew = ({validation, isSubmitted}) => {
    const {
        document,
        initialDocument: {note, notePosition, noteImplementation},
        changeValues,
    } = useDocumentContext();

    const handleValuesChanges = useCallback((values, valid) => {
        const {note} = document;
        if (!shallowEqual({note, notePosition, noteImplementation}, values)) {
            changeValues(values);
        }
    }, [document, changeValues]);

    const validationRules = useMemo(() => ({
        note: validation.note,
        notePosition: validation.notePosition,
        noteImplementation: validation.noteImplementation
    }), [validation]);

    return document
        ? <Form
            values={{note, notePosition, noteImplementation}}
            validationRules={validationRules}
            onChange={handleValuesChanges}
            isSubmitted={isSubmitted}
            render={(form) => <>
                <div className="row">
                    <div className="col-sm-12">
                        <TextArea
                            label="Podrobnosti k projektu" name="note" form={form}
                            elementConfig={{placeholder: 'Např.: Projekt bude financován z vlastních zdrojů/IROP projektů... Aktuálně se nacházíme ve fázi průzkumu trhu... Další kroky očekáváme během následujících X měsíců...'}}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <TextArea
                            label="Podrobnosti k Vaší pozici v projektu" name="notePosition" form={form}
                            elementConfig={{placeholder: 'Např.: Akviziční zákazník u kterého nedodáváme žádné služby... Náš stávající zákazník, u kterého dodáváma licence X, firewall Y včetně kompletní správy obou řešení...'}}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <TextArea
                            label="Návrh implementace řešení v prostředí zákazníka" name="noteImplementation" form={form}
                            elementConfig={{placeholder: 'Např.: EP konzole bude nasazena v prostředí zákazníka... EP konzole bude outsourcovaná... Nastavení produktu již provede zákazník sám...'}}
                        />
                    </div>
                </div>
            </>}/>
        : null;
};

export default DealFormDetailNew;