export const emptyItem = {
    itemName: null,
    itemProductTypeId: null,
    itemProductFamilyId: null,
    itemProductPeriodId: null,
    itemAmount: null,
    itemDiscount: null,
    itemDiscountId: null,
    itemDiscountCompetitor: null,
    itemDiscountCompetitorName: null,
    itemDiscountCompetitorPrice: null,
    itemExtraDays: null,
    itemPromoId: null,
    itemBonusCode: null,
    itemBasePrice: null,
    itemMessages: [],
    owner: {
        itemPrice: null
    },
    partner: {
        itemPrice: null,
        addressBookId: null
    },
    epc: {
        itemPrice: null,
        addressBookId: null
    },
    itemLicenseIdCodes: null
};

const createEvidenceItemModel = (adapter) => {

    return {
        select:(params)=> {
            return adapter().get({...params, action: 'selector'});
        },
        recalculate: (selection) => {
            return adapter().post({action: 'recalculate'}, selection);
        },
        emptyItem
    }
};

export default createEvidenceItemModel;