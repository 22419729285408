import {useHistory} from "react-router-dom";
import {useCallback} from "react";

const useNavigate = () => {
    const history = useHistory();
    return useCallback((to) => {
        history.push(to);
    }, [history]);
};

export default useNavigate;