import {useReducer, useCallback} from 'react';

const ADD_PRODUCT = 'ADD_PRODUCT';
const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
const REMOVE_ALL = 'REMOVE_ALL';
const CHANGE_QUANTITY = 'CHANGE_QUANTITY';

const initialState = {
    products: [],
    total: 0
};

const productPrice = (product) => product.productBasePrice * product.quantity;
const productsSum = (products) => products.reduce((sum, product) => sum + productPrice(product), 0);

const basketReducer = (initialState) => (state = initialState, action) => {
    switch (action.type) {
        case ADD_PRODUCT:

            return {
                ...state,
                products: [...state.products, action.product],
                total: state.total + productPrice(action.product)
            };
        case REMOVE_PRODUCT:
            const newProducts = [...state.products];
            newProducts.splice(action.index, 1);
            return {
                ...state,
                products: newProducts,
                total: productsSum(newProducts)
            };
        case CHANGE_QUANTITY:
            const changedProducts = [...state.products];
            changedProducts[action.index] = {...changedProducts[action.index], quantity: action.quantity};
            return {
                ...state,
                products: changedProducts,
                total: productsSum(changedProducts)
            };

        case REMOVE_ALL:
            return {
                ...initialState
            };

        default:
            return state;
    }
};

const useCart = () => {
    const [state, dispatch] = useReducer(basketReducer(initialState), initialState);

    const addProduct = useCallback((product) => {
        const existingIndex = state.products.findIndex((current) => current.productId === product.productId);
        if (existingIndex !== -1) {
            dispatch({type: CHANGE_QUANTITY, index:existingIndex, quantity: state.products[existingIndex].quantity + product.quantity});
        } else {
            dispatch({type: ADD_PRODUCT, product});
        }
    }, [dispatch, state]);

    const removeProduct = useCallback((index) => {
        dispatch({type: REMOVE_PRODUCT, index});
    }, [dispatch]);

    const changeQuantity = useCallback((index, quantity) => {
        dispatch({type: CHANGE_QUANTITY, index, quantity});
        // if (Number(quantity) === 0) {
        //     dispatch({type: REMOVE_PRODUCT, index});
        // } else {
        //     dispatch({type: CHANGE_QUANTITY, index, quantity});
        // }
    }, [dispatch]);

    const clearBasket = useCallback((index) => {
        dispatch({type: REMOVE_PRODUCT, index});
    }, [dispatch]);

    return {
        addProduct,
        removeProduct,
        changeQuantity,
        clearBasket,
        products: state.products,
        total: state.total
    }
};

export default useCart;