import React, {useEffect} from "react";
import Auth from "./Auth/Auth";
import {useSelector} from "react-redux";

const Sso = ({path}) =>{
    const token = useSelector((state) => state.auth.token, []);
    useEffect(() => {
        if (token && path) {
            window.location = window.location.protocol + '//' +window.location.hostname + path + "?token=" + token;
        }
    }, [token, path]);

    return <>
        {!token && <Auth />}
    </>;
}

export default Sso;