import React from 'react';
import {Channel, ChannelContact} from "@fik/components";
import Status from './Status';
import {EvidenceItems} from '@fik/evidence-item';
import Type from './Type';
import {Price, Box, Date} from "@fik/ui";
import arrayToDictionary from "@fik/utils/arrayToDictionary";

const Document = ({entity, dials, renderInfo, isEpc}) => {
    const {types, states} = dials;

    const statesDict = arrayToDictionary(states, 'id', 'label');
    const typesDict = arrayToDictionary(types, 'id', 'label');

    return (<Box>
        <div className="row">
            <div className="col-sm-2">
                <h3 className="border-bottom">{entity.code}</h3>
                <div><strong>Typ:</strong> <Type value={entity.documentType} enum={typesDict}/></div>
                <div><strong>Stav:</strong> {entity.businessStatus ? entity.businessStatus : <Status value={entity.status} enum={statesDict}/>}</div>
                <div><strong>Vytvořeno:</strong> <Date date={entity.dateCreated}/></div>
                {renderInfo ? renderInfo(entity) : null}
            </div>
            <div className="col-sm-5">
                <h3 className="border-bottom">Partner</h3>
                {entity.partner ? <Channel {...entity.partner} isCustomer={false}/> : null}
                <br />
                <h4 className="border-bottom">Obchodník</h4>
                <ChannelContact {...entity.partner} />
            </div>
            <div className="col-sm-5">
                <h3 className="border-bottom">Zákazník</h3>
                {entity.owner ? <Channel {...entity.owner} isCustomer={true}/> : null}
            </div>
        </div>
        <div className="row">
            <div className="col-lg-12">
                <EvidenceItems evidenceItems={entity.evidenceItems} epcPrice={isEpc}/>
                <Box>
                    <table className="table invoice-total">
                        <tbody>
                        <tr>
                            <td><strong>Cena bez DPH :</strong></td>
                            <td><Price price={entity.basePrice}/></td>
                        </tr>
                        <tr>
                            <td><strong>Cena s DPH :</strong></td>
                            <td><Price price={entity.vatPrice}/></td>
                        </tr>
                        </tbody>
                    </table>
                </Box>
            </div>
        </div>
    </Box>)
};

export default Document;
