import React, {useCallback} from 'react';
import {Form} from "@mouseover/react-form";
import {Redirect} from "react-router-dom";
import {Box, Row, Col} from "@fik/ui";
import useAuth from "@fik/auth/useAuth";
import {NavigationItem} from "@fik/navigation";

const controls = {
    username: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Jméno',
        },
        value: '',
        valid: false,
        touched: false,
        validation: {
            required: true,
            minLength: 4
        }
    },
    password: {
        elementType: 'input',
        elementConfig: {
            type: 'password',
            placeholder: 'Heslo',
        },

        value: '',
        valid: false,
        touched: false,
        validation: {
            required: true,
            minLength: 4,
            placeholder: 'Heslo',
        }
    },
    // 'remember': {
    //     elementType: 'checkbox',
    //     elementConfig: {autoComplete: 'off'},
    //     label: 'Zapamatovat si mě',
    //     groupClass: 'form-group m-b-xs m-t-m'
    // },
    'clientMode': {
        elementType: 'checkbox',
        elementConfig: {autoComplete: 'off'},
        label: 'Režim u klienta',
        groupClass: null
    }
};

function Auth() {

    const {authenticate, isAuthenticated, redirectPath, error, loading} = useAuth();

    const handleSubmit = useCallback((values, valid) => {
        if (valid) {
            authenticate(values);
        }
    }, [authenticate]);

    const date = new Date();

    if (isAuthenticated) {
        return <Redirect to={redirectPath}/>;
    }

    return (<div className="loginColumns">
        <Row>
            <Col lg={12}>
                <h2 className="font-bold">Vítejte na partnerské zóně&nbsp;ESET!</h2>
            </Col>
            <Col md={6}>
                <div className="alert alert-success">
                    Nejste partnerem ESETu? <br/><a href="https://www.eset.com/cz/prodejci/o-partnerstvi/" target="_blank"
                                                    rel="noopener noreferrer">Seznamte se s výhodami
                    partnerství</a>.
                </div>

            </Col>
            <Col md={6}>
                <Box isLoading={loading}>
                    {error ? <div className="p-w-xs m-xs alert alert-error"><p>Nepodařilo se přihlásit</p></div> : null}
                    <Form controls={controls} onSubmit={handleSubmit}>
                        <button type="submit" className="btn btn-primary block full-width m-b"
                                id="test_dotd0">Přihlásit se
                        </button>
                    </Form>

                    <div className="row">
                        <div className="col-lg-6 col-lg-offset-6 text-right">
                            <NavigationItem to="forgottenPassword">
                                <small>Zapomněli jste heslo?</small>
                            </NavigationItem>
                        </div>
                    </div>
                </Box>
            </Col>
        </Row>
        <hr/>
        <Row>
            <Col md={12} className="text-center">
                <span>© {date.getFullYear()} - <a href="https://www.eset.com/cz">ESET software spol. s r.o.</a></span>
            </Col>
        </Row>
    </div>);
}

export default Auth;
