import React from 'react';
import {isEmpty} from "@mouseover/js-utils";

const Number = (props) => {
    let {number, decimalLength = 2, wholePartLength:whole_part_length = 3, sectionsDelimiter:sections_delimiter = ' ', decimalDelimiter:decimal_delimiter = ','} = props;

    if (isEmpty(number)) {
        return null;
    }

    let re = '\\d(?=(\\d{' + (whole_part_length || 3) + '})+' + (decimalLength > 0 ? '\\D' : '$') + ')';
    let numStr = number.toFixed(Math.max(0, ~~decimalLength));
    return <React.Fragment>{(decimal_delimiter ? numStr.replace('.', decimal_delimiter) : numStr).replace(new RegExp(re, 'g'), '$&' + (sections_delimiter || ' '))}</React.Fragment>;
};


export default Number;