import React from 'react';
import PropTypes from 'prop-types';
import Product from "./Product";

const ProductsGrid = ({products, onAddProduct}) => {
    return <div className="row">
        {products.map((product) => <Product key={product.productId} product={product} onAddProduct={onAddProduct}/>)}
    </div>;
};

ProductsGrid.propTypes = {
    products: PropTypes.array,
    onAddProduct: PropTypes.func
};

export default ProductsGrid;