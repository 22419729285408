import React from 'react';
import {InvoicesTable} from "@fik/invoice";
import {Paginator, Box} from "@fik/ui";
import {useDataSource, useDataSourceWithQueryParams} from "@fik/data-source";

const Invoices = () => {
    const {pagination, items, handlePageChange} = useDataSourceWithQueryParams(
        {name: 'invoice', initialSearchParams: {}}
    );

    return (
        <Box>
            <InvoicesTable striped responsive rows={items} primary='code'/>
            <Paginator {...pagination}
                       onPageChange={handlePageChange}/>
        </Box>
    );
};

export default Invoices;