import {useCallback} from 'react';
import {createActionNames, createRequestState} from "@fik/api";
import wrapAsyncCallForDispatch from "@fik/utils/wrapAsyncCallForDispatch";
import {useModel} from "@fik/model";
import {useReducerWithThunk} from "@fik/utils/useReducerWithThunk";
import {useHookToLoading} from "@fik/loading";

const pageBlockActions = createActionNames('cmsMenu');

const pageBlocksReducer = (state, action) => {
    switch (action.type) {
        case pageBlockActions.FETCH_INIT:
            return {
                ...state,
                result: null,
                ...createRequestState(pageBlockActions, action.type),
            };
        case pageBlockActions.FETCH_SUCCESS:
            return {
                ...state,
                result: action.payload,
                ...createRequestState(pageBlockActions, action.type),
            };
        case pageBlockActions.FETCH_FAILURE:
            return {
                ...state,
                result: null,
                ...createRequestState(pageBlockActions, action.type),
            };
        case 'RESET':
            return {
                ...state,
                isFetching: false,
                isSuccess: false,
                isFailed: false,
                result: null
            };
        default:
            return state;
    }
};

const useCmsPageBlocks = () => {
    const load = useModel(
        (model) =>  wrapAsyncCallForDispatch(model.cms().getPageBlocks)(pageBlockActions),
        []
    );

    const [state, dispatch] = useReducerWithThunk(pageBlocksReducer, {
            isFetching: false,
            isSuccess: false,
            isFailed: false,
            result: null
        }
    );

    useHookToLoading('banners', state);

    const initPage = useCallback((path, blocks) => {
        if (path && blocks) {
            if (path === '/') {
                path = 'homepage';
            }
            const [category, page = 'default'] = path.replace(/^(\/p\/)|(\/)/i, '').split('/');
            if (category && page) {
                dispatch(load(category, page, blocks));
            } else {
                dispatch({type: 'RESET'});
            }
        } else {
            dispatch({type: 'RESET'});
        }
    }, [dispatch, load]);

    return [state, initPage];
};

export default useCmsPageBlocks;