import React from 'react';
import PropTypes from 'prop-types';

// aby slo pouzit bootstrap tabs
import $ from 'jquery';
import useCmsNavigationRef from "../useCmsNavigationRef";


const TextPage = ({html}) => {

    const content = useCmsNavigationRef({onContentChange: init});

    if (html) {
        return <div ref={content} dangerouslySetInnerHTML={{__html: html}} />
    }
    return null;
};

TextPage.propTypes = {
    html: PropTypes.string
};

export default TextPage;


const tabClickHandler = function (e) {
    e.preventDefault();
    $(this).tab('show');
};

const init = function (contentElement) {
    if (!window.jQuery) {
        window.jQuery = $;
        window.$ = $;
        require('bootstrap');
    }

    // Collapse ibox function
    $(contentElement).find('.collapse-link').click(function () {
        const ibox = $(this).closest('div.ibox');
        const button = $(this).find('i');
        const content = ibox.find('div.ibox-content');
        content.slideToggle(200);
        button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
        ibox.toggleClass('').toggleClass('border-bottom');
        setTimeout(function () {
            ibox.resize();
            ibox.find('[id^=map-]').resize();
        }, 50);
    });

    // tabs
    $(contentElement)
        .on('click.bs.tab.data-api', '[data-toggle="tab"]', tabClickHandler)
        .on('click.bs.tab.data-api', '[data-toggle="pill"]', tabClickHandler);


    // youtube
    const youtube = contentElement.querySelectorAll(".youtube");

    for (let i = 0; i < youtube.length; i++) {

        const source = "https://img.youtube.com/vi/"+ youtube[i].dataset.embed +"/sddefault.jpg";

        const image = new Image();
        image.src = source;
        image.addEventListener( "load", function() {
            youtube[ i ].appendChild( image );
        }( i ) );

        youtube[i].addEventListener( "click", function() {

            const iframe = document.createElement( "iframe" );

            iframe.setAttribute( "frameborder", "0" );
            iframe.setAttribute( "allowfullscreen", "" );
            iframe.setAttribute( "src", "https://www.youtube.com/embed/"+ this.dataset.embed +"?rel=0&showinfo=0&autoplay=1" );

            this.innerHTML = "";
            this.appendChild( iframe );
        } );
    }
};
