import React, {useContext} from 'react';
import PropTypes from "prop-types";
import {ModelContext} from "@fik/model";

const DownloadLink = ({resource, params, children, ...props}) => {
    const {createDownloadLink} = useContext(ModelContext);
    return (
        <a href={createDownloadLink(resource, params)} target='_blank' rel='noopener noreferrer'
           {...props}
        >{children}</a>
    );
};

DownloadLink.propTypes = {
    resource: PropTypes.string.isRequired,
    params: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func, PropTypes.string])
};


export default DownloadLink;
