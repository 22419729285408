import {useEffect} from 'react';
import {createActionNames, createRequestState} from "@fik/api";
import wrapAsyncCallForDispatch from "@fik/utils/wrapAsyncCallForDispatch";
import {useModel} from "@fik/model";
import {useReducerWithThunk} from "@fik/utils/useReducerWithThunk";

const menuActions = createActionNames('cmsMenu');

menuActions.UPDATE_MENU = 'UPDATE_MENU';

const mapCmsMenuToMenu = (content, category = null) => {
    return content.map((item) => {

        const menuItem = {
            label: item.visibleName
        };

        if (category) {
            menuItem.to = '/p/' + encodeURIComponent(category) + '/' + encodeURIComponent(item.systemName);
        } else {

            menuItem.icon = 'fa ' + item.icon;

            if (!item.isSingle && item.pages) {
                menuItem.content = mapCmsMenuToMenu(item.pages, item.systemName);
            }

            if (item.isSingle && item.singlePage) {
                menuItem.to = '/p/' + encodeURIComponent(item.systemName) + '/' + encodeURIComponent(item.singlePage.systemName);
            }
        }

        return menuItem;
    });
};

const mergeMenu = (menu, loadedMenu) => {
    if (!loadedMenu) {
        return menu;
    }

    const newLoadedMenu = [...loadedMenu];
    const categoryMap = newLoadedMenu.reduce((o, row, index) => ({...o,[row.label]:index}), {});
    const newMenu = menu.filter((element) => {
        if (!categoryMap.hasOwnProperty(element.label)) {
            return true;
        }

        element.content.forEach((item) => {
            if (item.atIndex) {
                if (newLoadedMenu[categoryMap[element.label]].content[item.atIndex].to !== item.to) {
                    newLoadedMenu[categoryMap[element.label]].content.splice(item.atIndex, 0, item);
                }
            } else {
                newLoadedMenu[categoryMap[element.label]].content.push(item);
            }
        });

        return false;
    });

    Array.prototype.splice.apply(newMenu, [3, 0, ...newLoadedMenu])

    return newMenu;
};

const menuReducer = (state, action) => {
    switch (action.type) {
        case menuActions.FETCH_INIT:
            return {
                ...state,
                ...createRequestState(menuActions, action.type),
            };
        case menuActions.FETCH_SUCCESS:
            const loadedMenu = mapCmsMenuToMenu(action.payload);
            return {
                ...state,
                loadedMenu,
                currentMenu: mergeMenu(state.menu, loadedMenu),
                ...createRequestState(menuActions, action.type),
            };
        case menuActions.UPDATE_MENU:
            return {
                ...state,
                menu: action.menu,
                currentMenu: mergeMenu(action.menu, state.loadedMenu),
            };
        case menuActions.FETCH_FAILURE:
            return {
                ...state,
                ...createRequestState(menuActions, action.type),
            };
        default:
            return state;
    }
};

const useCmsMenu = (menu) => {
    const loadCmsMenu = useModel(
        (model) => wrapAsyncCallForDispatch(model.cms().getMenu)(menuActions),
        []
    );

    const [state, dispatch] = useReducerWithThunk(menuReducer, {
            isFetching: false,
            isSuccess: false,
            isFailed: false,
            currentMenu: menu,
            loadedMenu: [],
            menu
        }
    );

    useEffect(() => {
        dispatch(loadCmsMenu());
    }, [loadCmsMenu, dispatch]);

    useEffect(() => {
        dispatch({type: menuActions.UPDATE_MENU, menu});
    }, [menu, dispatch]);

    return state.currentMenu;
};

export default useCmsMenu;