import React from 'react';
import PropTypes from 'prop-types';
import {NavigationItem} from "@fik/navigation";
import {Col, Row} from "@fik/ui";

const PageHeader = ({title, section, children}) => {
    if (!title) {
        return null;
    }
    return (
        <Row className="wrapper border-bottom white-bg page-heading">
            <Col lg={4}>
                <h2>{title}</h2>
                <ol className="breadcrumb">
                    <li>
                        <NavigationItem to="homepage">Home</NavigationItem>
                    </li>
                    {section ? <li>{section}</li> : null}
                    <li className="active">
                        <strong>{title}</strong>
                    </li>
                </ol>
            </Col>
            <Col lg={8}>
                {children}
            </Col>
        </Row>
    );
};

PageHeader.propTypes = {
    title: PropTypes.string,
    section: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func])
};

export default PageHeader;