import React, {useCallback, useEffect} from "react";
import {Form} from "@mouseover/react-form";
import {useEntityAction} from "@fik/entity";

const LicenseNote = ({id, resellerNote, onSave}) => {
    //- save note hook
    const [{isSuccess}, saveNote] = useEntityAction({name: 'license', params: {id}, action: 'saveResellerNote'});

    //- save note
    const handleNoteSave = useCallback((values, valid) => {
        if (valid) {
            saveNote({
                id,
                values
            });
        }
    }, [id, saveNote]);

    //- refresh after save
    useEffect(() => {
        if (onSave && isSuccess) {
            onSave();
        }
    }, [onSave, isSuccess]);

    return <Form className='form'
                 onSubmit={handleNoteSave}
                 values={{resellerNote}}
                 controls={{
                     resellerNote: {
                         elementType: 'textarea',
                         elementConfig: {
                             rows: 8
                         },
                         validation: {
                             required: false,
                         }
                     }
                 }}
    >
        <button
            type="submit"
            className="btn btn-primary"
        >Uložit</button>
    </Form>;
};

export default LicenseNote;