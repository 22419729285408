import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Toolbar from '../Navigation/Toolbar/Toolbar';
import Sidebar from "../Navigation/Sidebar/Sidebar";
import PageHeader from "../Navigation/PageHeader/PageHeader";

import {useSelector} from "react-redux";
import {usePageInfo, PageBlock} from "@fik/page";
import {LoadingSpinnerOverlay, LoadingProvider, RefreshingInfo, useLoadingContext, SpinnerCircle} from "@fik/loading";
import Footer from "../Navigation/Footer/Footer";

const BannerContainer = ({children}) => <div className='wrapper-content row' style={{paddingBottom: '0', marginBottom: '-10px', paddingTop: '20px'}}><div className='col-lg-12 no-padding'>{children}</div></div>;

BannerContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func])
};


const Layout = ({children, header = null, renderInfo = null}) => {

    const [showSidebar, setShowSidebar] = useState(false);

    const handleDrawToggleClicked = useCallback(() => {
        setShowSidebar(!showSidebar);
    }, [showSidebar, setShowSidebar]);

    const {title, section} = usePageInfo();

    const {username,userId} = useSelector(state => state.auth);

    const {isLoading, isRefreshing, resources} = useLoadingContext();

    useEffect(() => {
        setShowSidebar(false);
    }, [setShowSidebar, title]);

    useEffect(() => {
        if (showSidebar) {
            window.scrollTo(0, 0);
        }
    }, [showSidebar]);
    return (
        <>
            <RefreshingInfo show={isLoading || isRefreshing} style={{position: 'fixed', zIndex: 9999999, top: '5px', left: 0, right: 0}}>
                <div className='bg-muted clearfix text-center' style={{margin: 'auto', maxWidth: '120px', border: '1px solid black', padding: '5px 10px', lineHeight: '22px'}} >
                    <div className='pull-left m-r-xs'><SpinnerCircle/></div> Načítá se{process.env.NODE_ENV === 'development'
                    ? Object.entries(resources).filter(([key, item]) => item.isFetching).reduce((acc,[key, item]) => acc + (acc ? ', ' : ' ') + key, '')
                    : '...'}
                </div>
            </RefreshingInfo>

            <Sidebar visible={showSidebar}/>
            <div id="page-wrapper" className="gray-bg">
                <div id="page-header">
                    <Toolbar drawerToggleClicked={handleDrawToggleClicked} username={username} userId={userId}/>
                </div>
                <PageHeader title={title} section={section}>{renderInfo}</PageHeader>
                <LoadingProvider>
                    <LoadingSpinnerOverlay>
                        <PageBlock name='banners' container={BannerContainer} />
                    </LoadingSpinnerOverlay>
                </LoadingProvider>
                <main>
                    {header}
                    <div className="wrapper wrapper-content">
                        {children}
                    </div>
                </main>
                <Footer/>
            </div>
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
    header: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func])
};

export default Layout;