import React from "react";
import {EvidenceItems} from '@fik/evidence-item';

const OffersTableRowDetail = ({offer: {evidenceItems}}) => {
    return <>
        <div className="table-responsive  m-l-lg  m-r-md">
            {evidenceItems ? <EvidenceItems evidenceItems={evidenceItems}/> : null}
        </div>
    </>
};

export default OffersTableRowDetail;