import React from "react";
import PropTypes from 'prop-types';
import {DealForm} from "@fik/deal";
import {DocumentProvider} from "@fik/document-edit";
import {useEntity} from "@fik/entity";
import useIsEpc from "../../useIsEpc";
import {Col, Row} from "@fik/ui";


const EditDeal = ({id}) => {

    const {deal} = useEntity({name: 'deal'}, id);
    const isEpc = useIsEpc();

    return deal ? <DocumentProvider document={deal} isEpc={isEpc} validate={false} existing={true}>
        <Row>
            <Col sm={12} lg={9}><DealForm formType={deal.renew ? 'renew' : 'new'}/></Col>
        </Row>
    </DocumentProvider> : null;
};

EditDeal.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default EditDeal;