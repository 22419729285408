import createEvidenceItemModel from "@fik/model/createEvidenceItemModel";
import createDialsModel from "@fik/model/createDialsModel";
import createEntityModel from "@fik/model/createEntityModel";
import createDocumentModel from "@fik/model/createDocumentModel";
import createCmsModel from "@fik/model/createCmsModel";
import createAuthenticationModel from "@fik/auth/createAuthenticationModel";
import createDataSourceModel from "@fik/model/createDataSourceModel";
import * as axios from "axios";
import queryString from 'query-string'
import {getOnPath} from "@mouseover/js-utils/src";
import navigation from "./navigation";
import sideBarMenu from "./sidebarMenu";
import models from "./models";

const createStore = () => {
    let store = {};
    return {
        get: (key) => store[key],
        save: (key, value) => store = {...store, [key]: value}
    }
};


function getTags(resellerProfile) {
    if (!resellerProfile) return [];

    if (resellerProfile.branchOffice && resellerProfile.branchOffice.branchOfficeId) {
        return getOnPath(resellerProfile, ['branchOffice', 'tags']);
    }

    return resellerProfile.addressBook ? getOnPath(resellerProfile, ['addressBook', 'tags']) : [];
}

const createResellerZoneModel = ({
                                     baseURL,
                                     adapters: {authenticationAdapters, cmsAdapter, dataSourceAdapter, identityAdapter, resourceAdapter}
                                 }) => {

    const axiosInstance = axios.create({
        baseURL: baseURL
    });

    const authenticationModel = createAuthenticationModel(window.localStorage, authenticationAdapters(axiosInstance));
    const resellerzoneResourceAdapter = resourceAdapter(axiosInstance);
    const store = createStore();

    return {
        createDownloadLink: (resource, params) => {
            const {id, ...queryParams} = params || {};
            queryParams['access_token'] = authenticationModel.authStorage.getAccessToken();
            return baseURL + (!baseURL.endsWith('/') ? '/' : '') + 'api/download/' + resource + (id ? '/' + encodeURIComponent(id) : '') + (queryParams ? '?' + queryString.stringify(queryParams) : '')
        },
        resources: {
            contact: {name: 'contact', storeName: 'contacts'},
            branchOffice: {name: 'branchOffice', storeName: 'branchOffices'},
            product: {name: 'product', storeName: 'products'},
            productTree: {name: 'product-tree', storeName: 'product-tree'},
            customer: {name: 'owner', storeName: 'customer'},
            deal: {name: 'deal', storeName: 'deals'},
            invoice: {name: 'invoice', storeName: 'invoices'},
            license: {name: 'license', storeName: 'licenses'},
            offer: {name: 'offer', storeName: 'offers'},
            order: {name: 'order', storeName: 'orders'},
            profile: {name: 'profile', storeName: 'profile', timeToRefresh: 1},
            loyalties: {name: 'loyalties', storeName: 'loyalties'},
            resellerProfile: {name: 'reseller-profile', storeName: 'reseller-profile'},
            resellerPlan: {name: 'reseller-plan', storeName: 'reseller-plan'},
            projectPrice: {name: 'project-price', storeName: 'project-prices'},
            identity: {name: 'identity', adapterName: 'identity', storeName: 'identity'},
            processedCourse: {name: 'processed-course', storeName: 'processed-course'},
            esetEvidenceItem:  {name: 'esetEvidenceItem', storeName: 'esetEvidenceItem'}
        },
        evidenceItems: () => createEvidenceItemModel(() => resellerzoneResourceAdapter('eset-evidence-item')),
        dials: () => createDialsModel(resellerzoneResourceAdapter),
        entity: () => createEntityModel({entity: resellerzoneResourceAdapter, identity: identityAdapter(axiosInstance)}, 'entity', models(authenticationModel)),
        document: () => createDocumentModel(resellerzoneResourceAdapter),
        cms: () => createCmsModel(cmsAdapter(axiosInstance, store)),
        authentication: () => authenticationModel,
        dataSource: (name) => createDataSourceModel(dataSourceAdapter(axiosInstance, name)),
        navigation: () => navigation,
        menu: (resellerProfile) => {
            const tags = getTags(resellerProfile);
            return {
                sideBarMenu: sideBarMenu(navigation, tags),
            };
        }
    };
};

export default createResellerZoneModel;
