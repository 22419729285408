import React from 'react';

const GridItemCourse = ({label, date, description, link}) => {
    return <div className="ibox">
        <div className="ibox-content product-box product-course">
            <div className="product-desc">
                <small className="text-muted">{date}</small>
                <a href={link} target="_blank" rel="noopener noreferrer" className="product-name"> {label}</a>
                <div className="small m-t-xs">
                    {description}
                </div>
                {/*<div className="m-t text-righ">*/}

                {/*    <a href="#" className="btn btn-xs btn-outline btn-primary">Info <i className="fa fa-long-arrow-right"></i> </a>*/}
                {/*</div>*/}
            </div>
        </div>
    </div>;
};

export default GridItemCourse;