import React from "react";
import {useDataSource, useDataSourceWithQueryParams} from "@fik/data-source";
import {Box} from "@fik/ui";
import {CertificationsList} from "@fik/components";

const Certifications = () => {
    const {pagination, items, handlePageChange, refresh} = useDataSourceWithQueryParams(
        {name: 'resellerProfile', initialSearchParams: {}, dataSourceResource: 'certification-data-source'}
    );

    return (
        <Box>
            <CertificationsList
                items={items}
                pagination={pagination}
                onPageChange={handlePageChange}/>
        </Box>
    );
};

export default Certifications;