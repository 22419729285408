import React, {useCallback, useEffect, useMemo, useState} from "react";
import handleClick from "@fik/utils/handleClick";
import {Box} from "@fik/ui";
import {Form, FormField} from "@mouseover/react-form";
import {useDocumentContext} from "@fik/document-edit";

const validationRules = {
    contactFirstname: {
        required: true
    },
    contactSurname: {
        required: true
    },
    contactPosition: {
        required: true
    },
    contactEmail: {
        required: true,
        email: true
    },
    contactPhone: {
        required: false,
        pattern: {
            message: 'Špatný formát telefonu (+420...)',
            params: '^(\\+420)[0-9]{9}$'
        }
    }
};
const DealBusinessStatus = () => {
    const {
        document,
        initialDocument,
        changeValues,
    } = useDocumentContext();


    return document ? <Box title="Stav"
                tools={() => <>
                    <div>{document.businessStatus}</div>
                </>} /> : null;
}

export default DealBusinessStatus;