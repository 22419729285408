import {useCallback, useReducer} from 'react';
import {createActionNames, createRequestState} from "@fik/api";
import {useModel, promiseWrapper} from "@fik/model";
import {useHookToLoading} from "@fik/loading";

const saveActions = createActionNames('save');

const initialState = {
    result: null,
    documentType: null,
    isFetching: false,
    isSuccess: false,
    isFailed: false,
};

const saveReducer = (state, action) => {
    switch (action.type) {
        case saveActions.FETCH_INIT:
            return {
                ...state,
                result: null,
                documentType: action.documentType,
                ...createRequestState(saveActions, action.type)

            };

        case saveActions.FETCH_SUCCESS:
            return {
                ...state,
                result: {
                    ...action.payload,
                },
                ...createRequestState(saveActions, action.type)

            };

        case saveActions.FETCH_FAILURE:
            return {
                ...state,
                result: {
                    ...action.payload,
                },
                ...createRequestState(saveActions, action.type)
            };

        default:
            return state;
    }
};

const createDocumentSave = (documentModel) => async (document, documentType, dispatch) => {
    dispatch({type: saveActions.FETCH_INIT, document, documentType});
    const {isSuccess, data} = await promiseWrapper(documentModel.save, documentType, document);

    if (isSuccess) {
        dispatch({type: saveActions.FETCH_SUCCESS, payload: data, document});
    } else {
        dispatch({type: saveActions.FETCH_FAILURE, payload: data, document});
    }
};

export const useDocumentSave = () => {
    const save = useModel(
        (model) => createDocumentSave(model.document()),
        []
    );

    const [state, dispatch] = useReducer(saveReducer, initialState);

    const saveDocument = useCallback((documentType, document) => {
        save(document, documentType, dispatch);
    }, [save, dispatch]);

    useHookToLoading(`${state.documentType}-save`, state);

    return {
        isSaving: state.isFetching,
        isSaved: state.isSuccess,
        isFailed: state.isFailed,
        documentType: state.documentType,
        messages: state.result && state.result.messages ? state.result.messages : [],
        result: state.result,
        saveDocument
    }
};

export default useDocumentSave;