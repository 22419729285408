import React from 'react';
import {FormField} from "@mouseover/react-form";
import PropTypes from "prop-types";

const ContactFields = ({form}) => (<>
    <FormField form={form}
               name='contactFirstname'
               label='Jméno'
               elementType='input'
    />
    <FormField form={form}
               name='contactSurname'
               label='Příjmení'
               elementType='input'
    />
    <FormField form={form}
               name='contactEmail'
               label='Email'
               elementType='input'
    />
    <FormField form={form}
               name='contactPhone'
               label='Telefon'
               elementType='input'
    />
</>);

ContactFields.propTypes = {
    form: PropTypes.object
};

export default ContactFields;