import {usePureEntity} from "@fik/entity";
import React, {useEffect, useMemo} from 'react';
import {NavigationContext} from "@fik/navigation";
import useAuth from "@fik/auth/useAuth";

const ResellerzoneNavigationProvider = ({navigation, menu, ...props}) => {
    const {isAuthenticated} = useAuth()
    const [{entity: resellerProfile, loading}, load] = usePureEntity({name: 'profile'})

    useEffect(() => {
        if (isAuthenticated ) {
            load();
        }
    }, [isAuthenticated, load]);

    const currentMenu = useMemo(() => menu(resellerProfile), [menu, resellerProfile]);

    return <NavigationContext.Provider value={{navigation, menu: currentMenu}} {...props} />;
};

export default ResellerzoneNavigationProvider;