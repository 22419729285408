import {useEffect, useRef} from "react";

const useRefOn = (value) => {
    const ref = useRef(value);
    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref;
};

export default useRefOn;