import {isString} from "@mouseover/js-utils";

export const stringifyObjectKeyAndValues = (obj) => {
    if (isString(obj)) {
        return obj;
    }
    return Object.entries(obj).sort().reduce(
        (result, [key, value]) => result.concat(key, '_', value, '_'),
        '_'
    );
};

export default stringifyObjectKeyAndValues;
