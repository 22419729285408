import React, {useCallback, useEffect, useRef, useState} from "react";
import handleClick from "@fik/utils/handleClick";
import useDropdown from "@fik/ui/DropDown/useDropDown";

const CompaniesDropDown = ({items, onSelect, onClose}) => {
    const {element, isOpen, setIsOpen, toggle} = useDropdown();
    const [canBeClosed, setCanBeClosed] = useState();

    useEffect(() => {
        setIsOpen((items && items.length > 0) || false);
        setCanBeClosed(true);
    }, [items, setCanBeClosed]);

    useEffect(()=> {
        console.log('[isOpen]', isOpen);
        if (onClose && !isOpen && canBeClosed) {
            onClose();
        }
    }, [onClose, isOpen, canBeClosed]);

    const handleSelect = useCallback((item) => {
        setIsOpen(false);
        if (onSelect) {
            onSelect(item);
        }
    }, [onSelect, setIsOpen]);

    return isOpen && items ? <ul ref={element} className='dropdown-menu open' style={{zIndex: 9999991}} role="menu">
        {items.map((item, index) => {
            const {name, city, companyId} = item;
            return <li key={index}>
                <a href="#" onClick={handleClick(() => handleSelect(item))}>{name}, {companyId}, {city}</a>
            </li>;
        })}
    </ul> : null;
}

export default CompaniesDropDown;