import React, {useCallback, useMemo, useState} from "react";
import {useDocumentContext} from "@fik/document-edit";
import {Form, useFormContainer} from "@mouseover/react-form";
import {TextArea} from "@fik/form";
import {getValue, shallowEqual} from "@mouseover/js-utils";
import arrayRemoveAny from "@fik/utils/arrayRemoveAny";

const MultiChoice = ({name, label, choices, form}) => {
    const {values = {}, validation = {}, setValue} = form;
    const checked = getValue(values, name) || [];
    const [touched, setTouched] = useState(false);
    const messages = getValue(validation, ['children', name, 'messages']);
    const handleChange = (choice) => {
        if (!touched) {
            setTouched(true);
        }

        if (checked.indexOf(choice) === -1) {
            setValue([...checked, choice], name);
        } else {
            const newValue = arrayRemoveAny(checked, choice);
            setValue(newValue.length === 0 ? null : newValue, name);
        }
    }

    return <>
        <label>{label}</label>
        {touched && messages && messages.map((message) => <p className='alert-warning'>{message}</p>)}
        {choices.map((choice) => <div key={choice} className='form-group' key={choice}><label className="btn btn-sm no-padding text-normal"> <input
            type="checkbox" onChange={() => handleChange(choice)} checked={checked.indexOf(choice) !== -1}/> {choice}</label></div>)}
    </>
}

const SingleChoice = ({name, label, choices, form}) => {
    const {values = {}, validation = {}, setValue} = form;
    const checked = getValue(values, name);
    const [touched, setTouched] = useState(false);
    const messages = getValue(validation, ['children', name, 'messages']);
    const handleChange = (choice) => {
        if (!touched) {
            setTouched(true);
        }

        if (checked !== choice) {
            setValue(choice, name);
        } else {
            setValue(null, name);
        }
    }

    return <>
        <label>{label}</label>
        {touched && messages && messages.map((message) => <p key={message} className='alert-warning'>{message}</p>)}
        {choices.map((choice) => <div key={choice} className='form-group' key={choice}><label className="btn btn-sm no-padding text-normal"> <input
            type="radio" onChange={() => handleChange(choice)} checked={checked === choice}/> {choice}</label></div>)}
    </>
}

const RenewElements = ({elements, form}) => {
    const formContainer = useFormContainer('renewData', form);
    return <div className="row">
        {elements && elements.map(({name, label, placeholder, type, choices}) =>
            <div key={name} className="col-sm-12">
                {type === 'text' && <TextArea
                    label={label} name={name} form={formContainer}
                    elementConfig={{placeholder: placeholder ?? ''}}
                />}
                {type === 'multiChoice' && <MultiChoice name={name} label={label} choices={choices} form={formContainer}/>}
                {type === 'singleChoice' && <SingleChoice name={name} label={label} choices={choices} form={formContainer}/>}
            </div>)}
    </div>
}

const DealFormDetailRenew = ({validation, isSubmitted, elements}) => {
    const {
        document,
        initialDocument: {renewData = {}},
        changeValues,
    } = useDocumentContext();

    const handleValuesChanges = useCallback((values, valid) => {
        const {note} = document;
        if (!shallowEqual({renewData}, values)) {
            changeValues(values);
        }
    }, [document, changeValues]);

    const validationRules = useMemo(() => ({
        renewData: {
            children: elements?.reduce((acc, {name}) => ({...acc, [name]: {required: true}}), {}) ?? {}
        }
    }), [validation, elements]);

    return document
        ? <Form
            values={{renewData}}
            validationRules={validationRules}
            onChange={handleValuesChanges}
            isSubmitted={isSubmitted}
            render={(form) => <RenewElements elements={elements} form={form}/>}/>
        : null;
};

export default DealFormDetailRenew;