import {useEffect, useReducer, useCallback} from 'react';
import {createActionNames, createRequestState} from "@fik/api";
import {useModel} from "@fik/model";
import {useHookToLoading} from "@fik/loading";

const validateActions = createActionNames('validate');

const createValidate = (documentModel, documentType) => async (document, dispatch) => {
    dispatch({type: validateActions.FETCH_INIT, document});
    try {
        const payload = await documentModel.validate(documentType, document);
        dispatch({type: validateActions.FETCH_SUCCESS, payload, document});
    } catch (error) {
        dispatch({type: validateActions.FETCH_FAILURE, document});
    }
};

const filterAvailableButtons = (documentTypes, buttons) => {
    return buttons.reduce((available, button) => {
        if (documentTypes.indexOf(button.documentType) >= 0) {
            available.push(button);
        }
        return available;
    }, []);
};

const buttons = [
    {
        documentType: 'order',
        label: 'Objednat'
    },
    {
        documentType: 'offer',
        label: 'Nabídnout'
    },
    {
        documentType: 'projectPrice',
        label: 'Zažádat o projektovou cenu'
    }
];

const initialState = {
    document: null,
    availableButtons: [],
    result: {
        valid: false,
        messages: [],
        possibleDocumentTypes: [],
        isLimitedByCredit: false,
        projectPrice: false,
        resellerDocument: true,
    },
    isFetching: false,
    isSuccess: false,
    isFailed: false,
};

const validationReducer = (state, action) => {
    switch (action.type) {
        case validateActions.FETCH_INIT:
            return {
                ...initialState,
                document: action.document,
                ...createRequestState(validateActions, action.type)
            };

        case validateActions.FETCH_SUCCESS:
            return {
                ...state,
                result: {
                    ...action.payload,
                },
                ...createRequestState(validateActions, action.type),
                availableButtons: filterAvailableButtons(action.payload.possibleDocumentTypes, buttons)
            };

        case validateActions.FETCH_FAILURE:
            return {
                ...state,
                ...createRequestState(validateActions, action.type),
                availableButtons: []
            };
        default:
            return state;
    }
};

const useDocumentValidator = ({document, shouldValidate, documentType}) => {

    const validate = useModel(
        (model) =>  createValidate(model.document(), documentType),
        []
    );

    const [state, dispatch] = useReducer(validationReducer, initialState);

    useHookToLoading('document-validate', state);

    const validateDocument = useCallback((document) => {
        validate({
            ...document,
            // je nutne odstranit obsah priloh - tzn neposilat je k validaci s kazdou zmenou (maji az 2mb povolene)
            attachments: document.attachments.map(attachment => ({...attachment, attachmentContent: null}))
        }, dispatch);
    }, [validate, dispatch]);

    useEffect(() => {
        if (shouldValidate) {
            validateDocument(document);
        }
    }, [document, shouldValidate, validateDocument]);

    return {
        validateDocument,
        availableButtons: state.availableButtons,
        valid: state.result.valid,
        validationMessages: state.result.messages || [],
    }
};

export default useDocumentValidator;
