import {useCallback} from "react";
import {getFailed, getLoaded, getLoading, getRefreshing, getResults} from "@fik/api/selectors";
import {useResourceSelectorWithParams} from "@fik/api";

const useResourceEntitySelector = (context, params, method, deps) => {
    const selectorCallback = useCallback((state) => {
        const entity = getResults(state);
        return {
            loadingState: {
                isFetching: getLoading(state),
                isSuccess: getLoaded(state),
                isFailed: getFailed(state),
                isRefreshing: getRefreshing(state),
            },
            loading: getLoading(state),
            [context.name]: entity,
            entity
        };
    }, [context]);

    return useResourceSelectorWithParams(context, params, selectorCallback, method);
};

export default useResourceEntitySelector;