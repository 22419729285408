import React from 'react';
import PropTypes from "prop-types";

const Button = (props) => {
    const classNames = ['btn-' + props.btnType, 'btn'];
    if (props.className) {
        classNames.push(props.className);
    }
    return <button
            disabled={props.disabled}
            className={classNames.join(' ')}
            onClick={props.clicked || props.onClick}
        >{props.children}</button>;
};

Button.propTypes = {
    className: PropTypes.string,
    btnType: PropTypes.string.isRequired,
    clicked: PropTypes.func
};

export default Button;