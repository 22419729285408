import React, {useCallback, useEffect} from "react";
import PropTypes from 'prop-types';
import {usePureEntity} from "@fik/entity";
import ChannelPickList from "./ChannelPickList";

export const ChannelPicker = ({entityContext, onPick, params}) => {

    const [{entity: items}, load] = usePureEntity(entityContext);

    const handlePick = useCallback((values) => {
        onPick(values);
    }, [onPick]);

    useEffect(() => {
        load(params);
    }, [params, load]);

    return <>
        {items ? <ChannelPickList items={items} onPick={handlePick}/> : null}
    </>;
};

ChannelPicker.propTypes = {
    onPick: PropTypes.func,
    params: PropTypes.shape({
        pattern: PropTypes.string
    })
};

export default ChannelPicker;