import React from 'react';
import PropTypes from 'prop-types';
import {Table, TableRow} from "@fik/table";
import EvidenceItemName from "./EvidenceItemName";
import EvidenceItemAmount from "./EvidenceItemAmount";
import EvidenceItemDiscount from "./EvidenceItemDiscount";
import EvidenceItemBonusCode from "./EvidenceItemBonusCode";
import EvidenceItemPromoWithName from "./EvidenceItemPromoWithName";
import EvidenceItemPrice from "./EvidenceItemPrice";

const EvidenceItems = (props) => {

    const visibleDiscounts = props.visibleDiscounts || true;
    const visiblePrices = props.visiblePrices || true;
    const ownerPrice = props.ownerPrice || true;
    const partnerPrice = props.partnerPrice || true;
    const epcPrice = props.epcPrice || false;

    return <Table responsive
                  containerClassName="m-t"
                  rows={props.evidenceItems}
                  renderRow={props.renderRow}
                  renderRowMessages={props.renderRowMessages}
                  primary='licenseId'>
        <EvidenceItemName header='Položka'/>
        <EvidenceItemAmount header='Počet'/>
        {visibleDiscounts && <EvidenceItemDiscount header='Sleva'/>}
        {visibleDiscounts && <EvidenceItemBonusCode header='Bonus kód'/>}
        {visibleDiscounts && <EvidenceItemPromoWithName header='Promo'/>}
        {visiblePrices && ownerPrice &&
        <EvidenceItemPrice header={() => <>Doporučená <abbr title="Doporučená koncová cena">EUP</abbr> bez DPH</>} channel='owner'/>}
        {visiblePrices && partnerPrice && <EvidenceItemPrice header='Cena partner' channel='partner'/>}
        {visiblePrices && epcPrice && <EvidenceItemPrice header='Cena EPC' channel='epc'/>}
        {props.itemMenu && <TableRow className='text-right' render={props.itemMenu}/>}
    </Table>;
};


EvidenceItems.propTypes = {
    visibleDiscounts: PropTypes.bool,
    visiblePrices: PropTypes.bool,
    ownerPrice: PropTypes.bool,
    partnerPrice: PropTypes.bool,
    epcPrice: PropTypes.bool,
    evidenceItems: PropTypes.array,
    itemMenu: PropTypes.func,
    renderRow: PropTypes.func,
    renderRowMessages: PropTypes.func,
};


export default EvidenceItems;