import React, {useState} from 'react';
import handleClick from "@fik/utils/handleClick";
import FileBox from "./FileBox";
import PropTypes from 'prop-types';

const FileManager = ({filesTree}) => {
    const [currentFolder, setCurrentFolder] = useState(filesTree.length > 0 ? filesTree[0] : 0);
    return <div className="row">
        <div className="col-lg-3">
            <div className="file-manager">
                <ul className="folder-list" style={{padding: 0}}>
                    {filesTree.map((folder) => <li key={folder.name}
                                                   className={(currentFolder && folder.name === currentFolder.name ? "active" : "")}>
                            <a href={"#" + folder.name} onClick={handleClick(() => setCurrentFolder(folder))}><i
                                className="fa fa-folder"/> {folder.title}</a>
                        </li>
                    )}
                </ul>
                <div className="clearfix"/>
            </div>
        </div>
        <div className="col-lg-9">
            <div className="row">
                <div className="col-lg-12">
                    {currentFolder ? currentFolder.files.map((file) => <FileBox key={file.file} file={file} folder={currentFolder} />) : null}
                </div>
            </div>
        </div>
    </div>
};

FileManager.propTypes = {
    filesTree: PropTypes.array.isRequired
};

export default FileManager;