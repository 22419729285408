import React, {useMemo} from 'react';
import {Paginator} from "@fik/ui";
import {Box} from "@fik/ui";
import {OffersTable, OffersFilter} from "@fik/offer";
import {useDials} from "@fik/dials";
import {useDataSource, useDataSourceWithQueryParams} from "@fik/data-source";
import arrayToDictionary from "@fik/utils/arrayToDictionary";
import useIsEpc from "../../useIsEpc";

const DEFAULT_PARAMS = {
    fulltext: null,
    state: null,
    type: 'all'
};

const Offers = () => {

    const {types, states} = useDials({name: 'offer'});

    const {pagination, items, setSearchParams, handlePageChange, queryParams, refresh, handleSearch} = useDataSourceWithQueryParams(
        {
            name: 'offer',
            defaultParams: DEFAULT_PARAMS
        }
    );

    const submitSearchHandler = (values, valid) => {
        if (valid) {
            values.type = 'all';
            setSearchParams(values);
        }
    };

    return (
        <Box>
            <OffersFilter onSubmitSearch={handleSearch}
                          states={states}
            />

            <OffersTable striped responsive
                         partnerVisible={useIsEpc()}
                         statesDict={arrayToDictionary(states, 'id', 'label')}
                         typesDict={arrayToDictionary(types, 'id', 'label')}
                         rows={items}
            />

            <Paginator {...pagination}
                       onPageChange={handlePageChange}/>

        </Box>
    );
};

export default Offers;