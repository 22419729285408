import React, {useMemo} from 'react';
import {NavigationItem} from "@fik/navigation";
import {Col, Row} from "@fik/ui";


const Salesman = ({salesman: data, path, moreLink = true}) => {

    const imagePath = useMemo(() => data ? (path === "/" ? "" : path) + "/img/profiles/" + data.staffProfileImage : null, [path, data]);

    if (!data) {
        return null;
    }

    return (
        <div className="contact-box no-borders" style={{minHeight: '200px'}}>
            <Row>
                <Col sm={4} className="no-padding no-margins no-padding">
                    <div className="text-center">
                        <img className="img-thumbnail m-t-xs img-responsive" src={imagePath}
                             alt="obrázek obchodníka"/>
                    </div>
                </Col>
                <Col sm={8}>
                    <strong className="h4">{data.staffFullName}</strong>
                    <address>
                        <strong>{data.staffCompanyName}</strong><br/>
                        {data.staffStreet}<br/>
                        {data.staffZipCode} {data.staffCity}<br/>
                        <strong>Mobil/Tel:</strong> {data.staffMobile}<br/>
                        <strong>E-mail:</strong> <a href={'mailto:' + data.staffEmail}>{data.staffEmail}</a>
                    </address>

                    {moreLink
                        ? <div className="text-right">
                            <NavigationItem
                                className="btn btn-sm btn-primary"
                                to="contacts">Další kontakty</NavigationItem>
                        </div>
                        : null}
                </Col>
            </Row>
        </div>
    );
};

export default Salesman;