import React from "react";
import PropTypes from "prop-types";
import {NavigationItem} from "@fik/navigation";
import {DateTime} from "@fik/ui";

const ArticlesList = ({articles}) => {
    return articles.map(({id, author, title, short, date, isExternalLink, link}) => <div key={'article_' + id} className="chat-element">
        <div className="media-body">
            <small className="pull-right text-navy feed-author">{author}</small>
            {isExternalLink ? <a href={link} target="_blank" rel="noopener noreferrer">
                <strong>{title}</strong>
            </a> : <NavigationItem to="article:detail" params={{id}}><strong>{title}</strong></NavigationItem>}

            <p className="m-b-xs">{short.replace(/(<([^>]+)>)/ig).replace(/\&nbsp;/g, '')}</p>
            <small className="text-muted"><DateTime date={date}/></small>
            <hr/>
        </div>
    </div>)
};

ArticlesList.propTypes = {
    articles: PropTypes.array
};

export default ArticlesList;
